import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useCreateBatchOrders } from 'api/batch/create-orders';
import { GET_BALANCE_QUERY } from 'api/meters';
import { GET_ORDERS_QUERY, GetOrdersResponse } from 'api/orders/get';
import { CarrierRate } from 'components/carrier-rate';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import startCase from 'lodash/startCase';
import { FC } from 'react';
import { calculateInsurance } from 'utils/misc';

type Order = ArrayElement<GetOrdersResponse['results']>;

interface BuyShipmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  order: Order;
  onSuccess?: () => void;
}

export const BuyShipmentModal: FC<BuyShipmentModalProps> = (props) => {
  const { isOpen, onClose, order, onSuccess } = props;
  const { notifySuccess, notifyError } = useNotification();

  const createBatchOrder = useCreateBatchOrders();

  const onBuyLabel = () => {
    const onError = onClose;
    const handleSuccess = (response: any) => {
      if (response?.status?.at(0)?.status === 'error') {
        onClose();
        notifyError(response?.status?.at(0)?.message);
      } else {
        queryClient.invalidateQueries(GET_BALANCE_QUERY);
        queryClient.invalidateQueries(GET_ORDERS_QUERY);
        onClose();
        notifySuccess('Label purchased successfully');
        onSuccess?.();
      }
    };

    createBatchOrder.mutate({ orderIds: [order.id] }, { onSuccess: handleSuccess, onError });
  };

  const selectedRate = order?.rates?.find((r) => r.id === order.selectedRate);

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={createBatchOrder.reset}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Buy Label</ModalHeader>
        <ModalBody>
          <Text>
            You are buying a label for order #{order.platformId}. Purchasing the label will fulfill the order on{' '}
            {startCase(order.platform)}.
          </Text>
          <Stack spacing="2" my="4">
            <Text fontWeight="medium">Summary</Text>
            <Stack divider={<hr />} rounded="md" border="1px" borderColor="gray.300" bg="gray.50">
              <Flex justify="space-between" p="3">
                <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                  Dimensions
                </Text>
                <Text color="muted">
                  {[order.parcel?.length, order.parcel?.width, order.parcel?.height].filter(Boolean).join(' x ')}
                </Text>
              </Flex>

              <Flex justify="space-between" p="3">
                <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                  Weight
                </Text>
                <Text color="muted">{order.parcel?.weight || order.totalWeightOz} oz.</Text>
              </Flex>

              <Flex justify="space-between" p="3">
                <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                  Recipient Address
                </Text>
                <Text color="muted" maxW="50%" textAlign="end">
                  {[
                    order.shippingAddress?.line1,
                    order.shippingAddress?.line2,
                    order.shippingAddress?.city,
                    order.shippingAddress?.postal,
                    order.shippingAddress?.country,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </Text>
              </Flex>
            </Stack>
          </Stack>
          <Stack spacing="2" my="4">
            <Text fontWeight="medium">Selected Rate</Text>

            <CarrierRate
              carrier={selectedRate?.carrier || ''}
              price={Number(selectedRate?.price).toFixed(2)}
              service={selectedRate?.name || ''}
              details={selectedRate?.details || []}
            />

            {order.insuranceCents && (
              <Text color="muted">+ ${calculateInsurance(order.insuranceCents).toFixed(2)} insurance</Text>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button type="button" disabled={createBatchOrder.isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button ml="2" type="button" colorScheme="brand" onClick={onBuyLabel} isLoading={createBatchOrder.isLoading}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
