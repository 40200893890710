import { Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { PageBody, PageHeader } from 'components/Page';
import { AccountSettings } from './_components/account-settings';
import { CarrierAccountSettings } from './_components/carrier-account-settings';
import { DeveloperSettings } from './_components/developer-settings';
import { LoginSettings } from './_components/login-settings';
import { PackingSlipsSettings } from './_components/packing-slip-settings';
import { StoreSettings } from './_components/store-settings';

export default function () {
  return (
    <>
      <PageHeader>
        <Heading as="h1">Settings</Heading>
      </PageHeader>
      <PageBody>
        <Tabs colorScheme="brand" isLazy>
          <TabList>
            <Tab>Account</Tab>
            <Tab>Stores</Tab>
            <Tab>Carrier Accounts</Tab>
            <Tab>Packing Slips</Tab>
            <Tab>Dashboard Logins</Tab>
            <Tab>Developer Settings</Tab>
          </TabList>

          <TabPanels>
            <TabPanel px="0">
              <AccountSettings />
            </TabPanel>
            <TabPanel px="0">
              <StoreSettings />
            </TabPanel>
            <TabPanel px="0">
              <CarrierAccountSettings />
            </TabPanel>
            <TabPanel px="0">
              <PackingSlipsSettings />
            </TabPanel>
            <TabPanel px="0">
              <LoginSettings />
            </TabPanel>
            <TabPanel px="0">
              <DeveloperSettings />
            </TabPanel>
            <TabPanel px="0">
              <DeveloperSettings />
            </TabPanel>
            <TabPanel px="0">
              <DeveloperSettings />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageBody>
    </>
  );
}
