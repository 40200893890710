import {
  Button,
  Card,
  CardBody,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Input,
  ModalProps,
  Select,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GetOrderReponse, useGetOrder, useSplitOrder, useUpdateLineItems } from 'api/orders';
import { useGetProducts } from 'api/products/get';
import { useNotification } from 'contexts/notification.context';
import { useState } from 'react';
import { Control, UseFormRegister, useFieldArray, useForm } from 'react-hook-form';
import {
  LuCheck,
  LuChevronLeft,
  LuPenSquare,
  LuPencil,
  LuPlusCircle,
  LuSplitSquareHorizontal,
  LuTrash,
  LuTrash2,
  LuX,
} from 'react-icons/lu';
import { z } from 'zod';

interface Props extends Omit<ModalProps, 'children'> {
  orderId: number;
}

const editSchema = z.object({
  lineItems: z.array(
    z.object({
      lineItemId: z.coerce.number().nullable(),
      price: z.coerce.number(),
      quantity: z.coerce.number(),
      productId: z.coerce.number(),
    }),
  ),
});

const splitSchema = z.object({
  orders: z.array(
    z.object({
      platformId: z.string(),
      lineItems: z.array(
        z.object({
          lineItemId: z.coerce.number().nullable(),
          productId: z.coerce.number(),
          quantity: z.coerce.number(),
          price: z.coerce.number(),
        }),
      ),
    }),
  ),
});

const SplitOrderForm = (props: {
  parentIndex: number;
  control: Control<z.infer<typeof splitSchema>>;
  register: UseFormRegister<z.infer<typeof splitSchema>>;
  lineItems: GetOrderReponse['lineItems'];
  irregularItems: Array<{ lineItemId: number; diff: string }>;
  platformId: string;
  setPlatformId: (id: string) => void;
  onRemove: () => void;
}) => {
  const { parentIndex, control, register, lineItems, irregularItems, onRemove, platformId, setPlatformId } =
    props;

  const { fields } = useFieldArray({ control, name: `orders.${parentIndex}.lineItems` });
  const [isEditing, setIsEditing] = useState(false);
  const [pid, setPid] = useState(platformId);

  return (
    <Stack>
      <HStack gap={1}>
        {!isEditing && (
          <>
            <Text py={1} fontSize="md" fontWeight="medium">
              {parentIndex + 1}. Order {platformId} {parentIndex === 0 ? '(original)' : ''}{' '}
            </Text>
            <Button
              ml={1}
              size="xs"
              variant="outline"
              hidden={parentIndex === 0}
              onClick={() => setIsEditing(!isEditing)}
            >
              <LuPenSquare />
            </Button>
            <Button
              size="xs"
              color="red.500"
              variant="outline"
              onClick={onRemove}
              borderColor="red.200"
              hidden={parentIndex === 0}
            >
              <LuTrash />
            </Button>
          </>
        )}

        {isEditing && (
          <>
            <Input size="sm" maxW="44" value={pid || ''} onChange={(e) => setPid(e.target.value)} />
            <Button
              ml={1}
              color="green.500"
              borderColor="green.400"
              size="xs"
              variant="outline"
              hidden={parentIndex === 0}
              onClick={() => {
                setPlatformId(pid);
                setIsEditing(!isEditing);
              }}
            >
              <LuCheck />
            </Button>
            <Button
              size="xs"
              color="red.500"
              variant="outline"
              borderColor="red.200"
              onClick={() => {
                setPlatformId(platformId);
                setIsEditing(!isEditing);
              }}
            >
              <LuX />
            </Button>
          </>
        )}
      </HStack>
      <Card minW="xs" minH="52">
        <CardBody p={2}>
          <Table size="sm">
            <Tbody>
              {fields?.map((li: any, index) => {
                const productName = lineItems?.find((l) => l.id === li.lineItemId)?.product?.name;
                const productSku = lineItems?.find((l) => l.id === li.lineItemId)?.product?.sku;
                const irregular = irregularItems.find((item) => item.lineItemId === li.lineItemId);

                return (
                  <Tr key={index} bg={irregular && 'yellow.100'}>
                    <Td display="flex" alignItems={'center'}>
                      <Stack gap={0} maxW="48">
                        <Text isTruncated>{productName}</Text>
                        <Text isTruncated mt={1} color="zinc.500">
                          {productSku}
                        </Text>
                      </Stack>
                    </Td>
                    <Td>
                      <Input
                        ml="auto"
                        w="24"
                        type="number"
                        size="sm"
                        {...register(`orders.${parentIndex}.lineItems.${index}.quantity`)}
                      />
                    </Td>
                    <Td maxW="24">
                      {irregular?.diff && (
                        <Text minW="8" color="red.500">
                          {irregular?.diff || ''}
                        </Text>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Stack>
  );
};

export const EditLineItemsModal = (props: Props) => {
  const { isOpen, onClose, orderId } = props;

  const order = useGetOrder({ id: orderId });
  const products = useGetProducts({ per: 99999 });
  const editLineItems = useUpdateLineItems();
  const splitOrder = useSplitOrder();

  const notify = useNotification();

  const editForm = useForm<z.infer<typeof editSchema>>({
    resolver: zodResolver(editSchema),
    values: {
      lineItems:
        order.data?.lineItems?.map((li) => ({
          lineItemId: li.id,
          price: Number(li?.priceCents || li?.product?.priceCents) / 100,
          productId: li?.product?.id,
          quantity: li?.quantity,
        })) || [],
    },
  });

  const splitForm = useForm<z.infer<typeof splitSchema>>({
    resolver: zodResolver(splitSchema),
    values: {
      orders: [
        {
          platformId: order?.data?.platformId!,
          lineItems:
            order?.data?.lineItems?.map((li) => ({
              lineItemId: li.id,
              productId: li.product?.id,
              quantity: li.quantity,
              price: Number(li?.priceCents || li?.product?.priceCents) / 100,
            })) || [],
        },
      ],
    },
  });

  const editFields = useFieldArray({ control: editForm.control, name: 'lineItems' });
  const splitFields = useFieldArray({ control: splitForm.control, name: 'orders' });

  const [mode, setMode] = useState<'split' | 'edit' | null>(null);

  const isEdit = mode === 'edit';
  const isSplit = mode === 'split';

  const irregularItems: Array<{ lineItemId: number; diff: string }> = [];

  const splitOrders = splitForm.watch('orders');
  for (const li of order.data?.lineItems || []) {
    const totalQuantity = splitOrders.reduce((acc, o) => {
      const item = o.lineItems.find((l) => l.lineItemId === li.id) || { quantity: 0 };
      return acc + Number(item?.quantity);
    }, 0);

    if (totalQuantity !== li.quantity) {
      const diff = totalQuantity - li.quantity;
      irregularItems.push({
        lineItemId: li.id,
        diff: diff > 0 ? `+${diff}` : `${diff}`,
      });
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent overflow="auto">
        <DrawerHeader>
          <Button variant="link" w="fit-content" colorScheme="brand" fontWeight="normal" onClick={onClose}>
            <LuChevronLeft />
            Back
          </Button>
          <HStack justifyContent="space-between" px={2}>
            <Stack gap={1}>
              <span>Line Items</span>
            </Stack>

            <HStack justifyContent="end">
              <Button
                size="sm"
                leftIcon={<LuPencil />}
                onClick={() => setMode('edit')}
                variant={isEdit ? 'solid' : 'outline'}
              >
                Edit
              </Button>
              <Button
                size="sm"
                onClick={() => setMode('split')}
                variant={isSplit ? 'solid' : 'outline'}
                isDisabled={editForm.formState.isDirty}
                leftIcon={<LuSplitSquareHorizontal />}
              >
                Split
              </Button>
            </HStack>
          </HStack>
        </DrawerHeader>

        <DrawerBody>
          {/* Split */}
          <form
            id="split-form"
            onSubmit={splitForm.handleSubmit((values) => {
              splitOrder.mutate(
                { ...values, id: orderId },
                {
                  onSuccess: () => {
                    notify.success('Order split successfully');
                  },
                },
              );
            })}
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} hidden={!isSplit} gap={4} py="4">
              {splitFields.fields.map((o, index) => {
                return (
                  <Stack key={o.id}>
                    <SplitOrderForm
                      setPlatformId={(id) => splitFields.update(index, { ...o, platformId: id })}
                      platformId={o.platformId}
                      onRemove={() => splitFields.remove(index)}
                      irregularItems={irregularItems}
                      register={splitForm.register}
                      control={splitForm.control}
                      parentIndex={index}
                      lineItems={order.data?.lineItems || []}
                    />
                  </Stack>
                );
              })}

              <Flex>
                <chakra.button
                  type="button"
                  h={52}
                  mt="auto"
                  w="full"
                  gap="2"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="1px dashed"
                  fontSize="sm"
                  _hover={{ bg: 'zinc.50' }}
                  rounded="md"
                  borderColor="zinc.300"
                  onClick={() =>
                    splitFields.append({
                      platformId: `${order?.data!.platformId}-${splitFields.fields.length + 1}`,
                      // Line items from the original order are
                      // duplicated and set to 0 quantity
                      lineItems: splitFields?.fields?.[0].lineItems?.map((li) => ({
                        ...li,
                        quantity: 0,
                      })),
                    })
                  }
                >
                  Add <LuPlusCircle />
                </chakra.button>
              </Flex>
            </SimpleGrid>
          </form>

          {/* Edit */}
          <form
            id="edit-form"
            onSubmit={editForm.handleSubmit((values) => {
              editLineItems.mutate(
                { id: orderId, ...values },
                {
                  onSuccess: () => {
                    order.refetch();
                    setMode(null);
                  },
                },
              );
            })}
          >
            <Table mt="5" hidden={isSplit} size="sm">
              <Thead>
                <Tr>
                  <Th>Item</Th>
                  <Th>Quantity</Th>
                  <Th>Price</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {editFields.fields.map((li, index) => {
                  const productName = products.data?.results?.find((p) => p?.id === li.productId)?.name;
                  const productSku = products.data?.results?.find((p) => p?.id === li.productId)?.sku;

                  const price = editForm.getValues().lineItems[index].price;
                  const quantity = editForm.getValues().lineItems[index].quantity;

                  const isNew = li.lineItemId === null;

                  return (
                    <Tr role="group" key={index}>
                      <Td>
                        {!!li.lineItemId && (
                          <>
                            <Text>{productName}</Text>
                            <Text color="zinc.500">{productSku}</Text>
                          </>
                        )}

                        {!li.lineItemId && (
                          <Select size="sm" maxW="52" {...editForm.register(`lineItems.${index}.productId`)}>
                            {products.data?.results?.map((product, i) => (
                              <option key={i} value={product?.id}>
                                {product?.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          size="sm"
                          maxW="52"
                          hidden={!isNew && !isEdit}
                          {...editForm.register(`lineItems.${index}.quantity`)}
                        />
                        <Text hidden={isNew || isEdit}>{quantity}</Text>
                      </Td>
                      <Td>
                        <Text hidden={isNew || isEdit}>{price}</Text>
                        <Input
                          size="sm"
                          maxW="52"
                          type="number"
                          hidden={!isNew && !isEdit}
                          {...editForm.register(`lineItems.${index}.price`)}
                        />
                      </Td>
                      <Td>
                        <Button
                          size="xs"
                          color="red.500"
                          variant="outline"
                          hidden={!isEdit}
                          borderColor="red.200"
                          onClick={() => editFields.remove(index)}
                        >
                          <LuTrash2 />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </form>
          <Flex p="4" hidden={!isEdit}>
            <HStack mx="auto">
              <Button
                variant="ghost"
                leftIcon={<LuPlusCircle />}
                onClick={() =>
                  editFields.append({
                    lineItemId: null,
                    quantity: 0,
                    price: 0,
                    // @ts-ignore
                    productId: products.data?.results?.[0]?.id,
                  })
                }
              >
                Add item
              </Button>
            </HStack>
          </Flex>
        </DrawerBody>
        <Divider />
        <DrawerFooter>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            ml="2"
            type="submit"
            colorScheme="brand"
            form={mode === 'edit' || mode === null ? 'edit-form' : 'split-form'}
          >
            Confirm
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
