import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { AddStripePaymentMethod } from 'pages/(app)/billing/_components/add-stripe-payment-method';
import { useUserStore } from 'store';

export function OIDCBanner() {
  const oidcClient = useUserStore((state) => state.oidcClient);
  const patch = useUserStore((state) => state.patch);

  if (!oidcClient) return null;

  return (
    <>
      <HStack
        pos="absolute"
        bottom="0"
        border="1px"
        borderColor="brandBlack"
        opacity={0.95}
        left="0"
        right="0"
        bg="brandYellow"
        zIndex={'overlay'}
        p={4}
        m={4}
        rounded="md"
      >
        <Stack>
          <Text>
            Looks like you've signed up to VESYL while connecting your account to{' '}
            <strong>{oidcClient}</strong>.
          </Text>
          <Text>
            Add a payment method to finish setting up your account. After that, you can return to{' '}
            <strong>{oidcClient}</strong>'s page.
          </Text>
        </Stack>

        <HStack ml="auto">
          <Button variant={'ghost'} onClick={() => patch({ oidcClient: null })}>
            Close
          </Button>
          <AddStripePaymentMethod />
        </HStack>
      </HStack>
    </>
  );
}
