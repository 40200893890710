import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { StorePlatform } from 'types/enums';
import { Store, StoreSyncInterval } from './get';

type UpdateStore = {
  storeId: number;
  platform: StorePlatform;
  name?: string;
  syncInterval?: StoreSyncInterval;
};

type UpdateStoreResponse = Store;

export const updateStore = async (input: UpdateStore) => {
  const { storeId, platform, name, syncInterval } = input;
  const { data } = await axios.put(`/v3/stores/${platform}/${storeId}`, { name, syncInterval });
  return data;
};

const useUpdateStore = (opts?: UseMutationOptions<UpdateStoreResponse, {}, UpdateStore>) =>
  useMutation<UpdateStoreResponse, {}, UpdateStore>(updateStore, opts);

export default useUpdateStore;
