import { getReports } from 'api/reports';
import { useMemo, useRef } from 'react';
import { reportsColumns as columns } from './helpers';
import { CustomAgGrid, getServerSideDatasource } from 'components/ag-grid';
import { AgGridReact } from 'ag-grid-react';

export const Reports = () => {
  const tableId = 'reports-list';
  const gridRef = useRef<AgGridReact>(null);

  const dataSource = useMemo(() => getServerSideDatasource(getReports, {}), []);
  const reportsColumns = useMemo(() => columns(), []);

  return (
    <>
      <CustomAgGrid
        height="calc(100vh - 330px)"
        tableId={tableId}
        ref={gridRef}
        columnDefs={reportsColumns}
        dataSource={dataSource}
      />
    </>
  );
};
