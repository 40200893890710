import { Box, Button, Stack, Text, chakra } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdatePhone } from 'api/auth/update-phone';
import { PhoneInput } from 'components/PhoneInput';
import { useNotification } from 'contexts/notification.context';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'router';
import { z } from 'zod';

const schema = z.object({
  phone: z.string(),
});

type FormValues = z.infer<typeof schema>;

export default function () {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state as { user: User; phone: string; updatePhoneToken: string };
  const { notifySuccess } = useNotification();
  const updatePhone = useUpdatePhone();

  useEffect(() => {
    if (!data?.user?.id) {
      navigate('/login');
    }
  }, [navigate, data?.user?.id]);

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const { handleSubmit, register } = form;

  const onSubmit = (e: FormValues) => {
    updatePhone.mutate(
      { phone: e.phone, updatePhoneToken: location.state.updatePhoneToken },
      {
        onSuccess(response) {
          notifySuccess('Phone changed successfully');
          navigate('/phone-validation', {
            state: { user: response.user, updatePhoneToken: location.state.updatePhoneToken },
          });
        },
      },
    );
  };

  return (
    <Box>
      <Box w="full" alignItems="center" justifyContent="center" textAlign="center">
        <Text fontSize="lg" fontWeight="semibold" letterSpacing="tight" color="gray.900" marginBottom={2}>
          Update phone
        </Text>
        <Text fontSize="sm" marginBottom={6} color="muted">
          Please enter your new phone number:
        </Text>
        <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
          <Stack gap="3.5">
            <PhoneInput country="US" placeholder="Phone number" {...register('phone')} />
            <Button type="submit" colorScheme="brand" w="100%">
              Confirm
            </Button>
          </Stack>
        </chakra.form>

        <Text mt="4" fontSize="sm" fontWeight="medium">
          <Button to="/login" as={Link} type="button" colorScheme="brand" variant="link">
            Return to sign in
          </Button>
        </Text>
      </Box>
    </Box>
  );
}
