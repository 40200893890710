import { paths } from 'api/types';
import axios from 'config/axios';
import { UseMutationOptions, useMutation, useQuery } from 'react-query';

/**
 * Update OAuth Client
 */
export type UpdateOAuthClientInput =
  paths['/api/v3/oauth-client']['post']['requestBody']['content']['application/json'];
export type UpdateOAuthClientResponse =
  paths['/api/v3/oauth-client']['post']['responses']['200']['content']['application/json'];

export const updateOAuthClient = (opts?: UseMutationOptions<UpdateOAuthClientResponse, any, UpdateOAuthClientInput>) =>
  useMutation<UpdateOAuthClientResponse, any, UpdateOAuthClientInput>(async (input) => {
    const response = await axios.post(`/v3/oauth-client`, input, {});
    return response?.data;
  }, opts);

/**
 * Get OAuth Client
 */
export type GetOAuthClientResponse =
  paths['/api/v3/oauth-client']['get']['responses']['200']['content']['application/json'];
export const GET_OAUTH_CLIENT_QUERY = 'useGetOAuthClient';

export const useGetOAuthClient = () =>
  useQuery<GetOAuthClientResponse>([GET_OAUTH_CLIENT_QUERY], async () => {
    const response = await axios.get(`/v3/oauth-client`, {});
    return response.data;
  });
