import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { FiAlertCircle, FiEdit2 } from 'react-icons/fi';

export const ComboboxOption = forwardRef((props: ButtonProps & { selected?: boolean }, ref: any) => (
  <Button
    pl="4"
    w="full"
    size="sm"
    rounded="none"
    textAlign="left"
    variant="unstyled"
    fontWeight="normal"
    position="relative"
    bg={props.selected ? 'brand.50' : undefined}
    color={props.selected ? 'brand.500' : undefined}
    _hover={{ bg: 'brand.50', color: 'brand.500' }}
    {...props}
    ref={ref}
  />
));

export const ComboboxOptions = forwardRef((props: BoxProps & { isLoading?: boolean }, ref: any) => {
  const { isLoading, ...rest } = props;

  const loadingMarkup = (
    <Flex h="full" alignItems="center" justify="center" py="4">
      <Spinner color="gray.400" />
    </Flex>
  );

  return (
    <Box
      py="2"
      mt="2"
      w="full"
      bg="white"
      maxH="48"
      minH="14"
      shadow="sm"
      rounded="lg"
      border="1px"
      overflowY="auto"
      zIndex="popover"
      position="absolute"
      borderColor="gray.300"
      {...rest}
      ref={ref}
    >
      {isLoading ? loadingMarkup : props.children}
    </Box>
  );
});

export const AddressPreview = (props: {
  hasErrors?: boolean;
  onEdit?: () => void;
  address?: Record<string, any> | null;
}) => {
  const { hasErrors, address, onEdit } = props;

  const bgDefault = useColorModeValue('slate.50', 'gray.800');
  const borderColorDefault = useColorModeValue('slate.300', 'gray.600');

  return (
    <Flex
      p="3"
      border="1px"
      rounded="lg"
      bg={hasErrors ? 'red.50' : bgDefault}
      borderColor={hasErrors ? 'red.300' : borderColorDefault}
    >
      <Stack spacing="0" w="full">
        {hasErrors ? (
          <>
            <HStack spacing="1">
              <Text fontWeight="medium">Invalid address</Text>
              <Icon as={FiAlertCircle} color="red.500" />
            </HStack>
            <Text color="muted">Please check all the fields.</Text>
          </>
        ) : (
          <>
            <Text fontWeight="medium">{address?.name}</Text>
            <Text>{address?.company ?? ''}</Text>
            <Text>{address?.line1}</Text>
            <Text>{address?.line2 ?? ''}</Text>
            <Text>
              {address?.city} {address?.state} {address?.postal}
            </Text>
            <Text>{address?.phone ?? ''}</Text>
            <Text>{address?.email ?? ''}</Text>
          </>
        )}
      </Stack>
      <IconButton
        size="xs"
        icon={<FiEdit2 />}
        aria-label="Edit"
        variant="outline"
        alignSelf="end"
        ml="auto"
        onClick={onEdit}
      />
    </Flex>
  );
};
