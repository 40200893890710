import {
  Box,
  Button,
  Divider,
  FormControl,
  HStack,
  Icon,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { GetOrdersResponse } from 'api/orders/get';
import { AddressPopover } from 'components/AddressPopover';
import { ProductsPopover } from 'components/new/products-popover';
import { StoreLogo } from 'components/store-logo';
import { useDisclosure } from 'hooks/use-disclosure';
import { groupBy, noop, range, startCase, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { HiOutlineExclamation, HiOutlineTruck } from 'react-icons/hi';
import { OrderStatus, StorePlatform } from 'types/enums';
import { dayjs } from 'utils/dates';
import { ouncesToPounds, parseCarrier } from 'utils/misc';
import { useNavigate } from '../../../../router';
import { BuyShipmentModal } from './buy-shipment-modal';
import { EditOrderDrawer } from './edit-order-drawer';
import { useOrderContext } from './order-context';
import { GetStoreResponse } from 'api/stores';
import { BiSolidGift } from 'react-icons/bi';

type Order = ArrayElement<GetOrdersResponse['results']>;

export const columns = ({
  onEdit = noop,
  stores,
}: {
  onEdit: () => void;
  stores: GetStoreResponse | undefined;
}) =>
  [
    {
      colId: 'checkbox',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      lockPosition: true,
      resizable: false,
    },
    {
      headerName: 'Platform',
      field: 'platform',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.platform;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;

        return (
          <Box display="flex" alignItems="center" h="full">
            <StoreLogo platform={value} size={20} />
          </Box>
        );
      },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      initialPinned: 'right',
      cellRenderer: (params: ICellRendererParams) => {
        const order: Order = params.data;
        const isCompleted = params.data.status === 'completed';

        const editDrawer = useDisclosure();
        const buyModal = useDisclosure();
        const navigate = useNavigate();
        const { updateOrder } = useOrderContext();

        const multipleRowsSelected = params.api?.getSelectedNodes().length > 1;
        const selectedRate = order?.rates?.find((rate) => rate.id === order.selectedRate);
        const isInternational = order.shippingAddress?.country !== 'US';

        // International orders should either be purchased
        // in Quick Ship or through the Orders API because
        // of Customs Forms
        const onBuy = () =>
          isInternational ? navigate('/ship', { state: { order, origin: 'ORDERS_UI' } }) : buyModal.onOpen();
        const onQuickShip = () => navigate('/ship', { state: { order, origin: 'QUICK_SHIP_ORDER' } });
        const tooltipText = [];

        if (multipleRowsSelected) tooltipText.push('Multiple orders are selected.');
        if (updateOrder?.isLoading) tooltipText.push('Order is being updated.');
        if (order.cantBuyLabelReason.length > 0) tooltipText.push(...order.cantBuyLabelReason);
        if (isCompleted) tooltipText.push('Order is already completed.');

        return (
          <Box display="flex" alignItems="center" h="full">
            <HStack>
              <Button
                size="xs"
                variant="outline"
                title="Open in Quick Ship"
                onClick={onQuickShip}
                isDisabled={isCompleted}
              >
                <HiOutlineTruck />
              </Button>
              <Button
                onClick={editDrawer.onOpen}
                variant="outline"
                size="xs"
                isDisabled={multipleRowsSelected || isCompleted}
              >
                Edit
              </Button>
              <Tooltip
                label={
                  tooltipText.length > 0 && (
                    <List>
                      {tooltipText.map((e, index) => (
                        <ListItem key={index}>
                          <Text textColor={'white'}>
                            {index + 1}. {e}
                          </Text>
                        </ListItem>
                      ))}
                    </List>
                  )
                }
                backgroundColor={'red.300'}
              >
                <Button
                  onClick={onBuy}
                  variant="outline"
                  size="xs"
                  isDisabled={
                    // Disable if multiple rows are selected
                    // to avoid confusion (only bulk actions are
                    // allowed when multiple rows are selected)
                    multipleRowsSelected ||
                    // Can't buy if there isn't a rate selected
                    !selectedRate ||
                    // Disable if an order is being updated
                    updateOrder?.isLoading ||
                    // Disable if there's information missing
                    !order.canBuyLabel ||
                    // Disable if the order status is `completed`. Only
                    // `ready_to_ship` orders can be bought
                    isCompleted
                  }
                >
                  Buy
                </Button>
              </Tooltip>
            </HStack>

            {buyModal.isOpen && (
              <BuyShipmentModal
                isOpen={buyModal.isOpen}
                onClose={buyModal.onClose}
                order={order}
                onSuccess={() => {
                  params?.api?.refreshServerSide();
                  params?.api?.deselectAll();
                }}
              />
            )}
            {editDrawer.isOpen && (
              <EditOrderDrawer
                onEdit={() => {
                  params?.api?.refreshServerSide();
                  params?.api?.deselectAll();
                }}
                onClose={editDrawer.onClose}
                isOpen={editDrawer.isOpen}
                order={order}
              />
            )}
          </Box>
        );
      },
    },
    {
      headerName: 'ID',
      field: 'platformId',
      filter: 'agNumberColumnFilter',
      sortable: true,
      filterParams: {
        filterOptions: [
          'equals',
          'lessThan',
          'greaterThan',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
      },
      valueGetter: (params: ValueGetterParams) => {
        const platform = params.data.platform;
        if (platform === StorePlatform.Shopify) return params.data.reference;
        return params.data.platformId;
      },
    },
    {
      headerName: 'From',
      field: 'from',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.from;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;

        return <AddressPopover address={value} />;
      },
    },
    {
      headerName: 'Address',
      field: 'shippingAddress',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.shippingAddress;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;

        return <AddressPopover address={value} />;
      },
    },
    {
      headerName: 'Store',
      field: 'stores',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: stores?.map((store) => {
          return { id: store.id, name: store.name, platform: store.platform };
        }),
        convertValuesToStrings: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value.name;
        },
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.storeName;
      },
    },
    {
      headerName: 'Name',
      field: 'shippingAddress',
      valueGetter: (params: ValueGetterParams) => {
        const value = params.data.shippingAddress;
        if (!value) return null;

        const name = value.name;
        const company = value.company;

        let text = '';

        if (!name && !company) return null;
        if (!name && company) text = company;
        if (name && !company) {
          const names = name.split(' ');
          if (names.length === 1) text = names[0];
          else if (names.length === 2) text = `${names[0]} ${names[1]?.charAt(0)}.`;
          else if (names.length > 2) text = `${names[0]} ${names[1].charAt(0)}. ${names[2].charAt(0)}.`;
        }

        return text;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;

        return (
          <Text isTruncated maxW="28" title={value}>
            {value}
          </Text>
        );
      },
    },
    {
      headerName: 'Zone',
      field: 'uspsZone',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: range(1, 10),
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.uspsZone;
      },
    },
    {
      headerName: 'PO Number',
      field: 'poNumber',
      filter: 'agSetColumnFilter',
      sortable: true,
      filterParams: {
        values: ['Duplicates'],
        suppressMiniFilter: true,
        suppressSelectAll: true,
        defaultToNothingSelected: true,
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.options?.poNumber;
      },
    },
    {
      headerName: 'Qty',
      field: 'totalQuantity',
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        maxNumConditions: 1,
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.totalQuantity;
      },
    },
    {
      headerName: 'Items',
      field: 'lineItems',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'], maxNumConditions: 1 },
      cellRenderer: (params: ICellRendererParams) => {
        const data: Order = params.data;
        if (!data) return null;
        const products = data.lineItems?.map((v) => ({ ...v.product, quantity: v.quantity }));
        if (!products) return null;

        let title = '';
        const skus = uniqBy(products, 'sku');

        if (skus.length === 0) return `(${skus.length} items)`;

        if (skus.length === 1) title = skus[0].name;
        else title = `(${skus.length} items)`;

        return <ProductsPopover products={products} title={title} />;
      },
    },
    {
      headerName: 'Weight',
      field: 'totalWeightOz',
      valueGetter: (params: ValueGetterParams) => {
        const order: Order = params.data;
        return order?.parcel?.weight || order?.totalWeightOz;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const data: Order = params.data;
        const { value } = params;

        const { isOpen, onOpen, onClose } = useDisclosure();

        if (!value) {
          return (
            <>
              <Tooltip label={`Mising field "weight". Click to fix.`}>
                <span>
                  <Button variant="unstyled" size="xs" onClick={onOpen}>
                    <HiOutlineExclamation size="18px" />
                  </Button>
                </span>
              </Tooltip>
              {isOpen && (
                <EditOrderDrawer
                  onEdit={onEdit}
                  isOpen={isOpen}
                  onClose={onClose}
                  order={data}
                  validateFields={['weightLbs', 'weightOz']}
                />
              )}
            </>
          );
        }

        return (
          <Text>
            {ouncesToPounds(value)}{' '}
            <Text as="span" fontSize="xs">
              lbs
            </Text>
          </Text>
        );
      },
    },
    {
      headerName: 'Ship Method',
      field: 'shippingMethod',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains', 'equals'] },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.shippingMethod;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;

        return (
          <Text maxW="28" isTruncated title={value}>
            {value}
          </Text>
        );
      },
    },
    {
      headerName: 'Tags',
      field: 'tags',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'] },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.tags;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value || value.length === 0) return null;
        return (
          <Box display="flex" alignItems="center" h="full">
            <HStack spacing={1}>
              {value.slice(0, 2).map((tag: any, index: number) => (
                <Tag size="sm" key={index}>
                  {tag.name}
                </Tag>
              ))}
              {value.length > 2 && (
                <Popover>
                  <PopoverTrigger>
                    <Tag size="sm" cursor="pointer">
                      + {value.length - 2} others
                    </Tag>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverCloseButton />
                      <PopoverBody display="flex" alignItems="center" h="full">
                        {value.slice(2, value.length).map((tag: any, index: number) => (
                          <Tag size="sm" key={index} mr={1}>
                            {tag.name}
                          </Tag>
                        ))}
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              )}
            </HStack>
          </Box>
        );
      },
    },
    {
      headerName: 'Shipping Paid',
      field: 'totals',
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.totals?.shippingCents;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        return <span>${((value ?? 0) / 100).toFixed(2)}</span>;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => startCase(params.value),
        values: Object.values(OrderStatus),
        defaultToNothingSelected: true,
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        const status = value === 'ready_to_ship' ? 'ready' : value;
        let color = 'gray';
        if (value === 'ready_to_ship') color = 'green';
        if (value === 'open') color = 'orange';

        return (
          <Box display="flex" alignItems="center" h="full">
            <Tag size="sm" colorScheme={color}>
              {startCase(status) ?? ''}
            </Tag>
          </Box>
        );
      },
    },
    {
      headerName: 'Notes',
      field: 'notes',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.notes;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;
        const { isOpen, onClose, onOpen } = useDisclosure();
        return (
          <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
            <PopoverTrigger>
              <Button variant="unstyled">
                <HStack>
                  <Text fontWeight="normal" isTruncated>
                    {value.length} {value.length === 1 ? 'note' : 'notes'}
                  </Text>
                  {isOpen ? <FaCaretUp /> : <FaCaretDown />}
                </HStack>
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverBody>
                  {value.map((note: any, index: number) => (
                    <Box key={index} mb="2">
                      <Text>{note.user?.name}</Text>
                      <Text>{note.content}</Text>
                      <Text fontSize="xs" color="gray.500">
                        {dayjs(note.createdAt).format('MMM D, YYYY [at] h:mm A')}
                        {note.author ? `, ${note.author}` : ''}
                      </Text>
                      {index !== value.length - 1 && <Divider my="2" />}
                    </Box>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        );
      },
    },
    {
      headerName: 'Rates',
      field: 'rates',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.rates;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        const order = params.data;
        const { updateOrder } = useOrderContext();
        const [selectedRate, setSelectedRate] = useState(order.selectedRate || '');
        const isReadyToShip = params.data.status === 'ready_to_ship';

        useEffect(() => {
          setSelectedRate(order.selectedRate || '');
        }, [order.updatedAt]);

        if (!updateOrder) return null;

        const onSubmit = async (values: { selectedRate: string }) => {
          if (!values.selectedRate) return;
          setSelectedRate(values.selectedRate);

          try {
            await updateOrder?.mutateAsync({
              id: order.id,
              selectedRate: values.selectedRate,
              skipAutomations: true,
            });
            params?.api?.refreshServerSide();
            params?.api?.deselectAll();
          } catch (error) {
            setSelectedRate('');
          }
        };

        if (order?.rates?.length === 0 || !order.canFetchRates) return null;

        return (
          <FormControl minW="150px">
            <Select
              maxW="60"
              minW="full"
              value={selectedRate}
              size="xs"
              isDisabled={order?.rates?.length === 0 || !order.canFetchRates || !isReadyToShip}
              onChange={(ev) => onSubmit({ selectedRate: ev.target.value })}
            >
              <option value="">-- Select an option --</option>
              {Object.entries(groupBy(value, 'carrier')).map(([carrier, rates]) => (
                <optgroup label={parseCarrier(carrier)} key={carrier}>
                  {rates.map((rate, index) => (
                    <option value={rate.id} key={index}>
                      ${rate?.price?.toFixed(2)} - {rate.name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </Select>
          </FormControl>
        );
      },
    },

    {
      headerName: 'Created',
      field: 'createdAt',
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
      },
      valueGetter: (params: ValueGetterParams) => {
        return dayjs.utc(params.data.createdAt).format('YYYY-MM-DD');
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        return <>{value}</>;
      },
    },
    {
      headerName: 'Gift Message',
      field: 'giftMessage',
      filterParams: {
        values: ['hasGift', 'noGift'],
        valueFormatter: (params: ValueFormatterParams) => {
          return startCase(params.value);
        },
      },
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.giftMessage;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;

        return (
          <Tooltip label={value.message} shouldWrapChildren={true}>
            <Box display="flex" alignItems="center" h="full">
              <Icon as={BiSolidGift} boxSize={5} />
            </Box>
          </Tooltip>
        );
      },
    },
  ] as ColDef<Order>[];
