import { useQuery } from 'react-query';
import { Invoice } from 'types/payment';

import axios from '../../config/axios';

export type GetInvoicesResponse = { results: Invoice[]; total: number };

export type GetInvoices = {
  per?: number;
  page?: number;
};

export const getInvoices = async ({ per, page }: GetInvoices) => {
  const { data } = await axios.get(`/v3/invoices`, {
    params: {
      per,
      page,
    },
  });
  return data;
};

export const GET_INVOICES_QUERY = 'invoices';

const useGetInvoices = ({ per, page }: GetInvoices) =>
  useQuery<GetInvoicesResponse>([GET_INVOICES_QUERY, { per, page }], () => getInvoices({ per, page }), {});
export default useGetInvoices;
