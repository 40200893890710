import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { PutOrdersIdResponse } from 'types/api-types';
import axios from '../../config/axios';
import { paths } from 'api/types';
import { NonUndefined } from 'react-hook-form';

export type Data = PutOrdersIdResponse;
export type Variables = NonUndefined<
  paths['/api/v3/orders/{id}']['put']['requestBody']
>['content']['application/json'] & {
  id: number;
};
type Error = AxiosError<{}>;

export const useUpdateOrder = (opts?: UseMutationOptions<Data, Error, Variables>) =>
  useMutation<Data, Error, Variables>(async (input) => {
    const { id, ...payload } = input;
    const response = await axios.put(`/v3/orders/${id}`, payload);
    return response?.data;
  }, opts);
