import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Spinner,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { GET_ORDER_FILE_QUERY, useGetOrderImport, useRemoveOrderImport } from 'api/orders';
import { PageBody, PageHeader } from 'components/Page';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { queryClient } from 'config/query-client';
import { countBy, startCase } from 'lodash';
import { FiCheck, FiTrash2, FiX } from 'react-icons/fi';
import { HiOutlineExclamationTriangle } from 'react-icons/hi2';
import { useNavigate, useParams } from 'router';
import { formatBytes } from 'utils/misc';

const importStatusColor: { [key: string]: string } = {
  pending: 'gray',
  processing: 'gray',
  processed: 'blue',
  completed: 'green',
  processed_with_errors: 'red',
  completed_with_errors: 'yellow',
  failed: 'red',
};

export default function () {
  const params = useParams('/orders/import/:id');
  const navigate = useNavigate();
  const importId = Number(params.id as string);
  const orderImport = useGetOrderImport({ id: importId }, { enabled: !!importId, refetchInterval: 5000 });
  const removeOrderImport = useRemoveOrderImport();

  const grouped = countBy(orderImport.data?.jobs, 'status');
  const isCompleted = !!orderImport.data?.completedAt;

  const deleteImportModal = useDisclosure();

  const onRemoveImport = () => {
    removeOrderImport.mutate(
      { id: importId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([GET_ORDER_FILE_QUERY]);
          deleteImportModal.onClose();
          navigate('/orders/import');
        },
      },
    );
  };

  return (
    <>
      <PageHeader>
        <Heading as="h1">Import #{importId}</Heading>
        <Button
          ml="auto"
          variant="outline"
          leftIcon={<FiTrash2 />}
          color="red.500"
          borderColor="red.500"
          onClick={deleteImportModal.onOpen}
        >
          Delete
        </Button>
      </PageHeader>
      <PageBody>
        <Card mb="4">
          <CardBody>
            <HStack gap="20" alignItems="start" pr="8" w="full">
              <Stack spacing={1}>
                <Text fontWeight="semibold">File</Text>
                <Text color="muted" fontSize="sm">
                  {orderImport.data?.file?.name}
                </Text>
                <Text color="muted" fontSize="sm">
                  {formatBytes(orderImport.data?.file?.sizeBytes || 0)}
                </Text>
              </Stack>
              <Stack spacing={1} mr="auto">
                <Text fontWeight="semibold">Options</Text>
                <Box color="muted">
                  <HStack spacing={1}>
                    {orderImport.data?.isTransactional ? <FiCheck /> : <FiX />}
                    <Text>Transactional</Text>
                  </HStack>
                  <HStack spacing={1}>
                    {true ? <FiCheck /> : <FiX />}
                    <Text>Run Automations</Text>
                  </HStack>
                  <HStack spacing={1}>
                    <HiOutlineExclamationTriangle />
                    <Text>Conflict strategy:</Text>
                    <Text>Merge</Text>
                  </HStack>
                </Box>
              </Stack>

              <Stack ml="auto">
                <Text fontWeight="semibold">Imported</Text>
                {isCompleted ? (
                  <HStack>
                    <Text fontSize="xl">{grouped.completed || 0}</Text>
                    <Text fontSize="xs" color="muted">
                      / {orderImport.data?.jobs?.length}
                    </Text>
                  </HStack>
                ) : (
                  <Text color="muted">---</Text>
                )}
              </Stack>

              <Stack>
                <Text fontWeight="semibold">Duration</Text>
                <Text color="muted">{isCompleted ? orderImport.data?.duration : '---'}</Text>
              </Stack>

              <Stack>
                <Text fontWeight="semibold">Status</Text>
                {isCompleted ? (
                  <Tag
                    size="sm"
                    colorScheme={
                      {
                        completed: 'green',
                        completed_with_errors: 'yellow',
                        default: 'gray',
                      }[orderImport.data?.status || 'default']
                    }
                  >
                    {startCase(orderImport.data?.status)}
                  </Tag>
                ) : (
                  '---'
                )}
              </Stack>
            </HStack>
          </CardBody>
        </Card>

        <Box border="1px" borderColor="gray.200" rounded="lg">
          <Table size="md" rounded="lg">
            <Thead>
              <Tr>
                <Th border="none" roundedTop="lg">
                  <Text>Order Number</Text>
                </Th>
                <Th>
                  <Text>Status</Text>
                </Th>
                <Th border="none" roundedTop="lg">
                  <Text>Errors</Text>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {orderImport.data?.jobs?.map((oi, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Text fontSize="sm">{oi.platformId}</Text>
                    </Td>
                    <Td>
                      {oi.status === 'processing' ? (
                        <Spinner size="sm" color="gray.500" emptyColor="gray.100" />
                      ) : (
                        <Tag size="sm" colorScheme={importStatusColor[oi.status] || 'gray'}>
                          {startCase(oi.status)}
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      <Stack>
                        {oi.errors?.map((e, i) => (
                          <Text fontSize="xs" color="red.500" key={i}>
                            {e.message}
                          </Text>
                        ))}
                      </Stack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </PageBody>

      <ConfirmationModal
        onClose={deleteImportModal.onClose}
        isOpen={deleteImportModal.isOpen}
        header="Delete Import"
        isLoading={removeOrderImport.isLoading}
        description={
          <>
            <Text fontWeight="semibold">Are you sure you want to delete this import and its orders?</Text>{' '}
            <Alert status="error" mt="4">
              <AlertIcon />
              <AlertDescription>
                This action will permanently delete all orders imported by this file ({grouped.completed} total).
              </AlertDescription>
            </Alert>
            <Text mt="2">This action cannot be undone.</Text>
            <Text mt="2">
              Orders that have been shipped or in &quot;completed&quot; state <b>will not be deleted</b>.
            </Text>
          </>
        }
        type="delete"
        onConfirm={onRemoveImport}
      />
    </>
  );
}
