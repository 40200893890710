import { IStatusPanelParams } from 'ag-grid-community';
import { Select } from '@chakra-ui/react';
import { useTableStore } from 'store';

export const PaginationController = (props: IStatusPanelParams, tableId: string) => {
  const tableStore = useTableStore(tableId);
  return (
    <Select
      size="xs"
      defaultValue=""
      onChange={(e) => {
        props.api.paginationSetPageSize(Number(e.target.value));
        tableStore.setPageSize(Number(e.target.value));
      }}
    >
      <option value="" disabled hidden>
        Show {props.api.paginationGetPageSize()}
      </option>
      <option value="12">Show 12</option>
      <option value="50">Show 50</option>
      <option value="100">Show 100</option>
      <option value="250">Show 250</option>
      <option value="500">Show 500</option>
    </Select>
  );
};
