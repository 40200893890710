import { Box, HStack, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { queryClient } from 'config/query-client';
import { LuSettings, LuUser } from 'react-icons/lu';
import { MdKeyboardArrowRight, MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useUserStore } from 'store';

interface UserProfileProps {
  name: string;
  email: string;
  isCollapsed?: boolean;
}

export const UserProfile = (props: UserProfileProps) => {
  const { name, email, isCollapsed = false } = props;

  const { patch } = useUserStore();
  const intercom = useIntercom();
  const navigate = useNavigate();

  const onLogout = () => {
    patch({ authToken: null });
    intercom.shutdown();
    queryClient.removeQueries();
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          {!isCollapsed ? (
            <MenuButton
              cursor="pointer"
              padding="3px"
              borderRadius="5px"
              transition="background-color 200ms linear"
              _hover={{ backgroundColor: 'zinc.700' }}
              textAlign="left"
            >
              <HStack spacing="3" ps="2" justify="space-between">
                <Box overflow="hidden" whiteSpace="nowrap">
                  <Box display="flex">
                    <Text
                      color="zinc.200"
                      title={name}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      fontWeight="medium"
                      fontSize="sm"
                      mr="4px"
                    >
                      {name}
                    </Text>
                  </Box>
                  <Text color="zinc.200" title={email} overflow="hidden" textOverflow="ellipsis" fontSize="xs">
                    {email}
                  </Text>
                </Box>
                <MdKeyboardArrowRight
                  size={20}
                  color="gray"
                  style={{ transform: isOpen ? 'rotate(-90deg)' : '', transition: 'transform 150ms ease' }}
                />
              </HStack>
            </MenuButton>
          ) : (
            <MenuButton
              as={IconButton}
              p="0.5"
              alignItems="center"
              variant="ghost"
              color="white"
              _active={{ bg: 'zinc.200' }}
              _hover={{ bg: 'zinc.500' }}
              size="lg"
              w="full"
              icon={<LuUser />}
            />
          )}
          <MenuList ml={5}>
            <MenuItem icon={<LuSettings />} onClick={() => navigate('/settings')}>
              <Text>Settings</Text>
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={<MdLogout />} onClick={onLogout}>
              <Text title={email} textOverflow="ellipsis">
                Sign out of Vesyl
              </Text>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
