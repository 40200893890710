import {
  Button,
  Divider,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import useReadShipment from 'api/shipments/read';
import { startCase } from 'lodash';
import { LuDownload } from 'react-icons/lu';

interface Props extends Omit<ModalProps, 'children'> {
  forms: NonNullable<ReturnType<typeof useReadShipment>['data']>['forms'];
}

export function DownloadFormsModal(props: Props) {
  const { forms, ...modalProps } = props;

  return (
    <Modal {...modalProps} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Download Forms</ModalHeader>

        <ModalBody>
          <Stack divider={<Divider />}>
            {forms?.map((f) => {
              return (
                <HStack justifyContent="space-between" key={f.formUrl}>
                  <Text fontWeight="medium">{startCase(f.formType)}</Text>
                  <HStack color="facebook.400" gap={1}>
                    <LuDownload />
                    <Link color="facebook.400" href={f.formUrl} target="_blank" fontWeight="medium">
                      Download
                    </Link>
                  </HStack>
                </HStack>
              );
            })}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={modalProps.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
