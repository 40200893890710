import { useQuery } from 'react-query';
import axios from 'config/axios';
import { StorePlatform } from 'types/enums';

export type StoreSyncInterval = 'daily' | 'weekly' | null;

export type Store = {
  id: number;
  platform: StorePlatform;
  name?: string;
  lastSyncAt: Date;
  syncInterval: StoreSyncInterval;
  url?: string;
  needsAuth?: boolean | null;
  userLoginName?: string;
};

export type StoreWithCustom = Omit<Store, 'platform'> & { platform: StorePlatform | 'custom' };

export type GetStoreResponse = Store[];
export type GetStoreResponseWithCustomStore = StoreWithCustom[];

export const getStores = async <TParam>(include?: TParam) => {
  const { data } = await axios.get(`/v3/stores${include ? `?include=${include}` : ''}`);

  return data;
};

export const GET_STORES_QUERY = 'getStores';

const useGetStores = <TParam>(include?: TParam) =>
  useQuery<TParam extends string ? GetStoreResponseWithCustomStore : GetStoreResponse>(['getStores', include], () =>
    getStores(include),
  );

export default useGetStores;
