import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import useReadShipment from 'api/shipments/read';
import { CarrierLogo } from 'components/CarrierLogo';
import { CostBreakdown, Item as ItemCostBreakdown } from 'components/cost-breakdown';
import { EmptyState } from 'components/EmptyState';
import ContainerLayout from 'components/layouts/container-layout';
import { PageBody, PageHeader } from 'components/Page';
import { PrintLabelModal } from 'components/print-label-modal';
import { StoreLogo } from 'components/store-logo';
import { Timeline } from 'components/timeline';
import { useDisclosure } from 'hooks/use-disclosure';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { FaRegEyeSlash } from 'react-icons/fa';
import { FiCheck, FiCheckCircle, FiClipboard, FiDollarSign, FiInfo, FiMapPin } from 'react-icons/fi';
import { LuBox, LuFileStack, LuMapPinOff, LuNavigation, LuPackage, LuPrinter } from 'react-icons/lu';
import { TbPlaneArrival, TbPlaneDeparture } from 'react-icons/tb';
import useClipboard from 'react-use-clipboard';
import { useParams } from 'router';
import { formatDate } from 'utils/dates';
import { ouncesToPounds } from 'utils/misc';
import { renderAddress } from '../helpers';
import { DownloadFormsModal } from '../_components/download-forms-modal';

export default function () {
  const { id } = useParams('/shipments/:id');

  const getShipment = useReadShipment(Number(id));

  const shipment = getShipment?.data;
  const products = shipment?.order?.lineItems?.map((li) => ({ ...li.product, quantity: li.quantity })) || [];
  const isMultiParcel = shipment?.parcels && shipment?.parcels?.length > 1;

  const [isCopied, setCopied] = useClipboard(shipment?.tracker?.code ?? '---', {
    successDuration: 1400,
  });

  const printLabelModal = useDisclosure();
  const downloadFormsModal = useDisclosure();

  if (!id || getShipment.error) {
    return (
      <EmptyState py="60" icon={FaRegEyeSlash} header="Shipment not found!">
        <Text>We could not find this shipment.</Text>
      </EmptyState>
    );
  }

  const feesDetails: ItemCostBreakdown[] = [
    {
      title: (
        <HStack spacing={1}>
          <CarrierLogo carrier={shipment?.purchasedRate?.carrier || ''} height={20} />
          <Text textColor="muted">{shipment?.purchasedRate?.service || ''}</Text>
        </HStack>
      ),
      details: shipment?.purchasedRate?.details?.slice(1, shipment.purchasedRate.details.length),
      value: shipment?.purchasedRate?.price || 0,
    },
    {
      title: <Text textColor="muted">Delivery Confirmation</Text>,
      details: [startCase(lowerCase(shipment?.options?.deliveryConfirmation || ''))],
      value: shipment?.signaturePriceCents ? shipment.signaturePriceCents / 100 : 0,
    },
  ];

  if (shipment?.refundStatus === 'refunded') {
    const total = feesDetails.reduce((acc, fee) => acc + fee.value, 0);
    feesDetails.push({
      title: <Text textColor="muted">Refund</Text>,
      value: -total,
    });
  }

  if (shipment?.fees?.length) {
    const insuranceFee = shipment?.fees?.find((fee) => fee.type === 'InsuranceFee');
    if (insuranceFee) {
      feesDetails.push({
        title: 'Insurance',
        details: [`$${shipment?.insuranceCents ? shipment.insuranceCents / 100 : 0}`],
        value: insuranceFee.amountCents,
      });
    }
  }

  const trackingDetails =
    shipment?.trackingDetails?.map((tracking, index) => ({
      id: index,
      title: startCase(tracking.status),
      description: tracking.message,
      datetime: tracking.dateTime.toString(),
      date: formatDate(tracking.dateTime, 'MM/DD/YYYY') || '',
      icon: () => {
        if (tracking.statusDetail === 'status_update') return <FiClipboard size="1.2rem" />;
        if (tracking.statusDetail === 'arrived_at_facility') return <TbPlaneArrival size="1.2rem" />;
        if (tracking.statusDetail === 'departed_facility') return <TbPlaneDeparture size="1.2rem" />;
        if (tracking.statusDetail === 'out_for_delivery') return <FiInfo size="1.2rem" />;
        if (tracking.statusDetail === 'arrived_at_destination') return <FiCheckCircle size="1.2rem" />;
        return <FiInfo size="1.2rem" />;
      },
    })) || [];

  const zoneMarkup = (
    <HStack gap={2}>
      {shipment?.uspsZone ? <Tag size="sm">{shipment?.uspsZone}</Tag> : ''}
      {shipment?.uspsPmiZone ? (
        <Tag size="sm" title="Priority Mail International">
          PMI - {shipment?.uspsPmiZone}
        </Tag>
      ) : (
        ''
      )}
      {shipment?.uspsFcpiZone ? (
        <Tag size="sm" title="First Class Package International">
          FCPI - {shipment?.uspsFcpiZone}
        </Tag>
      ) : (
        ''
      )}
    </HStack>
  );

  return (
    <ContainerLayout>
      <Flex flexDirection="column" alignContent="space-between">
        <PageHeader justifyContent="space-between">
          <HStack>
            <Heading as="h1" maxW="sm" isTruncated>
              Shipment {getShipment?.data?.tracker?.code}
            </Heading>
          </HStack>

          <HStack>
            <Button
              type="button"
              variant="outline"
              leftIcon={<LuFileStack />}
              onClick={downloadFormsModal.onOpen}
              hidden={!getShipment.data?.forms?.length}
            >
              Download Forms
            </Button>
            <Button type="button" variant="outline" leftIcon={<LuPrinter />} onClick={printLabelModal.onOpen}>
              Print Label
            </Button>
          </HStack>
        </PageHeader>
        <PageBody>
          <SimpleGrid columns={[1, null, 5]} gap="6">
            <GridItem colSpan={[1, null, 3]}>
              {/* Address */}
              <Card>
                <CardHeader flexDir="row" display="flex" alignItems="center" gap="2">
                  <FiMapPin />
                  <Text as="h3" fontSize="md" fontWeight="medium">
                    Address
                  </Text>
                </CardHeader>
                <CardBody pt={0}>
                  <SimpleGrid columns={[2]}>
                    <Stack gap="1">
                      <Text fontWeight="semibold">Sender</Text>
                      {renderAddress(shipment?.from as any)}
                    </Stack>

                    <Stack gap="1">
                      <Text fontWeight="semibold">Recipient</Text>
                      {renderAddress(shipment?.to as any)}
                    </Stack>
                  </SimpleGrid>
                  <SimpleGrid columns={[2]} mt="4">
                    <Stack gap="1">
                      <Text fontWeight="semibold">USPS Zone</Text>
                      {zoneMarkup}
                    </Stack>
                  </SimpleGrid>
                </CardBody>
              </Card>
              <Card mt="6">
                {/* Packages */}
                <CardHeader flexDir="row" display="flex" alignItems="center" gap="2">
                  <LuBox />
                  <Text as="h3" fontSize="md" fontWeight="medium">
                    Package
                  </Text>
                </CardHeader>
                <CardBody pt={0}>
                  <Tabs size="sm" variant="solid-rounded" colorScheme="brand">
                    <TabList mb="4" hidden={!isMultiParcel}>
                      {shipment?.parcels?.map((_, index) => <Tab key={index}>{`Parcel #${index + 1}`}</Tab>)}
                    </TabList>
                    <TabPanels>
                      {shipment?.parcels?.map((p, index) => (
                        <TabPanel key={index} p="0">
                          <SimpleGrid columns={[3]}>
                            <Stack gap="1">
                              <Text fontWeight="semibold">Weight</Text>
                              <Text>{p?.weight ? ouncesToPounds(p.weight) : '-'} lbs</Text>
                            </Stack>
                            <Stack gap="1">
                              <Text fontWeight="semibold">Dimensions</Text>
                              <Text>
                                {p?.length || 0} x {p?.width || 0} x {p?.height || 0} in
                              </Text>
                            </Stack>
                            <Stack gap="1" hidden={index >= 1}>
                              <Text fontWeight="semibold">Dimensional Weight</Text>
                              <Text>{shipment?.dimWeight ? `${shipment?.dimWeight} lbs` : 'N/a'}</Text>
                            </Stack>
                          </SimpleGrid>

                          <SimpleGrid mt="4" hidden={index >= 1}>
                            <Stack gap="1">
                              <Text fontWeight="semibold">Cubic Size</Text>
                              <Text>{shipment?.cubicSize || 'N/a'}</Text>
                            </Stack>
                          </SimpleGrid>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </CardBody>
              </Card>

              {/* Fees */}
              <Card mt="6">
                <CardHeader flexDir="row" display="flex" alignItems="center" gap="2">
                  <FiDollarSign />
                  <Text as="h3" fontSize="md" fontWeight="medium">
                    Fees
                  </Text>
                </CardHeader>
                <CardBody pt={0}>
                  <CostBreakdown items={feesDetails} />
                </CardBody>
              </Card>
              <Card mt="6">
                <CardHeader>
                  <HStack>
                    <StoreLogo platform={'shopify' || shipment?.order?.platform} size={16} />
                    <Text as="h3" fontSize="md" fontWeight="medium">
                      Order {shipment?.order?.reference}
                    </Text>
                  </HStack>
                </CardHeader>
                <CardBody pt={0}>
                  <Stack hidden={!!shipment?.order}>
                    <EmptyState py="20" header="No order information" icon={LuPackage}>
                      <Text>This shipment is not associated with an order</Text>
                    </EmptyState>
                  </Stack>

                  <SimpleGrid columns={[2]} hidden={!shipment?.order}>
                    <Stack gap="3">
                      <Stack gap="1">
                        <Text fontWeight="semibold">Platform</Text>
                        <Text>{startCase(shipment?.order?.platform)}</Text>
                      </Stack>
                      x
                      <Stack gap="1">
                        <Text fontWeight="semibold">Reference #</Text>
                        <Text>{shipment?.order?.reference || 'N/a'}</Text>
                      </Stack>
                    </Stack>

                    <Stack>
                      <Text fontWeight="semibold">Products</Text>
                      <SimpleGrid columns={[1]}>
                        {products.length === 0 && <Text>No products found</Text>}
                        {products?.map((product, index) => (
                          <HStack key={product.id}>
                            <Image
                              w="10"
                              h="10"
                              rounded="md"
                              alt="No Image"
                              fontSize={8}
                              objectFit="cover"
                              src={product?.imageUrl || ''}
                            />
                            <Stack key={index} gap="0" mb="2">
                              <Text fontWeight="medium">
                                {product?.quantity}x {product?.name}
                              </Text>
                              <Text color="muted">{product?.sku}</Text>
                            </Stack>
                          </HStack>
                        ))}
                      </SimpleGrid>
                    </Stack>
                  </SimpleGrid>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem colSpan={[1, null, 2]}>
              <Card>
                <CardHeader borderBottom="1px solid" borderColor="zinc.200">
                  <HStack>
                    <LuNavigation />
                    <Text as="h3" fontSize="md" fontWeight="medium">
                      Tracking Details
                    </Text>
                  </HStack>
                  <HStack spacing={1}>
                    <Tooltip label="Copy tracking code">
                      {isCopied ? (
                        <HStack color="green.600">
                          <Text fontWeight="medium" fontSize="xs">
                            Copied
                          </Text>
                          <FiCheck />
                        </HStack>
                      ) : (
                        <IconButton
                          icon={<FiClipboard />}
                          variant="ghost"
                          size="xs"
                          onClick={setCopied}
                          aria-label="Copy tracking code"
                        />
                      )}
                    </Tooltip>
                    <Link
                      maxW="fit-content"
                      color="brand.900"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={shipment?.tracker?.url ?? '#'}
                      textColor="muted"
                      fontSize="xs"
                    >
                      {shipment?.tracker?.code}
                    </Link>
                  </HStack>
                </CardHeader>
                <CardBody pt={4} minH="xl" maxH="3xl" overflowY={'auto'}>
                  {trackingDetails.length === 0 || !trackingDetails ? (
                    <EmptyState py="20" header="No tracking details" icon={LuMapPinOff}>
                      <Text> No tracking information found</Text>
                    </EmptyState>
                  ) : (
                    <Timeline events={trackingDetails} />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </SimpleGrid>

          <PrintLabelModal
            // @TODO
            zpl={false}
            isOpen={printLabelModal.isOpen}
            onClose={printLabelModal.onClose}
            shipmentIds={[Number(id)]}
          />

          {getShipment.data?.forms && <DownloadFormsModal {...downloadFormsModal} forms={getShipment.data?.forms} />}
        </PageBody>
      </Flex>
    </ContainerLayout>
  );
}
