export const HAZARDOUS_MATERIALS = [
  { label: 'Limited Quantity', value: 'LIMITED_QUANTITY' },
  { label: 'Air Eligible Ethanol', value: 'AIR_ELIGIBLE_ETHANOL' },
  { label: 'Class 1 - Toy Propellant/Safety Fuse Package', value: 'CLASS_1' },
  { label: 'Class 3 - Flammable Liquids', value: 'CLASS_3' },
  { label: 'Division 4.1 - Mailable Flammable Solids', value: 'DIVISION_4_1' },
  { label: 'Division 5.1 - Oxidizers', value: 'DIVISION_5_1' },
  { label: 'Division 5.2 - Organic Peroxides', value: 'DIVISION_5_2' },
  { label: 'Division 6.1 - Toxic Materials', value: 'DIVISION_6_1' },
  { label: 'Division 6.2 - Infectious Substances', value: 'DIVISION_6_2' },
  { label: 'Class 7 - Radioactive Materials', value: 'CLASS_7' },
  { label: 'Class 8 - Corrosive Materials', value: 'CLASS_8_CORROSIVE' },
  { label: 'Class 8 - Non-spillable Wet Battery', value: 'CLASS_8_WET_BATTERY' },
  { label: 'Class 9 - Lithium Battery Loose Marked', value: 'CLASS_9_NEW_LITHIUM_INDIVIDUAL' },
  { label: 'Class 9 - Lithium Battery Installed Marked', value: 'CLASS_9_NEW_LITHIUM_DEVICE' },
  { label: 'Class 9 - Used or Returned Lithium Battery', value: 'CLASS_9_USED_LITHIUM' },
  { label: 'Class 9 - Lithium Battery Unmarked', value: 'CLASS_9_UNMARKED_LITHIUM' },
  { label: 'Class 9 - Dry Ice', value: 'CLASS_9_DRY_ICE' },
  { label: 'Class 9 - Magnetized Materials', value: 'CLASS_9_MAGNETIZED' },
  { label: 'Ground Only', value: 'GROUND_ONLY' },
  { label: 'ID8000 Consumer Commodity', value: 'ID8000' },
  { label: 'Lighters', value: 'LIGHTERS' },
  { label: 'Small Quantity Provision', value: 'SMALL_QUANTITY_PROVISION' },
  { label: 'Excepted Quantity Provision', value: 'EXCEPTED_QUANTITY_PROVISION' },
  { label: 'Primary (Non-USPS)', value: 'PRIMARY' },
  { label: 'Primary Contained (Non-USPS)', value: 'PRIMARY_CONTAINED' },
  { label: 'Primary Packed (Non-USPS)', value: 'PRIMARY_PACKED' },
  { label: 'Secondary (Non-USPS)', value: 'SECONDARY' },
  { label: 'Secondary Contained (Non-USPS)', value: 'SECONDARY_CONTAINED' },
  { label: 'Secondary Packed (Non-USPS)', value: 'SECONDARY_PACKED' },
  { label: 'ORMD (Non-USPS)', value: 'ORMD' },
  { label: 'Lithium (Non-USPS)', value: 'LITHIUM' },
];
