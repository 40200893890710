import axios from 'config/axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { paths } from 'api/types';

export type GetShipmentsQuery = paths['/api/v3/shipments']['get']['parameters']['query'];
export type GetShipmentsResponse = paths['/api/v3/shipments']['get']['responses']['200']['content']['application/json'];

export const getShipments = async (input: GetShipmentsQuery) => {
  const { data } = await axios.get<GetShipmentsResponse>(`/v4/shipments`, {
    params: input,
  });
  return data;
};

const useGetShipments = (input: GetShipmentsQuery, opts?: UseQueryOptions<any>) =>
  useQuery<GetShipmentsResponse>(['shipments', input], () => getShipments(input), opts);

export { useGetShipments };
