export const DHLEXPRESSLogo = (props: { width?: number; height?: number }) => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 196 63.7"
    width={props.width ?? 25}
    height={props.height ?? 25}
  >
    <rect width="196" height="63.7" fill="#fc0" />
    <g>
      <g>
        <g>
          <polygon
            points="0 29.9011 26.5058 29.9011 25.0581 31.8714 0 31.8714 0 29.9011 0 29.9011"
            fill="#d40511"
          />
          <polygon
            points="0 26.2454 29.1986 26.2454 27.7483 28.213 0 28.213 0 26.2454 0 26.2454"
            fill="#d40511"
          />
          <polygon
            points="0 33.5595 23.8146 33.5595 22.3733 35.5163 0 35.5163 0 33.5595 0 33.5595"
            fill="#d40511"
          />
        </g>
        <g>
          <polygon
            points="196 31.8714 169.5976 31.8714 171.0446 29.9024 196 29.9024 196 31.8714 196 31.8714"
            fill="#d40511"
          />
          <polygon
            points="196 35.5163 166.9128 35.519 168.3537 33.5595 196 33.5595 196 35.5163 196 35.5163"
            fill="#d40511"
          />
          <polygon
            points="173.7354 26.2454 196 26.2454 196 28.2143 172.287 28.2157 173.7354 26.2454 173.7354 26.2454"
            fill="#d40511"
          />
        </g>
      </g>
      <g>
        <path
          d="m25.6726,35.5163l12.578-17.0888h15.6082c1.7252,0,1.7026.655.8595,1.7975-.8562,1.16-2.3134,3.1547-3.1854,4.3323-.4426.5982-1.2431,1.6881,1.4089,1.6881h20.9101c-1.739,2.3822-7.3796,9.2709-17.5083,9.2709h-30.671Z"
          fill="#d40511"
        />
        <path
          d="m97.7056,26.244l-6.8202,9.2723h-17.9924s6.8172-9.2709,6.8233-9.2709l17.9893-.0014h0Z"
          fill="#d40511"
        />
        <path
          d="m123.7239,26.2454l-6.8239,9.2709h-17.9856s6.8178-9.2709,6.8239-9.2709h17.9856Z"
          fill="#d40511"
        />
        <path
          d="m129.5749,26.2454c.0006,0-1.314,1.7975-1.9528,2.6604-2.2593,3.0534-.2626,6.6105,7.1109,6.6105h28.8853l6.8226-9.2709h-40.8661Z"
          fill="#d40511"
        />
      </g>
      <g>
        <path
          d="m34.4685,7.8007l-6.2621,8.5079h34.1288c1.7249,0,1.7023.655.8593,1.7975-.8562,1.1587-2.2894,3.1763-3.1614,4.3539-.4427.5969-1.2431,1.6867,1.4089,1.6867h13.9564s2.2495-3.0615,4.1352-5.6206c2.5655-3.4815.2225-10.7254-8.9486-10.7254h-36.1165Z"
          fill="#d40511"
        />
        <polygon
          points="125.2688 24.1467 81.2619 24.1467 93.2956 7.8007 111.2819 7.8007 104.3858 17.173 112.4136 17.173 119.3153 7.8007 137.2989 7.8007 125.2688 24.1467 125.2688 24.1467"
          fill="#d40511"
        />
        <path
          d="m162.2084,7.8007l-12.0314,16.346h-19.0593s12.0355-16.346,12.0415-16.346h19.0492Z"
          fill="#d40511"
        />
      </g>
    </g>
    <g>
      <path
        d="m64.2428,55.8994v-12.9832h8.6555v2.5375h-5.7048v2.6557h5.1146v2.4393h-5.1146v2.813h5.9012v2.5375h-8.852Z"
        fill="#d40511"
      />
      <path
        d="m74.1769,55.8994l3.1473-4.9964-2.9309-5.0359h3.1277l1.6327,3.0882h.3147l1.6327-3.0882h2.9903l-2.9312,5.0359,3.1476,4.9964h-3.128l-1.8491-3.1277h-.3147l-1.8491,3.1277h-2.99Z"
        fill="#d40511"
      />
      <path
        d="m85.6843,59.6368v-13.7698h2.6952v1.731c.2755-.603.6719-1.0753,1.1901-1.4163.5179-.341,1.1114-.5115,1.7803-.5115,1.1276,0,2.0095.4298,2.6456,1.2884.6361.8592.9541,2.0362.9541,3.5312v.787c0,1.495-.318,2.6723-.9541,3.5308-.6361.8592-1.5179,1.2887-2.6456,1.2887-1.2985,0-2.2361-.6033-2.813-1.81v5.3506h-2.8525Zm4.5835-11.5668c-.7212,0-1.2981.3082-1.731.9247v3.7965c.4328.6033,1.0098.9048,1.731.9048.5902,0,1.0426-.213,1.3576-.6391.3147-.4261.472-1.0392.472-1.8394v-.6688c0-.7998-.1573-1.4129-.472-1.8394-.315-.4261-.7674-.6395-1.3576-.6395Z"
        fill="#d40511"
      />
      <path
        d="m97.1134,55.8994v-10.0324h2.6956v2.498c.1432-.4457.3376-.8687.5794-1.2688.2431-.3997.5577-.7279.9453-.9835.3862-.2559.8684-.3835,1.445-.3835.1188,0,.2269.0034.3255.0098.0972.0068.2188.023.3633.049v2.6361c-.1053-.0128-.2201-.0226-.3444-.0294-.1242-.0064-.2458-.0098-.3633-.0098-.7225,0-1.3127.1374-1.7705.4129-.4592.2755-.8008.5706-1.0237.8852v6.2162h-2.8522Z"
        fill="#d40511"
      />
      <path
        d="m109.4278,56.1354c-1.5085,0-2.7118-.4261-3.6098-1.2786-.8987-.8525-1.3478-2.046-1.3478-3.5801v-.787c0-1.5078.4166-2.6949,1.2492-3.5605s1.944-1.2985,3.3343-1.2985c1.4031,0,2.4889.4328,3.256,1.2985s1.1506,2.0656,1.1506,3.5997v1.2198h-6.1568c.1573,1.4035.9372,2.1047,2.3404,2.1047.4592,0,.8819-.0655,1.2688-.1965s.7704-.3214,1.1513-.5706l1.1601,1.7901c-.4983.3805-1.0655.6854-1.7016.9149-.6361.2292-1.3343.344-2.0946.344Zm-.4328-8.2227c-.5638,0-.9838.1806-1.2593.5409-.2748.3609-.4322.869-.472,1.5247h3.4626c-.0398-.6557-.1972-1.1638-.4727-1.5247-.2748-.3602-.6948-.5409-1.2586-.5409Z"
        fill="#d40511"
      />
      <path
        d="m118.5347,56.1354c-.813,0-1.5341-.105-2.1635-.3147s-1.2195-.5439-1.7705-1.0034l1.1412-1.8292c.8386.6293,1.757.944,2.7536.944,1.0358,0,1.5544-.3079,1.5544-.9244,0-.4328-.3613-.7279-1.0824-.8852l-1.0817-.236c-.9048-.1968-1.5969-.5608-2.075-1.0919-.4794-.5311-.7185-1.1965-.7185-1.9967,0-.9963.3572-1.7735,1.0723-2.3309.7144-.5574,1.7016-.8363,2.9602-.8363,1.4821,0,2.7611.3872,3.836,1.1607l-1.0817,1.8296c-.7212-.5243-1.5409-.787-2.4592-.787-.944,0-1.416.2887-1.416.8657,0,.472.3606.7867,1.0817.9443l1.102.236c1.8616.4065,2.7928,1.4035,2.7928,2.99,0,1.0362-.3903,1.8394-1.1702,2.4099-.7806.5702-1.8724.8555-3.2756.8555Z"
        fill="#d40511"
      />
      <path
        d="m128.0954,56.1354c-.813,0-1.5341-.105-2.1635-.3147s-1.2195-.5439-1.7705-1.0034l1.1412-1.8292c.8386.6293,1.757.944,2.7536.944,1.0358,0,1.5544-.3079,1.5544-.9244,0-.4328-.3613-.7279-1.0824-.8852l-1.0817-.236c-.9048-.1968-1.5969-.5608-2.075-1.0919-.4794-.5311-.7185-1.1965-.7185-1.9967,0-.9963.3572-1.7735,1.0723-2.3309.7144-.5574,1.7016-.8363,2.9602-.8363,1.4821,0,2.7611.3872,3.836,1.1607l-1.0817,1.8296c-.7212-.5243-1.5409-.787-2.4592-.787-.944,0-1.416.2887-1.416.8657,0,.472.3606.7867,1.0817.9443l1.102.236c1.8616.4065,2.7928,1.4035,2.7928,2.99,0,1.0362-.3903,1.8394-1.1702,2.4099-.7806.5702-1.8724.8555-3.2756.8555Z"
        fill="#d40511"
      />
    </g>
  </svg>
);
