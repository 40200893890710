enum Op {
  Eq = 'eq',
  Neq = 'neq',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  In = 'in',
  NotIn = 'not_in',
  Contains = 'contains',
  NotContains = 'not_contains',
  StartsWith = 'starts_with',
  NotStartsWith = 'not_starts_with',
}

export enum NumberCondition {
  AmountLineItems = 'amount_line_items',
  WeightLineItems = 'weight_line_items',
  OrderValue = 'order_value',
  OrderTotal = 'order_total',
  USPSZone = 'usps_zone',
  AmountProducts = 'amount_products',
  OrderWeightOz = 'order_weight_oz',
}
export enum StringCondition {
  CustomerName = 'customer_name',
  ProductName = 'product_name',
  ProductSKU = 'product_sku',
  ToCity = 'to_city',
  ShippingMethod = 'shipping_method',
}
export enum SelectCondition {
  ToCountry = 'to_country',
  ToState = 'to_state',
  Platform = 'platform',
  StoreName = 'store_name',
  SomeTags = 'some_tags',
  SomeProductsTags = 'some_products_tags',
  AllTags = 'all_tags',
  OrderStatus = 'order_status',
}
export enum BooleanCondition {
  IsDomestic = 'is_domestic',
  HasSelectedRate = 'has_selected_rate',
  HasVerifiedToAddress = 'has_verified_to_address',
  IsAddressPOBox = 'is_address_po_box',
}

export type NumberOperator = Op.Eq | Op.Neq | Op.Gt | Op.Gte | Op.Lt | Op.Lte;
export type SelectOperator = Op.In | Op.NotIn;
export type BooleanOperator = Op.Eq | Op.Neq;
export type StringOperator = Op.Contains | Op.NotContains | Op.StartsWith | Op.NotStartsWith | Op.Eq | Op.Neq;

export type Condition =
  | { type: NumberCondition; operator: string; value: number }
  | { type: StringCondition; operator: string; value: string }
  | { type: BooleanCondition; operator?: null; value: boolean }
  | { type: SelectCondition; operator: string; value: SelectConditionValue[] }
  | { type: 'custom'; operator?: null; value: string };

export type SelectConditionValue = { label: string; value: any; options?: Array<SelectConditionValue> };

export type ConditionFilter = Array<
  | {
      field: NumberCondition | StringCondition | BooleanCondition | 'custom';
      type: 'text' | 'number' | 'boolean';
      operators: Op[];
      options?: null;
    }
  | {
      field: SelectCondition;
      type: 'select';
      operators: Op[];
      options: Array<SelectConditionValue>;
    }
>;

export enum ActionType {
  SetInsuranceAmount = 'set_insurance_amount',
  SetDimensions = 'set_dimensions',
  SetWeight = 'set_weight',
  SkipPlatformNotification = 'skip_platform_notification',
  SkipImport = 'skip_import',
  SetBox = 'set_box',
  SetCustomBox = 'set_custom_box',
  SetFromAddress = 'set_from_address',
  SetRate = 'set_rate',
  SetLowestRate = 'set_lowest_rate',
  SetTags = 'set_tags',
  SetResidential = 'set_to_address_as_residential',
  SetStatus = 'set_status',
  SetDeliveryConfirmation = 'set_delivery_confirmation',
  SetCustom = 'set_custom',
  SetHazmat = 'set_hazmat',
  SetPaymentMethod = 'set_payment_method',
}

export type Action =
  | { type: ActionType.SetInsuranceAmount; data: number }
  | { type: ActionType.SetDimensions; data: { length: number; width: number; height: number } }
  | { type: ActionType.SetWeight; data: number }
  | { type: ActionType.SkipPlatformNotification; data: boolean }
  | { type: ActionType.SkipImport; data: boolean }
  | { type: ActionType.SetBox; data: { id: number } }
  | { type: ActionType.SetCustomBox; data: { id: number } }
  | { type: ActionType.SetFromAddress; data: { id: string } }
  | { type: ActionType.SetRate; data: { id: string } }
  | { type: ActionType.SetLowestRate; data: { ids: string[] } }
  | { type: ActionType.SetTags; data: { ids: string[] } }
  | { type: ActionType.SetResidential; data: boolean }
  | { type: ActionType.SetStatus; data: string }
  | { type: ActionType.SetDeliveryConfirmation; data: string }
  | { type: ActionType.SetHazmat; data: string }
  | { type: ActionType.SetCustom; data: { type: string; value: string } }
  | {
      type: ActionType.SetPaymentMethod;
      data: {
        type: string;
        account?: string;
        postalCode?: string;
        country?: string;
      };
    };
