import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {
    backdropFilter: 'blur(3px)',
  },
  body: {
    fontSize: 'sm',
    color: 'muted',
  },
  dialog: {},
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
