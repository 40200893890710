import { HStack } from '@chakra-ui/layout';
import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AgGridReact } from 'ag-grid-react';
import { useDeleteMutation, useGetQuery, usePostMutation } from 'api/client';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { MouseEvent, RefObject, forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { LuChevronDown, LuCopy, LuTrash2 } from 'react-icons/lu';
import { ArrayElement } from 'types/common';
import { z } from 'zod';

interface ViewsProps {
  viewEntity: string;
  selected?: 'views' | 'status';
  onViewSelect?: () => void;
}

const schema = z.object({
  name: z.string().nonempty({ message: 'Name is required' }),
});

type FormValues = z.infer<typeof schema>;

export const Views = forwardRef<AgGridReact, ViewsProps>((props, ref) => {
  const { viewEntity, selected, onViewSelect } = props;

  const gridRef = ref as RefObject<AgGridReact>;
  const gridApi = gridRef.current?.api;

  const views = useGetQuery('/api/v3/views', { params: { query: { entity: viewEntity } } });
  const createView = usePostMutation('/api/v3/views');
  const deleteView = useDeleteMutation('/api/v3/views/{id}');

  const [selectedView, setSelectedView] = useState<ArrayElement<(typeof views)['data']> | null>();

  const createViewModal = useDisclosure();
  const deleteViewModal = useDisclosure();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });
  const { handleSubmit, formState, register, reset } = form;

  const onSelect = (view: typeof selectedView | null, e: MouseEvent | null) => {
    if (view === null) {
      setSelectedView(null);
      return;
    }
    const currentViewId = selectedView?.id;
    setSelectedView(view);
    onViewSelect?.();

    if (!view) {
      gridApi?.setFilterModel(null);
      gridApi?.onFilterChanged();
      gridApi?.deselectAll();
    } else if (view.id) {
      // First click: fetches data
      if (view.id !== currentViewId) {
        e?.preventDefault();
        gridApi?.setFilterModel(view.filter);
        gridApi?.onFilterChanged();
        gridApi?.deselectAll();
      }
    }
  };

  useEffect(() => {
    if (!selected) return;
    if (selected === 'status') onSelect(null, null);
  }, [selected]);

  const onViewDelete = () => {
    deleteView.mutate(
      { params: { path: { id: String(selectedView?.id) } } },
      {
        onSuccess: () => {
          onSelect(null, null);
          views.refetch();
        },
      },
    );
  };

  const onViewCreate = async (e: FormValues) => {
    const filterModel = gridApi?.getFilterModel() as Record<string, never>;
    if (!filterModel) return;
    createView.mutate(
      {
        body: {
          name: e.name,
          entity: viewEntity,
          filter: filterModel,
        },
      },
      {
        onSuccess: async (newView) => {
          createViewModal.onClose();
          const { data } = await views.refetch();
          onSelect(
            data?.find((view) => view.id === newView?.id),
            null,
          );
          reset();
        },
      },
    );
  };

  return (
    <>
      <HStack>
        <Button
          size="sm"
          minW={14}
          as={Button}
          onClick={(e) => onSelect(undefined, e)}
          variant={selectedView === undefined ? 'solid' : 'unstyled'}
        >
          All
        </Button>

        {views?.data?.map((view) => {
          const isSelected = selectedView?.id === view.id;

          return (
            <Menu key={view.id} isLazy>
              <MenuButton
                minW={14}
                as={Button}
                size="sm"
                variant={isSelected ? 'solid' : 'ghost'}
                onClick={(e) => onSelect(view, e)}
              >
                {view.name} {isSelected && <Icon as={LuChevronDown} pt={1} />}
              </MenuButton>
              <MenuList fontSize="sm" zIndex="popover">
                <MenuItem icon={<LuCopy />} onClick={createViewModal.onOpen}>
                  Duplicate
                </MenuItem>
                <MenuItem icon={<LuTrash2 />} onClick={deleteViewModal.onOpen}>
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          );
        })}

        <IconButton
          size="sm"
          icon={<FiPlus />}
          variant={'ghost'}
          aria-label="Create new view"
          onClick={createViewModal.onOpen}
        />
      </HStack>

      {/* Delete  */}
      {selectedView && (
        <ConfirmationModal
          header={`Delete "${selectedView?.name}"`}
          description={`Are you sure you want to delete this view?`}
          isOpen={deleteViewModal.isOpen}
          onClose={deleteViewModal.onClose}
          onConfirm={() => onViewDelete()}
          type={'delete'}
        />
      )}

      {/* Create */}
      <Modal isOpen={createViewModal.isOpen} onClose={createViewModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <chakra.form onSubmit={handleSubmit((e) => onViewCreate(e))}>
            <ModalHeader>Create new View</ModalHeader>
            <ModalBody>
              <FormControl isInvalid={!!formState.errors.name}>
                <Input {...register('name')} placeholder="View name" />
                <FormErrorMessage>{formState?.errors?.name?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                isDisabled={createView.isLoading}
                variant="outline"
                onClick={() => {
                  createViewModal.onClose();
                  reset();
                }}
              >
                Cancel
              </Button>
              <Button
                isLoading={createView.isLoading}
                ml={2}
                variant="solid"
                type="submit"
                colorScheme="brand"
              >
                Save
              </Button>
            </ModalFooter>
          </chakra.form>
        </ModalContent>
      </Modal>
    </>
  );
});
