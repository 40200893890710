import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { EmptyState } from 'components/EmptyState';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { Carrier } from 'types/enums';
import { stopPropagate } from 'utils/misc';
import { z } from 'zod';
import { CountrySelect } from './CountrySelect';
import { customsFormSchema } from './schemas';
import { FormValues as FV } from '../index';
import { CreateShipmentResponse } from 'api/shipments/create';

const nonDeliveryOptions = [
  { label: 'Return to sender', value: 'return' },
  { label: 'Abandon', value: 'abandon' },
];

const contentsTypes = [
  { label: 'Merchandise', value: 'merchandise' },
  { label: 'Documents', value: 'documents' },
  { label: 'Gift', value: 'gift' },
  { label: 'Returned goods', value: 'returned_goods' },
  { label: 'Sample', value: 'sample' },
  { label: 'Other', value: 'other' },
];

type FormValues = z.infer<typeof customsFormSchema>;

export function CustomsDrawer(
  props: Omit<DrawerProps, 'children'> & {
    parcels: CreateShipmentResponse['parcels'];
    selectedRate?: { carrier: string };
  },
) {
  const { onClose, isOpen, selectedRate, parcels } = props;

  const form = useFormContext<FV>();

  const { control, handleSubmit, register, getValues, setValue, reset, formState } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(customsFormSchema),
    defaultValues: {
      customs: {
        nonDeliveryOption: 'return',
        contentsType: 'merchandise',
        items: [],
      },
    },
  });
  const { fields, append, remove, replace } = useFieldArray({ control, name: 'customs.items' });

  const customs = useWatch({ control, name: 'customs' });

  const handleClose = () => {
    onClose();
    reset();
  };

  // Populate form when drawer opens
  useEffect(() => {
    if (isOpen) {
      const parentValue = form.getValues('customs');
      if (parentValue) setValue('customs', parentValue);
      if (parentValue?.items) replace(parentValue.items);
    }
  }, [isOpen]);

  const appendItem = () => append({} as any);

  return (
    <Drawer {...props} size="md">
      <DrawerOverlay />
      <form
        onSubmit={stopPropagate(
          handleSubmit((v) => {
            form.setValue('customs', v.customs);
            handleClose();
          }),
        )}
        noValidate
      >
        <DrawerContent>
          <DrawerHeader>
            Customs Form
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody>
            <FormControl variant="floating">
              <Select {...register('customs.contentsType')} defaultValue={undefined}>
                {contentsTypes.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <FormLabel>Type of Contents</FormLabel>
            </FormControl>

            <FormControl py="4">
              <FormLabel>Non-delivery</FormLabel>
              <Controller
                name="customs.nonDeliveryOption"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    onChange={field.onChange}
                    value={field.value}
                    colorScheme="brand"
                    defaultValue="return"
                  >
                    <HStack>
                      {nonDeliveryOptions.map((option) => (
                        <Radio value={option.value} key={option.value}>
                          <Text>{option.label}</Text>
                        </Radio>
                      ))}
                    </HStack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl hidden={selectedRate?.carrier !== 'UPS'}>
              <FormLabel>Duties & Taxes</FormLabel>
              <Checkbox {...register('customs.deliveryDutyPaid')}>
                <Text>Shipper to pay Duties & Taxes (DDP)</Text>
              </Checkbox>
            </FormControl>

            <Text py="4" fontWeight="medium" fontSize="md">
              Items
            </Text>

            {fields?.length === 0 && (
              <Box border="1px dashed" rounded="lg" bg="zinc.50" borderColor="zinc.300" p="7">
                <EmptyState
                  header="No items added yet."
                  icon={FiSearch}
                  secondaryAction={{
                    children: 'Add',
                    size: 'sm',
                    variant: 'outline',
                    rightIcon: <FiPlus />,
                    onClick: () => appendItem(),
                  }}
                >
                  <Text>Add your first customs items.</Text>
                </EmptyState>
              </Box>
            )}

            <Stack spacing="4">
              {fields.map((f, index) => {
                return (
                  <Stack key={f.id} border="1px" borderColor="gray.200" rounded="lg" p="3" spacing="3.5">
                    <Flex justify="space-between">
                      <Text fontWeight="medium">Item #{index + 1}</Text>
                      <HStack spacing="1">
                        <IconButton
                          icon={<HiOutlineDuplicate />}
                          size="xs"
                          title="Duplicate"
                          variant="outline"
                          aria-label="Duplicate"
                          onClick={() => append(getValues(`customs.items.${index}`))}
                        />
                        <IconButton
                          icon={<FiTrash2 />}
                          size="xs"
                          color="red.500"
                          borderColor="red.400"
                          variant="outline"
                          aria-label="Remove"
                          onClick={() => remove(index)}
                        />
                      </HStack>
                    </Flex>
                    <FormControl
                      variant="floating"
                      isRequired
                      isInvalid={!!formState.errors.customs?.items?.[index]?.description}
                    >
                      <Input {...register(`customs.items.${index}.description`)} placeholder="" />
                      <FormLabel>Description</FormLabel>
                    </FormControl>
                    <HStack>
                      <FormControl
                        variant="floating"
                        isRequired
                        isInvalid={!!formState.errors.customs?.items?.[index]?.quantity}
                      >
                        <Input {...register(`customs.items.${index}.quantity`)} placeholder="" />
                        <FormLabel>Quantity</FormLabel>
                      </FormControl>
                      <FormControl
                        variant="floating"
                        isRequired
                        isInvalid={!!formState.errors.customs?.items?.[index]?.value}
                      >
                        <Input {...register(`customs.items.${index}.value`)} placeholder="" />
                        <FormLabel>Value ($)</FormLabel>
                      </FormControl>
                      <FormControl
                        variant="floating"
                        isRequired
                        isInvalid={!!formState.errors.customs?.items?.[index]?.weight}
                      >
                        <Input {...register(`customs.items.${index}.weight`)} placeholder="" />
                        <FormLabel>Weight (oz)</FormLabel>
                      </FormControl>
                    </HStack>
                    <HStack>
                      <FormControl
                        variant="floating"
                        isInvalid={!!formState.errors.customs?.items?.[index]?.hsTariffNumber}
                      >
                        <Input {...register(`customs.items.${index}.hsTariffNumber`)} placeholder="" />
                        <FormLabel>HS Tariff</FormLabel>
                      </FormControl>
                      <FormControl
                        variant={
                          getValues(`customs.items.${index}`)?.originCountry ? 'floating-active' : 'floating'
                        }
                        isRequired
                        isInvalid={!!formState.errors.customs?.items?.[index]?.originCountry}
                      >
                        {/* <Controlled /> not working here for some reason */}
                        <CountrySelect
                          value={customs?.items?.[index]?.originCountry}
                          onChange={(value: any) => setValue(`customs.items.${index}.originCountry`, value)}
                        />
                        <FormLabel>Origin Country</FormLabel>
                      </FormControl>
                    </HStack>

                    <RadioGroup
                      value={getValues(`customs.items.${index}.parcelId`)}
                      onChange={(value) => setValue(`customs.items.${index}.parcelId`, value)}
                      colorScheme="brand"
                      defaultValue="0"
                      hidden={parcels && parcels?.length < 2}
                    >
                      <Stack direction="row">
                        {parcels?.map((p, index) => (
                          <Radio key={index} size="sm" value={p?.id}>
                            Parcel #{index + 1} ({p?.width}x{p?.height}x{p?.length})
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  </Stack>
                );
              })}
              {fields.length > 0 && (
                <Flex pb="4" justify="end">
                  <Button size="sm" variant="outline" onClick={() => appendItem()}>
                    Add +
                  </Button>
                </Flex>
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Button mr={3} variant="outline" onClick={handleClose}>
              Discard
            </Button>
            <Button isDisabled={!getValues('customs.items')?.length} colorScheme="brand" type="submit">
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}
