import { axios } from 'config/axios';
import { UseMutationOptions, useMutation } from 'react-query';

export type CreateProductFileInput = FormData;
export type CreateProductFileResponse = any;

export const useCreateProductFile = (
  opts?: UseMutationOptions<CreateProductFileResponse, Error, CreateProductFileInput>,
) =>
  useMutation<CreateProductFileResponse, Error, CreateProductFileInput>(async (input) => {
    const response = await axios.post(`/v3/products/files`, input, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response?.data;
  }, opts);
