import {
  Button,
  Checkbox,
  PopoverContent,
  PopoverTrigger,
  Popover,
  useDisclosure,
  Tag,
  PopoverBody,
  Center,
  HStack,
  VStack,
  Text,
} from '@chakra-ui/react';
import { noop, startCase } from 'lodash';
import { FC, useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

type Props = {
  selectTitle: string;
  selectedValues: string[];
  onSelect: (selectedStatuses: string[]) => void;
};

export const SelectPopover: FC<Props> = (props) => {
  const { onSelect = noop, selectedValues: initialValues, selectTitle } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { isOpen } = useDisclosure();

  const onSelectValues = (status: string, checked: boolean) => {
    let selected = [...selectedValues];
    if (checked) selected.push(status);
    else selected = [...selected.filter((z) => z !== status)];
    setSelectedValues([...selected]);
    onSelect(selected);
  };

  const onClearSelected = () => {
    setSelectedValues([]);
    onSelect([]);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" variant="outline" rightIcon={isOpen ? <FaCaretUp /> : <FaCaretDown />}>
          {selectedValues.length > 0 && (
            <Tag size="sm" mr={1}>
              {selectedValues.length}
            </Tag>
          )}
          {selectTitle}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverBody p={5}>
          <VStack align="start">
            {initialValues.map((z) => (
              <HStack key={z}>
                <Checkbox
                  value={z}
                  isChecked={selectedValues.includes(z)}
                  onChange={(event) => onSelectValues(z, event.target.checked)}
                />
                <Text>{startCase(z)}</Text>
              </HStack>
            ))}
          </VStack>
          <Center mt={3}>
            <Button isDisabled={!selectedValues.length} variant="outline" size="sm" onClick={onClearSelected}>
              Clear
            </Button>
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
