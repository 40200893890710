import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  Textarea,
  useColorModeValue,
  useOutsideClick,
} from '@chakra-ui/react';
import { RadioGroup } from '@headlessui/react';
import { useExtractAddress } from 'api/addresses/extract';
import { Select as ReactSelect } from 'chakra-react-select';
import { LineOneInput } from 'components/LineOneInput';
import { PhoneInput } from 'components/PhoneInput';
import { ALL_STATES as allStates } from 'constants/states';
import { isEmpty } from 'lodash';
import { ClipboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FiAlertCircle, FiAlignJustify, FiClipboard, FiEdit2 } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { useQuickShipStore } from 'store';
import { chakraReactSelectStyles } from 'theme';
import { z } from 'zod';
import { CountrySelect } from './CountrySelect';

export const toAddressSchema = z.object({
  to: z
    .object({
      name: z.string().nonempty('Required'),
      company: z.string().optional(),
      line1: z.string().nonempty('Required'),
      line2: z.string().optional(),
      city: z.string().nonempty('Required'),
      state: z.string().nonempty('Required'),
      postal: z.string().nonempty('Required'),
      phone: z.string().optional(),
      country: z.string().nonempty('Required'),
      email: z.string().optional(),
      printCustom1: z.string().optional(),
      pastedText: z.string().optional(),
      residential: z.boolean().optional(),
    })
    .refine((data) => data.country === 'US' || !!data.phone, { path: ['phone'] }),
});

type FieldValues = z.infer<typeof toAddressSchema>;

export function ToAddress() {
  const { showPasteMode, showToEditMode, patch } = useQuickShipStore();

  const formRef = useRef(null);

  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  const methods = useFormContext<FieldValues>();
  const { register, control, setValue, trigger, formState, getValues } = methods;

  const to = useWatch({ control, name: 'to' });

  const extractAddress = useExtractAddress();

  const hasErrors = !isEmpty(formState.errors?.to);
  const isInternational = !!to?.country && to?.country !== 'US';

  useOutsideClick({
    ref: formRef,
    handler: useCallback(async () => {
      // Validate with Zod instead of using `trigger` so the input fields don't get red
      const valid = toAddressSchema.safeParse(getValues()).success;
      if (showToEditMode && valid) patch({ showToEditMode: false });
    }, [showToEditMode, trigger]),
  });

  useEffect(() => {
    const valid = toAddressSchema.safeParse(getValues()).success;
    if (!valid) patch({ showToEditMode: true });
  }, []);

  useEffect(() => {
    if (isInternational) setValue('to.phone', '');
    else setValue('to.phone', undefined);
  }, [isInternational]);

  useEffect(() => {
    if (selectedAddress) {
      populateAddress({
        line1: selectedAddress.line1,
        name: selectedAddress.name,
        company: selectedAddress.company,
        city: selectedAddress.city,
        state: selectedAddress.state,
        postal: selectedAddress.postal,
        country: selectedAddress.country,
      });
    }
  }, [selectedAddress]);

  // Reset the extractAddress mutation once pastedText is cleared
  useEffect(() => {
    if (!to?.pastedText && extractAddress.isSuccess) {
      extractAddress.reset();
      patch({ showToEditMode: true });
    }
  }, [to?.pastedText]);

  useEffect(() => {
    if (extractAddress?.data && extractAddress?.isSuccess && !isEmpty(extractAddress?.data || [])) {
      setSelectedAddress(extractAddress?.data[0]);
    }
  }, [extractAddress?.data]);

  const populateAddress = (address: {
    line1?: string;
    line2?: string | null;
    city?: string;
    state?: string;
    postal?: string;
    name?: string;
    company?: string;
    phone?: string;
    email?: string;
    country?: string;
    residential?: boolean;
  }) => {
    if (!address) return;

    if (address.line1) setValue('to.line1', address.line1 || '');
    if (address.line2) setValue('to.line2', address.line2 || '');
    if (address.city) setValue('to.city', address.city || '');
    if (address.state) setValue('to.state', address.state || '');
    if (address.postal) setValue('to.postal', address.postal || '');
    if (address.name) setValue('to.name', address.name || '');
    if (address.company) setValue('to.company', address.company || '');
    if (address.phone) setValue('to.phone', address.phone || '');
    if (address.email) setValue('to.email', address.email || '');
    if (address.country) setValue('to.country', address.country || '');
    if (address.residential) setValue('to.residential', address.residential || null);

    trigger('to');
  };

  const resetForm = () => {
    setValue('to', {
      ...getValues('to'),
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal: '',
      phone: undefined,
      name: '',
      company: '',
      country: '',
      email: '',
      printCustom1: '',
      residential: false,
    });
  };

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const text = e.clipboardData.getData('text/plain');
    extractAddress.mutateAsync(
      { address: text },
      {
        onSuccess: () => {
          resetForm();
          setValue('to.pastedText', text, { shouldDirty: true });
        },
      },
    );
  };

  const formMarkup = (
    <Stack spacing="3.5">
      {/* Paste Mode */}
      {showPasteMode && (
        <Stack spacing="1.5" pb="3">
          {/* Paste input */}
          {!extractAddress.isSuccess && (
            <Textarea
              minH="100px"
              {...register('to.pastedText')}
              disabled={extractAddress.isLoading}
              fontSize="sm"
              placeholder="Paste your address here..."
              onPaste={handlePaste}
              onKeyDown={(e) => {
                // Submit on ctrl/cmd + enter
                if ((e.metaKey || e.ctrlKey) && e.code === 'Enter') {
                  const text = getValues('to.pastedText');
                  if (!text) return;

                  extractAddress.mutate({ address: text }, { onSuccess: resetForm });
                }
              }}
            />
          )}

          {/* Edit input */}
          {extractAddress.isSuccess ? (
            <HStack py="1">
              <Text isTruncated color="muted">
                {to?.pastedText?.split('\n').join(' ')}
              </Text>
              <IconButton
                variant="outline"
                size="xs"
                icon={<FiEdit2 />}
                aria-label="Edit pasted address"
                onClick={() => extractAddress.reset()}
              />
            </HStack>
          ) : null}

          {/* Results */}
          {isEmpty(extractAddress?.data || []) && extractAddress.isSuccess ? (
            <Box p="4" border="1px" borderColor="slate.200" rounded="lg">
              <Text fontSize="sm" color="muted" textAlign="center">
                No addresses found. Please enter your address manually or try again.
              </Text>
            </Box>
          ) : null}

          {!isEmpty(extractAddress?.data || []) ? (
            <Stack>
              <RadioGroup as={Stack} value={selectedAddress || ''} onChange={setSelectedAddress}>
                {extractAddress?.data?.map((address, index) => (
                  <RadioGroup.Option key={index} value={address} defaultChecked={index === 0}>
                    {({ checked }) => (
                      <HStack
                        overflow="auto"
                        spacing="2"
                        p="3"
                        bg="white"
                        border="1px"
                        borderColor="slate.200"
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{ bg: 'slate.50' }}
                        _active={{ bg: 'slate.100' }}
                      >
                        <chakra.input type="radio" checked={checked} onChange={() => undefined} />
                        <Stack spacing="0" color="muted" flexGrow={1}>
                          <Text fontWeight="medium" isTruncated>
                            {address.name || 'No name'}
                          </Text>
                          <Text fontSize="xs" isTruncated>
                            {address.line1}
                          </Text>
                          <Text fontSize="xs" isTruncated>
                            {address.city}, {address.state} {address.postal}
                          </Text>
                        </Stack>
                        <Tag ml="auto" colorScheme="green" alignSelf="start" size="sm">
                          Verified
                        </Tag>
                      </HStack>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
              <Flex justify="end">
                <Button
                  size="xs"
                  variant="link"
                  fontWeight="normal"
                  onClick={() => {
                    patch({ showPasteMode: false });
                    extractAddress.reset();
                    resetForm();
                  }}
                >
                  Enter address manually
                </Button>
              </Flex>
            </Stack>
          ) : null}
        </Stack>
      )}

      {/* Form */}
      <Stack spacing="3.5" hidden={showPasteMode && !formState.errors.to}>
        <FormControl
          variant={to?.country ? 'floating-active' : 'floating'}
          isRequired
          isInvalid={!!formState.errors?.to?.country}
        >
          <Controller
            control={control}
            name="to.country"
            render={({ field: { ref, onChange, value } }) => (
              <CountrySelect ref={ref} value={value} onChange={onChange} />
            )}
          />
          <FormLabel>Country</FormLabel>
        </FormControl>
        <FormControl isInvalid={!!formState.errors?.to?.name} variant="floating" isRequired>
          <Input {...register('to.name')} placeholder=" " />
          <FormLabel>Name</FormLabel>
        </FormControl>
        <FormControl variant="floating" isInvalid={!!formState.errors?.to?.company}>
          <Input {...register('to.company')} placeholder=" " />
          <FormLabel>Company</FormLabel>
        </FormControl>
        <FormControl
          variant={to?.line1?.length ? 'floating-active' : 'floating'}
          isRequired
          isInvalid={!!formState.errors?.to?.line1}
        >
          {isInternational ? (
            <Input {...register('to.line1')} placeholder=" " />
          ) : (
            <Controller
              control={control}
              name="to.line1"
              render={({ field: { ref, onChange, value } }) => (
                <LineOneInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  inputValue={to?.line1 || ''}
                  onSelected={(v: any) => populateAddress(v)}
                />
              )}
            />
          )}
          <FormLabel>Address 1</FormLabel>
        </FormControl>

        <FormControl variant="floating">
          <Input {...register('to.line2')} placeholder=" " />
          <FormLabel>Address 2</FormLabel>
        </FormControl>

        <FormControl variant="floating" isRequired isInvalid={!!formState.errors?.to?.city}>
          <Input {...register('to.city')} placeholder=" " />
          <FormLabel>City</FormLabel>
        </FormControl>

        <SimpleGrid columns={2} spacing="2">
          <FormControl
            variant={to?.state ? 'floating-active' : 'floating'}
            isRequired
            isInvalid={!!formState.errors?.to?.state}
          >
            {isInternational ? (
              <Input {...register('to.state')} placeholder=" " />
            ) : (
              <Controller
                control={control}
                name="to.state"
                render={({ field: { ref, onChange, value } }) => (
                  <ReactSelect
                    ref={ref}
                    placeholder=" "
                    useBasicStyles
                    options={allStates}
                    chakraStyles={chakraReactSelectStyles}
                    onChange={(e: any) => onChange(e.value)}
                    value={allStates.find((o) => o.value === value) || ''}
                    components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
                  />
                )}
              />
            )}
            <FormLabel>State</FormLabel>
          </FormControl>
          <FormControl variant="floating" isRequired isInvalid={!!formState.errors?.to?.postal}>
            <Input {...register('to.postal')} placeholder=" " />
            <FormLabel>Zip</FormLabel>
          </FormControl>
        </SimpleGrid>

        <Checkbox {...register('to.residential')}>
          <Text>Residential</Text>
        </Checkbox>

        {to?.phone !== undefined && (
          <FormControl variant="floating" isInvalid={!!formState.errors?.to?.phone} isRequired={isInternational}>
            <PhoneInput placeholder=" " {...register('to.phone')} country={to?.country} />
            <FormLabel>Phone</FormLabel>
          </FormControl>
        )}
        {to?.email !== undefined && (
          <FormControl variant="floating" isInvalid={!!formState.errors?.to?.email}>
            <Input placeholder=" " {...register('to.email')} />
            <FormLabel>Email</FormLabel>
          </FormControl>
        )}
        {to?.printCustom1 !== undefined && (
          <FormControl variant="floating" isInvalid={!!formState.errors?.to?.printCustom1}>
            <Input placeholder=" " {...register('to.printCustom1')} />
            <FormLabel>Print Custom</FormLabel>
          </FormControl>
        )}
        <ButtonGroup variant="outline" size="xs">
          {to?.email === undefined && <Button onClick={() => setValue('to.email', '')}>+ Email</Button>}
          {to?.phone === undefined && <Button onClick={() => setValue('to.phone', '')}>+ Phone</Button>}
          {to?.printCustom1 === undefined && (
            <Button onClick={() => setValue('to.printCustom1', '')}>+ Print Custom</Button>
          )}
        </ButtonGroup>
      </Stack>
    </Stack>
  );

  const bgDefault = useColorModeValue('slate.50', 'gray.800');
  const borderColorDefault = useColorModeValue('slate.300', 'gray.600');

  const viewMarkup = (
    <Flex
      p="3"
      border="1px"
      rounded="lg"
      bg={hasErrors ? 'red.50' : bgDefault}
      borderColor={hasErrors ? 'red.300' : borderColorDefault}
    >
      <Stack>
        <Stack spacing="0">
          {hasErrors ? (
            <>
              <HStack spacing="1">
                <Text fontWeight="medium">Invalid address</Text>
                <Icon as={FiAlertCircle} color="red.500" />
              </HStack>
              <Text color="muted">Please check all the fields.</Text>
            </>
          ) : (
            <>
              <Text fontWeight="medium">{to?.name ?? ''}</Text>
              <Text color="muted">{to?.company ?? ''}</Text>
              <Text color="muted">{to?.line1}</Text>
              <Text color="muted">{to?.line2 ?? ''}</Text>
              <Text color="muted">
                {to?.city} {to?.state} {to?.postal}, {to?.country}
              </Text>
              <Text color="muted">{to?.phone ?? ''}</Text>
              <Text color="muted">{to?.email ?? ''}</Text>
            </>
          )}
        </Stack>
      </Stack>
      <IconButton
        size="xs"
        icon={<FiEdit2 />}
        aria-label="Edit"
        variant="outline"
        alignSelf="end"
        ml="auto"
        onClick={() => patch({ showToEditMode: true })}
      />
    </Flex>
  );

  return (
    <Stack spacing="4" ref={formRef}>
      <Flex alignItems="center" gap="2">
        <Icon as={GoLocation} />
        <Text as="h3" fontSize="md" fontWeight="medium">
          Recipient Address
        </Text>
        <Button
          ml="auto"
          size="xs"
          rightIcon={showPasteMode ? <FiAlignJustify /> : <FiClipboard />}
          variant="outline"
          onClick={() => {
            if (!showToEditMode) patch({ showToEditMode: true, showPasteMode: true });
            else patch({ showPasteMode: !showPasteMode });
          }}
        >
          {showPasteMode ? 'Fields' : 'Paste'}
        </Button>
      </Flex>

      {showToEditMode ? formMarkup : viewMarkup}
    </Stack>
  );
}
