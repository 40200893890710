import { Divider, Flex, HStack, Stack, Text } from '@chakra-ui/layout';
import { Button, Card, CardBody, CardHeader, Spinner, Tag, useDisclosure } from '@chakra-ui/react';
import { startCase } from 'lodash';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { Link } from 'router';
import { PurchaseOrderImportModal } from '../_components/po-import-modal';
import { useGetQuery } from 'api/client';
import { dayjs } from 'utils/dates';
import { useEffect, useState } from 'react';

export default function () {
  const [showAll, setShowAll] = useState<boolean>(false);

  const pos = useGetQuery('/api/v3/purchase-orders', {});

  const importModal = useDisclosure();

  useEffect(() => {
    if (!importModal.isOpen) pos.refetch();
  }, [importModal.isOpen]);

  const isLoading = pos.isLoading;
  const isEmpty = !pos?.data?.length;

  const isLoadingMarkup = isLoading && (
    <Flex minH="md" alignItems={'center'} justifyContent={'center'}>
      <Spinner />
    </Flex>
  );

  const isEmptyMarkup = isEmpty && (
    <Flex minH="md" alignItems={'center'} justifyContent={'center'}>
      <Text color="muted">No purchase orders found</Text>
    </Flex>
  );
  const posMarkup = (
    <>
      {pos.data?.slice(0, showAll ? pos.data?.length : 10).map((po) => (
        <Link key={po.id} to="/inventories/receiving/:poId" params={{ poId: String(po.id) }}>
          <HStack key={po.id} p={2} _hover={{ bg: 'zinc.100' }} rounded="md">
            <Stack gap={0.5}>
              <Text fontWeight={'medium'}>PO #{po.sequence}</Text>
              <Text fontSize={'xs'} color="zinc.500">
                {dayjs(po.createdAt).fromNow()}
              </Text>
            </Stack>
            <Tag size="sm" ml="auto" colorScheme={po.status === 'completed' ? 'green' : undefined}>
              {startCase(po.status)}
            </Tag>
          </HStack>
        </Link>
      ))}
    </>
  );

  return (
    <>
      <Flex mt={4} gap={4}>
        <Card w="full">
          <CardHeader fontWeight="medium">
            <HStack>
              <LuFileSpreadsheet color="DimGray" />
              <h2>Purchase Orders</h2>
              <Button onClick={importModal.onOpen} ml="auto" size="sm" colorScheme="brand">
                Import
              </Button>
            </HStack>
          </CardHeader>
          <Divider />
          <CardBody>
            <Stack>
              {isLoadingMarkup || isEmptyMarkup || posMarkup}

              <Button
                hidden={showAll || isLoading || isEmpty}
                size="sm"
                mx="auto"
                w="fit-content"
                variant="outline"
                mt={2}
                onClick={() => setShowAll(true)}
              >
                Load more...
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </Flex>
      {importModal.isOpen && <PurchaseOrderImportModal {...importModal} />}
    </>
  );
}
