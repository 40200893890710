import { paths } from 'api/types';
import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';

export type GetProductsQuery = paths['/api/v3/products']['get']['parameters']['query'];
export type GetProductsResponse = paths['/api/v3/products']['get']['responses']['200']['content']['application/json'];

export const getProducts = async (input?: GetProductsQuery) => {
  if (!input) return null;

  const result = await axios.get(`/v3/products`, { params: { ...input } });
  return result?.data;
};

export const GET_PRODUCTS_QUERY = 'getProducts';

export const useGetProducts = (input?: GetProductsQuery, opts?: UseQueryOptions<any>) =>
  useQuery<GetProductsResponse>([GET_PRODUCTS_QUERY, input], () => getProducts(input), opts ?? {});
