import { Button, Center, CenterProps, HStack, Icon, Square, Text, VStack } from '@chakra-ui/react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FiUploadCloud } from 'react-icons/fi';
import { useState } from 'react';

export interface DropzoneProps extends CenterProps {
  dropzoneOptions?: DropzoneOptions;
}

export const Dropzone = (props: DropzoneProps) => {
  const { dropzoneOptions, ...rest } = props;

  const [isDragging, setIsDragging] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    ...dropzoneOptions,
  });

  return (
    <Center
      px="6"
      py="4"
      borderWidth="1.5px"
      borderRadius="md"
      borderStyle="dashed"
      bg={isDragging ? 'gray.50' : 'bg-surface'}
      {...rest}
      {...getRootProps()}
    >
      <VStack spacing="3">
        <Square size="10" bg="gray.100" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="gray.500" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            <Button variant="link" size="sm">
              Click to upload
            </Button>
            <Text textStyle="sm" color="gray.500">
              or drag and drop
            </Text>
          </HStack>
          <Text textStyle="xs" color="gray.500">
            {/* @TODO: This component is only used on /orders/import, make this text generic if needed. */}
            CSV (.csv) files are supported
          </Text>
        </VStack>
      </VStack>
      <input {...getInputProps()} />
    </Center>
  );
};
