import { useMutation, UseMutationOptions } from 'react-query';
import axios from 'config/axios';
import { AxiosError } from 'axios';
import { PhoneVerificationStatus } from 'types/enums';

interface VerifyPhoneResponse {
  status: PhoneVerificationStatus;
}
export type VerifyPhoneError = AxiosError<{ message: string }>;
type VerifyPhone = {
  verificationCode: string;
  userId: string;
};

const verifyPhone = async (vars: VerifyPhone) => {
  const { data } = await axios.post<VerifyPhoneResponse>(`/v3/user/${vars.userId}/phone`, {
    verificationCode: vars.verificationCode,
  });
  return data;
};

export const useVerifyPhone = (opts?: UseMutationOptions<VerifyPhoneResponse, VerifyPhoneError, VerifyPhone>) =>
  useMutation<VerifyPhoneResponse, VerifyPhoneError, VerifyPhone>(verifyPhone, opts);
