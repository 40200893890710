import { Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { PageHeader } from 'components/Page';
import { Reports } from './_components/reports';
import { GenerateReport } from './_components/generate-report';
import { Analytics } from './_components/analytics';
import { Manifests } from './_components/manifests';

export default function () {
  return (
    <Box>
      <HStack justify="space-between">
        <PageHeader>
          <h1>Reporting</h1>
        </PageHeader>
        <GenerateReport />
      </HStack>
      <Tabs colorScheme="brand" isLazy>
        <TabList>
          <Tab>Analytics</Tab>
          <Tab>Reports</Tab>
          <Tab>Manifests</Tab>
          <Tab isDisabled>Scheduled Reports</Tab>
        </TabList>
        <TabPanels p="0" m="0">
          <TabPanel px="0" pt="6">
            <Analytics />
          </TabPanel>
          <TabPanel px="0" pt="6">
            <Reports />
          </TabPanel>
          <TabPanel px="0" pt="6">
            <Manifests />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
