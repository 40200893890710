import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GET_ADDRESS_QUERY, useCreateAddress, useUpdateAddress } from 'api/addresses';
import { LineOneInput } from 'components/LineOneInput';
import { PhoneInput } from 'components/PhoneInput';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { FC, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { StateSelect } from 'pages/(app)/ship/_components/StateSelect';
import { z } from 'zod';

interface PropTypes {
  changeAddress?: any;
  onClose: () => void;
  isOpen: boolean;
}

const schema = z.object({
  name: z.string().nonempty('Required'),
  city: z.string().nonempty('Required'),
  postal: z.string().nonempty('Required'),
  nickname: z.string().nonempty('Required'),
  line1: z.string().nonempty('Required'),
  state: z.string().nonempty('Required'),
  phone: z.string().nonempty('Required'),
  company: z.string().optional().nullable().default(null),
  line2: z.string().optional().nullable().default(null),
  primary: z.boolean(),
  residential: z.boolean(),
});

type FormValues = z.infer<typeof schema>;

export const AddressForm: FC<PropTypes> = ({ changeAddress, isOpen, onClose }) => {
  const { notifySuccess } = useNotification();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
  });

  const { handleSubmit, formState, register, setValue, trigger, control } = form;
  const { line1, state } = useWatch({ control });
  const createAddress = useCreateAddress({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(GET_ADDRESS_QUERY);
      notifySuccess('Address created');
    },
  });
  const updateAddress = useUpdateAddress({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(GET_ADDRESS_QUERY);
      notifySuccess('Address updated');
    },
  });

  const populateAddress = (location: {
    line1?: string;
    line2?: string | null;
    city?: string;
    state?: string;
    postal?: string;
    name?: string;
    company?: string;
    phone?: string;
    email?: string;
    country?: string;
    primary?: boolean;
    nickname?: string;
    residential?: boolean;
  }) => {
    if (!location) return;

    if (location.line1) setValue('line1', location.line1 || '');
    if (location.line2) setValue('line2', location.line2 || '');
    if (location.city) setValue('city', location.city || '');
    if (location.state) setValue('state', location.state || '');
    if (location.postal) setValue('postal', location.postal || '');
    if (location.name) setValue('name', location.name || '');
    if (location.company) setValue('company', location.company || '');
    if (location.phone) setValue('phone', location.phone || '');
    if (location.primary) setValue('primary', location.primary);
    if (location.nickname) setValue('nickname', location.nickname || '');
    if (location.residential) setValue('residential', location.residential || false);

    trigger();
  };

  useEffect(() => {
    populateAddress(changeAddress);
  }, [changeAddress]);

  const onSubmit = (e: FormValues) => {
    if (changeAddress) {
      updateAddress.mutate({
        id: changeAddress.id,
        country: 'US',
        ...e,
      });
      return;
    }
    createAddress.mutate({
      country: 'US',
      ...e,
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{changeAddress ? 'Edit address' : 'New address'}</ModalHeader>
          <ModalCloseButton />
          <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
            <ModalBody>
              <VStack spacing={3.5}>
                <FormControl variant="floating" isInvalid={!!formState.errors.company}>
                  <Input placeholder=" " {...register('company')} />
                  <FormLabel>Company</FormLabel>
                </FormControl>
                <FormControl isRequired variant="floating" isInvalid={!!formState.errors.name}>
                  <Input placeholder=" " {...register('name')} />
                  <FormLabel>Name</FormLabel>
                </FormControl>
                <FormControl
                  variant={line1?.length ? 'floating-active' : 'floating'}
                  isRequired
                  isInvalid={!!formState.errors?.line1}
                >
                  <Controller
                    control={control}
                    name="line1"
                    render={({ field: { ref, onChange, value } }) => (
                      <LineOneInput
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        inputValue={line1 || ''}
                        onSelected={(v: any) => populateAddress(v)}
                      />
                    )}
                  />
                  <FormLabel>Address 1</FormLabel>
                </FormControl>
                <FormControl variant="floating">
                  <Input {...register('line2')} placeholder=" " />
                  <FormLabel>Address 2</FormLabel>
                </FormControl>
                <FormControl isRequired variant="floating" isInvalid={!!formState.errors.city}>
                  <Input placeholder=" " {...register('city')} />
                  <FormLabel>City</FormLabel>
                </FormControl>
                <HStack w="full">
                  <FormControl
                    variant={state ? 'floating-active' : 'floating'}
                    isInvalid={!!formState.errors?.state}
                    isRequired
                  >
                    <Controller
                      control={control}
                      name="state"
                      render={({ field: { ref, onChange, value } }) => (
                        <StateSelect ref={ref} value={value} onChange={onChange} />
                      )}
                    />
                    <FormLabel>State</FormLabel>
                  </FormControl>
                  <FormControl isRequired variant="floating" isInvalid={!!formState.errors.postal}>
                    <Input placeholder=" " {...register('postal')} />
                    <FormLabel>Postal</FormLabel>
                  </FormControl>
                </HStack>
                <FormControl isRequired variant="floating" isInvalid={!!formState.errors.phone}>
                  <PhoneInput placeholder=" " {...register('phone')} />
                  <FormLabel>Phone Number</FormLabel>
                </FormControl>
                <FormControl isRequired variant="floating" isInvalid={!!formState.errors.nickname}>
                  <Input placeholder=" " {...register('nickname')} />
                  <FormLabel>Address Nickname</FormLabel>
                </FormControl>

                <VStack w="full" alignItems={'flex-start'}>
                  <Checkbox {...register('primary')}>Primary address</Checkbox>
                  <Checkbox {...register('residential')}>Residential</Checkbox>
                </VStack>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="brand">
                Save
              </Button>
            </ModalFooter>
          </chakra.form>
        </ModalContent>
      </Modal>
    </>
  );
};
