import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import axios from '../../config/axios';

export interface Response {
  user: User;
}

export type Input = { updatePhoneToken: string; phone: string };

type Error = AxiosError<{ message?: string; error?: string }>;

const updatePhone = async (input: Input) => {
  const payload = { updatePhoneToken: input.updatePhoneToken, phone: input.phone };
  const { data } = await axios.patch(`/v3/user/phone`, payload);
  return data;
};

export type UseUpdatePhoneMutation = (
  opts?: UseMutationOptions<Response, Error, Input>,
) => UseMutationResult<Response, Error, Input>;

export const useUpdatePhone: UseUpdatePhoneMutation = (opts) => useMutation(updatePhone, opts);
