import {
  Button,
  HStack,
  Input,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import noop from 'lodash/noop';
import { useEffect, useState } from 'react';
import { DateRange, DayPicker, DayPickerRangeProps } from 'react-day-picker';
import { formatDate, subDays, subMonths } from 'utils/dates';

export interface DateFilterProps extends Partial<DayPickerRangeProps> {
  inputProps?: InputProps;

  onStartDateChange?: (date: Date | null) => void;
  onEndDateChange?: (date: Date | null) => void;
}

const presetDates = [
  { title: 'Today', start: new Date() },
  { title: 'Yesterday', start: subDays(new Date(), 1) },
  { title: 'Last 7 days', start: subDays(new Date(), 7) },
  { title: 'Last 30 days', start: subDays(new Date(), 30) },
  { title: 'Last 90 days', start: subDays(new Date(), 90) },
  { title: 'Last 6 months', start: subMonths(new Date(), 6) },
  { title: 'Last 12 months', start: subMonths(new Date(), 12) },
];

/**
 * @example
 *
 * ```tsx
 * const [fromDate, setFromDate] = useState<Date | null>(null);
 * const [toDate, setToDate] = useState<Date | null>(null);
 *
 * <DateFilter
 *   inputProps={{ placeholder: 'Select date...' }}
 *   onStartDateChange={setFromDate}
 *   onEndDateChange={setToDate}
 * />
 * ```
 */
export const DateFilter = (props: DateFilterProps) => {
  const {
    onStartDateChange = noop,
    onEndDateChange = noop,
    inputProps = {},
    fromDate = new Date(),
    toDate = new Date(),
    ...rest
  } = props;

  const [range, setRange] = useState<DateRange | undefined>({ from: fromDate, to: toDate });

  useEffect(() => onStartDateChange(range?.from), [onStartDateChange, range?.from]);
  useEffect(() => onEndDateChange(range?.to), [onEndDateChange, range?.to]);

  const inputValue =
    range?.from && range?.to ? `${formatDate(range.from, 'MM/DD/YYYY')} - ${formatDate(range.to, 'MM/DD/YYYY')}` : '';

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Input readOnly value={inputValue} minW="56" w="fit-content" {...inputProps} />
      </PopoverTrigger>
      <PopoverContent p="0" minW="fit-content">
        <PopoverBody p="4">
          <HStack>
            <Stack>
              {presetDates.map((preset) => {
                const selected =
                  dayjs(range?.from).isSame(preset.start, 'day') &&
                  range?.to &&
                  dayjs(range.to).isSame(new Date(), 'day');

                return (
                  <Button
                    variant={selected ? 'solid' : 'ghost'}
                    colorScheme={selected ? 'brand' : undefined}
                    justifyContent="start"
                    size="xs"
                    key={preset.title}
                    onClick={() => setRange({ from: preset.start, to: new Date() })}
                  >
                    {preset.title}
                  </Button>
                );
              })}
            </Stack>

            <DayPicker selected={range} onSelect={setRange} {...rest} mode="range" />
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
