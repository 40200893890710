export const AXLEHIRELogo = (props: { width?: number; height?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130" width={props.width ?? 25} height={props.height ?? 25}>
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#2e2c6b"
                  d="m66.67,65.16c6.77-1.77,14.01-3.32,21.66-4.53v-23.48h7.15v22.46c9.87-1.23,20.01-1.8,29.96-1.7-3.52-30.27-29.24-53.76-60.44-53.76h0C31.39,4.14,4.14,31.39,4.14,65c0,7.96,1.53,15.57,4.31,22.54l58.22-58.22v35.83Z"
                />
                <path
                  fill="#2e2c6b"
                  d="m95.48,66.43v30.16h-.48v.47h-6.67v-29.56c-7.29,1.21-14.52,2.8-21.66,4.74v24.37h-6.67v-22.44c-22.65,6.93-39.29,15.98-47.77,21.17,10.51,18.24,30.21,30.52,52.77,30.52,33.61,0,60.86-27.25,60.86-60.86,0-.13,0-.26,0-.39-10.1-.1-20.36.5-30.37,1.82Z"
                />
              </g>
              <path
                fill="#2e2c6b"
                fillRule="evenodd"
                d="m60,67.01v-21.59l-35.35,35.35c11.37-5.59,23.2-10.2,35.35-13.77Z"
              />
            </g>
          </g>
        </g>
        <rect fill="none" width="130" height="130" />
      </g>
    </g>
  </svg>
);
