import { HStack, Input, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { getShipments } from 'api/shipments/get';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LuInfo, LuSearch } from 'react-icons/lu';
import { useMutation } from 'react-query';
import { useNavigate } from 'router';
import { z } from 'zod';

const schema = z.object({ query: z.string() });

export const CommandMenu = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<z.infer<typeof schema>>({ resolver: zodResolver(schema) });
  const query = form.watch('query');

  const navigate = useNavigate();

  const getShipment = useMutation({ mutationFn: getShipments });

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: any) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  // Reset the form when the menu is closed
  useEffect(() => {
    if (open === false) form.reset();
  }, [open]);

  // Reset the error if the query is erased
  useEffect(() => {
    if (!query) setError(null);
  }, [query]);

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} size="xl">
      <ModalOverlay />
      <ModalContent mt="44">
        <ModalBody>
          <form
            onSubmit={form.handleSubmit((values) => {
              setError(null);

              getShipment.mutate(
                { q: values.query },
                {
                  onSuccess: (data) => {
                    if (data.results?.length === 0) setError('Not found');

                    if (data.results?.length === 1) {
                      const shipmentId = data.results[0].id + '';
                      setOpen(false);
                      navigate(`/shipments/:id`, {
                        params: { id: shipmentId },
                      });
                    }
                  },
                },
              );
            })}
          >
            <HStack gap={3}>
              <LuSearch size="20" />
              <Input
                py={2}
                autoFocus
                fontSize="lg"
                color="muted"
                variant="unstyled"
                placeholder="Search..."
                {...form.register('query')}
              />

              {error && (
                <HStack justifyContent="end" gap={1} color="red.500" minW="20">
                  <LuInfo />
                  <Text fontSize="xs" color="red.500">
                    {error}
                  </Text>
                </HStack>
              )}

              {getShipment.isLoading && <Spinner />}
            </HStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
