import {
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GET_ME_QUERY } from 'api/auth';
import { invalidateQueries } from 'api/client';
import { GET_BALANCE_QUERY } from 'api/meters';
import { useAddFunds, useGetPaymentMethods } from 'api/payments';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiInfo } from 'react-icons/fi';
import { HiPlus } from 'react-icons/hi';
import { Link as ReactRouterLink } from 'router';
import { useAddFundsStore } from 'store';
import { PaymentCard } from 'types/payment';
import { getStripeRadarSession } from 'utils/stripe';
import { z } from 'zod';

const schema = z.object({
  rechargeAmount: z.coerce
    .number({ required_error: '', invalid_type_error: '' })
    .min(20, { message: 'Amount must be at least $20' }),
});

type FormValues = z.infer<typeof schema>;

export const AddFundsPopover = () => {
  const addFundsStore = useAddFundsStore();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: { rechargeAmount: addFundsStore.valueCents / 100 },
  });

  const { handleSubmit, formState, register, setValue, watch } = form;
  const { notifySuccess, notifyError } = useNotification();

  const [radarSessionId, setRadarSessionId] = useState<string>();
  const [paymentOptions, setPaymentOptions] = useState<PaymentCard[]>([]);
  const [primaryPaymentMethod, setPrimaryPaymentMethod] = useState<PaymentCard>();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const watchRechargeAmount = watch('rechargeAmount');

  useEffect(() => {
    if (!watchRechargeAmount) return;
    addFundsStore.setValueCents(watchRechargeAmount * 100);
  }, [watchRechargeAmount]);

  useEffect(() => {
    const getRadarSession = async () => {
      const radarSession = await getStripeRadarSession();
      setRadarSessionId(radarSession?.id);
    };
    getRadarSession();
  }, []);

  const addFunds = useAddFunds({
    onSuccess: () => {
      onClose();
      notifySuccess(`$${Number(watchRechargeAmount).toFixed(2)} added to balance`);
      invalidateQueries('/api/v3/user/meters');
      invalidateQueries('/api/v3/me');
    },
  });

  const { data: paymentMethods } = useGetPaymentMethods();

  useEffect(() => {
    if (!paymentMethods) return;
    setPaymentOptions(paymentMethods);
    setPrimaryPaymentMethod(paymentOptions.find((x: { isPrimary: boolean }) => x.isPrimary === true));
  }, [paymentMethods, paymentOptions]);

  const onSubmit = (e: FormValues) => {
    const amountCents = e.rechargeAmount * 100;
    const returnURL = 'https://app.vesyl.com';

    const paymentMethodId = primaryPaymentMethod?.id;

    if (paymentMethodId === 'none' || !paymentMethodId) {
      notifyError('Please select a payment method');
      return;
    }

    addFunds.mutate({ paymentMethodId, amountCents, returnURL, session: radarSessionId });
  };

  return (
    <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button p="0" rounded="full" size="sm" variant="outline" onClick={onOpen}>
          <HiPlus />
        </Button>
      </PopoverTrigger>
      <PopoverContent p={4}>
        <PopoverHeader border={0}>
          <Text overflow="hidden" textOverflow="ellipsis" fontWeight="medium" fontSize="md">
            Add funds
          </Text>
        </PopoverHeader>
        <PopoverCloseButton m={4} />
        <PopoverBody>
          <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
            <VStack align="left" spacing={2}>
              <Text
                paddingTop={2}
                overflow="hidden"
                textOverflow="ellipsis"
                color="muted"
                fontWeight="medium"
              >
                How much would you like to add?
              </Text>
              <FormControl isInvalid={!!formState.errors.rechargeAmount}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                    $
                  </InputLeftElement>
                  <Input
                    type="number"
                    disabled={addFunds.isLoading || !primaryPaymentMethod}
                    {...register('rechargeAmount')}
                  />
                  <InputRightElement color="muted" mr={2}>
                    USD
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formState?.errors?.rechargeAmount?.message}</FormErrorMessage>
              </FormControl>
              <ButtonGroup isAttached variant="outline">
                {[25, 50, 100, 200].map((amount) => (
                  <Button
                    key={amount}
                    px={5}
                    w="full"
                    colorScheme="gray"
                    onClick={() => setValue('rechargeAmount', amount)}
                    bg={watchRechargeAmount === amount ? 'gray.100' : 'white'}
                    disabled={addFunds.isLoading}
                  >
                    ${amount}
                  </Button>
                ))}
              </ButtonGroup>
              <Button
                isDisabled={addFunds.isLoading || !primaryPaymentMethod}
                isLoading={addFunds.isLoading}
                variant="solid"
                type="submit"
                colorScheme="brand"
              >
                Add funds
              </Button>
              <HStack spacing={1}>
                {primaryPaymentMethod ? (
                  <>
                    <Icon as={FiInfo} color="muted" />
                    <Text color="muted" fontSize="xs">
                      This will use{' '}
                      <Link textDecoration="underline" p={0} as={ReactRouterLink} color="muted" to="/billing">
                        {primaryPaymentMethod?.card.brand?.toUpperCase() ||
                          primaryPaymentMethod?.card.issuer?.toUpperCase()}{' '}
                        **
                        {primaryPaymentMethod?.card.lastFour}
                      </Link>
                    </Text>
                  </>
                ) : (
                  <Text color="red.500" fontSize="xs">
                    No primary payment method.{' '}
                    <Link textDecoration="underline" p={0} as={ReactRouterLink} color="red" to="/billing">
                      Add one here
                    </Link>
                  </Text>
                )}
              </HStack>
            </VStack>
          </chakra.form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
