import {
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useConnectWooStore } from 'api/stores/woocommerce/connect';
import { useNotification } from 'contexts/notification.context';
import { FC, PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { SiWoo } from 'react-icons/si';
import { z } from 'zod';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
      {children}
    </Stack>
  );
};

const schema = z.object({
  name: z.string().nonempty(),
  url: z.string().nonempty(),
});

export type FormValues = z.infer<typeof schema>;

export const ConnectWooCommerceForm: FC<{}> = () => {
  const connectStore = useConnectWooStore();
  const { notifyError } = useNotification();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: '', url: '' },
    resolver: zodResolver(schema),
  });

  const { register, formState } = methods;

  const handleSubmit = async (values: FormValues) => {
    const { name, url } = values;
    connectStore.mutate(
      { name, url },
      {
        onSuccess: (result) => {
          if (result?.url) window.location.replace(result.url);
          else notifyError('Error connecting store. Please try again later.');
        },
      },
    );
  };

  return (
    <>
      <Stack>
        <Flex justify="center" alignItems="center" bg="zinc.200" rounded="full" boxSize={14}>
          <Icon as={SiWoo} boxSize={8} />
        </Flex>
        <Stack spacing={0}>
          <Text fontSize="lg" fontWeight="semibold">
            WooCommerce Store
          </Text>
          <Text color="muted">Connect Vesyl to your WooCommerce store to import your orders.</Text>
        </Stack>
      </Stack>

      <Divider my={6} />

      <chakra.form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Stack spacing="3.5" divider={<StackDivider />}>
          <FormControl isRequired isInvalid={!!formState.errors?.name}>
            <Section>
              <FormLabel variant="inline">Name</FormLabel>
              <Input {...register('name')} placeholder="e.g. My Woo Store" />
            </Section>
          </FormControl>
          <FormControl isRequired isInvalid={!!formState.errors?.url}>
            <Section>
              <FormLabel variant="inline">URL</FormLabel>
              <Input {...register('url')} placeholder="e.g. https://mywoostore.com" />
            </Section>
          </FormControl>
        </Stack>
        <Flex justify="end" pt="6">
          <Button type="submit" colorScheme="brand" isLoading={connectStore.isLoading}>
            Connect
          </Button>
        </Flex>
      </chakra.form>
    </>
  );
};
