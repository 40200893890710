import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  GET_AUTOMATIONS_QUERY,
  useCreateAutomation,
  useGetAutomation,
  useGetAutomationFilters,
  useUpdateAutomation,
} from 'api/automation';
import { Select as ReactSelect } from 'chakra-react-select';
import { TagInput } from 'components/tag-input';
import { queryClient } from 'config/query-client';
import { orderBy } from 'lodash';
import { FC } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { BsExclamationTriangle } from 'react-icons/bs';
import { FiArrowDown, FiTrash2 } from 'react-icons/fi';
import { parseAction, parseConditionField, parseOperator } from 'pages/(app)/automations/_components/utils';
import { chakraReactSelectStyles } from 'theme';
import {
  ActionType,
  BooleanCondition,
  NumberCondition,
  SelectCondition,
  SelectConditionValue,
  StringCondition,
} from 'types/automation';
import { z } from 'zod';
import { CountrySelect } from 'pages/(app)/ship/_components/CountrySelect';

interface Props {
  automation: { id: number } | null;

  /**
   * Used when duplicating an automation. It forces the `isNew`
   * variable below to be true, and adds the word "Copy" to the name.
   */
  forceCreate?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const schema = z.object({
  name: z.string().min(3).max(50),
  conditions: z.array(
    z.union([
      z.object({
        type: z.nativeEnum(SelectCondition),
        operator: z.string(),
        value: z.array(z.object({ label: z.string(), value: z.union([z.string(), z.number()]) })),
      }),
      z.object({ type: z.nativeEnum(StringCondition), operator: z.string(), value: z.string() }),
      z.object({ type: z.nativeEnum(NumberCondition), operator: z.string(), value: z.coerce.number() }),
      z.object({ type: z.nativeEnum(BooleanCondition), value: z.boolean() }),
      z.object({ type: z.literal('custom'), value: z.string() }),
    ]),
  ),
  actions: z.array(
    z.union([
      z.object({
        type: z.literal(ActionType.SetDimensions),
        data: z.object({
          width: z.coerce.number().min(0.1),
          height: z.coerce.number().min(0),
          length: z.coerce.number().min(0.1),
        }),
      }),
      z.object({ type: z.literal(ActionType.SetInsuranceAmount), data: z.coerce.number().min(1) }),
      z.object({ type: z.literal(ActionType.SetWeight), data: z.coerce.number().min(1) }),
      z.object({ type: z.literal(ActionType.SkipPlatformNotification), data: z.coerce.boolean() }),
      z.object({ type: z.literal(ActionType.SkipImport), data: z.coerce.boolean() }),
      z.object({ type: z.literal(ActionType.SetFromAddress), data: z.object({ id: z.string() }) }),
      z.object({ type: z.literal(ActionType.SetBox), data: z.object({ id: z.coerce.number() }) }),
      z.object({ type: z.literal(ActionType.SetCustomBox), data: z.object({ id: z.coerce.number() }) }),
      z.object({ type: z.literal(ActionType.SetRate), data: z.object({ id: z.string() }) }),
      z.object({ type: z.literal(ActionType.SetLowestRate), data: z.object({ ids: z.array(z.string()) }) }),
      z.object({ type: z.literal(ActionType.SetTags), data: z.object({ ids: z.array(z.string()) }) }),
      z.object({ type: z.literal(ActionType.SetResidential), data: z.coerce.boolean() }),
      z.object({ type: z.literal(ActionType.SetStatus), data: z.string() }),
      z.object({ type: z.literal(ActionType.SetDeliveryConfirmation), data: z.string() }),
      z.object({ type: z.literal(ActionType.SetHazmat), data: z.string() }),
      z.object({
        type: z.literal(ActionType.SetCustom),
        data: z.object({ type: z.string(), value: z.string() }),
      }),
      z.object({
        type: z.literal(ActionType.SetPaymentMethod),
        data: z.object({
          type: z.string({
            description: 'The payment method type. Types: Sender, receiver or third party',
          }),
          account: z.optional(z.string({ description: 'The account number. Optional for sender type.' })),
          postalCode: z.optional(z.string({ description: 'The postal code. Optional for sender type' })),
          country: z.optional(
            z.string({ description: 'The country. Optional for sender and receiver type' }),
          ),
        }),
      }),
    ]),
  ),
});

type FormValues = z.infer<typeof schema>;

export const AutomationForm: FC<Props> = (props) => {
  const { automation, isOpen, onClose, forceCreate = false } = props;

  const getAutomationFilters = useGetAutomationFilters();

  const getAutomation = useGetAutomation(
    // @ts-expect-error the `enabled` option will make sure `automation` is defined
    { id: automation?.id },
    { enabled: !!automation?.id && getAutomationFilters.isFetched, cacheTime: 0 },
  );
  const createAutomation = useCreateAutomation();
  const updateAutomation = useUpdateAutomation();

  const automationName = getAutomation.data?.name
    ? `${getAutomation.data?.name} ${forceCreate ? 'Copy' : ''}`.trim()
    : undefined;
  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: Object.assign({}, getAutomation.data, { name: automationName }),
  });

  const {
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    unregister,
    resetField,
    getValues,
    formState: { errors },
  } = methods;
  const conditionForm = useFieldArray({ control, name: 'conditions' });
  const actionForm = useFieldArray({ control, name: 'actions' });

  const isNew = !automation?.id || forceCreate === true;
  const isLoading =
    getAutomation.isLoading ||
    createAutomation.isLoading ||
    updateAutomation.isLoading ||
    getAutomationFilters.isLoading;

  const conditionFormMarkup = conditionForm.fields?.map((item, index) => {
    const { conditions = [] } = getAutomationFilters.data || {};

    const selectedField = watch(`conditions.${index}.type`);

    const selectedCondition = conditions?.find?.((c) => c.field === selectedField);

    const operators = selectedCondition?.operators;
    const conditionType = selectedCondition?.type;
    const options = selectedCondition?.options;

    if (operators?.length && !getValues(`conditions.${index}.operator`)) {
      setValue(`conditions.${index}.operator`, operators[0]);
    }

    return (
      <Stack spacing="2" key={item.id}>
        <HStack>
          <FormControl>
            <Select
              {...register(`conditions.${index}.type`, {
                onChange: () => {
                  resetField(`conditions.${index}.value`, { defaultValue: '' });
                  resetField(`conditions.${index}.operator`, { defaultValue: '' });
                },
              })}
              isDisabled={isLoading}
            >
              <option value="">-- Select an option --</option>
              {orderBy(conditions, (c) => parseConditionField(c.field)).map((c) => (
                <option key={c.field} value={c.field}>
                  {parseConditionField(c.field)}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isDisabled={isLoading || !selectedCondition}>
            {/* 
              - boolean: fixed true/false operators
              - custom: no operators, free-form text
              - others: operators come from the API
            */}
            {/* eslint-disable-next-line no-nested-ternary */}
            {conditionType === 'boolean' ? (
              <Select
                {...register(`conditions.${index}.value`, {
                  setValueAs: (value) => (typeof value === 'boolean' ? value : value === 'true'),
                })}
              >
                <option value="">-- Select an option --</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </Select>
            ) : operators ? (
              <Select {...register(`conditions.${index}.operator`)}>
                {operators?.map((o) => (
                  <option key={o} value={o}>
                    {parseOperator(o)}
                  </option>
                ))}
              </Select>
            ) : null}
          </FormControl>
          <FormControl isDisabled={isLoading || !selectedCondition}>
            <Flex justify="center">
              {conditionType === 'text' && <Input {...register(`conditions.${index}.value`)} />}
              {conditionType === 'number' && <Input {...register(`conditions.${index}.value`)} />}
              {conditionType === 'select' && Array.isArray(options) && (
                <Controller
                  control={control}
                  name={`conditions.${index}.value`}
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                    return (
                      <FormControl isInvalid={!!error}>
                        <ReactSelect
                          isMulti
                          chakraStyles={chakraReactSelectStyles}
                          ref={ref}
                          useBasicStyles
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value as unknown as SelectConditionValue}
                          options={options || []}
                          placeholder="Select..."
                          closeMenuOnSelect={false}
                        />
                      </FormControl>
                    );
                  }}
                />
              )}
            </Flex>
          </FormControl>
          <IconButton
            size="sm"
            color="red.400"
            borderColor="red.200"
            _hover={{ bg: 'red.50' }}
            disabled={isLoading}
            variant="outline"
            icon={<FiTrash2 />}
            aria-label="Remove"
            onClick={() => conditionForm.remove(index)}
          />
        </HStack>
      </Stack>
    );
  });

  const actionFormMarkup = actionForm.fields.map((item, index) => {
    const { actions = [] } = getAutomationFilters.data || {};

    const type = watch(`actions.${index}.type`);
    const actionData = watch(`actions.${index}.data`);
    const hasError = !!(errors as any).actions?.[index]?.data;

    const options = actions.find((a) => a.type === type)?.options || [];

    const isSelectType = [
      ActionType.SetFromAddress,
      ActionType.SetBox,
      ActionType.SetCustomBox,
      ActionType.SetRate,
      ActionType.SetStatus,
      ActionType.SetDeliveryConfirmation,
      ActionType.SetHazmat,
    ].includes(type);

    if (isSelectType && !getValues(`actions.${index}.data`) && options?.length > 0) {
      setValue(`actions.${index}.data`, options[0]?.value);
    }

    if ([ActionType.SetCustom].includes(type) && !getValues(`actions.${index}.data`)) {
      setValue(`actions.${index}.data.type`, options[0]?.value);
      setValue(`actions.${index}.data.value`, options[0]?.options ? options[0]?.options?.[0]?.value : '');
    }

    if ([ActionType.SetPaymentMethod].includes(type) && !getValues(`actions.${index}.data`)) {
      setValue(`actions.${index}.data.type`, options[0]?.value);
    }

    return (
      <Stack spacing="2" key={item.id}>
        <HStack alignItems="start">
          <FormControl>
            <Select
              defaultValue={item.type}
              onChange={(e) => {
                unregister(`actions.${index}.data`);
                register(`actions.${index}.data`);
                setValue(`actions.${index}.type`, e.target.value as any);
              }}
            >
              <option value="">-- Select an option --</option>
              {actions.map((a: any) => (
                <option key={a.type} value={a.type}>
                  {parseAction(a.type)}
                </option>
              ))}
            </Select>
          </FormControl>

          <Box w="full">
            {type === ActionType.SetDimensions && (
              <>
                <Stack direction="row" spacing="2" alignItems="start">
                  <FormControl isInvalid={hasError}>
                    <InputGroup>
                      <Input {...register(`actions.${index}.data.length`)} placeholder="L" />
                      <InputRightElement>in</InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl isInvalid={hasError}>
                    <InputGroup>
                      <Input {...register(`actions.${index}.data.width`)} placeholder="W" />
                      <InputRightElement>in</InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl isInvalid={hasError}>
                    <InputGroup>
                      <Input {...register(`actions.${index}.data.height`)} placeholder="H" />
                      <InputRightElement>in</InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Stack>
                {hasError && (
                  <Text color="red.500" pt="1">
                    Invalid dimensions
                  </Text>
                )}
              </>
            )}

            {type === ActionType.SetInsuranceAmount && (
              <FormControl isInvalid={hasError}>
                <InputGroup maxW="50%">
                  <InputLeftElement>$</InputLeftElement>
                  <Input {...register(`actions.${index}.data`)} placeholder="Insurance" />
                </InputGroup>
                {hasError && <FormErrorMessage>Invalid insurance amount</FormErrorMessage>}
              </FormControl>
            )}

            {type === ActionType.SetWeight && (
              <FormControl isInvalid={hasError}>
                <InputGroup maxW="50%">
                  <Input {...register(`actions.${index}.data`)} placeholder="Weight" />
                  <InputRightElement>oz</InputRightElement>
                </InputGroup>
                {hasError && <FormErrorMessage>Invalid weight</FormErrorMessage>}
              </FormControl>
            )}

            {/* Select-type actions */}
            {isSelectType && (
              <>
                <FormControl isInvalid={!!false}>
                  <Select
                    value={JSON.stringify(watch(`actions.${index}.data`) || '{}')}
                    onChange={(e) => {
                      setValue(`actions.${index}.data`, JSON.parse(e.target.value) as any);
                    }}
                  >
                    {options.map((o, i) => (
                      <option key={i} value={JSON.stringify(o.value)}>
                        {o.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {[ActionType.SetTags].includes(type) && (
              <TagInput
                value={(actionData as { ids: string[] })?.ids?.map((id) => ({ value: id, label: id })) || []}
                onChange={(value) => {
                  const ids = value.map((v: any) => v?.value).filter(Boolean);
                  setValue(`actions.${index}.data.ids`, ids);
                }}
              />
            )}

            {/* Multi-select actions */}
            {[ActionType.SetLowestRate].includes(type) && (
              <>
                <FormControl>
                  <ReactSelect
                    isMulti
                    chakraStyles={chakraReactSelectStyles}
                    useBasicStyles
                    hideSelectedOptions
                    options={options || []}
                    placeholder="Select..."
                    closeMenuOnSelect={false}
                    onChange={(value: any) => {
                      const ids = value.map((v: any) => v?.value?.id).filter(Boolean);
                      setValue(`actions.${index}.data.ids`, ids);
                    }}
                    value={(actionData as { ids: [] })?.ids?.map((id: any) => {
                      const option = options.find((o) => o.value.id === id);
                      return { label: option?.label, value: option?.value };
                    })}
                  />
                </FormControl>
              </>
            )}
            {type === ActionType.SetCustom && (
              <>
                <Stack direction="row" spacing="2" alignItems="start">
                  <FormControl isInvalid={hasError}>
                    <Controller
                      control={control}
                      name={`actions.${index}.data`}
                      render={() => {
                        const secondSelectOptions = options.find(
                          (customItem: SelectConditionValue) =>
                            customItem.value === getValues(`actions.${index}.data.type`),
                        )?.options;

                        switch (getValues(`actions.${index}.data.type`)) {
                          // case 'example':
                          //   return <Input {...register(`actions.${index}.data.value`)} type="text" />;
                          //   break;
                          default:
                            return (
                              <Flex gap={2}>
                                <Select
                                  value={JSON.stringify(watch(`actions.${index}.data.type`) || '{}')}
                                  onChange={(e) => {
                                    setValue(`actions.${index}.data.type`, JSON.parse(e.target.value) as any);
                                    setValue(
                                      `actions.${index}.data.value`,
                                      options.find(
                                        (customItem: SelectConditionValue) =>
                                          customItem.value === getValues(`actions.${index}.data.type`),
                                      )?.options?.[0]?.value ?? '',
                                    );
                                  }}
                                >
                                  {options.map((o, i) => (
                                    <option key={i} value={JSON.stringify(o.value)}>
                                      {o.label}
                                    </option>
                                  ))}
                                </Select>
                                {options.find(
                                  (customItem: SelectConditionValue) =>
                                    customItem.value === getValues(`actions.${index}.data.type`),
                                )?.options ? (
                                  <Select
                                    value={JSON.stringify(watch(`actions.${index}.data.value`) || '{}')}
                                    onChange={(e) => {
                                      setValue(
                                        `actions.${index}.data.value`,
                                        JSON.parse(e.target.value) as any,
                                      );
                                    }}
                                  >
                                    {secondSelectOptions &&
                                      secondSelectOptions?.map((o, i) => (
                                        <option key={i} value={JSON.stringify(o.value)}>
                                          {o.label}
                                        </option>
                                      ))}
                                  </Select>
                                ) : (
                                  <Input {...register(`actions.${index}.data.value`)} type="text" />
                                )}
                              </Flex>
                            );
                            break;
                        }
                      }}
                    />
                  </FormControl>
                </Stack>
                {hasError && (
                  <Text color="red.500" pt="1">
                    Invalid options for custom field
                  </Text>
                )}
              </>
            )}
            {type === ActionType.SetPaymentMethod && (
              <>
                <Stack direction="row" spacing="2" alignItems="start">
                  <FormControl isInvalid={hasError}>
                    <Controller
                      control={control}
                      name={`actions.${index}.data`}
                      render={() => {
                        return (
                          <Flex direction="column" gap="2" alignItems="start" justifyItems="start">
                            <Select
                              width={watch(`actions.${index}.data.type`) === 'SENDER' ? '50%' : 'full'}
                              value={JSON.stringify(
                                watch(`actions.${index}.data.type`).toUpperCase() || '{}',
                              )}
                              onChange={(e) => {
                                setValue(`actions.${index}.data.type`, JSON.parse(e.target.value) as any);
                                if (JSON.parse(e.target.value) === 'SENDER') {
                                  unregister(`actions.${index}.data.account`);
                                  unregister(`actions.${index}.data.postalCode`);
                                  unregister(`actions.${index}.data.country`);
                                }
                              }}
                            >
                              {options.map((o, i) => (
                                <option key={i} value={JSON.stringify(o.value)}>
                                  {o.label}
                                </option>
                              ))}
                            </Select>
                            {getValues(`actions.${index}.data.type`) &&
                              watch(`actions.${index}.data.type`) !== 'SENDER' && (
                                <Stack direction="row" spacing="2" alignItems="start">
                                  <FormControl
                                    variant="floating-active"
                                    isRequired={
                                      getValues(`actions.${index}.data.type`).toUpperCase() !== 'SENDER'
                                    }
                                  >
                                    <Input {...register(`actions.${index}.data.account`)} />
                                    <FormLabel>Account</FormLabel>
                                  </FormControl>
                                  <FormControl
                                    variant="floating-active"
                                    isRequired={
                                      getValues(`actions.${index}.data.type`).toUpperCase() !== 'SENDER'
                                    }
                                  >
                                    <Input {...register(`actions.${index}.data.postalCode`)} />
                                    <FormLabel>Zipcode</FormLabel>
                                  </FormControl>
                                  <FormControl
                                    variant="floating-active"
                                    isRequired={
                                      getValues(`actions.${index}.data.type`).toUpperCase() === 'THIRD_PARTY'
                                    }
                                  >
                                    <Controller
                                      name={`actions.${index}.data.country`}
                                      control={control}
                                      render={({ field: { ref, value, onChange } }) => (
                                        <CountrySelect value={value} ref={ref} onChange={onChange} />
                                      )}
                                    />
                                    <FormLabel>Country</FormLabel>
                                  </FormControl>
                                </Stack>
                              )}
                          </Flex>
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
              </>
            )}
          </Box>
          <IconButton
            size="sm"
            color="red.400"
            borderColor="red.200"
            _hover={{ bg: 'red.50' }}
            disabled={isLoading}
            variant="outline"
            icon={<FiTrash2 />}
            aria-label="Remove"
            onClick={() => actionForm.remove(index)}
          />
        </HStack>
      </Stack>
    );
  });

  const onSubmit = (data: FormValues) => {
    const { actions, name, conditions } = data;

    const onSuccess = () => {
      queryClient.invalidateQueries(GET_AUTOMATIONS_QUERY);
      onClose();
    };

    if (isNew) createAutomation.mutate({ conditions, actions, name }, { onSuccess });
    else updateAutomation.mutate({ conditions, actions, name, id: automation!.id }, { onSuccess });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>{isNew ? 'New Automation' : `Edit ${getAutomation.data?.name ?? ''}`}</ModalHeader>
            <ModalBody>
              {getAutomation.data?.isActive === false && !isNew && (
                <HStack justify="start" color="brand.600" mb="4" spacing=" 1">
                  <Icon as={BsExclamationTriangle} />
                  <Text>This automation is currently paused.</Text>
                </HStack>
              )}
              <Stack rounded="lg" border="1px dashed lightgray" px="6" py="4" spacing="2">
                <Box>
                  <Text fontSize="lg">If...</Text>
                  <Text fontSize="sm" color="muted">
                    Choose the conditions necessary to trigger your automation.
                  </Text>
                </Box>
                {conditionFormMarkup}
                <Button
                  disabled={isLoading}
                  variant="outline"
                  alignSelf="start"
                  size="sm"
                  onClick={() => conditionForm.append({ operator: '', value: null, type: '' } as any)}
                >
                  + and
                </Button>
              </Stack>
              <Flex justify="center" py="4">
                <Icon fontSize="xl" as={FiArrowDown} color="gray.500" />
              </Flex>
              <Stack rounded="lg" border="1px dashed lightgray" px="6" py="4" spacing="2">
                <Text fontSize="lg">...do this</Text>
                <Text fontSize="sm" color="muted">
                  Define the actions that will be performed when the conditions are met.
                </Text>
                {actionFormMarkup}
                <Button
                  size="sm"
                  variant="outline"
                  alignSelf="start"
                  disabled={isLoading}
                  onClick={() => actionForm.append({ data: null, type: '' } as any)}
                >
                  + add
                </Button>
              </Stack>
              <FormControl isInvalid={!!errors.name} pt="4" isDisabled={isLoading}>
                <FormLabel>Automation Name</FormLabel>
                <Input {...register('name')} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={onClose}
                disabled={updateAutomation.isLoading || createAutomation.isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="brand"
                type="submit"
                isLoading={updateAutomation.isLoading || createAutomation.isLoading}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
};
