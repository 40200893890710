import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { paths } from '../types';

/**
 * Get tags
 */

export type GetTagsResponse = paths['/api/v3/tags']['get']['responses']['200']['content']['application/json'];

export const GET_TAGS_QUERY = 'getTags';

export const useGetTags = (options?: UseQueryOptions<any>) =>
  useQuery<GetTagsResponse>(
    GET_TAGS_QUERY,
    async () => {
      const result = await axios.get(`/v3/tags`);
      return result?.data;
    },
    options,
  );
