import {
  Button,
  ButtonProps,
  chakra,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { GetOrdersResponse } from 'api/orders/get';
import React, { FC, useRef } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { FiCheck, FiClipboard } from 'react-icons/fi';
import useClipboard from 'react-use-clipboard';
import { getCountryNames } from 'utils/misc';

type Address =
  | ArrayElement<GetOrdersResponse['results']>['shippingAddress']
  | ArrayElement<GetOrdersResponse['results']>['from'];

interface Props {
  address: Address;
  triggerButtonProps?: ButtonProps;
}

export const AddressPopover: FC<Props> = React.memo(({ address, triggerButtonProps = {} }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const ref = useRef(null);

  if (!address) return null;

  const name = address.name || address.company || '';
  const line = `${address.line1 ?? ''} ${address?.line2 ?? ''}`.trim();
  const cityStatePostal = `${address.city ?? ''} ${address.state ?? ''} ${address.postal ?? ''}`.trim();
  const country = getCountryNames(address?.country || '');

  const [isCopied, setCopied] = useClipboard(`${name}\n${line}\n${cityStatePostal}\n${country}`, {
    successDuration: 1400,
  });

  return (
    <Popover onClose={onClose} isOpen={isOpen} isLazy onOpen={onOpen} closeOnBlur closeOnEsc closeDelay={0}>
      <PopoverTrigger>
        {!isCopied ? (
          <Button variant="unstyled" {...triggerButtonProps}>
            <HStack>
              <Text fontWeight="normal" isTruncated>
                {address.line1 || '-'}
              </Text>
              {isOpen ? <FaCaretUp /> : <FaCaretDown />}
            </HStack>
          </Button>
        ) : (
          <Flex fontWeight="medium" alignItems="center" gap="1" color="green.600">
            Copied
            <Icon as={FiCheck} h="4" w="4" />
          </Flex>
        )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="fit-content" variants={{}}>
          <PopoverBody p="5">
            <chakra.address fontSize="sm" fontStyle="normal" display="flex" flexDir="column">
              <Stack>
                <Text fontWeight="semibold">{name}</Text>
                <Text>{line}</Text>
                <Text>{cityStatePostal}</Text>
                <Text>{country}</Text>
              </Stack>
            </chakra.address>

            <Flex mt="4" justify="start">
              <Button
                ref={ref}
                onClick={() => {
                  setCopied();
                  onClose();
                }}
                variant="outline"
                size="xs"
                leftIcon={<FiClipboard />}
              >
                Copy
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
});
