import { Card, CardBody } from '@chakra-ui/react';
import { useGetInvoices } from 'api/payments';
import { DataTable } from 'components/data-table';
import { useEffect, useMemo, useState } from 'react';
import { Invoice } from 'types/payment';
import { invoicesColumns } from './helpers';

export const Invoices = () => {
  const [pagination, setPagination] = useState<any>({ per: 10, page: 0 });
  // const [sorting, setSorting] = useState<SortingState>([]);

  const invoices = useGetInvoices({
    page: pagination?.page,
    per: pagination?.per,
  });

  const [data, setData] = useState<Invoice[]>([]);
  const [total, setTotal] = useState<number>(0);
  const columns = useMemo(() => invoicesColumns, []);

  useEffect(() => {
    if (!invoices.isSuccess) return;
    if (invoices.data?.results) setData(invoices.data?.results);
    if (invoices.data?.total) setTotal(invoices.data?.total);
  }, [invoices.data]);

  return (
    <>
      <Card>
        <CardBody p="0">
          <DataTable
            id="invoices"
            isLoading={invoices.isLoading}
            columns={columns}
            rowCount={total}
            pagination={pagination}
            data={data}
            onPaginationChange={setPagination}
            isSelectable={false}
          />
        </CardBody>
      </Card>
    </>
  );
};
