import { paths } from 'api/types';
import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

export type DeleteOrdersBody = paths['/api/v3/orders']['delete']['requestBody']['content']['application/json'];
export type DeleteOrdersResponse = paths['/api/v3/orders']['delete']['responses']['200']['content']['application/json'];
export type DeleteOrdersError = AxiosError<{ message: string }>;

export const deleteOrders = async (input?: DeleteOrdersBody) => {
  if (!input) return null;

  const { ordersId } = input;

  const result = await axios.delete(`/v3/orders`, { data: { ordersId } });
  return result?.data;
};

export const useDeleteOrders = (opts?: UseMutationOptions<DeleteOrdersResponse, DeleteOrdersError, DeleteOrdersBody>) =>
  useMutation<DeleteOrdersResponse, DeleteOrdersError, DeleteOrdersBody>(deleteOrders, opts);
