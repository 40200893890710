import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';

interface UpdateUser {
  firstName: string;
  lastName: string;
  companyName?: string;
  phone?: string;
}

interface UpdateUserResponse {
  firtName: string;
  lastName: string;
  companyName: string;
  phone: string;
}

export const updateUser = async (vars: UpdateUser) => {
  const { data } = await axios.put(`/v3/user`, vars);
  return data;
};

export type ErrorResponse = AxiosError<{ message?: string; error?: string }>;

export const useUpdateUser = (opts?: UseMutationOptions<UpdateUserResponse, ErrorResponse, UpdateUser>) =>
  useMutation<UpdateUserResponse, ErrorResponse, UpdateUser>(updateUser, opts);
