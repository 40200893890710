import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetAddresses } from 'api/addresses';
import { GET_ME_QUERY } from 'api/auth';
import { GET_PAYMENT_METHODS_QUERY, useAddPaymentMethod } from 'api/payments';
import { queryClient } from 'config/query-client';
import { ALL_STATES } from 'constants/states';
import { useState, useEffect, ChangeEvent, FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { BiCreditCard, BiHome } from 'react-icons/bi';
import { getStripeRadarSession } from 'utils/stripe';

export interface FormValues {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvc: string;
  makePrimary: boolean;
}

interface Props {
  onSuccess?: () => void;
}

export const PaymentModal: FC<Props> = ({ onSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [radarSessionId, setRadarSessionId] = useState<string>();

  useEffect(() => {
    const getRadarSession = async () => {
      const radarSession = await getStripeRadarSession();
      setRadarSessionId(radarSession?.id);
    };
    getRadarSession();
  }, []);

  const addPaymentMethod = useAddPaymentMethod({
    onSuccess: (result) => {
      if (result.redirectUrl !== null) {
        window.location.href = result.redirectUrl;
      }
      if (onSuccess) onSuccess();
      queryClient.invalidateQueries(GET_PAYMENT_METHODS_QUERY);
      queryClient.invalidateQueries(GET_ME_QUERY);
      reset();
      onClose();
    },
  });

  const getAddresses = useGetAddresses();

  const primaryAddress = getAddresses.data?.results?.find((a) => a.primary === true);

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { makePrimary: true },
  });

  const {
    setValue,
    register,
    reset,
    formState: { errors },
  } = methods;

  const handleSubmit = async (values: FormValues) => {
    const {
      cvc,
      city,
      line1,
      state,
      cardNumber,
      line2 = '',
      postalCode,
      expirationYear,
      expirationMonth,
      makePrimary = false,
    } = values;

    const card = {
      cvc,
      makePrimary,
      returnUrl: 'http://app.vesyl.com',
      number: cardNumber,
      expirationMonth: Number(expirationMonth),
      expirationYear: Number(expirationYear),
      billingAddress: { line1, line2, postalCode, state, city, country: 'US' },
      session: radarSessionId,
    };

    await addPaymentMethod.mutate(card);
  };

  const closeAndReset = () => {
    reset();
    onClose();
  };

  const onSetPrimaryAddress = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked) {
      if (!primaryAddress) return;
      setValue('line1', primaryAddress?.line1);
      setValue('line2', primaryAddress?.line2 || '');
      setValue('city', primaryAddress?.city);
      setValue('state', primaryAddress?.state);
      setValue('postalCode', primaryAddress?.postal);
    } else {
      setValue('line1', '');
      setValue('line2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('postalCode', '');
    }
  };

  return (
    <>
      <Button float="right" variant="solid" colorScheme="brand" onClick={onOpen}>
        Add payment method
      </Button>

      <Modal isOpen={isOpen} onClose={closeAndReset}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Credit Card</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)} id="add-credit-card">
                <Text pb="4" fontWeight="medium" display="inline-flex" alignItems="center" gap="1">
                  <BiCreditCard /> Payment information
                </Text>
                <Stack spacing="2">
                  <FormControl isInvalid={!!errors.cardNumber}>
                    <FormLabel>Card Number</FormLabel>
                    <Input placeholder="" maxLength={19} {...register('cardNumber', { required: true })} />
                    {errors.cardNumber && <FormErrorMessage>Required</FormErrorMessage>}
                  </FormControl>
                  <Flex alignItems="center" gap="4">
                    <Box maxWidth="40">
                      <FormControl isInvalid={!!errors.expirationMonth || !!errors.expirationYear}>
                        <FormLabel>Expiration Date</FormLabel>
                        <InputGroup>
                          <Input
                            placeholder="MM"
                            textAlign="center"
                            sx={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', borderRight: '0px' }}
                            {...register('expirationMonth', { required: true })}
                          />
                          <Input
                            placeholder="YY"
                            textAlign="center"
                            sx={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderLeft: '0px' }}
                            {...register('expirationYear', { required: true })}
                          />
                        </InputGroup>
                        {(errors.expirationMonth || errors.expirationYear) && (
                          <FormErrorMessage>Required</FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <FormControl maxW="32" isInvalid={!!errors.cvc}>
                      <FormLabel>CVC</FormLabel>
                      <Input {...register('cvc', { required: true })} placeholder="123" />
                      {errors.cvc && <FormErrorMessage>Required</FormErrorMessage>}
                    </FormControl>
                  </Flex>
                  <Checkbox {...register('makePrimary')}>Use card as primary payment method</Checkbox>
                </Stack>

                <Text pt="6" pb="4" fontWeight="medium" display="inline-flex" alignItems="center" gap="1">
                  <BiHome /> Billing information
                </Text>
                <Stack spacing="2">
                  {primaryAddress && <Checkbox onChange={onSetPrimaryAddress}>Same as primary address</Checkbox>}
                  <FormControl isInvalid={!!errors.line1}>
                    <FormLabel>Line 1</FormLabel>
                    <Input {...register('line1', { required: true })} />
                    {errors?.line1 && <FormErrorMessage>Required</FormErrorMessage>}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Line 2</FormLabel>
                    <Input {...register('line2')} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>City</FormLabel>
                    <Input {...register('city')} />
                  </FormControl>
                  <Flex gap="2">
                    <FormControl w="60%">
                      <FormLabel>State</FormLabel>
                      <Select isInvalid={!!errors.state} {...register('state')}>
                        {ALL_STATES.map((state) => (
                          <option key={state.value} value={state.value}>
                            {state.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl w="40%" isInvalid={!!errors.postalCode}>
                      <FormLabel>Zip Code</FormLabel>
                      <Input {...register('postalCode', { required: true })} />
                      {errors?.postalCode && <FormErrorMessage>Required</FormErrorMessage>}
                    </FormControl>
                  </Flex>
                </Stack>
              </form>
            </FormProvider>
          </ModalBody>

          <ModalFooter display="flex" gap="2">
            <ButtonGroup>
              <Button type="button" variant="outline" onClick={closeAndReset} disabled={addPaymentMethod.isLoading}>
                Cancel
              </Button>
              <Button form="add-credit-card" type="submit" colorScheme="brand" isLoading={addPaymentMethod.isLoading}>
                Save Card
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
