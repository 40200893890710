import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { usePutMutation } from 'api/client';
import { useNotification } from 'contexts/notification.context';
import { useForm } from 'react-hook-form';

interface EditBatchModalProps extends Omit<ModalProps, 'children'> {
  batch: { id: number; displayName: string };
}

export function EditBatchModal(props: EditBatchModalProps) {
  const { batch, ...rest } = props;

  const form = useForm({ defaultValues: { name: batch.displayName } });

  const notify = useNotification();

  const editBatch = usePutMutation('/api/v3/batch/{id}');

  const onSubmit = form.handleSubmit((values) => {
    editBatch.mutate(
      {
        params: { path: { id: String(batch.id) } },
        body: values,
      },
      { onSuccess: () => (rest.onClose(), notify.success('Batch updated successfully')) },
    );
  });

  console.log(form.formState.errors);

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalHeader>Edit Batch</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...form.register('name')} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="brand">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
