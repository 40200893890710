import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { GET_LOGINS_QUERY } from 'api/logins/get';
import { useUpdateLogin } from 'api/logins/update';
import { queryClient } from 'config/query-client';
import { startCase } from 'lodash';
import { useForm } from 'react-hook-form';
import { Login } from 'types';

export const PermissionModal = (props: Omit<ModalProps, 'children'> & { login: Login }) => {
  const { login, onClose, ...rest } = props;

  const form = useForm({ defaultValues: { ...login.permissions } });
  const updateLogin = useUpdateLogin();

  const fields = Object.keys(form.getValues()) as Array<keyof typeof login.permissions>;

  const onSubmit = (values: any) => {
    updateLogin.mutate(
      { id: Number(login.id), permissions: { ...values } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([GET_LOGINS_QUERY]);
          onClose();
        },
      },
    );
  };

  return (
    <Modal onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader>Permissions</ModalHeader>
          <ModalBody>
            <Text mb="6">Update permissions for the user {login.fullName}.</Text>
            <Stack>
              {fields.map((p) => (
                <Checkbox key={p} {...form.register(p)}>
                  {startCase(p)}
                </Checkbox>
              ))}
            </Stack>
          </ModalBody>
          <ModalFooter gap="2">
            <Button isDisabled={updateLogin.isLoading} onClick={onClose} variant="outline" type="submit">
              Cancel
            </Button>
            <Button isLoading={updateLogin.isLoading} colorScheme="brand" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
