import { Card, CardBody, CardHeader, Divider, Flex, Text } from '@chakra-ui/react';
import { useGetQuery } from 'api/client';
import { LuWarehouse } from 'react-icons/lu';
import { useParams } from 'router';
import { Tree } from '../_components/location-tree';

export default function () {
  const params = useParams('/inventories/locations/:id');

  const rootLocationId = Number(params.id);

  const locations = useGetQuery('/api/v3/locations', {});

  const location = locations?.data?.find((l) => l.id === rootLocationId);
  if (!location) return null;

  return (
    <>
      <Flex w="full" direction={{ base: 'column', md: 'row' }} gap="4" alignItems="start">
        <Card minW="full">
          <CardHeader>
            <Text fontSize="md" fontWeight="medium" display="flex" gap="2" alignItems={'center'}>
              <LuWarehouse />
              Locations
            </Text>
          </CardHeader>
          <Divider />
          <CardBody minH="sm">
            <Tree data={[location]} />
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}
