import { Box, Button, Heading, SimpleGrid, Spacer, useDisclosure } from '@chakra-ui/react';
import { useGetAddresses } from 'api/addresses';
import ContainerLayout from 'components/layouts/container-layout';
import { PageBody, PageHeader } from 'components/Page';
import { FC, useMemo } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useQuery } from 'react-query';
import AddressCard from './_components/address-card';
import { AddressForm } from './_components/address-form';

export default function () {
  const { data: addresses } = useGetAddresses();
  const { refetch: refetchAddresses } = useQuery('addresses');
  const addressFormModal = useDisclosure();

  const userAddresses = useMemo(() => {
    if (!addresses?.results) return [];
    const sortedAddress = [...addresses?.results].sort((a, b) => (a.primary < b.primary ? 1 : -1));
    return [...sortedAddress];
  }, [addresses]);

  return (
    <>
      <ContainerLayout>
        <PageHeader>
          <Heading as="h1">Addresses</Heading>
          <Spacer />
          <Button
            type="button"
            colorScheme="brand"
            leftIcon={<FiPlus size="1.2em" />}
            onClick={addressFormModal.onOpen}
          >
            Add address
          </Button>
          {addressFormModal.isOpen && <AddressForm {...addressFormModal} />}
        </PageHeader>
        <PageBody>
          <SimpleGrid columns={[1, 2, 3]} gap={3}>
            {userAddresses.map((address) => {
              return (
                <Box key={`address ${address.id}`}>
                  <AddressCard address={address} refetchAddresses={refetchAddresses} />
                </Box>
              );
            })}
          </SimpleGrid>
        </PageBody>
      </ContainerLayout>
    </>
  );
}
