import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from '../../config/axios';

export interface AddFundsResponse {
  id: string;
}

export interface AddFunds {
  paymentMethodId: string;
  amountCents: number;
  returnURL: string;
  session?: string | null;
  invoiceId?: number | null;
}

type ErrorResponse = AxiosError<{ message?: string; error?: string }>;

const addFunds = async (vars: AddFunds) => {
  const { data } = await axios.post('/v3/payments', vars);
  return data;
};

const useAddFunds = (opts?: UseMutationOptions<AddFundsResponse, ErrorResponse, AddFunds>) =>
  useMutation<AddFundsResponse, ErrorResponse, AddFunds>(addFunds, opts);

export default useAddFunds;
