import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { PostUserPasswordResetBody, PostUserPasswordResetResponse, PostUserResetTokenBody } from 'types/api-types';

export type RequestError = AxiosError<{ errors: { msg: string; param: string }[] }>;

const createResetToken = async (vars: PostUserResetTokenBody) => {
  const { data } = await axios.post('/v3/user/reset_token', vars);
  return data;
};

export const useCreateResetToken = (opts?: UseMutationOptions<{}, any, PostUserResetTokenBody>) =>
  useMutation<{}, any, PostUserResetTokenBody>(createResetToken, opts);

const updatePassword = async (vars: PostUserPasswordResetBody) => {
  const { data } = await axios.post('/v3/user/password/reset', vars);
  return data;
};

export const useUpdatePassword = (
  opts?: UseMutationOptions<PostUserPasswordResetResponse, any, PostUserPasswordResetBody>,
) => useMutation<PostUserPasswordResetResponse, any, PostUserPasswordResetBody>(updatePassword, opts);
