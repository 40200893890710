import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';
import { startCase } from 'lodash';
import { UPSLogo } from './UPS';
import { USPSLogo } from './USPS';
import { OSMLogo } from './OSM';
import { FEDEXLogo } from './FEDEX';
import { EPGLogo } from './EPG';
import { CDLLogo } from './CDL';
import { VEHOLogo } from './VEHO';
import { LASERSHIPLogo } from './LASERSHIP';
import { UDSLogo } from './UDS';
import { AXLEHIRELogo } from './AXLEHIRE';
import { DHLEXPRESSLogo } from './DHLEXPRESS';
import { PASSPORTGLOBALLogo } from './PASSPORTGLOBAL';

interface CarrierLogoProps extends FlexProps {
  height?: number;
  width?: number;
  carrier: string;
}
export const CarrierLogo: FC<CarrierLogoProps> = (props) => {
  const { height = 25, width = 25, carrier, ...rest } = props;

  const carrierName = carrier.toUpperCase();

  if (['UPS', 'UPSDAP', 'UPSMAILINNOVATIONS', 'UPSSUREPOST'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="UPS">
        <UPSLogo height={height} width={width} />
      </Flex>
    );
  }

  if (carrierName === 'USPS') {
    return (
      <Flex alignItems="center" {...rest} title="USPS">
        <USPSLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['OSM', 'OSMWORLDWIDE'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="OSM">
        <OSMLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['FEDEX', 'FEDEXSMARTPOST'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="FEDEX">
        <FEDEXLogo height={height} width={width} />
      </Flex>
    );
  }

  if (carrierName === 'DHLEXPRESS') {
    return (
      <Flex alignItems="center" {...rest} title="DHL Express">
        <DHLEXPRESSLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['CDL', 'COLUMBUSLASTMILE'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="CDL">
        <CDLLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['EPG', 'RRDONNELLEY'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="EPG">
        <EPGLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['LASERSHIP'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="LaserShip">
        <LASERSHIPLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['UDS', 'UNITEDDELIVERYSERVICE'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="UDS">
        <UDSLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['VEHO'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="Veho">
        <VEHOLogo height={height} width={width} />
      </Flex>
    );
  }

  if (['AXLEHIRE'].includes(carrierName)) {
    return (
      <Flex alignItems="center" {...rest} title="Axlehire">
        <AXLEHIRELogo height={height} width={width} />
      </Flex>
    );
  }

  if (carrierName === 'PASSPORTGLOBAL') {
    return (
      <Flex alignItems="center" {...rest} title="Passport Global">
        <PASSPORTGLOBALLogo height={height} width={width} />
      </Flex>
    );
  }

  return <Flex {...rest}>{startCase(carrier)}</Flex>;
};
