import type { AxiosError } from 'axios';
import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

type LookupInput = { q: string };
export type LookupResponse = Array<{
  label: string;
  value: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postal: string;
  };
}>;

const url = `https://us-autocomplete-pro.api.smartystreets.com/lookup`;
const key = `8949839502462708`;

export type LookupError = AxiosError<{ message: string }>;

export const verifyAddress = async (input: LookupInput) => {
  const { q } = input;

  if (q?.length < 2) return [];

  const result = await axios.get(`${url}?key=${key}&search=${q}`, {
    validateStatus: (status) => status < 500,
  });

  if (!result?.data?.suggestions) return [];

  return result.data?.suggestions?.map((s: any) => ({
    label: `${s.street_line},  ${s.city}, ${s.state}`,
    value: {
      line1: s.street_line,
      line2: s.secondary,
      city: s.city,
      state: s.state,
      postal: s.zipcode,
    },
  }));
};

export const useSmartyLookup = (input: LookupInput, opts?: UseQueryOptions<LookupResponse>) =>
  useQuery<LookupResponse>(['smartyLookup', input], () => verifyAddress(input), opts);
