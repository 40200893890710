import { Box, Button, ButtonGroup, Card, CardBody, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { Store as StoreType } from 'api/stores';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { StoreLogo } from 'components/store-logo';
import { useDisclosure } from 'hooks/use-disclosure';
import { RiErrorWarningLine } from 'react-icons/ri';
import { StorePlatform } from 'types/enums';
import { timeSince } from 'utils/dates';

interface Props {
  store: StoreType;
  onEdit: () => void;
  onDelete: () => void;
}

const StoreCard = ({ store, onEdit, onDelete }: Props) => {
  const { onClose, onOpen, isOpen } = useDisclosure();

  const handleDelete = async () => {
    onDelete();
    onClose();
  };

  return (
    <Card h="full">
      <CardBody h="full">
        <VStack alignItems="left" h="full">
          <HStack h="full">
            <StoreLogo variant="circle" platform={store.platform} opacity={0.6} />
            <VStack align="left" spacing="1">
              <HStack spacing={1}>
                <Text fontWeight="semibold">{store.name}</Text>
                {store.platform === StorePlatform.Ebay && (
                  <Icon
                    as={RiErrorWarningLine}
                    title="You are using an older version of the eBay integration. Please create a new store using the new eBay integration."
                    color="red.500"
                  />
                )}
              </HStack>
              <Text color="muted">
                {store.lastSyncAt ? `Updated ${timeSince(store.lastSyncAt).toLowerCase()}` : 'Not synchronized'}
              </Text>
            </VStack>
          </HStack>
          <Box alignSelf="end">
            <ButtonGroup gap="2">
              <Button size="xs" type="button" variant="link" onClick={onOpen}>
                Delete
              </Button>
              <ConfirmationModal
                header={`Delete "${store.name}"?`}
                description={`Are you sure you want to delete the store "${store.name}"?`}
                type="delete"
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={handleDelete}
              />
              <Button size="xs" variant="outline" type="button" onClick={onEdit}>
                Edit
              </Button>
            </ButtonGroup>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default StoreCard;
