import { Input, InputProps } from '@chakra-ui/react';
import { FC, forwardRef } from 'react';
import { useIMask } from 'react-imask';

interface PhoneInputProps extends InputProps {
  country?: string | null;
  disabled?: boolean;
}

export const PhoneInput: FC<PhoneInputProps> = forwardRef(({ country = 'US', ...props }, ref) => {
  let phoneMask;
  switch (country) {
    case 'US':
      phoneMask = '(000) 000-0000';
      break;
    default:
      phoneMask = '';
      break;
  }

  const { ref: maskRef } = useIMask({ mask: phoneMask });

  return (
    <Input
      {...props}
      disabled={props.disabled}
      ref={(el) => {
        // @ts-ignore
        if (ref) ref(el);
        // @ts-ignore
        maskRef.current = el;
      }}
    />
  );
});
