import { VisibilityState } from '@tanstack/react-table';
import { ColumnState } from 'ag-grid-community';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface QuickShipConfig {
  // To
  showPasteMode: boolean;
  showToEditMode: boolean;

  // From
  fromAddressId: string | null;

  // Return
  showReturnAddress: boolean;
  returnAddressId: string | null;

  // Options
  showMoreOptions: boolean;

  // General
  printFormat: string | null;
  carrierAccountIds: string[] | null;
  patch: (patch: Partial<QuickShipConfig>) => void;
}

export const useQuickShipStore = create<QuickShipConfig>()(
  persist(
    (set) => ({
      showPasteMode: false,
      showToEditMode: true,
      showReturnAddress: false,
      showMoreOptions: false,
      fromAddressId: null,
      returnAddressId: null,
      printFormat: null,
      carrierAccountIds: null,
      patch: (patch: Partial<QuickShipConfig>) => set(patch),
    }),
    {
      name: 'qs_config',
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !['showToEditMode'].includes(key))),
    },
  ),
);

interface TableStore {
  columnVisibility: VisibilityState;
  setColumnVisibility: (columnVisibility: VisibilityState) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  columnConfiguration: ColumnState[];
  setColumnConfiguration: (columnConfiguration: ColumnState[]) => void;
}

export const useTableStore = (tableId: string) =>
  create<TableStore>()(
    persist(
      (set) => ({
        columnVisibility: {},
        setColumnVisibility: (cv) => set({ columnVisibility: cv }),
        pageSize: 10,
        setPageSize: (ps) => set({ pageSize: ps }),
        columnConfiguration: Array<ColumnState>(),
        setColumnConfiguration: (cc) => set({ columnConfiguration: cc }),
      }),
      {
        name: tableId,
      },
    ),
  )();

interface UserStore {
  authToken: string | null;
  lastLoginAt: string | null;
  isSidebarCollapsed: boolean;

  /**
   * During the OAuth flow, if the user doesn't have a VESYL account
   * and wants to sign up, they are redirected to this app with an `oidc_client`
   * query parameter set. We store this value in the state so we can show
   * a banner prompting them to add a payment method, etc.
   */
  oidcClient: string | null;

  patch: (patch: Partial<UserStore>) => void;
}

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      isSidebarCollapsed: false,
      authToken: null,
      lastLoginAt: null,
      oidcClient: null,
      patch: (patch: Partial<UserStore>) => set(patch),
    }),
    { name: 'vsyl_user' },
  ),
);

interface AddFundsStore {
  valueCents: number;
  setValueCents: (valueCents: number) => void;
}

export const useAddFundsStore = () =>
  create<AddFundsStore>()(
    persist(
      (set) => ({
        valueCents: 2500,
        setValueCents: (vc) => set({ valueCents: vc }),
      }),
      { name: 'add_funds' },
    ),
  )();
