import { useQuery } from 'react-query';
import axios from '../../config/axios';

export const getShipmentLabelTypes = async (shipmentIds: string[]) => {
  if (shipmentIds.length === 0) return { label: 'Plain Paper - 8.5x11', value: 'letter' };
  let queryStringIds = '';
  for (let i = 0; i < shipmentIds.length; i += 1) {
    queryStringIds += `shipmentIds[]=${shipmentIds[i]}&`;
  }

  const { data } = await axios.get(`/v3/labels/types?${queryStringIds}`);
  return data;
};

const useGetShipmentLabelTypes = (shipmentIds: any) =>
  useQuery(['label_types', shipmentIds], () => getShipmentLabelTypes(shipmentIds));

export default useGetShipmentLabelTypes;
