import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateResetToken } from 'api/auth';
import { useForm } from 'react-hook-form';
import { Link } from 'router';
import { z } from 'zod';

const validationSchema = z.object({
  email: z.string().email('Must be a valid email').nonempty({ message: 'Required' }),
});

export interface FormValues {
  email: string;
}

export default function () {
  const methods = useForm<FormValues>({ mode: 'onBlur', resolver: zodResolver(validationSchema) });
  const { formState, register, handleSubmit } = methods;

  const createResetToken = useCreateResetToken();

  const onSubmit = (values: FormValues) => {
    createResetToken.mutate(values);
  };

  return (
    <Stack gap="2">
      {createResetToken.isSuccess && (
        <Alert status="success" fontSize="sm">
          <AlertIcon />
          <Stack gap="1">
            <AlertTitle>Success!</AlertTitle>
            <AlertDescription>
              If an account with that email exists, we will send a password reset link to that email.
            </AlertDescription>
          </Stack>
        </Alert>
      )}

      <Text fontWeight="medium" fontSize="lg">
        Reset password
      </Text>
      <Text fontSize="sm" color="muted" pb="4">
        Enter the email address associated with your account. We&lsquo;ll send you a link to reset your password.
      </Text>
      <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
        <VStack spacing={3.5}>
          <FormControl isDisabled={createResetToken.isSuccess} isRequired isInvalid={!!formState.errors.email}>
            <FormLabel>Email</FormLabel>
            <Input placeholder=" " {...register('email')} />
            <FormErrorMessage>{formState?.errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <Button
            w="100%"
            type="submit"
            colorScheme="brand"
            isDisabled={createResetToken.isSuccess}
            isLoading={createResetToken.isLoading}
          >
            Reset password
          </Button>

          <Button size="sm" colorScheme="brand" variant="link" as={Link} to="/login">
            Return
          </Button>
        </VStack>
      </chakra.form>
    </Stack>
  );
}
