import { CardBody, Heading, Card, VStack } from '@chakra-ui/react';
import { PageBody, PageHeader } from 'components/Page';
import { useNavigate } from 'react-router';
import { CreateProduct } from './_components/create-product';
import { z } from 'zod';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { GET_PRODUCTS_QUERY } from 'api/products/get';
import { useCreateProduct } from 'api/products/create';

const invalid_type_error = 'Must be a number';

const schema = z.object({
  name: z.string(),
  sku: z.string(),
  description: z.string().nullable(),
  weightOz: z.coerce.number({ invalid_type_error }).min(0).max(1900),
  weightLbs: z.coerce.number({ invalid_type_error }).min(0).max(120),
  heightIn: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
  widthIn: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
  lengthIn: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
  price: z.coerce.number({ invalid_type_error }),
  imageUrl: z.string().nullable(),
  originCountry: z.string().nullable(),
  hsTariffCode: z.string().nullable(),
  tags: z.array(z.string()).nullable(),
  location: z.string().nullable(),
});

export type FormValues = z.infer<typeof schema>;

export default function () {
  const { notifySuccess } = useNotification();
  const navigate = useNavigate();

  const createProduct = useCreateProduct();

  const onSubmit = async (values: FormValues) => {
    const {
      name,
      sku,
      description,
      weightLbs,
      weightOz,
      heightIn,
      lengthIn,
      widthIn,
      price,
      hsTariffCode,
      imageUrl,
      originCountry,
      tags,
      location,
    } = values;

    const weight = Number(weightLbs) * 16 + Number(weightOz);

    const priceInCents = price ? price * 100 : 0;

    createProduct.mutate(
      {
        name,
        sku,
        description,
        weightOz: weight,
        heightIn,
        widthIn,
        lengthIn,
        priceCents: parseInt(priceInCents.toFixed(0)),
        hsTariffCode,
        originCountry,
        imageUrl,
        location,
        tags: tags || [],
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([GET_PRODUCTS_QUERY]);
          notifySuccess('Your product has been successfully created');
          navigate('/products');
        },
      },
    );
  };

  return (
    <VStack>
      <PageHeader alignSelf="flex-start">
        <Heading as="h1">Create Product</Heading>
      </PageHeader>
      <PageBody maxW={1100}>
        <Card>
          <CardBody>
            <CreateProduct onSubmit={onSubmit} isLoading={createProduct.isLoading} />
          </CardBody>
        </Card>
      </PageBody>
    </VStack>
  );
}
