import axios from 'config/axios';
import { useQuery } from 'react-query';
import { GetOriginAddressesResponse } from 'types/api-types';

export type GetAddressesResponse = GetOriginAddressesResponse;

export const getAddresses = async () => {
  const { data } = await axios.get<GetAddressesResponse>(`/v3/origin-addresses`, { params: { per: 9999, page: 0 } });
  return data;
};

export const GET_ADDRESS_QUERY = 'addresses';

const useGetAddresses = () => useQuery<GetAddressesResponse>(GET_ADDRESS_QUERY, getAddresses);

export default useGetAddresses;
