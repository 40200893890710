import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';

type UpdateBox = {
  id: number;
  name?: string | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  isActive?: boolean;
  isFavorite?: boolean;
  predefinedPackage?: 'SoftPack' | null;
  weight?: number | null;
};
export type UpdateBoxResponse = {
  id: number;
  length: number;
  width: number;
  height: number;
  isEnabled: boolean;
  createdAt: string;
  updatedAt: string;
  weight: number | null;
};
export type UpdateBoxError = AxiosError<{ message: string }>;

export const updateBox = async ({ id, ...vars }: UpdateBox) => {
  const { data } = await axios.put(`/v3/boxes/${id}`, vars);
  return data;
};

const useUpdateBox = (opts?: UseMutationOptions<UpdateBoxResponse, UpdateBoxError, UpdateBox>) =>
  useMutation<UpdateBoxResponse, UpdateBoxError, UpdateBox>(updateBox, opts);

export default useUpdateBox;
