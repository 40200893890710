import { useMutation, UseMutationOptions } from 'react-query';
import axios from 'config/axios';
import { StorePlatform } from 'types/enums';

type Response = {};
export type SyncStoreRequestBody = {
  id: number;
  platform: StorePlatform;
}[];

export const syncStore = async (vars: SyncStoreRequestBody) => {
  const { data } = await axios.post('/v3/stores/sync', { stores: vars });

  return data;
};

const useSyncStore = (opts?: UseMutationOptions<Response, {}, SyncStoreRequestBody>) =>
  useMutation<{}, {}, SyncStoreRequestBody>(syncStore, opts);

export default useSyncStore;
