import { useQuery } from 'react-query';
import axios from 'config/axios';
import { PhoneVerificationStatus } from 'types/enums';

interface PhoneVerificationResponse {
  status: PhoneVerificationStatus;
}

const sendPhoneVerification = async (userId: string) => {
  const { data } = await axios.get<PhoneVerificationResponse>(`/v3/user/${userId}/phone`);
  return data;
};

export const useSendPhoneVerification = (userId: string) =>
  useQuery<PhoneVerificationResponse>(['sendPhoneVerification', userId], () => sendPhoneVerification(userId));
