import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useGetQuery } from 'api/client';
import { dayjs } from 'utils/dates';

type Props = Omit<DrawerProps, 'children'> & {
  inventoryId: number;
  productId: number;
};

export function InventoryLogsDrawer(props: Props) {
  const { productId, inventoryId, ...rest } = props;

  const logs = useGetQuery('/api/v3/inventory/{id}/logs', {
    params: { path: { id: String(inventoryId) } },
  });

  return (
    <Drawer {...rest} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Inventory Logs</DrawerHeader>
        <DrawerBody>
          <SimpleGrid columns={3} py={2}>
            <Text fontWeight="bold">User</Text>
            <Text fontWeight="bold" align="center">
              Qty.
            </Text>
            <Text fontWeight="bold" align="center">
              Date
            </Text>
          </SimpleGrid>
          <Stack divider={<Divider />}>
            {logs?.data?.map((l, i) => (
              <SimpleGrid key={i} columns={3} py={2}>
                <Text color="muted">{l.notes}</Text>
                <Text align="center">{l.value}</Text>
                <Text color="muted" align="center" title={l.createdAt}>
                  {dayjs(l.createdAt).fromNow()}
                </Text>
              </SimpleGrid>
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
