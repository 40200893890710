import {
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useConnectShipstation } from 'api/stores/shipstation';
import { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaGear } from 'react-icons/fa6';
import { useNavigate } from 'react-router';
import { z } from 'zod';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
      {children}
    </Stack>
  );
};

const validationSchema = z.object({
  apiKey: z.string().nonempty('Required'),
  apiSecret: z.string().nonempty('Required'),
  name: z.string().nonempty('Required'),
});

type FormValues = z.infer<typeof validationSchema>;

export const ConnectShipstationForm: FC<{}> = () => {
  const connect = useConnectShipstation();
  const navigate = useNavigate();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { apiKey: '', apiSecret: '' },
    resolver: zodResolver(validationSchema),
  });

  const { formState, register } = methods;

  const onSubmit = async (values: FormValues) => {
    const { apiKey, apiSecret, name } = values;
    connect.mutate(
      { apiKey, apiSecret, name },
      {
        onSuccess: () => {
          navigate('/stores');
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <>
        <HStack gap="4">
          <Flex justify="center" alignItems="center" bg="zinc.200" rounded="full" boxSize={14}>
            <Icon as={FaGear} boxSize={6} />
          </Flex>
          <Stack spacing={0}>
            <Text fontSize="lg" fontWeight="semibold">
              ShipStation Store
            </Text>
            <Text color="muted">Connect VESYL to your ShipStation account to start importing your orders.</Text>
            <Text color="muted" mt="1">
              You can find your API Key and API Secret under &quot;Settings&quot; at{' '}
              <Link href="https://ss.shipstation.com/#/settings/api">https://ss.shipstation.com/#/settings/api</Link>
            </Text>
            .
          </Stack>
        </HStack>

        <Divider my={6} />

        <chakra.form onSubmit={methods.handleSubmit((e) => onSubmit(e))}>
          <Stack spacing="3.5" divider={<StackDivider />}>
            <FormControl isRequired isInvalid={!!formState.errors?.name}>
              <Section>
                <FormLabel variant="inline">Name</FormLabel>
                <Input {...register('name')} placeholder="e.g., My ShipStation Store" />
              </Section>
            </FormControl>
            <FormControl isRequired isInvalid={!!formState.errors?.apiKey}>
              <Section>
                <FormLabel variant="inline">API Key</FormLabel>
                <Input {...register('apiKey')} />
              </Section>
            </FormControl>
            <FormControl isRequired isInvalid={!!formState.errors?.apiSecret}>
              <Section>
                <FormLabel variant="inline">API Secret</FormLabel>
                <Input {...register('apiSecret')} />
              </Section>
            </FormControl>
          </Stack>
          <Flex justify="end" pt="6">
            <Button type="submit" colorScheme="brand" isLoading={connect.isLoading}>
              Connect
            </Button>
          </Flex>
        </chakra.form>
      </>
    </FormProvider>
  );
};
