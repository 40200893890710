import axios from 'config/axios';

export interface StripeSetupIntentResponse {
  stripeId: string;
  stripeClientSecret: string;
}

export const StripeSetupIntent = async () => {
  const { data } = await axios.post('/v3/user/payment-methods/stripe-setup-intent');
  return data;
};
