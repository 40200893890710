import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateFile } from 'api/file';
import { useGetSettings, useUpdateSettings } from 'api/settings';
import { get, isEmpty } from 'lodash';
import { ChangeEvent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  packingListFormat: z.string().optional(),
  packingListCompany: z.string().optional(),
  packingListThankYouMessage: z.string().optional(),
  packingListGiftMessage: z.string().optional(),
  packingListCompanyLogoUrl: z.string().optional(),
  packingSlipIncludeGiftMessage: z.boolean(),
});

type FormValues = z.infer<typeof schema>;

export const PackingSlipsSettings = () => {
  const fileInputRef = useRef<any>(null);

  const updateSettings = useUpdateSettings();
  const settings = useGetSettings();
  const createFile = useCreateFile();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: {
      packingListFormat: settings?.data?.packingListFormat || undefined,
      packingListCompany: settings?.data?.packingListCompany || undefined,
      packingListThankYouMessage: settings?.data?.packingListThankYouMessage || undefined,
      packingListCompanyLogoUrl: settings?.data?.packingListCompanyLogoUrl || undefined,
      packingSlipIncludeGiftMessage: settings?.data?.packingSlipIncludeGiftMessage || false,
    },
  });

  const handleFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    form.setValue('packingListCompanyLogoUrl', undefined);

    const [file] = e.target.files || [];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    createFile.mutate(formData, {
      onSuccess: (data) => {
        if (data.url) {
          form.setValue('packingListCompanyLogoUrl', data.url, { shouldDirty: true });
        }
      },
    });
  };

  const onSubmit = async (values: FormValues) => {
    const payload: Record<string, any> = {};

    Object.keys(form.formState.dirtyFields).forEach((key) => {
      if (get(values, key)) payload[key] = get(values, key);
      if (key === 'packingSlipIncludeGiftMessage') payload[key] = get(values, key, false);
    });

    updateSettings.mutate(payload);
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <Text fontWeight="semibold" fontSize="lg">
              Packing Slips
            </Text>
            <Text fontSize="sm" color="muted">
              Manage your packing slips settings.
            </Text>
          </CardHeader>
          <CardBody maxW="lg">
            <Stack gap="3.5">
              <FormControl>
                <FormLabel>Format</FormLabel>
                <Select defaultValue="default" {...form.register('packingListFormat')}>
                  <option value="default">PDF Default</option>
                  <option value="with_label">PDF With Label</option>
                  <option value="simplified_zpl">ZPL Location & No-SKU</option>
                </Select>
              </FormControl>

              <HStack>
                <FormControl>
                  <FormLabel>Company Logo</FormLabel>
                  <Button
                    isLoading={createFile.isLoading}
                    variant="outline"
                    onClick={() => fileInputRef?.current?.click()}
                    size="sm"
                  >
                    Choose file...
                  </Button>
                  <chakra.input onChange={handleFileSelected} ref={fileInputRef} hidden type="file" />
                  <Text mt="2" fontSize="xs" color="zinc.500">
                    For better results, use a square image (1:1) under 1MB in size.
                  </Text>
                </FormControl>
                {form.getValues('packingListCompanyLogoUrl') && (
                  <Image
                    rounded="md"
                    shadow="md"
                    alt="Failed to load image"
                    src={form.getValues('packingListCompanyLogoUrl')}
                    w="24"
                  />
                )}
              </HStack>

              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input {...form.register('packingListCompany')} />
              </FormControl>

              <FormControl>
                <FormLabel>Thank You Message</FormLabel>
                <Textarea {...form.register('packingListThankYouMessage')} />
              </FormControl>

              <FormControl>
                <Checkbox
                  {...form.register('packingSlipIncludeGiftMessage')}
                  isChecked={!!form.getValues('packingSlipIncludeGiftMessage')}
                >
                  Include Gift Message on Packing Slip
                </Checkbox>
              </FormControl>
            </Stack>
          </CardBody>
          <CardFooter maxW="lg">
            <Button
              ml="auto"
              colorScheme="brand"
              type="submit"
              isDisabled={isEmpty(form.formState.dirtyFields)}
            >
              Save
            </Button>
          </CardFooter>
        </Card>
      </form>
    </>
  );
};
