import { paths } from 'api/types';
import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';

export const GET_CARRIER_TYPES_QUERY = 'getCarrierTypes';

type GetCarrierTypesResponse = paths['/api/v3/carrier-types']['get']['responses']['200']['content']['application/json'];

export const useGetCarrierTypes = (opts?: UseQueryOptions<any>) =>
  useQuery<GetCarrierTypesResponse>(
    [GET_CARRIER_TYPES_QUERY],
    async () => {
      const result = await axios.get(`/v3/carrier-types`, {});
      return result?.data;
    },
    opts ?? {},
  );
