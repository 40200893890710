import { theme as proTheme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';
import { ChakraStylesConfig } from 'chakra-react-select';
import components from './components';

export const brand = {
  50: '#FFFFFF',
  100: '#FEFFFF',
  200: '#E3ECF1',
  300: '#C8DAE4',
  400: '#ACC7D6',
  500: '#91B5C9',
  600: '#6B9CB7',
  700: '#4D809D',
  800: '#3B6277',
  900: '#284352',
  950: '#1F333F',
};

export const slate = {
  50: '#f8fafc',
  100: '#f1f5f9',
  200: '#e2e8f0',
  300: '#cbd5e1',
  400: '#94a3b8',
  500: '#64748b',
  600: '#475569',
  700: '#334155',
  800: '#1e293b',
  900: '#0f172a',
};

export const zinc = {
  50: '#fafafa',
  100: '#f4f4f5',
  200: '#e4e4e7',
  300: '#d4d4d8',
  400: '#a1a1aa',
  500: '#71717a',
  600: '#52525b',
  700: '#3f3f46',
  800: '#27272a',
  900: '#18181b',
};

// Pro theme fonts are be overriding custom theme ones.
delete proTheme.fonts;

const customTheme = {
  ...proTheme,
  styles: {
    global: {
      '#root': { bg: 'zinc.100' },
      '& .react-datepicker': {
        border: '0px',
        '&__header': { bg: 'none', borderBottom: 'none' },
        '&__month': { mt: 0 },
      },
      '.react-datepicker-popper': {
        'z-index': '99',
      },
    },
  },
  initialColorMode: 'light',
  useSystemColorMode: false,
  colors: {
    brand,
    slate,
    zinc,
    gray: zinc,
    brandBlack: '#000000',
    brandBlue: '#91b5c9',
    brandGreen: '#b1c9b4',
    brandLightGreen: '#dee8de',
    brandYellow: '#edfc33',
    brandWhite: '#fcfffc',
  },
  fonts: {
    heading: `'Nohemi', ui-sans-serif, system-ui, -apple-system`,
    body: `'Inter', ui-sans-serif, system-ui, -apple-system`,
  },
  components: { ...components },
  breakpoints: { ...proTheme.breakpoints, '3xl': '1800px' },
};

export const theme = extendTheme(customTheme, proTheme);

export const chakraReactSelectStyles: ChakraStylesConfig = {
  dropdownIndicator: (provided) => ({ ...provided, p: 0 }),
  control: (provided) => ({ ...provided, p: 0 }),
  input: (provided) => ({
    ...provided,
    fontSize: 'sm',
    w: 'auto',
    p: 0,
    outline: 'none',
    focus: 'none',
    border: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    border: 'none',
    fontSize: 'sm',
    w: 'auto',
    py: 0,
    px: 3,
    outline: 'none',
    focus: 'none',
  }),
  container: (provided) => ({
    ...provided,
    rounded: 'xs',
    fontSize: 'sm',
    h: 'auto',
    py: 0,
  }),

  inputContainer: (provided) => ({ ...provided, p: 0 }),
  menu: (provided) => ({ ...provided, zIndex: 40 }),
  menuList: (provided) => ({ ...provided, rounded: 'none', shadow: 'xs' }),
  noOptionsMessage: (provided) => ({ ...provided, fontSize: 'sm' }),
  loadingMessage: (provided) => ({ ...provided, fontSize: 'sm' }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 'sm',
    bg: state.isSelected ? 'brand.50' : 'white',
    color: state.isSelected ? 'brand.500' : 'base',
    _hover: { bg: 'brand.50', color: 'brand.500' },
  }),
};
