import { paths } from 'api/types';
import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';

export type GetApiKeysResponse = paths['/api/v3/api-keys']['get']['responses']['200']['content']['application/json'];

export const GET_API_KEYS_QUERY = 'GET_API_KEYS_QUERY';

export const useGetApiKeys = (opts: UseQueryOptions<GetApiKeysResponse> = {}) =>
  useQuery<GetApiKeysResponse>(
    [GET_API_KEYS_QUERY],
    () => {
      return axios.get(`/v3/api-keys`, {}).then((res) => res.data);
    },
    opts,
  );
