import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Nohemi';
        src: url('./fonts/Nohemi-Medium.otf') format('opentype');
        font-weight: normal;
      }

      @font-face {
        font-family: 'Nohemi';
        src: url('./fonts/Nohemi-Bold.otf') format('opentype');
        font-weight: bold;
      }

      @font-face {
        font-family: 'MessinaSans';
        src: url('./fonts/MessinaSans-Book.otf') format('opentype');
        font-weight: normal;
      }
      
      @font-face {
        font-family: 'MessinaSans';
        src: url('./fonts/MessinaSans-BookItalic.otf') format('opentype');
        font-weight: normal;
        font-style: italic;
      }

      @font-face {
        font-family: 'MessinaSans';
        src: url('./fonts/MessinaSansWeb-SemiBold.woff') format('woff');
        font-weight: semibold;
      }
    `}
  />
);
