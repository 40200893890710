import { Box, HStack, Stack, Text } from '@chakra-ui/layout';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useDeleteMutation, useGetQuery, usePostMutation, usePutMutation } from 'api/client';
import { Select as ReactSelect } from 'chakra-react-select';
import { useNotification } from 'contexts/notification.context';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { LuArrowLeft, LuCheck, LuTrash2, LuUndo2 } from 'react-icons/lu';
import { useNavigate, useParams } from 'router';
import { chakraReactSelectStyles } from 'theme';

// @todo: Move this to a "LocationSelect" component
// prettier-ignore
interface Node { name: string; id: number; children?: Node[];}
function getLocationOptions(node: Node, parentPath: string = ''): { path: string; id: number }[] {
  const result: { path: string; id: number }[] = [];

  const currentPath = parentPath ? `${parentPath}.${node.name}` : node.name;
  result.push({ path: currentPath, id: node.id });

  if (node.children) {
    for (const child of node.children) {
      result.push(...getLocationOptions(child, currentPath));
    }
  }

  return result;
}

export default function PurchaseOrderDetail() {
  const params = useParams('/inventories/receiving/:poId');
  const navigate = useNavigate();

  const notify = useNotification();

  const editPoItem = usePutMutation('/api/v3/purchase-orders/{poId}/items/{itemId}');
  const removePoItem = useDeleteMutation('/api/v3/purchase-orders/{poId}/items/{itemId}');
  const receivePo = usePostMutation('/api/v3/purchase-orders/{id}/receive');
  const po = useGetQuery(`/api/v3/purchase-orders/{id}`, { params: { path: { id: params.poId } } });
  const locations = useGetQuery('/api/v3/locations', {});

  const locationOptions = locations?.data
    ?.map((loc) => getLocationOptions(loc))
    .flat()
    .map((loc) => ({ value: loc.id, label: loc.path }));

  useEffect(() => {
    if (po.isSuccess) po.refetch();
  }, [editPoItem.isSuccess]);

  if (!po.data) return null;

  return (
    <Stack mt={4} gap={4}>
      <Stack color="LightGray">
        <Button
          fontWeight={'normal'}
          w="fit-content"
          gap={2}
          size="sm"
          variant="link"
          onClick={() => navigate(-1)}
        >
          <LuArrowLeft color="gray" />
          <Text fontSize="sm" color="zinc.500">
            Back
          </Text>
        </Button>
      </Stack>

      <Card minW="sm" h="fit-content">
        <CardHeader>
          <HStack justifyContent={'space-between'}>
            <Text fontSize={'md'}>PO #{po.data.sequence}</Text>
          </HStack>
        </CardHeader>
        <CardBody p={0}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>SKU</Th>
                <Th>Quantity</Th>
                <Th>Lot Num.</Th>
                <Th>Location</Th>
                <Th>Action</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {po?.data?.items?.map((item) => {
                const isReady = item.status === 'ready';

                return (
                  <Tr key={item.id} bg={isReady ? 'green.50' : undefined}>
                    <Td>{item.sku}</Td>
                    <Td>
                      <Input
                        isReadOnly={isReady}
                        maxW="32"
                        size="sm"
                        defaultValue={item.quantity || ''}
                        onChange={debounce((e) => {
                          const quantity = Number(e.target.value);
                          if (isNaN(quantity)) return;

                          editPoItem.mutate({
                            params: {
                              path: {
                                poId: String(po.data!.id),
                                itemId: String(item.id),
                              },
                            },
                            body: { quantity, status: 'open' },
                          });
                        }, 600)}
                      />
                    </Td>
                    <Td>
                      <Input
                        isReadOnly={isReady}
                        maxW="32"
                        size="sm"
                        defaultValue={item.lotNumber || ''}
                        onChange={debounce((e) => {
                          let lotNumber = e.target.value;
                          if (!lotNumber) lotNumber = null;

                          editPoItem.mutate({
                            params: {
                              path: {
                                poId: String(po.data!.id),
                                itemId: String(item.id),
                              },
                            },
                            body: { lotNumber, status: 'open' },
                          });
                        }, 600)}
                      />
                    </Td>
                    <Td maxW={'48'}>
                      <ReactSelect
                        closeMenuOnSelect
                        isReadOnly={isReady}
                        useBasicStyles
                        isSearchable
                        options={locationOptions}
                        value={locationOptions?.find((c) => c.value === item.location?.id) || ''}
                        placeholder="Select..."
                        size="sm"
                        chakraStyles={chakraReactSelectStyles}
                        onChange={({ value }: any) => {
                          let locationId = value ? Number(value) : null;
                          editPoItem.mutate({
                            params: {
                              path: {
                                poId: String(po.data!.id),
                                itemId: String(item.id),
                              },
                            },
                            body: { locationId, status: 'open' },
                          });
                        }}
                      />
                    </Td>
                    <Td>
                      <HStack>
                        <Button
                          size="sm"
                          gap={1}
                          variant={'outline'}
                          isDisabled={!item.location?.id}
                          onClick={() => {
                            const status = !isReady ? 'ready' : 'open';

                            editPoItem.mutate(
                              {
                                params: { path: { poId: String(po.data!.id), itemId: String(item.id) } },
                                body: { status },
                              },
                              { onSuccess: () => po.refetch() },
                            );
                          }}
                        >
                          {!isReady ? 'OK' : 'Undo'}
                          {!isReady ? <LuCheck /> : <LuUndo2 />}
                        </Button>
                      </HStack>
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <BsThreeDots size="14px" />
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            color="red.500"
                            icon={<LuTrash2 />}
                            onClick={() => {
                              if (!po.data?.id) return;

                              removePoItem.mutate(
                                {
                                  params: {
                                    path: {
                                      poId: String(po.data.id),
                                      itemId: String(item.id),
                                    },
                                  },
                                },
                                { onSuccess: () => po.refetch() },
                              );
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        <CardFooter>
          <Box>
            {editPoItem.isLoading && <Spinner size="sm" />}
            {editPoItem.isSuccess && (
              <HStack gap={1}>
                <LuCheck color="green" size="10px" />
                <Text color="zinc.400" fontSize="xs">
                  Saved
                </Text>
              </HStack>
            )}
          </Box>

          <Button
            ml="auto"
            colorScheme="brand"
            isDisabled={po.data.items?.some((item) => item.status === 'open')}
            onClick={() => {
              if (!po.data) return;

              receivePo.mutate(
                {
                  // @ts-ignore
                  body: {},
                  params: { path: { id: String(po.data.id) } },
                },
                {
                  onSuccess: () => {
                    navigate(-1);
                    notify.success('Purchase Order received successfully');
                  },
                },
              );
            }}
          >
            Receive
          </Button>
        </CardFooter>
      </Card>
    </Stack>
  );
}
