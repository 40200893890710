import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Stack } from '@chakra-ui/layout';
import { Alert, AlertIcon, Button, Input } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAcceptInvitation } from 'api/user';
import { PasswordChecklist, PasswordInput } from 'components/password-input';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'router';
import { z } from 'zod';

const schema = z.object({
  name: z.string().nonempty({ message: 'Name is required' }),
  password: z.string().min(8, { message: 'Password must be at least 8 characters long' }),
});

type FormValues = z.infer<typeof schema>;

export default function () {
  const [params] = useSearchParams();
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const acceptInvitation = useAcceptInvitation();

  const token = params.get('t');
  const name = params.get('name');
  const email = params.get('email');

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: name || '',
      password: '',
    },
  });

  const password = form.watch('password');

  const onSubmit = async (values: FormValues) => {
    if (!token || !email) return;

    const onSuccess = () => navigate('/login', { state: { email } });
    acceptInvitation.mutate({ inviteToken: token, ...values }, { onSuccess });
  };

  if (!token || !email) {
    return (
      <Alert status="error">
        <AlertIcon />
        This URL is invalid or has expired. Please request a new invitation.
      </Alert>
    );
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input isDisabled value={email || ''} />
        </FormControl>
        <FormControl isRequired isInvalid={!!form.formState?.errors?.name?.message}>
          <FormLabel>Name</FormLabel>
          <Input {...form.register('name')} />
          <FormErrorMessage>{form.formState?.errors?.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!form.formState?.errors.password}>
          <FormLabel>Password</FormLabel>
          <Controller name="password" control={form.control} render={({ field }) => <PasswordInput {...field} />} />
          <PasswordChecklist onChange={setIsValidPassword} value={password} />
        </FormControl>

        <Button
          type="submit"
          colorScheme="brand"
          isLoading={acceptInvitation.isLoading}
          isDisabled={!isValidPassword || !form.formState.isValid}
        >
          Create account
        </Button>
      </Stack>
    </form>
  );
}
