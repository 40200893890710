import { Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import {
  Button,
  Card,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useGetQuery } from 'api/client';
import { EmptyState } from 'components/EmptyState';
import { PageHeader } from 'components/Page';
import { startCase } from 'lodash';
import { useState } from 'react';
import { LuEye, LuPackageSearch, LuZoomIn } from 'react-icons/lu';
import { useNavigate } from 'router';
import { toUSD } from 'utils/currency';
import { dayjs } from 'utils/dates';

export default function BatchesPage() {
  const [showAll, setShowAll] = useState(false);

  const batches = useGetQuery('/api/v3/batch', {});

  const navigate = useNavigate();

  const isLoading = batches.isLoading;
  const isEmpty = batches.isSuccess && batches.data?.length === 0;

  const isLoadingMarkup = isLoading && null;

  const isEmptyMarkup = isEmpty && (
    <Flex justifyContent="center" h="md">
      <EmptyState
        header="No batches found"
        icon={LuPackageSearch}
        maxW="xs"
        primaryAction={{
          size: 'sm',
          children: 'Go to Orders',
          onClick: () => navigate('/orders'),
        }}
      >
        <Text color="zinc.500" fontSize="sm">
          You can create batches from the Orders page or by importing a CSV file.
        </Text>
      </EmptyState>
    </Flex>
  );

  const batchesMarkup = (
    <Card mb="4">
      <TableContainer rounded="md">
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Sequence</Th>
              <Th>Orders</Th>
              <Th>Total</Th>
              <Th>Created</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody>
            {batches?.data?.slice(0, showAll ? undefined : 10)?.map((batch) => {
              const successfulOrders = batch?.jobs?.filter((job) => job.status === 'completed').length;
              const failedOrders = batch?.jobs?.filter((job) => job.status === 'failed').length;

              const statusColor =
                {
                  completed_with_errors: 'red',
                  completed: 'green',
                  failed: 'red',
                  pending: 'yellow',
                }[batch.status] || 'gray';

              return (
                <Tr key={batch.id}>
                  <Td>{batch.displayName}</Td>
                  <Td>{batch.sequence}</Td>
                  <Td>
                    {successfulOrders} / {batch?.jobs?.length}
                  </Td>
                  <Td>{toUSD(batch.totalCents / 100)}</Td>
                  <Td>
                    <Tag size="sm" colorScheme={statusColor}>
                      {startCase(batch.status)}
                    </Tag>
                  </Td>
                  <Td color="muted" fontSize={'sm'}>
                    {dayjs(batch.createdAt).format('MMM D, YYYY h:mm A')}
                  </Td>
                  <Td>
                    <Button
                      size="xs"
                      variant={'outline'}
                      gap={2}
                      onClick={() => navigate('/orders/batches/:id', { params: { id: String(batch.id) } })}
                    >
                      <LuEye />
                      View
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Flex p={4} justifyContent={'center'}>
          <Button variant={'outline'} onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show less' : 'Show more'}
          </Button>
        </Flex>
      </TableContainer>
    </Card>
  );

  return (
    <Stack gap={0}>
      <PageHeader>
        <Heading as="h1">Batches</Heading>
      </PageHeader>

      {isLoadingMarkup || isEmptyMarkup || batchesMarkup}
    </Stack>
  );
}
