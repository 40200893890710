import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  title: { fontWeight: 'semibold' },
  description: { fontWeight: 'normal', marginTop: `0px !important` },
  container: { rounded: 'md', fontSize: 'sm' },
});

const custom = definePartsStyle((props) => {
  const status = props.status as 'info' | 'warning' | 'error' | 'success';

  const statusColor = {
    info: 'blue',
    success: 'green',
    warning: 'yellow',
    error: 'red',
  };

  const color = statusColor[status];

  if (!color) return {};

  return {
    container: { border: '1px solid', borderColor: `${color}.200`, bg: `${color}.50` },
    title: { color: `${color}.700` },
    icon: { color: `${color}.400` },
  };
});

const size = { md: defineStyle({ w: 5, h: 5 }) };
const sizes = { md: definePartsStyle({ icon: size.md }) };

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: { custom },
  defaultProps: { variant: 'custom' },
});
