import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { USER_INVITATIONS_QUERY, useInviteUser } from 'api/user';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  email: z.string().email().nonempty(),
  name: z.string().nonempty(),
});

type FormValues = z.infer<typeof schema>;

export const InviteUserModal = (props: Omit<ModalProps, 'children'>) => {
  const { onClose } = props;

  const form = useForm<FormValues>();
  const { formState } = form;

  const inviteUser = useInviteUser();
  const { notifySuccess } = useNotification();

  const onSubmit = (data: FormValues) => {
    const onSuccess = () => {
      queryClient.invalidateQueries([USER_INVITATIONS_QUERY]);
      notifySuccess('User invited successfully');
      onClose();
    };

    inviteUser.mutate(data, { onSuccess });
  };

  return (
    <Modal {...props} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite User</ModalHeader>
        <ModalBody>
          <Text mb="6">
            Invite a user to your account. They will receive an email with a link to create an account and access your
            dashboard.
          </Text>

          <form id="invite-user" onSubmit={form.handleSubmit(onSubmit)}>
            <Stack gap="2">
              <FormControl isRequired isInvalid={!!form.formState.errors.name}>
                <FormLabel>Name</FormLabel>
                <Input placeholder="Jane Smith" {...form.register('name')} />
                <FormErrorMessage>{formState?.errors?.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!form.formState.errors.email}>
                <FormLabel>Email</FormLabel>
                <Input placeholder="jane@company.com" {...form.register('email')} />
                <FormErrorMessage>{formState?.errors?.email?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter gap="2">
          <Button type="button" isDisabled={inviteUser.isLoading} variant="outline" onClick={onClose}>
            Close
          </Button>
          <Button
            form="invite-user"
            type="submit"
            isDisabled={!formState.isValid}
            isLoading={inviteUser.isLoading}
            colorScheme="brand"
            mr={3}
          >
            Send email invitation
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
