import {
  Box,
  Divider,
  Flex,
  FormControl,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  Stack,
  Text,
  Card,
  CardBody,
} from '@chakra-ui/react';
import { useGetLogs, GetLogsResponse } from 'api/logs/get';
import { EmptyState } from 'components/EmptyState';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { BsLightningChargeFill } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { ArrayElement } from 'types/common';
import { formatDate } from 'utils/dates';

export const AutomationLogs = () => {
  const logRef = useRef<any>(null);
  const logs = useGetLogs({ type: 'automation' });

  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    logRef.current?.scrollIntoView?.({ behavior: 'smooth' });
  }, [logRef.current]);

  const handleSearch = (log: ArrayElement<GetLogsResponse>): boolean => {
    if (!searchQuery || searchQuery === '') return true;

    return [log.automation?.name, log.order?.platformId, log.order?.reference]
      .join(' ')
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  };

  if (logs.isSuccess && logs.data?.length === 0)
    return (
      <>
        <Card>
          <EmptyState minH="lg" header="Logs not found" icon={AiOutlineUnorderedList}>
            <Text>No logs to show. Please check again later.</Text>
          </EmptyState>
        </Card>
      </>
    );

  return (
    <>
      <Card minH="lg">
        <CardBody>
          <Stack spacing="2">
            <Text fontSize="lg">Automation Logs</Text>
            <Text color="muted">A log of all automations triggered for your orders.</Text>
          </Stack>
          <Divider my="4" />
          <Box overflowY="scroll" maxH="lg" ref={logRef}>
            <FormControl maxW="50%" pb="2">
              <InputGroup size="sm">
                <InputLeftAddon>
                  <Icon as={FiSearch} />
                </InputLeftAddon>
                <Input placeholder="Search..." onChange={(e) => setSearchQuery(e.target.value || '')} />
              </InputGroup>
            </FormControl>
            {logs?.data
              ?.filter((log) => log.order && log.automation)
              ?.filter(handleSearch)
              ?.map((log, index) => {
                return (
                  <HStack my="4" key={index} color="muted">
                    <Flex bg="gray.200" p="1.5" rounded="full">
                      <Icon color="gray.600" as={BsLightningChargeFill} />
                    </Flex>
                    <HStack spacing="1">
                      <Text>Automation</Text>
                      <Text fontWeight="semibold">{log.automation?.name}</Text>
                      <Text> triggered for Order</Text>{' '}
                      <Text fontWeight="semibold">
                        {log.order?.platform === 'shopify' ? log.order.reference : log.order?.platformId}
                      </Text>
                    </HStack>
                    <Spacer />
                    <Text color="gray.400" ml="auto">
                      {formatDate(log.createdAt, 'YYYY-MM-DD HH:mm:ss')}
                    </Text>
                  </HStack>
                );
              })}
          </Box>
        </CardBody>
      </Card>
    </>
  );
};
