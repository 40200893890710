import { defineStyleConfig } from '@chakra-ui/react';

export const textareaTheme = defineStyleConfig({
  defaultProps: {},
  sizes: {
    sm: { rounded: 'sm' },
    md: { fontSize: 'sm', rounded: 'sm' },
    lg: { rounded: 'sm' },
  },
});
