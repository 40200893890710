import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { paths } from 'api/types';
import axios from 'config/axios';

/**
 * Update Order
 */
export type UpdateOrderInput = paths['/api/v3/orders/{id}']['put']['requestBody'] & { id: number };
export type UpdateOrderResponse =
  paths['/api/v3/orders/{id}']['put']['responses']['200']['content']['application/json'];

export const useUpdateOrder = (opts: UseMutationOptions<UpdateOrderResponse, Error, UpdateOrderInput> = {}) =>
  useMutation<UpdateOrderResponse, Error, UpdateOrderInput>(async (input) => {
    const { id, ...payload } = input;
    const response = await axios.put(`/v3/orders/${id}`, payload);
    return response?.data;
  }, opts);

/**
 * Create Order File
 */
export type CreateOrderFileInput = FormData;
export type CreateOrderFileResponse = any;
export const useCreateOrderFile = (opts?: UseMutationOptions<CreateOrderFileResponse, Error, CreateOrderFileInput>) =>
  useMutation<CreateOrderFileResponse, Error, CreateOrderFileInput>(async (input) => {
    const response = await axios.post(`/v3/orders/files`, input, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response?.data;
  }, opts);

/**
 * Get Order Files
 */
export type GetOrderFilesResponse =
  paths['/api/v3/orders/files']['get']['responses']['200']['content']['application/json'];

export const GET_ORDER_FILES_QUERY = 'get_order_files';

export const useGetOrderFiles = (input?: any, opts?: UseQueryOptions<any>) =>
  useQuery<GetOrderFilesResponse>(
    [GET_ORDER_FILES_QUERY, input],
    async (_input) => {
      const result = await axios.get(`/v3/orders/files`);
      return result?.data;
    },
    opts ?? {},
  );

/**
 * Get Order File
 */
export type GetOrderFileInput = { id: number } & paths['/api/v3/orders/files/{id}']['get']['parameters']['query'];
export type GetOrderFileResponse =
  paths['/api/v3/orders/files/{id}']['get']['responses']['200']['content']['application/json'];

export const GET_ORDER_FILE_QUERY = 'get_order_file';

export const useGetOrderFile = (
  input: GetOrderFileInput,
  opts?: UseQueryOptions<GetOrderFileInput, any, GetOrderFileResponse>,
) =>
  useQuery<GetOrderFileInput, any, GetOrderFileResponse>(
    [GET_ORDER_FILE_QUERY, input],
    async () => {
      const { id, ...params } = input;
      const result = await axios.get(`/v3/orders/files/${id}`, { params });
      return result?.data;
    },
    opts ?? {},
  );

/**
 * Import Orders
 */
export type ImportOrdersInput = paths['/api/v3/orders/imports']['post']['requestBody']['content']['application/json'];
export type ImportOrdersResponse =
  paths['/api/v3/orders/imports']['post']['responses']['200']['content']['application/json'];
export const useImportOrders = (opts?: UseMutationOptions<ImportOrdersResponse, Error, ImportOrdersInput>) =>
  useMutation<ImportOrdersResponse, Error, ImportOrdersInput>(async (input) => {
    const response = await axios.post(`/v3/orders/imports`, input, {});
    return response?.data;
  }, opts);

/**
 * Get Order Import
 */
export type GetOrderImportInput = { id: number };
export type GetOrderImportResponse =
  paths['/api/v3/orders/imports/{id}']['get']['responses']['200']['content']['application/json'];

export const GET_ORDER_IMPORT_QUERY = 'get_order_import';

export const useGetOrderImport = (
  input: GetOrderImportInput,
  opts?: UseQueryOptions<GetOrderImportInput, any, GetOrderImportResponse>,
) => {
  const { id } = input;
  return useQuery<GetOrderImportInput, any, GetOrderImportResponse>(
    [GET_ORDER_IMPORT_QUERY, input],
    async () => {
      const result = await axios.get(`/v3/orders/imports/${id}`);
      return result?.data;
    },
    opts ?? {},
  );
};

/**
 * Remove Order Import
 */
export type RemoveOrderImportInput = { id: number };
export const useRemoveOrderImport = (opts?: UseMutationOptions<any, Error, RemoveOrderImportInput>) =>
  useMutation<any, Error, RemoveOrderImportInput>(async (input) => {
    await axios.delete(`/v3/orders/imports/${input.id}`);
    return {};
  }, opts);

/**
 * Get Order
 */
export type GetOrderReponse = paths['/api/v3/orders/{id}']['get']['responses']['200']['content']['application/json'];

export const GET_ORDER_QUERY = 'get_order';

export const useGetOrder = (input?: { id: number }, opts?: UseQueryOptions<any>) =>
  useQuery<GetOrderReponse>(
    [GET_ORDER_QUERY, input],
    async (_input) => {
      const result = await axios.get(`/v3/orders/${input?.id}`);
      return result?.data;
    },
    opts ?? {},
  );

/**
 * Update Line Items
 */
export type UpdateLineItemsInput =
  paths['/api/v3/orders/{id}/line-items']['put']['requestBody']['content']['application/json'] & { id: number };
export type UpdateLineItemsResponse =
  paths['/api/v3/orders/{id}/line-items']['put']['responses']['200']['content']['application/json'];

export const useUpdateLineItems = (
  opts: UseMutationOptions<UpdateLineItemsResponse, Error, UpdateLineItemsInput> = {},
) =>
  useMutation<UpdateLineItemsResponse, Error, UpdateLineItemsInput>(async (input) => {
    const { id, ...payload } = input;
    const response = await axios.put(`/v3/orders/${id}/line-items`, payload);
    return response?.data;
  }, opts);

/**
 * Split Order
 */

export type SplitOrderInput =
  paths['/api/v3/orders/{id}/split']['post']['requestBody']['content']['application/json'] & { id: number };
export type SplitOrderResponse =
  paths['/api/v3/orders/{id}/split']['post']['responses']['200']['content']['application/json'];

export const useSplitOrder = () =>
  useMutation({
    mutationFn: async (input: SplitOrderInput) => {
      const response = await axios.post<SplitOrderResponse>(`/v3/orders/${input.id}/split`, input);
      return response.data;
    },
  });
