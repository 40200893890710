import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react';
import { Logo } from '../Logo';
import { Sidebar } from './Sidebar';
import { ToggleButton } from './ToggleButton';

export const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box width="full" pb="4" pt="5" px={{ base: '4', md: '8' }} bg="brandBlack" shadow="xs">
      <Flex justify="space-between">
        <Logo variant="logomark" color="yellow" my="auto" w="10" />
        <ToggleButton color="zinc.200" zIndex="1" isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
        <Drawer
          size="xs"
          placement="left"
          isOpen={isOpen}
          onClose={onClose}
          preserveScrollBarGap
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Sidebar w="full" />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};
