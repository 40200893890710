import { Box, Card, CardBody, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FiCheckCircle, FiInfo, FiX, FiXCircle } from 'react-icons/fi';

export type Variant = 'success' | 'error' | 'info';

interface Props {
  onClose: () => void;
  title: string;
  description?: ReactNode;
  variant?: Variant;
}

export const Notification = (props: Props) => {
  const { onClose, title } = props;

  const description = props.description ?? '';
  const variant = props.variant ?? 'info';

  const icons: { [key in Variant]: () => JSX.Element } = {
    success: () => <Icon as={FiCheckCircle} h={6} w={6} textColor="green.700" />,
    error: () => <Icon as={FiXCircle} h={6} w={6} textColor="red.800" />,
    info: () => <Icon as={FiInfo} h={6} w={6} textColor="blue.500" />,
  };

  return (
    <Card maxW="sm">
      <CardBody>
        <HStack align="baseline" spacing={3}>
          <Box>{icons[variant]()}</Box>
          <VStack align="start" spacing={1}>
            <Text fontWeight="medium">{title}</Text>
            <Text textColor="muted">{description}</Text>
          </VStack>
          <Icon w={5} h={5} as={FiX} alignSelf="space-between" onClick={onClose} />
        </HStack>
      </CardBody>
    </Card>
  );
};
