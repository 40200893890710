import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/react';
import { useUser } from 'contexts/auth-context';
import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { Permission } from 'types';

const Loading = () => (
  <Flex w="full" h="100vh" justify="center" alignItems="center">
    <Spinner mx="auto" my="auto" />
  </Flex>
);

export const RequirePermission = (
  props: PropsWithChildren<{
    permission: keyof Permission;

    /**
     * If true, this component will render `props.children` when the
     * user has required permissions. This is helpful if you are already
     * using <Outlet /> in the _layout component (to prevent two <Outlet>s)
     * from being rendered.
     */
    noOutlet?: Boolean;
  }>,
) => {
  const { permission, noOutlet = false } = props;

  const user = useUser();

  if (user === undefined) return <Loading />;

  if (user?.permissions[permission]) {
    return noOutlet ? <>{props.children}</> : <Outlet />;
  }

  return null;
};
