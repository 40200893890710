import { Tag } from '@chakra-ui/react';
import { ComponentProps, FC } from 'react';
import { FaEbay, FaEtsy, FaShopify } from 'react-icons/fa';
import { FaGear } from 'react-icons/fa6';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { MdStorefront } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { SiWoo, SiFauna } from 'react-icons/si';
import { Order } from 'types';
import { StorePlatform } from 'types/enums';

type Platform = Order['platform'];

interface StorePlatformLogoProps extends ComponentProps<'div'> {
  platform?: Platform | string;
  variant?: 'circle' | 'normal';
  size?: number;
  circleSize?: number;
  opacity?: number | string;
}

export const StoreLogo: FC<StorePlatformLogoProps> = (props) => {
  const { opacity, variant = 'normal', platform, size, circleSize, ...rest } = props;

  let logo = null;

  switch (platform) {
    case StorePlatform.Ebay:
      logo = <RiErrorWarningLine fontSize={size ?? 22} title="eBay" />;
      break;
    case StorePlatform.Ebay2:
      logo = <FaEbay fontSize={size ?? 38} title="eBay" />;
      break;
    case StorePlatform.Shopify:
      logo = <FaShopify fontSize={size ?? 28} title="Shopify" />;
      break;
    case StorePlatform.WooCommerce:
      logo = <SiWoo fontSize={size ?? 28} title="WooCommerce" />;
      break;
    case StorePlatform.Etsy:
      logo = <FaEtsy fontSize={size ?? 28} title="Etsy" />;
      break;
    case StorePlatform.Extensiv:
      logo = <SiFauna fontSize={size ?? 28} title="Extensiv" />;
      break;
    case 'manual':
      logo = <LuFileSpreadsheet fontSize={size ?? 28} title="Manual" />;
      break;
    case StorePlatform.ShipStation:
      logo = <FaGear fontSize={size ?? 28} title="ShipStation" />;
      break;
    default:
      logo = <MdStorefront fontSize={size ?? 28} title="Store" />;
      break;
  }

  return (
    <div {...rest}>
      {variant === 'circle' ? (
        <Tag borderRadius="full" p={circleSize ?? 5} color="muted" opacity={opacity ?? 1}>
          {logo}
        </Tag>
      ) : (
        <>{logo}</>
      )}
    </div>
  );
};
