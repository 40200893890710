import {
  Box,
  Divider,
  HStack,
  IconButton,
  Spacer,
  Stack,
  StackProps,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useUserStore } from 'store';

export interface NavItemProps {
  path: string;
  label: string;
  icon: React.ReactElement;
  rightIcon?: React.ReactElement;

  children?: Omit<NavItemProps, 'icon' | 'canView'>[];
}

export const NavItem = (props: NavItemProps) => {
  const { icon, path, children, label, rightIcon } = props;

  const isCollapsed = useUserStore((state) => state.isSidebarCollapsed);

  const bg = useColorModeValue('zinc.800', 'zinc.800');

  const submenu = useDisclosure();

  const navigate = useNavigate();
  const location = useLocation();

  const isActive = path === location.pathname;

  const baseStyles: StackProps = {
    color: 'zinc.200',
    px: '2',
    py: '0.26rem',
    rounded: 'md',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 0.2s',
    _hover: { bg: 'zinc.800' },
    _active: { bg: 'zinc.800' },
  };

  if (isCollapsed)
    return (
      <Tooltip label={label} placement="right">
        <IconButton
          aria-label={label}
          icon={icon}
          onClick={() => navigate(path)}
          variant="ghost"
          size="lg"
          color={isActive ? 'zinc.100' : 'zinc.300'}
          _hover={{ bg }}
          _active={{ bg }}
          bg={isActive ? bg : undefined}
        />
      </Tooltip>
    );

  return (
    <Stack color="zinc.200" spacing={1}>
      <Link to={path} onClick={submenu.onToggle}>
        <HStack {...baseStyles} bg={isActive ? bg : undefined}>
          <Box fontSize="lg" color={isActive ? 'zinc.200' : 'zinc.500'}>
            {icon}
          </Box>
          <Text fontWeight={isActive ? 'semibold' : 'normal'} color="zinc.200">
            {label}
          </Text>
          <Spacer />
          {rightIcon}
        </HStack>
      </Link>
      <Stack spacing={0.5} hidden={!submenu.isOpen || !children}>
        {children?.map((child) => {
          const active = child.path === location.pathname;

          return (
            <Link key={child.path} to={child.path}>
              <Divider orientation="vertical" h="full" />
              <HStack ml={2} {...baseStyles} bg={active ? bg : undefined}>
                <Text ml={4} color={active ? 'zinc.200' : 'zinc.400'}>
                  {child.label}
                </Text>
              </HStack>
            </Link>
          );
        })}
      </Stack>
    </Stack>
  );
};
