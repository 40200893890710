import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type GetAuthUrlResponse = { url: string };
type GetAuthUrlInput = { id: number };

export const getAuthUrl = async (input?: GetAuthUrlInput) => {
  if (!input) return null;

  const { id } = input;

  const result = await axios.get(`/v3/stores/shopify/${id}/auth-url`);

  return result?.data;
};

export const useGetAuthUrl = (opts?: UseMutationOptions<GetAuthUrlResponse, {}, GetAuthUrlInput>) =>
  useMutation<GetAuthUrlResponse, {}, GetAuthUrlInput>(getAuthUrl, opts);
