import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Divider,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { usePostMutation } from 'api/client';
import { StoreLogo } from 'components/store-logo';
import { useOutsideClick } from 'hooks/use-outside-click';
import { FiRefreshCcw } from 'react-icons/fi';
import { HiChevronDown } from 'react-icons/hi';
import { timeSince } from 'utils/dates';

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
  onSync?: () => Promise<void> | void;
  stores?: {
    id: number;
    name: string;
    platform: string;
    lastSyncAt: string | null;
  }[];
};

export function SyncStoresButton(props: Props) {
  const { onSync, stores = [], disabled = false, isLoading: orderListLoading } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useOutsideClick(onClose);

  const syncStores = usePostMutation('/api/v3/stores/sync');

  const isLoading = syncStores.isLoading || orderListLoading;

  const onSyncStore = async (id: number, platform: string) => {
    syncStores.mutate({ body: { stores: [{ id, platform: platform as any }] } }, { onSuccess: onSync });
  };

  const onSyncAll = () => {
    if (!stores) return;

    syncStores.mutate(
      { body: { stores: stores?.map((store) => ({ id: store.id, platform: store.platform as any })) } },
      { onSuccess: onSync },
    );
  };

  return (
    <div ref={buttonRef}>
      <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
        <ButtonGroup isAttached isDisabled={disabled || isLoading}>
          <Button
            borderRight={'1px'}
            borderColor={'#A1C1D2'}
            colorScheme="brand"
            onClick={onSyncAll}
            isLoading={syncStores.isLoading}
          >
            Sync Stores
          </Button>
          <PopoverTrigger>
            <Button
              px={0}
              colorScheme="brand"
              onClick={onOpen}
              isDisabled={!stores?.length || syncStores.isLoading}
            >
              <HiChevronDown />
            </Button>
          </PopoverTrigger>
        </ButtonGroup>
        <PopoverContent width="auto">
          {stores?.length === 0
            ? null
            : stores?.map((store, index) => {
                return (
                  <Card key={index} border="none" borderBottom="1px solid" borderRadius="none">
                    <CardBody display="flex" justifyContent="space-between" alignItems="center" px={4} py={4}>
                      <HStack justifyItems="center">
                        <StoreLogo variant="circle" platform={store.platform} size={18} circleSize={3} />
                        <Box marginLeft="16px" maxWidth="600px" marginRight="16px">
                          <Text alignSelf="flex-start" maxWidth="150px" isTruncated fontWeight="medium">
                            {store.name}
                          </Text>
                          <Text color="zinc.500">
                            {store.lastSyncAt ? `Synced ${timeSince(store.lastSyncAt)}` : 'Click to sync'}
                          </Text>
                        </Box>
                      </HStack>
                      <Button
                        marginLeft={4}
                        type="button"
                        variant="outline"
                        size="sm"
                        width={10}
                        height={10}
                        isDisabled={syncStores.isLoading}
                        onClick={() => onSyncStore(store.id, store.platform)}
                      >
                        <FiRefreshCcw />
                      </Button>
                    </CardBody>
                  </Card>
                );
              })}
          <Divider />
        </PopoverContent>
      </Popover>
    </div>
  );
}
