import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';

type RefundShipment = { id: string };
export type RefundShipmentResponse = {};
export type RefundShipmenetError = AxiosError<{ message: string }>;

export const refundShipment = async ({ id }: RefundShipment) => {
  const result = await axios.post(`/v3/shipments/${id}/refund`);
  if (result?.status !== 200) throw new Error('Error submitting refund');
  return result?.data;
};

const useRefundShipment = (opts?: UseMutationOptions<RefundShipmentResponse, RefundShipmenetError, RefundShipment>) =>
  useMutation<RefundShipmentResponse, RefundShipmenetError, RefundShipment>(refundShipment, opts);

export default useRefundShipment;
