import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';

type DeletePaymentMethod = { id: string };
export type DeletePaymentMethodResponse = { status: string };
export type DeletePaymentMethodError = AxiosError<{ message: string }>;

export const deletePaymentMethod = async ({ id }: DeletePaymentMethod) => {
  const { data } = await axios.delete(`/v3/user/payment-methods/${id}`);
  return data;
};

const useDeletePaymentMethod = (
  opts?: UseMutationOptions<DeletePaymentMethodResponse, DeletePaymentMethodError, DeletePaymentMethod>,
) => useMutation<DeletePaymentMethodResponse, DeletePaymentMethodError, DeletePaymentMethod>(deletePaymentMethod, opts);

export default useDeletePaymentMethod;
