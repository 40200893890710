import axios from 'config/axios';
import { UseMutationOptions, useMutation } from 'react-query';

/**
 * Create  File
 */
export type CreateFileInput = FormData;
export type CreateFileResponse = any;
export const useCreateFile = (opts?: UseMutationOptions<CreateFileResponse, Error, CreateFileInput>) =>
  useMutation<CreateFileResponse, Error, CreateFileInput>(async (input) => {
    const response = await axios.post(`/v3/files`, input, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response?.data;
  }, opts);
