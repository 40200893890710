import { Notification, Variant as NotificationVariant } from 'components/notification';
import React, { PropsWithChildren, ReactNode, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

interface NotifyInput {
  title: string;
  description?: ReactNode;
  variant?: NotificationVariant;
}

const NotificationContext = React.createContext<{
  notify: (input: NotifyInput) => void;
  notifySuccess: (input: ReactNode) => void;
  notifyInfo: (input: ReactNode) => void;
  notifyError: (input: ReactNode) => void;
  info: (input: ReactNode) => void;
  success: (input: ReactNode) => void;
  error: (input: ReactNode) => void;
}>({
  notify: () => {},
  notifySuccess: () => {},
  notifyInfo: () => {},
  notifyError: () => {},
  success: () => {},
  info: () => {},
  error: () => {},
});

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const notify = (input: NotifyInput) => {
    const { title, description, variant } = input;

    toast.custom((t) => (
      <Notification title={title} variant={variant} description={description} onClose={() => toast.dismiss(t.id)} />
    ));
  };

  const value = {
    notify,
    notifySuccess: (text: ReactNode) => notify({ title: 'Success', description: text, variant: 'success' }),
    notifyInfo: (text: ReactNode) => notify({ title: 'Info', description: text, variant: 'info' }),
    notifyError: (text: ReactNode) => notify({ title: 'Error', description: text, variant: 'error' }),
    // Aliases
    info: (text: ReactNode) => notify({ title: 'Info', description: text, variant: 'info' }),
    success: (text: ReactNode) => notify({ title: 'Success', description: text, variant: 'success' }),
    error: (text: ReactNode) => notify({ title: 'Error', description: text, variant: 'error' }),
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Toaster containerStyle={{ marginTop: '1rem' }} position="bottom-right" />
    </NotificationContext.Provider>
  );
}
