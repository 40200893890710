import { Button, Icon, Link, Tag, Text } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { startCase } from 'lodash';
import { HiArrowRight } from 'react-icons/hi';
import { Link as RouterLink } from 'router';
import { Invoice, PaymentLog } from 'types/payment';
import { formatCentsToDollars } from 'utils/currency';
import { formatDate } from 'utils/dates';
import { useDisclosure } from '../../../../hooks/use-disclosure';
import { PayInvoiceModal } from './pay-invoice-modal';

const columnHelper = createColumnHelper<PaymentLog>();
const invoiceColumnHelper = createColumnHelper<Invoice>();

export const columns = [
  columnHelper.accessor('createdAt', {
    enableSorting: false,
    header: 'Date',
    meta: { sortId: 'createdAt' },
    cell: (props) => <Text>{formatDate(props.getValue(), 'MM/DD/YYYY')}</Text>,
  }),

  columnHelper.accessor('type', {
    enableSorting: false,
    header: 'Type',
    meta: { sortId: 'type' },
    cell: (props) => <Tag size="sm">{startCase(props.getValue())}</Tag>,
  }),

  columnHelper.accessor('description', {
    enableSorting: false,
    header: 'Description',
    meta: { sortId: 'description' },
    cell: (props) => <Text color="muted">{props.getValue() ?? ''}</Text>,
  }),

  columnHelper.accessor('amountCents', {
    enableSorting: false,
    header: 'Amount',
    meta: { sortId: 'amountCents' },
    cell: (props) => (
      <Text fontWeight="medium">{props.getValue() === 0 ? '-' : formatCentsToDollars(props.getValue() ?? '')}</Text>
    ),
  }),

  columnHelper.display({
    enableSorting: false,
    id: 'receipt',
    header: 'Receipt',
    cell: (props) => {
      const paymentLog = props.row.original;

      if (!paymentLog.receiptUrl) return null;

      if (paymentLog.shipment?.id) {
        return (
          <Link as={RouterLink} to="/shipments/:id" params={{ id: paymentLog.shipment.id }}>
            View Shipment <Icon as={HiArrowRight} />
          </Link>
        );
      }

      return (
        <Link href={paymentLog.receiptUrl} target="_blank" rel="noopener noreferrer">
          View
        </Link>
      );
    },
  }),
] as ColumnDef<PaymentLog>[];

export const invoicesColumns = [
  invoiceColumnHelper.accessor('createdAt', {
    enableSorting: false,
    header: 'Date',
    cell: (props) => <Text>{formatDate(props.getValue(), 'MM/DD/YYYY')}</Text>,
  }),

  invoiceColumnHelper.accessor('id', {
    enableSorting: false,
    header: 'Invoice',
    cell: (props) => <Text color="muted">Invoice {props.getValue()}</Text>,
  }),

  invoiceColumnHelper.accessor('from', {
    enableSorting: false,
    header: 'From',
    cell: (props) => <Text>{formatDate(props.getValue(), 'MM/DD/YYYY')}</Text>,
  }),

  invoiceColumnHelper.accessor('to', {
    enableSorting: false,
    header: 'To',
    cell: (props) => <Text>{formatDate(props.getValue(), 'MM/DD/YYYY')}</Text>,
  }),

  invoiceColumnHelper.accessor('due', {
    enableSorting: false,
    header: 'Due',
    cell: (props) => <Text>{formatDate(props.getValue(), 'MM/DD/YYYY')}</Text>,
  }),

  invoiceColumnHelper.accessor('amount', {
    enableSorting: false,
    header: 'Total',
    cell: (props) => <Text fontWeight="medium">{props.getValue()}</Text>,
  }),

  invoiceColumnHelper.accessor('amountDue', {
    enableSorting: false,
    header: 'Amount Due',
    cell: (props) => <Text fontWeight="medium">{props.getValue()}</Text>,
  }),

  invoiceColumnHelper.accessor('amountPaid', {
    enableSorting: false,
    header: 'Amount Paid',
    cell: (props) => <Text fontWeight="medium">{props.getValue()}</Text>,
  }),

  invoiceColumnHelper.display({
    enableSorting: false,
    id: 'report',
    header: 'Report',
    cell: (props) => {
      const invoice = props.row.original;

      if (!invoice.reportDownloadUrl) return null;

      return (
        <Link href={invoice.reportDownloadUrl} target="_blank" rel="noopener noreferrer">
          View
        </Link>
      );
    },
  }),
  invoiceColumnHelper.display({
    enableSorting: false,
    id: 'pay',
    header: '',
    cell: (props) => {
      const invoice = props.row.original;

      const payModal = useDisclosure();

      return (
        <>
          <Button
            onClick={payModal.onOpen}
            variant="outline"
            size="xs"
            isDisabled={invoice.amount === invoice.amountPaid}
          >
            Pay
          </Button>
          {payModal.isOpen && <PayInvoiceModal isOpen={payModal.isOpen} onClose={payModal.onClose} invoice={invoice} />}
        </>
      );
    },
  }),
] as ColumnDef<Invoice>[];
