import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import useGenerateReport from 'api/reports/generate-report';
import { startCase } from 'lodash';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { GET_REPORTS_QUERY } from '../../../../api/reports';
import { queryClient } from '../../../../config/query-client';
import { useNotification } from '../../../../contexts/notification.context';
import { addDays, formatDate, startOf, subDays, subMonths } from '../../../../utils/dates';
import { reportTypes } from './helpers';

const presetDates = [
  {
    title: 'Today',
    start: new Date(),
    end: new Date(),
  },
  {
    title: 'Yesterday',
    start: subDays(new Date(), 1),
    end: new Date(),
  },
  {
    title: 'Last 7 days',
    start: subDays(new Date(), 7),
    end: new Date(),
  },
  {
    title: 'Last 30 days',
    start: subDays(new Date(), 30),
    end: new Date(),
  },
  {
    title: 'Month to date',
    start: startOf(new Date(), 'month'),
    end: new Date(),
  },
  {
    title: 'Last month',
    start: startOf(subMonths(new Date(), 1), 'month'),
    end: subDays(startOf(new Date(), 'month'), 1),
  },
];

export const GenerateReport = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [activeDateFilter, setActiveDateFilter] = useState<string>('');

  const form = useForm();
  const { handleSubmit } = form;
  const { notifySuccess, notifyError } = useNotification();

  const generateReport = useGenerateReport({
    onSuccess: () => {
      onClose();
      notifySuccess(`Report generating!`);
      queryClient.invalidateQueries(GET_REPORTS_QUERY);
    },
  });

  const onSubmit = () => {
    const submitStartDate = formatDate(startDate || '');
    const submitEndDate = formatDate(endDate || '');
    const submitType = type || 'shipment';

    if (!submitStartDate || !submitEndDate || submitEndDate < submitStartDate) {
      notifyError('Invalid start and end date for report.');
      return;
    }

    generateReport.mutate({ startDate: submitStartDate, endDate: submitEndDate, type: submitType });
  };

  const openModal = () => {
    setStartDate(startOf(new Date(), 'month'));
    setEndDate(new Date());
    setActiveDateFilter('Month to date');
  };

  const { isOpen, onOpen, onClose } = useDisclosure({ onOpen: openModal });
  // const [radarSessionId, setRadarSessionId] = useState<string>();
  // const [errorMessage, setErrorMessage] = useState<string>('');

  const closeAndReset = () => {
    onClose();
  };

  return (
    <>
      <Button float="right" variant="solid" colorScheme="brand" onClick={onOpen}>
        Generate Report
      </Button>

      <Modal isOpen={isOpen} onClose={closeAndReset}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Report</ModalHeader>
          <chakra.form onSubmit={handleSubmit(() => onSubmit())}>
            <ModalBody>
              <VStack gap={5}>
                <Flex gap={3}>
                  <Text fontWeight="medium" display="flex" alignItems="center" width={195}>
                    Report Type
                  </Text>
                  <Select onChange={(e) => setType(e.target.value)}>
                    {reportTypes.map((t) => (
                      <option key={t} value={t}>
                        {startCase(t)}
                      </option>
                    ))}
                  </Select>
                </Flex>

                <Flex gap={10}>
                  <Box>
                    <VStack align="left" minW="max-content">
                      <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                        Report Period
                      </Text>
                      {presetDates.map((item) => (
                        <Text
                          textColor={item.title === activeDateFilter ? 'blue' : 'muted'}
                          fontSize="sm"
                          key={item.title}
                          onClick={() => {
                            setStartDate(item.start);
                            setEndDate(item.end);
                            setActiveDateFilter(item.title);
                          }}
                          cursor="pointer"
                        >
                          {item.title}
                        </Text>
                      ))}
                    </VStack>
                  </Box>
                  <Box>
                    <ReactDatePicker
                      selectsRange
                      dateFormat="M/d/yy"
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={maxDate}
                      inline
                      onChange={(dates) => {
                        if (Array.isArray(dates)) {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                          setActiveDateFilter('');
                          if (start && !end) {
                            setMaxDate(addDays(start, 31));
                          }
                        }
                      }}
                    />
                  </Box>
                </Flex>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button disabled={generateReport.isLoading} variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="brand"
                  disabled={generateReport.isLoading}
                  isLoading={generateReport.isLoading}
                  loadingText="Generating..."
                >
                  Generate
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </chakra.form>
        </ModalContent>
      </Modal>
    </>
  );
};
