import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type EbayRequestBody = { url: string; name: string };
type EbayResponse = { url: string };
export const connectEbayStore = async (vars: EbayRequestBody) => {
  const { data } = await axios.post<EbayResponse>(`/v3/stores/ebay/connect`, vars);
  return data;
};

type ShopifyRequestBody = { name: string; url: string };
type ShopifyResponse = { url: string };
export const connectShopifyStore = async (vars: ShopifyRequestBody) => {
  const { data } = await axios.post<ShopifyResponse>(`/v3/stores/shopify/connect`, vars);
  return data;
};

export const useConnectEbayStore = (opts?: UseMutationOptions<EbayResponse, {}, EbayRequestBody>) =>
  useMutation<EbayResponse, {}, EbayRequestBody>(connectEbayStore, opts);

export const useConnectShopifyStore = (opts?: UseMutationOptions<ShopifyResponse, {}, ShopifyRequestBody>) =>
  useMutation<ShopifyResponse, {}, ShopifyRequestBody>(connectShopifyStore, opts);
