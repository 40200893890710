import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type ConnectWooResult = { url: string };
type ConnectWooInput = { url: string; name: string };

const connect = async (input: ConnectWooInput) => {
  if (!input) return null;

  const result = await axios.post(`/v3/stores/woo/connect`, input);
  return result?.data;
};

export const useConnectWooStore = (opts?: UseMutationOptions<ConnectWooResult, {}, ConnectWooInput>) =>
  useMutation<ConnectWooResult, {}, ConnectWooInput>(connect, opts);
