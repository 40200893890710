import { Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, useDisclosure } from '@chakra-ui/react';
import { isString, truncate } from 'lodash';
import { FC } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { ProductsList, ProductsListProps } from '../products-list';

interface Props {
  products: ProductsListProps['products'];
  title?: string;
}

export const ProductsPopover: FC<Props> = (props) => {
  const { products, title } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const titleMarkup = isString(title) ? (
    truncate(title, { length: 22, omission: '...' })
  ) : (
    <>
      {products.length ?? 0} {products.length === 1 ? 'item' : 'items'}{' '}
    </>
  );

  return (
    <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverTrigger>
        <Button variant="link" fontWeight="normal" gap="1" color="brandBlack">
          {titleMarkup} {isOpen ? <FaCaretUp /> : <FaCaretDown />}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody p="4" overflow="auto">
            <ProductsList products={products} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
