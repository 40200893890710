import { Box, HStack, StackProps, Text, chakra } from '@chakra-ui/react';
import { CSSProperties, PropsWithChildren, createContext, useContext, useState } from 'react';
import { LuBox } from 'react-icons/lu';
import { zinc } from 'theme';

/**
 * Context
 */

const SelectedNodeContext = createContext<{
  selectedNodeId: number | null;
  setSelectedNodeId: (id: number) => void;
}>({
  selectedNodeId: null,
  setSelectedNodeId: () => {},
});

export const SelectedNodeProvider = ({ children }: PropsWithChildren) => {
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);

  return (
    <SelectedNodeContext.Provider value={{ selectedNodeId, setSelectedNodeId }}>
      {children}
    </SelectedNodeContext.Provider>
  );
};

export const useSelectedNode = () => useContext(SelectedNodeContext);

type Node = { id: number; name: string; children: Node[]; inventories: any[] };

export const TreeNode = ({
  node,
  level,
  onSelect,
}: {
  node: Node;
  level: number;
  onSelect?: (input: Node) => void;
}) => {
  const { selectedNodeId, setSelectedNodeId } = useSelectedNode();

  const handleClick = () => {
    setSelectedNodeId(node.id);
    onSelect?.(node);
  };

  const isSelected = selectedNodeId === node.id;

  const styles: CSSProperties = {};
  const item: StackProps = {
    p: 1.5,
    mt: 0.5,
    rounded: 'lg',
    cursor: 'pointer',
    onClick: handleClick,
    _hover: { bg: 'zinc.100' },
    paddingLeft: level === 0 ? '2' : `${level * 20}px`,
    ...(isSelected && {
      bg: 'zinc.50',
      fontWeight: 'bold',
    }),
  };

  return (
    <div style={styles}>
      <chakra.details>
        <chakra.summary>
          <HStack {...item}>
            <LuBox color={zinc[500]} />
            <Text>{node.name}</Text>
          </HStack>
        </chakra.summary>

        <div>
          {node.children &&
            node.children.length > 0 &&
            node.children.map((child) => (
              <TreeNode onSelect={onSelect} key={child.id} node={child} level={level + 1} />
            ))}

          {node.inventories && (
            <Box pl={1}>
              {node.inventories.map((i, index) => (
                <HStack
                  {...item}
                  key={index}
                  color="muted"
                  bg="transparent"
                  fontWeight="normal"
                  gap={1}
                  paddingLeft={`${(level + 1) * 20}px`}
                >
                  <Text color={i.availableQuantity > 0 ? 'green.600' : 'red.600'}>
                    {i.availableQuantity || 0}
                  </Text>
                  <Text color="muted" ml={1}>
                    {i.product?.name}
                  </Text>
                </HStack>
              ))}
            </Box>
          )}
        </div>
      </chakra.details>
    </div>
  );
};

export function Tree({ data, onSelect }: { data: Node[]; onSelect?: (input: Node) => void }) {
  return (
    <SelectedNodeProvider>
      {data.map((node, index) => (
        <TreeNode onSelect={onSelect} key={index} node={node} level={0} />
      ))}
    </SelectedNodeProvider>
  );
}
