import { accordionTheme } from './accordion';
import { alertTheme } from './alert';
import { buttonTheme } from './button';
import { cardTheme } from './card';
import { drawerTheme } from './drawer';
import { formTheme } from './form';
import { formErrorTheme } from './form-error';
import { inputTheme } from './input';
import { linkTheme } from './link';
import { modalTheme } from './modal';
import { selectTheme } from './select';
import { tagTheme } from './tag';
import { textTheme } from './text';
import { textareaTheme } from './textarea';
import { tooltipTheme } from './tooltip';

export default {
  Input: inputTheme,
  Tag: tagTheme,
  Select: selectTheme,
  Button: buttonTheme,
  Link: linkTheme,
  Text: textTheme,
  Tooltip: tooltipTheme,
  Alert: alertTheme,
  Form: formTheme,
  FormError: formErrorTheme,
  Card: cardTheme,
  Modal: modalTheme,
  Accordion: accordionTheme,
  Textarea: textareaTheme,
  Drawer: drawerTheme,
};
