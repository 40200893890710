import { Card, CardBody, Divider, Flex, HStack, Icon, IconButton, Stack, Text, chakra } from '@chakra-ui/react';
import { useGetAutomations, useReorderAutomations, useUpdateAutomation } from 'api/automation';
import { EmptyState } from 'components/EmptyState';
import { useDisclosure } from 'hooks/use-disclosure';
import { isEqual } from 'lodash';
import { FC, Fragment, useEffect, useState } from 'react';
import { BiCog } from 'react-icons/bi';
import { FiEdit2, FiPause, FiPlay, FiTrash2 } from 'react-icons/fi';
import { LuCopy } from 'react-icons/lu';
import { MdDragHandle } from 'react-icons/md';
import { ReactSortable } from 'react-sortablejs';
import { AutomationForm } from '../automation-form';
import { DeleteAutomationModal } from '../delete-automation-modal';

const Sortable = chakra(ReactSortable<any>);

const AutomationsList: FC = () => {
  const editAutomationModal = useDisclosure();
  const deleteAutomationModal = useDisclosure();

  const [selectedAutomationId, setSelectedAutomationId] = useState<number | null>(null);
  const [copyAutomationId, setCopyAutomationId] = useState<number | null>(null);
  const getAutomations = useGetAutomations();
  const reorderAutomations = useReorderAutomations();
  const updateAutomation = useUpdateAutomation();

  const [automations, setAutomations] = useState<any[]>([]);

  useEffect(() => {
    if (!getAutomations.data) return;
    setAutomations(getAutomations.data);
  }, [getAutomations.data]);

  useEffect(() => {
    if (!editAutomationModal.isOpen && !deleteAutomationModal.isOpen) {
      setSelectedAutomationId(null);
      setCopyAutomationId(null);
    }
  }, [editAutomationModal.isOpen, deleteAutomationModal.isOpen]);

  const handleOrderUpdate = (updated: any[]) => {
    const hasOrderChanged = !isEqual(updated, automations);
    if (!hasOrderChanged) return;

    const onError = () => setAutomations(automations);
    reorderAutomations.mutate(updated.map((a, index) => ({ id: a.id, priority: index + 1 }), { onError }));
    setAutomations(updated);
  };

  const automationsListMarkup = (
    <Sortable handle="#sortable-handle" list={automations} setList={handleOrderUpdate} w="full">
      {automations?.map((item: any) => (
        <Fragment key={item.id}>
          <Flex alignItems="center" justify="space-between" key={item.id}>
            <Flex gap="4" py="4" opacity={item.disabled ? '0.4' : 1}>
              <Icon id="sortable-handle" _hover={{ cursor: 'move' }} as={MdDragHandle} fontSize="22px" />
              <Text color="muted" opacity={item.isActive ? 1 : 0.5}>
                {item.name}
              </Text>
            </Flex>
            <HStack spacing="1">
              <IconButton
                disabled={updateAutomation.isLoading || getAutomations.isLoading}
                variant="outline"
                size="sm"
                icon={<LuCopy />}
                aria-label="Duplicate"
                title="Duplicate"
                onClick={() => {
                  setCopyAutomationId(item.id);
                  editAutomationModal.onOpen();
                }}
              />
              <IconButton
                disabled={updateAutomation.isLoading || getAutomations.isLoading}
                variant="outline"
                size="sm"
                icon={<FiEdit2 />}
                aria-label="Edit"
                title="Edit"
                onClick={() => {
                  setSelectedAutomationId(item.id);
                  editAutomationModal.onOpen();
                }}
              />
              <IconButton
                disabled={updateAutomation.isLoading || getAutomations.isLoading}
                variant="outline"
                size="sm"
                icon={item.isActive ? <FiPause /> : <FiPlay />}
                aria-label="Pause Automation"
                title="Pause Automation"
                onClick={() => {
                  updateAutomation.mutate(
                    { id: item.id, isActive: !item.isActive },
                    { onSuccess: () => getAutomations.refetch() },
                  );
                }}
              />
              <IconButton
                disabled={updateAutomation.isLoading || getAutomations.isLoading}
                color="red.400"
                borderColor="red.200"
                _hover={{ bg: 'red.50' }}
                variant="outline"
                size="sm"
                icon={<FiTrash2 />}
                aria-label="Remove"
                onClick={() => {
                  setSelectedAutomationId(item.id);
                  deleteAutomationModal.onOpen();
                }}
              />
            </HStack>
          </Flex>
        </Fragment>
      ))}
    </Sortable>
  );

  const automationsEmptyStateMarkup = getAutomations.isFetched && !automations.length && (
    <EmptyState
      minH="md"
      header="No automations found"
      icon={BiCog}
      secondaryAction={{
        children: 'Create',
        variant: 'outline',
        size: 'sm',
        onClick: editAutomationModal.onOpen,
      }}
    >
      <Text>Get started by creating your first automation.</Text>
    </EmptyState>
  );

  const automationId = selectedAutomationId || copyAutomationId;

  return (
    <>
      <Card minH="lg">
        <CardBody>
          <Stack spacing="2">
            <Text fontSize="lg">Manage your automations</Text>
            <HStack spacing="0.5">
              <Text color="muted">List of all automations you have created. You can edit, pause or delete them.</Text>
            </HStack>
          </Stack>
          <Divider my="4" />

          {automationsListMarkup}
          {automationsEmptyStateMarkup}
        </CardBody>
      </Card>

      {editAutomationModal.isOpen && (
        <AutomationForm
          forceCreate={!!copyAutomationId}
          automation={automationId ? { id: automationId } : null}
          {...editAutomationModal}
        />
      )}

      {deleteAutomationModal.isOpen && (
        <DeleteAutomationModal
          automation={{ id: selectedAutomationId }}
          isOpen={deleteAutomationModal.isOpen}
          onClose={() => {
            setSelectedAutomationId(null);
            deleteAutomationModal.onClose();
          }}
        />
      )}
    </>
  );
};

export default AutomationsList;
