import { StyleConfig } from '@chakra-ui/react';

export const tagTheme: StyleConfig = {
  baseStyle: {
    container: { rounded: 'full' },
  },
  sizes: {
    md: {
      container: { fontSize: 'xs' },
    },
  },
};
