import { FC } from 'react';
import { useGetZone } from 'api/usps/get-zone';
import { Tag, TagProps } from '@chakra-ui/react';

interface Props extends TagProps {
  fromZip: string;
  toZip: string;
}

export const ZoneTag: FC<Props> = (props) => {
  const { fromZip, toZip, ...rest } = props;

  const getZone = useGetZone({ fromZip: fromZip ?? '', toZip: toZip ?? '' });

  return <Tag {...rest}>Zone {getZone?.data?.zone || '--'}</Tag>;
};
