import { z } from 'zod';

export const customsItemSchema = z.object({
  description: z.string().nonempty(),
  quantity: z.coerce.number().min(1),
  value: z.coerce.number().min(0.01),
  weight: z.coerce.number().min(0.1),
  hsTariffNumber: z.string().optional(),
  originCountry: z.string(),
});

export const customsFormSchema = z.object({
  customs: z
    .object({
      nonDeliveryOption: z.enum(['return', 'abandon']).optional(),
      contentsType: z.enum(['documents', 'gift', 'merchandise', 'returned_goods', 'sample', 'other']).optional(),
      deliveryDutyPaid: z.boolean().optional().nullable(),
      items: z
        .array(
          customsItemSchema.merge(
            z.object({
              parcelId: z.string().optional().default('0'),
            }),
          ),
        )
        .nonempty(),
    })
    .optional(),
});
