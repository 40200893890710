import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { useUser } from 'contexts/auth-context';
import { Navigate } from 'react-router';
import { useUserStore } from 'store';

export default function () {
  const authToken = useUserStore((state) => state.authToken);
  const user = useUser();

  if (user === undefined) {
    return (
      <Flex w="full" h="100vh" justify="center" alignItems="center">
        <Spinner mx="auto" my="auto" />
      </Flex>
    );
  }

  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  return <Navigate to="/ship" replace />;
}
