import {
  Box,
  Button,
  Card,
  CardBody,
  Collapse,
  Flex,
  FormControl,
  HStack,
  Heading,
  Icon,
  Progress,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useCreateProductFile } from 'api/products/create-file';
import { useGetProductFiles } from 'api/products/get-files';
import { useImportProducts } from 'api/products/import';
import { PageBody, PageHeader } from 'components/Page';
import { Dropzone } from 'components/dropzone';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { useNotification } from 'contexts/notification.context';
import { useEffect, useState } from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { LuDownload } from 'react-icons/lu';
import { dayjs } from 'utils/dates';
import { formatBytes } from 'utils/misc';

export default function () {
  const [file, setFile] = useState<File | null>(null);

  const [selectedFileId, setSelectedFileId] = useState<number | null>(null);

  const { notifySuccess, notifyError } = useNotification();

  const confirmModal = useDisclosure();

  const importProducts = useImportProducts();
  const createProductFile = useCreateProductFile();
  const productFiles = useGetProductFiles();

  useEffect(() => {
    if (!confirmModal.isOpen) setSelectedFileId(null);
  }, [confirmModal.isOpen]);

  const onImportProduct = async () => {
    if (!selectedFileId) return;

    const result = await importProducts.mutateAsync({ fileId: selectedFileId });

    if (result.errors?.length === 0) {
      notifySuccess(`${result.total || 'All'} products imported successfully`);
      productFiles.refetch();
    } else {
      const first = result.errors?.[0] as any;
      notifyError(
        <>
          <Text mb="2">
            Error in product <strong>{first?.sku}</strong>
          </Text>
          {first?.messages?.map((m: any) => <p>• {m}</p>)}
        </>,
      );
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    setFile(acceptedFiles[0]);

    createProductFile.mutate(formData, {
      onError: () => setFile(null),
      onSuccess: async () => {
        setFile(null);
        productFiles.refetch();
      },
    });
  };

  return (
    <>
      <PageHeader>
        <Heading as="h1">Import Products</Heading>
        <Button
          ml="auto"
          variant="outline"
          leftIcon={<LuDownload />}
          onClick={() => window.open('/vesyl-product-import-template.csv', '_blank')}
        >
          Download Template
        </Button>
      </PageHeader>
      <PageBody>
        <Card>
          <CardBody>
            <FormControl id="file">
              <Dropzone minH="60" dropzoneOptions={{ maxFiles: 1, onDrop }} />
            </FormControl>

            {/* Progress */}
            <Collapse animateOpacity in={!!file}>
              {file && (
                <Box border="1px" borderColor="gray.200" p="4" rounded="md" mt="6">
                  <HStack spacing="4">
                    <Flex alignItems="center" justify="center" bg="gray.100" rounded="full" h="10" w="10">
                      <Icon as={FaFileCsv} color="gray.500" fontSize="xl" />
                    </Flex>
                    <Stack w="full" spacing="1">
                      <Text fontWeight="semibold">Uploading...</Text>
                      <Text pb="2" color="gray.500">
                        {file.name} - {formatBytes(file.size)}
                      </Text>
                      <Progress size="xs" isIndeterminate colorScheme="brand" value={100} />
                    </Stack>
                  </HStack>
                </Box>
              )}
            </Collapse>
            <TableContainer>
              <Table variant="simple" size="sm" mt="10" opacity={productFiles.isFetching ? 0.3 : 1}>
                <Thead>
                  <Tr>
                    <Th>File name</Th>
                    <Th>File size</Th>
                    <Th>Date uploaded</Th>
                    <Th>Uploaded by</Th>
                    <Th>Status</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {productFiles.data?.map((f) => {
                    const hasImport = f.productImports?.length > 0;

                    return (
                      <Tr key={f.id}>
                        <Td>{f.name}</Td>
                        <Td>{formatBytes(f.sizeBytes)}</Td>
                        <Td>{dayjs(f.createdAt).fromNow()}</Td>
                        <Td>{f.user.fullName}</Td>

                        {/* Status */}
                        <Td>
                          {hasImport && (
                            <Tag size="sm" colorScheme="green">
                              Completed
                            </Tag>
                          )}
                        </Td>

                        {/* Action */}
                        <Td alignItems="center">
                          <Button
                            size="xs"
                            variant="primary"
                            hidden={hasImport}
                            isLoading={importProducts.isLoading && importProducts.variables?.fileId === f.id}
                            isDisabled={importProducts.isLoading}
                            onClick={() => {
                              setSelectedFileId(f.id);
                              confirmModal.onOpen();
                            }}
                          >
                            Import
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
        <ConfirmationModal
          type="confirm"
          header="Import Products"
          description={
            <p>
              This will import all products from{' '}
              <strong>{productFiles.data?.find((f) => f.id === selectedFileId)?.name}</strong> file. Continue?
            </p>
          }
          onConfirm={async () => {
            await onImportProduct();
          }}
          isOpen={confirmModal.isOpen}
          onClose={confirmModal.onClose}
          isLoading={importProducts.isLoading}
        />
      </PageBody>
    </>
  );
}
