import { Box, Button, Center, chakra, FormControl, PinInput, PinInputField, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSendPhoneVerification } from 'api/auth/send-phone-verification';
import { useVerifyPhone } from 'api/auth/verify-phone';
import { useNotification } from 'contexts/notification.context';
import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Path, useNavigate } from 'router';
import { PhoneVerificationStatus } from 'types/enums';
import { formatPhone } from 'utils/misc';
import { z } from 'zod';

const schema = z.object({
  code: z.string().length(6),
});

type FormValues = z.infer<typeof schema>;

export default function () {
  const navigate = useNavigate();
  const location = useLocation();
  const [isConfirmButtonEnabled, setIsConfirmButtonEnabled] = useState<boolean>(true);
  const [isResendButtonEnabled, setIsResendButtonEnabled] = useState<boolean>(true);
  const data = location.state as { user: User; updatePhoneToken: string };
  const verifyPhone = useVerifyPhone();
  const { notifySuccess, notifyError } = useNotification();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const { handleSubmit, formState, control, trigger } = form;

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (!data?.user?.id) {
      navigate('/login');
    }
  }, [navigate, data?.user?.id]);

  const sendPhoneVerification = useSendPhoneVerification(data?.user?.id);

  const resendCode = () => {
    setIsResendButtonEnabled(false);
    sendPhoneVerification.refetch();
    delay(() => setIsResendButtonEnabled(true), 3000);
  };

  const onSubmit = (e: FormValues) => {
    const value = e.code;
    setIsConfirmButtonEnabled(false);
    verifyPhone.mutate(
      { verificationCode: value, userId: data?.user?.id },
      {
        onSuccess(response) {
          if (response.status === PhoneVerificationStatus.Approved) {
            navigate('/login?userRegistered=true' as Path);
            notifySuccess('Phone successfully verified.');
            return;
          }
          notifyError('Incorrect code');
        },
      },
    );
    setIsConfirmButtonEnabled(true);
  };

  return (
    <Box>
      <Box width="full" alignItems="center" justifyContent="center" textAlign="center">
        <Text fontSize="lg" fontWeight="semibold" letterSpacing="tight" color="gray.900" marginBottom={2}>
          Phone Validation
        </Text>
        <Text fontSize="sm" marginBottom={6} color="muted">
          We sent a verification code to <b>{formatPhone(data?.user?.phone)}</b>.
          <br />
          Please enter the code below:
        </Text>
        <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))} textAlign="center">
          <FormControl isInvalid={!!formState.errors.code}>
            <Controller
              control={control}
              name="code"
              render={({ field }) => (
                <PinInput
                  isDisabled={sendPhoneVerification.isError}
                  onChange={(e) => field.onChange(e)}
                  value={field.value}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              )}
            />
          </FormControl>

          <Box marginY="6" width="full">
            <Button
              isDisabled={sendPhoneVerification.isError}
              w="full"
              type="submit"
              colorScheme="brand"
              disabled={!isConfirmButtonEnabled || !!formState.errors.code}
            >
              Confirm
            </Button>
          </Box>
        </chakra.form>

        <Center flexDir="column" gap="2">
          <Text fontSize="sm">
            {`Didn't receive a code? `}
            <Button
              onClick={resendCode}
              type="button"
              colorScheme="brand"
              variant="link"
              disabled={!isResendButtonEnabled}
            >
              Resend code
            </Button>
          </Text>

          <Text fontSize="sm">
            {`Wrong phone number? `}

            <Button
              onClick={() =>
                navigate('/edit-phone', {
                  state: {
                    user: data?.user,
                    updatePhoneToken: location.state.updatePhoneToken,
                  },
                })
              }
              type="button"
              colorScheme="brand"
              variant="link"
            >
              Change it
            </Button>
          </Text>
        </Center>
      </Box>
    </Box>
  );
}
