import { Button, ButtonProps, Flex, Input, InputProps } from '@chakra-ui/react';
import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import useClipboard from 'react-use-clipboard';

export const SecretInput = (props: InputProps & { buttonProps?: ButtonProps; clipboard?: boolean }) => {
  const { buttonProps, clipboard = false, ...inputProps } = props;

  const [isHidden, setIsHidden] = useState(true);

  const [isCopied, setCopied] = useClipboard(inputProps?.value + '' || '', { successDuration: 1400 });

  return (
    <>
      {isHidden ? (
        <Button
          w="fit-content"
          variant="outline"
          size="sm"
          rightIcon={<FiEye />}
          {...buttonProps}
          onClick={() => setIsHidden(false)}
        >
          View
        </Button>
      ) : (
        <Flex w="full">
          <Input {...inputProps} />
          {clipboard && (
            <Button
              h="1.75rm"
              minW="16"
              variant="outline"
              rounded="md"
              borderLeft={0}
              roundedLeft="none"
              onClick={setCopied}
              size={inputProps.size || 'md'}
            >
              {isCopied ? 'Copied!' : 'Copy'}
            </Button>
          )}
        </Flex>
      )}
    </>
  );
};
