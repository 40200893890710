import {
  Button,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  GET_CARRIER_ACCOUNTS_QUERY,
  useGetCarrierAccounts,
  useRemoveCarrierAccount,
} from 'api/carrier-accounts';
import { CarrierLogo } from 'components/CarrierLogo';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { queryClient } from 'config/query-client';
import { startCase } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { LuMoreVertical, LuPlus } from 'react-icons/lu';
import { GetCarrierAccountsResponse } from 'types/api-types';
import { CarrierSettingsModal } from './carrier-settings-modal';
import { EditCarrierModal } from './edit-carrier-modal';

export const CarrierAccountSettings: FC<{}> = () => {
  const isProduction = import.meta.env.PROD;
  const carrierAccounts = useGetCarrierAccounts();
  const removeCarrierAccount = useRemoveCarrierAccount();

  const editCarrierModal = useDisclosure();
  const carrierSettingsModal = useDisclosure();
  const confirmDeleteModal = useDisclosure();

  const [selectedCarrierAccount, setSelectedCarrierAccount] = useState<GetCarrierAccountsResponse[0] | null>(
    null,
  );

  useEffect(() => {
    if (!editCarrierModal.isOpen) setSelectedCarrierAccount(null);
  }, [editCarrierModal.isOpen]);

  useEffect(() => {
    if (!carrierSettingsModal.isOpen) setSelectedCarrierAccount(null);
  }, [carrierSettingsModal.isOpen]);

  const handleDelete = () => {
    const carrierAccountId = selectedCarrierAccount?.id;
    if (!carrierAccountId) return;

    removeCarrierAccount.mutate(carrierAccountId, {
      onError: () => {
        confirmDeleteModal.onClose();
        setSelectedCarrierAccount(null);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CARRIER_ACCOUNTS_QUERY]);
        confirmDeleteModal.onClose();
        setSelectedCarrierAccount(null);
      },
    });
  };

  return (
    <>
      <HStack justify="space-between">
        <Stack mb="5" gap="0">
          <Text fontWeight="semibold" fontSize="lg">
            Your Carriers
          </Text>
          <Text textColor="muted">Manage your carrier accounts</Text>
        </Stack>

        <Button onClick={editCarrierModal.onOpen} leftIcon={<LuPlus />} variant="primary">
          Add Carrier
        </Button>
      </HStack>
      <SimpleGrid columns={[1, 2, 3]} gap={3}>
        {carrierAccounts.data?.map((ca) => (
          <Card key={ca.id} minH="36">
            <CardBody>
              <HStack mb="auto" w="full">
                <Stack gap="1">
                  <HStack minH={54}>
                    <CarrierLogo carrier={ca.type} height={54} width={54} pr={2} />
                    <Text fontWeight="medium" fontSize="lg">
                      {ca.readable}
                    </Text>
                  </HStack>
                </Stack>
                <Spacer />
                <Menu>
                  <MenuButton as={Button} variant="ghost" size="xs">
                    <LuMoreVertical />
                  </MenuButton>
                  <MenuList fontSize="sm">
                    <MenuItem
                      isDisabled={ca.type.toLowerCase() !== 'usps'}
                      onClick={() => {
                        setSelectedCarrierAccount(ca);
                        carrierSettingsModal.onOpen();
                      }}
                    >
                      Settings
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </CardBody>
            <CardFooter gap="3">
              <Tag size="sm" title={`${startCase(ca.billingType)} managed`}>
                {startCase(ca.billingType)}
              </Tag>
              <Spacer />
              <Button
                variant="link"
                size="xs"
                ml="auto"
                hidden={ca.credentials === null}
                onClick={() => {
                  setSelectedCarrierAccount(ca);
                  confirmDeleteModal.onOpen();
                }}
              >
                Delete
              </Button>

              <Button
                variant="outline"
                size="xs"
                hidden={ca.credentials === null}
                onClick={() => {
                  setSelectedCarrierAccount(ca);
                  editCarrierModal.onOpen();
                }}
              >
                Edit
              </Button>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>

      {confirmDeleteModal.isOpen && (
        <ConfirmationModal
          {...confirmDeleteModal}
          type="delete"
          header="Delete Carrier Account"
          description={`Are you sure you want to delete ${selectedCarrierAccount?.readable}? This action cannot be undone.`}
          onConfirm={handleDelete}
        />
      )}

      {editCarrierModal.isOpen && (
        <EditCarrierModal
          selectedCarrier={selectedCarrierAccount?.type}
          carrierId={selectedCarrierAccount?.id}
          {...editCarrierModal}
        />
      )}

      {carrierSettingsModal.isOpen && (
        <CarrierSettingsModal {...carrierSettingsModal} carrier={selectedCarrierAccount!} />
      )}
    </>
  );
};
