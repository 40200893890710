import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Stack, Text } from '@chakra-ui/layout';
import { Alert, AlertDescription, AlertTitle, Button } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdatePassword } from 'api/auth';
import { PasswordChecklist, PasswordInput } from 'components/password-input';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'router';
import { z } from 'zod';

const schema = z.object({
  password: z.string().nonempty('Required'),
});

type FormValues = z.infer<typeof schema>;

export default function () {
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const form = useForm<FormValues>({ resolver: zodResolver(schema) });

  const { formState, watch, handleSubmit, control } = form;
  const password = watch('password');

  const updatePassword = useUpdatePassword();

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const token = params.get('t');

  if (!token)
    return (
      <Alert status="error">
        <Stack>
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>This URL is expired or invalid. Please request a new password reset link.</AlertDescription>
        </Stack>
      </Alert>
    );

  const onSubmit = (values: FormValues) => {
    updatePassword.mutate(
      {
        confirmPassword: values.password,
        newPassword: values.password,
        resetToken: token,
      },
      {
        onSuccess: (result) => {
          navigate('/login', { state: { email: result.email } });
        },
      },
    );
  };

  return (
    <Stack>
      <Text fontWeight="medium" fontSize="lg">
        Update your password
      </Text>
      <Text fontSize="sm" color="muted" pb="4">
        Enter your new password below.
      </Text>
      <form onSubmit={handleSubmit((e) => onSubmit(e))}>
        <Stack gap="3.5">
          <FormControl isDisabled={updatePassword.isLoading} isRequired isInvalid={!!formState.errors.password}>
            <FormLabel>New password</FormLabel>
            <Controller name="password" control={control} render={({ field }) => <PasswordInput {...field} />} />
            <PasswordChecklist onChange={setIsValidPassword} value={password} />
          </FormControl>

          <Button
            isDisabled={!isValidPassword}
            isLoading={updatePassword.isLoading}
            colorScheme="brand"
            width="full"
            type="submit"
          >
            Update password
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
