import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type CreateLabelPDFBody = { shipmentIds: number[]; format: string; packingList: boolean };
export type CreateLabelPDFResponse = { url: string };
export type CreateLabelPDFError = AxiosError<{ message: string }>;

export const labelPDF = async ({ shipmentIds, format, packingList }: CreateLabelPDFBody) => {
  const { data } = await axios.post('/v3/shipments/label-pdf', { shipmentIds, format, packingList });
  return data;
};

const useCreateLabelPDF = (
  opts?: UseMutationOptions<CreateLabelPDFResponse, CreateLabelPDFError, CreateLabelPDFBody>,
) => useMutation<CreateLabelPDFResponse, CreateLabelPDFError, CreateLabelPDFBody>(labelPDF, opts);

export default useCreateLabelPDF;
