import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useGetQuery } from 'api/client';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';

export default function () {
  const locations = useGetQuery('/api/v3/locations', {});

  return (
    <>
      <Tabs colorScheme="brand" variant="solid-rounded" isLazy my={4}>
        <TabList>
          {locations.data?.map((l) => (
            <Tab key={l.id} as={Link} to={`/inventories/locations/${l.id}`}>
              {l.originAddress?.nickname || l.originAddress?.line1}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Outlet />
    </>
  );
}
