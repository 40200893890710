import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  SimpleGrid,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  keyframes,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetFileMappings } from 'api/file-mappings';
import { useCreateOrderFile, useGetOrderFiles, useImportOrders } from 'api/orders';
import { Logo } from 'components/Logo';
import { PageBody, PageHeader } from 'components/Page';
import { Dropzone } from 'components/dropzone';
import { maxBy, startCase } from 'lodash';
import { useState } from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { FiArrowRight, FiChevronDown } from 'react-icons/fi';
import { LuTag } from 'react-icons/lu';
import { useNavigate } from 'react-router';
import { dayjs } from 'utils/dates';
import { formatBytes } from 'utils/misc';

const bounce = keyframes({
  '0%, 20%, 50%, 80%, 100%': { transform: 'translateY(0)' },
  '40%': { transform: 'translateX(-30px)' },
  '60%:': { transform: 'translateX(30px)' },
});

const orderImportStatusColor: { [key: string]: string } = {
  processing: 'gray',
  completed: 'green',
  completed_with_errors: 'yellow',
};

type OrderFileMapping = {
  fileMappingId: number;
  orderFileId: number;
  fileMappingName: string;
  filename: string;
};

export default function () {
  const [file, setFile] = useState<File | null>(null);
  const [orderFileMappings, setOrderFileMappings] = useState<Array<OrderFileMapping>>([]);
  const [orderFileMapping, setOrderFileMapping] = useState<OrderFileMapping | null>(null); // Selected mapping
  const [isTransactional, setIsTransactional] = useState(false);
  const [shouldRunAutomations, setShouldRunAutomations] = useState(true);

  const navigate = useNavigate();
  const importOrdersModal = useDisclosure();

  const importOrders = useImportOrders();
  const createOrderFile = useCreateOrderFile();
  const orderFiles = useGetOrderFiles();
  const fileMappings = useGetFileMappings();

  const onImportOrders = () => {
    if (!orderFileMapping) return;

    importOrders.mutate(
      {
        fileId: orderFileMapping.orderFileId,
        fileMappingId: orderFileMapping.fileMappingId,
        isDryRun: false,
        isTransactional,
        shouldRunAutomations,
      },
      {
        onError: () => setOrderFileMapping(null),
        onSuccess: (data) => {
          importOrdersModal.onClose();
          navigate(`/orders/import/${data.id}`);
        },
      },
    );
  };

  const onDrop = (acceptedFiles: File[]) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    setFile(acceptedFiles[0]);

    createOrderFile.mutate(formData, {
      onError: () => setFile(null),
      onSuccess: async () => {
        setFile(null);
        orderFiles.refetch();
      },
    });
  };

  return (
    <>
      <PageHeader>
        <Heading as="h1">Import Orders</Heading>

        <Button variant="outline" ml="auto" leftIcon={<LuTag />} onClick={() => navigate('/products/import')}>
          Import Products
        </Button>
      </PageHeader>
      <PageBody>
        <Card>
          <CardBody>
            <FormControl id="file">
              <Dropzone minH="60" dropzoneOptions={{ maxFiles: 1, onDrop }} />
            </FormControl>

            {/* Progress */}
            <Collapse animateOpacity in={!!file}>
              {file && (
                <Box border="1px" borderColor="gray.200" p="4" rounded="md" mt="6">
                  <HStack spacing="4">
                    <Flex alignItems="center" justify="center" bg="gray.100" rounded="full" h="10" w="10">
                      <Icon as={FaFileCsv} color="gray.500" fontSize="xl" />
                    </Flex>
                    <Stack w="full" spacing="1">
                      <Text fontWeight="semibold">Uploading...</Text>
                      <Text pb="2" color="gray.500">
                        {file.name} - {formatBytes(file.size)}
                      </Text>
                      <Progress size="xs" isIndeterminate colorScheme="brand" value={100} />
                    </Stack>
                  </HStack>
                </Box>
              )}
            </Collapse>

            <Table variant="simple" mt="10" size="sm" opacity={orderFiles.isFetching ? 0.3 : 1}>
              <Thead>
                <Tr>
                  <Th>File name</Th>
                  <Th>File size</Th>
                  <Th>Date uploaded</Th>
                  <Th>Uploaded by</Th>
                  <Th>Status</Th>
                  <Th>Mapping</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {orderFiles.data?.map((f) => {
                  const orderMapping = orderFileMappings.find((m) => m.orderFileId === f.id);
                  const name = orderMapping?.fileMappingName || 'Select';
                  const canImport = f.orderImports?.length === 0;

                  return (
                    <Tr key={f.id}>
                      <Td>{f.name}</Td>
                      <Td>{formatBytes(f.sizeBytes)}</Td>
                      <Td>{dayjs(f.createdAt).fromNow()}</Td>
                      <Td>{f.user.fullName}</Td>

                      {/* Status */}
                      <Td>
                        {f.orderImports?.length > 0 ? (
                          <Tag
                            size="sm"
                            colorScheme={
                              orderImportStatusColor[maxBy(f.orderImports, 'id')?.status || ''] || 'gray'
                            }
                          >
                            {startCase(maxBy(f.orderImports, 'id')?.status)}
                          </Tag>
                        ) : (
                          <Tag size="sm" colorScheme="blue">
                            Ready
                          </Tag>
                        )}
                      </Td>

                      {/* Mapping */}
                      <Td>
                        <Menu>
                          <MenuButton
                            hidden={!canImport}
                            variant="outline"
                            as={Button}
                            size="xs"
                            rightIcon={<FiChevronDown />}
                          >
                            {name}
                          </MenuButton>
                          <MenuList>
                            {fileMappings.data?.map((ma) => {
                              return (
                                <MenuItem
                                  key={ma.id}
                                  onClick={() =>
                                    setOrderFileMappings([
                                      ...orderFileMappings,
                                      {
                                        filename: f.name,
                                        orderFileId: f.id,
                                        fileMappingId: ma.id,
                                        fileMappingName: ma.name,
                                      },
                                    ])
                                  }
                                >
                                  {ma.name}
                                </MenuItem>
                              );
                            })}

                            {fileMappings.data && fileMappings.data?.length > 0 && <MenuDivider />}

                            <MenuItem onClick={() => navigate(`/orders/files/${f.id}/mapping`)}>
                              Create new mapping...
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>

                      {/* Action */}
                      <Td alignItems="center">
                        {orderMapping && canImport && (
                          <Button
                            size="xs"
                            variant="primary"
                            onClick={() => {
                              setOrderFileMapping(orderMapping);
                              importOrdersModal.onOpen();
                            }}
                          >
                            Import
                          </Button>
                        )}

                        {!canImport && (
                          <HStack gap="1">
                            <Button
                              gap="1"
                              size="xs"
                              variant="outline"
                              onClick={() => {
                                const importId = maxBy(f.orderImports, 'id')?.id;
                                navigate(`/orders/import/${importId}`);
                              }}
                            >
                              View
                            </Button>
                          </HStack>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </PageBody>

      <Modal size="xl" {...importOrdersModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import orders</ModalHeader>
          <ModalBody>
            <Text>
              You are about to import the orders in <b>{orderFileMapping?.filename}</b> into VESYL.
            </Text>

            <SimpleGrid
              columns={3}
              py="5"
              border="1px"
              borderColor="gray.200"
              rounded="lg"
              bg="gray.50"
              my="5"
            >
              <Stack alignItems="center" justify="center">
                <Icon as={FaFileCsv} color="gray.500" fontSize="xl" />
                <Text fontWeight="semibold">{orderFileMapping?.filename}</Text>
              </Stack>

              <Flex alignItems="center" justify="center">
                <Icon animation={`${bounce} 2s infinite`} as={FiArrowRight} color="gray.500" fontSize="xl" />
              </Flex>

              <Stack alignItems="center" justify="center">
                <Logo variant="horizontal" w="28" color="black" opacity={0.8} />
              </Stack>
            </SimpleGrid>

            <Text fontWeight="semibold" pb="2">
              Options
            </Text>

            <Text fontSize="sm" color="gray.500">
              Please review the following settings before importing orders.
            </Text>

            <FormControl mt="4">
              <HStack>
                <Checkbox
                  isChecked={shouldRunAutomations}
                  onChange={(e) => setShouldRunAutomations(e.target.checked)}
                >
                  <Text>Run automations</Text>
                  <Text fontSize="xs" fontWeight="normal">
                    Run automations on all imported orders. This might take a while depending on the number of
                    orders imported.
                  </Text>
                </Checkbox>
              </HStack>
            </FormControl>

            <FormControl mt="4">
              <HStack>
                <Checkbox isChecked={isTransactional} onChange={(e) => setIsTransactional(e.target.checked)}>
                  <Text>Transactional</Text>
                  <Text fontSize="xs" fontWeight="normal">
                    &quot;All or nothing&quot;. Only import orders if all of them are valid. If one order is
                    invalid, none of them will be imported.
                  </Text>
                </Checkbox>
              </HStack>
            </FormControl>

            {/* @TODO: Enable this when/if dry run imports are implemented */}
            {/* <FormControl mt="4">
              <HStack>
                <Checkbox isChecked={isDryRun} onChange={(e) => setIsDryRun(e.target.checked)}>
                  <Text>Dry run</Text>
                  <Text fontSize="xs" fontWeight="normal">
                    Test the import process without actually importing any orders. If true, other options will be
                    ignored.
                  </Text>
                </Checkbox>
              </HStack>
            </FormControl> */}
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={importOrders.isLoading} variant="outline" onClick={importOrdersModal.onClose}>
              Close
            </Button>
            <Button
              isLoading={importOrders.isLoading}
              variant="primary"
              ml="2"
              onClick={() => onImportOrders()}
            >
              Import orders
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
