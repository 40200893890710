import { Button, Divider, Flex, FormControl, FormLabel, Input, Stack, StackDivider, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { WooCommerceStore } from 'api/stores';
import { useUpdateWooCommerceStore } from 'api/stores/woocommerce/update';
import { useNotification } from 'contexts/notification.context';
import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { z } from 'zod';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
      {children}
    </Stack>
  );
};

const schema = z.object({
  name: z.string().nonempty(),
});

export type FormValues = z.infer<typeof schema>;

interface Props {
  store: WooCommerceStore;
}

export const EditWooCommerceForm = ({ store }: Props) => {
  const updateStore = useUpdateWooCommerceStore();

  const { notifySuccess } = useNotification();
  const navigate = useNavigate();

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: { name: store.name },
  });

  const { register, formState } = methods;

  const onSubmit = async (values: FormValues) => {
    updateStore.mutate(
      { storeId: store.id, ...values },
      {
        onSuccess: () => {
          notifySuccess('Your store has been successfully updated');
          navigate('/stores');
        },
      },
    );
  };

  return (
    <>
      <Stack>
        <Stack spacing={0}>
          <Text fontSize="lg" fontWeight="semibold">
            Update store
          </Text>
          <Text color="muted">Update your WooCommerce store information.</Text>
        </Stack>
      </Stack>

      <Divider my={6} />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing="3.5" divider={<StackDivider />}>
          <FormControl isRequired isInvalid={!!formState.errors?.name}>
            <Section>
              <FormLabel variant="inline">Name</FormLabel>
              <Input {...register('name')} placeholder="e.g. My Woo Store" />
            </Section>
          </FormControl>
        </Stack>
        <Flex justify="end" pt="6">
          <Button type="submit" colorScheme="brand" isLoading={updateStore.isLoading}>
            Update
          </Button>
        </Flex>
      </form>
    </>
  );
};
