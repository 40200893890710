import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const PageHeader = (props: PropsWithChildren<FlexProps>) => {
  const { children, ...rest } = props;

  return (
    <Flex
      alignItems={{ base: 'start', md: 'center' }}
      flexDir={{ base: 'column', sm: 'row' }}
      minH="12"
      pb="4"
      pt="0"
      sx={{
        'h1, h2, h3': {
          py: 4,
          fontSize: '3xl',
          fontWeight: 'normal',
          color: useColorModeValue('gray.800', 'gray.300'),
          lineHeight: '1.2rem',
        },
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export const PageBody = (props: PropsWithChildren<FlexProps>) => {
  const { children } = props;

  return (
    <Flex w="full" flexDir="column" rounded="md" mb="8" bg="transparent" {...props}>
      {children}
    </Flex>
  );
};
