import { round } from 'lodash';
import { FormEvent } from 'react';

export const ouncesToPounds = (numberInOunces: number) => {
  return parseFloat((Math.round(numberInOunces) / 16).toFixed(4));
};

export const getCountryNames = (country: string) => {
  try {
    return new Intl.DisplayNames(['en'], { type: 'region' }).of(country);
  } catch (error) {
    return '';
  }
};

export function parseCarrier(name: string): string {
  switch (name.toUpperCase()) {
    case 'USPS':
      return 'USPS';
    case 'UPSDAP':
      return 'VESYL UPS';
    case 'UPS':
      return 'UPS';
    case 'UPSSUREPOST':
      return 'UPS SurePost';
    case 'UPSMAILINNOVATIONS':
      return 'UPS Mail Innovations';
    case 'FEDEX':
      return 'FedEx';
    case 'FEDEXSMARTPOST':
      return 'FedEx Ground Economy';
    case 'OSMWORLDWIDE':
      return 'OSM';
    case 'RRDONNELLEY':
      return 'ePost Global';
    case 'EPG':
      return 'ePost Global';
    default:
      return name;
  }
}

export function calculateInsurance(insuranceCents: number): number {
  const amount = insuranceCents / 100;
  return amount <= 0.5 ? 0.5 : amount * 0.005;
}

export function orNull(value: any) {
  return value || null;
}

export function ozToLbsOz(oz: number | undefined) {
  if (!oz) return { lbs: null, ounces: null };

  const lbs = ouncesToPounds(oz);
  const ounces = round(oz % 16, 2);
  return { lbs, ounces };
}

function insertChar(str: string, value: string, index: number) {
  return str.slice(0, index) + value + str.slice(index);
}

export function formatPhone(phone: string | undefined): string | null {
  if (!phone) return null;

  let formattedPhone = '('.concat(phone.replace('+1', ''));
  formattedPhone = insertChar(formattedPhone, ') ', 4);
  formattedPhone = insertChar(formattedPhone, '-', 9);

  return formattedPhone;
}

// Use this when you need a form inside another (nested forms)
//  so that the submit button in the inner form doesn't trigger
//  the submit event of the outer form.
export function stopPropagate(callback: () => void) {
  return (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    callback();
    e.stopPropagation();
  };
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function isProductEditingDisabled(platforms?: string[] | null) {
  if (!platforms) return false;
  if (platforms.includes('custom')) return false;
  if (platforms.includes('manual')) return false;

  return true;
}

export const statuses = [
  'pre_transit',
  'in_transit',
  'out_for_delivery',
  'delivered',
  'available_for_pickup',
  'return_to_sender',
  'failure',
  'cancelled',
  'error',
  'unknown',
];

export function getNameFromStatus(status: string): string | undefined {
  switch (status) {
    case 'pre_transit':
      return 'Pre-Transit';
    case 'in_transit':
      return 'In Transit';
    case 'out_for_delivery':
      return 'Out for Delivery';
    case 'delivered':
      return 'Delivered';
    case 'available_for_pickup':
      return 'Available for Pickup';
    case 'return_to_sender':
      return 'Return to Sender';
    case 'failure':
      return 'Failure';
    case 'cancelled':
      return 'Cancelled';
    case 'error':
      return 'Error';
    default:
      return 'Unknown';
  }
}

export function parseStatus(status: string): string | undefined {
  switch (status) {
    case 'Pre-transit':
      return 'pre_transit';
    case 'In Transit':
      return 'in_transit';
    case 'Out for Delivery':
      return 'out_for_delivery';
    case 'Delivered':
      return 'delivered';
    case 'Available for Pickup':
      return 'available_for_pickup';
    case 'Return to Sender':
      return 'return_to_sender';
    case 'Failure':
      return 'failure';
    case 'Cancelled':
      return 'cancelled';
    case 'Error':
      return 'error';
    case 'Unknown':
      return 'unknown';
    default:
      return '';
  }
}
