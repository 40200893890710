import axios from 'config/axios';
import { useQuery } from 'react-query';

export type Location = { name: string; id: string };

type GetShopifyStoreLocationsResponse = Location[];
type GetShopifyStoreLocationsInput = { id: number };

export const getLocations = async (input?: GetShopifyStoreLocationsInput) => {
  if (!input) return null;
  const { id } = input;
  const result = await axios.get(`/v3/stores/shopify/${id}/locations`);
  return result?.data;
};

export const useGetShopifyStoreLocations = (input?: { id: number }) =>
  useQuery<GetShopifyStoreLocationsResponse>(['shopify_store_locations', input], () => getLocations(input), {});
