import { HStack, Text } from '@chakra-ui/layout';
import { Icon, IconButton } from '@chakra-ui/react';
import { IStatusPanelParams } from 'ag-grid-community';
import { useState } from 'react';
import { LuChevronFirst, LuChevronLast, LuChevronLeft, LuChevronRight } from 'react-icons/lu';

export const Pagination = (props: IStatusPanelParams) => {
  const { api } = props;
  const [pageSize, setPageSize] = useState(api.paginationGetPageSize());
  const [currentPage, setCurrentPage] = useState(api.paginationGetCurrentPage());
  const [totalPage, setTotalPage] = useState(api.paginationGetTotalPages());
  const [rowCount, setRowCount] = useState(api.paginationGetRowCount());
  const [previousDisable, setPreviousDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(false);

  api.addEventListener('paginationChanged', (params: IStatusPanelParams) => {
    setPageSize(params.api.paginationGetPageSize());
    setCurrentPage(params.api.paginationGetCurrentPage());
    setTotalPage(params.api.paginationGetTotalPages());
    setRowCount(params.api.paginationGetRowCount());

    handlePagination();
  });

  const handlePagination = () => {
    if (api.paginationGetCurrentPage() === 0) {
      setPreviousDisable(true);
    } else {
      setPreviousDisable(false);
    }

    if (api.paginationGetCurrentPage() + 1 === api.paginationGetTotalPages()) {
      setNextDisable(true);
    } else {
      setNextDisable(false);
    }
  };
  return (
    <HStack spacing={12} m={3}>
      <Text>
        <b>{currentPage * pageSize + 1}</b> to{' '}
        <b>{(currentPage + 1) * pageSize > rowCount ? rowCount : (currentPage + 1) * pageSize}</b> of <b>{rowCount}</b>
      </Text>
      <HStack>
        <IconButton
          aria-label="Go to first page"
          size="xs"
          variant="outline"
          isDisabled={previousDisable}
          icon={<Icon boxSize={4} as={LuChevronFirst} />}
          onClick={() => api.paginationGoToFirstPage()}
        />
        <IconButton
          aria-label="Go to previous page"
          size="xs"
          variant="outline"
          isDisabled={previousDisable}
          icon={<Icon boxSize={4} as={LuChevronLeft} />}
          onClick={() => api.paginationGoToPreviousPage()}
        />
        <Text>
          Page <b>{currentPage + 1}</b> of <b>{totalPage}</b>
        </Text>
        <IconButton
          aria-label="Go to next page"
          size="xs"
          variant="outline"
          isDisabled={nextDisable}
          icon={<Icon boxSize={4} as={LuChevronRight} />}
          onClick={() => api.paginationGoToNextPage()}
        />
        <IconButton
          aria-label="Go to last page"
          size="xs"
          variant="outline"
          isDisabled={nextDisable}
          icon={<Icon boxSize={4} as={LuChevronLast} />}
          onClick={() => api.paginationGoToLastPage()}
        />
      </HStack>
    </HStack>
  );
};
