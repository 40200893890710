import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { ColDef, IStatusPanelParams } from 'ag-grid-community';
import { startCase } from 'lodash';
import { useState } from 'react';
import { FiEye } from 'react-icons/fi';

export const ColumnsMenu = (props: IStatusPanelParams) => {
  const { api } = props;
  const [columns, setColumns] = useState<ColDef[] | undefined>(api.getColumnDefs());

  const handleSelect = (value: string | string[]) => {
    const shownColumns = [...value];
    if (!columns || !shownColumns) return;
    api.setColumnDefs(
      columns?.map((column) => {
        return { ...column, hide: !shownColumns.includes(column?.colId ?? '') };
      }),
    );
    setColumns(api.getColumnDefs());
  };

  return (
    <Menu placement="top" closeOnSelect={false}>
      <MenuButton as={Button} leftIcon={<FiEye />} size="xs" variant="outline">
        Show / Hide Columns
      </MenuButton>
      <MenuList overflowY="scroll" maxHeight={400}>
        <MenuOptionGroup
          defaultValue={columns?.filter((col) => !col.hide).map((col) => col.colId || '') || []}
          type="checkbox"
          onChange={handleSelect}
        >
          {columns
            ?.filter((c) => c.colId !== 'checkbox')
            .map((col) => {
              return (
                <MenuItemOption key={col.colId} value={col.colId}>
                  {col.headerName ? startCase(col.headerName) : startCase(col.colId)}
                </MenuItemOption>
              );
            })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
