import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IStatusPanelParams,
  StatusPanelDef,
  GridReadyEvent,
  RowDataUpdatedEvent,
  ColumnVisibleEvent,
  DragStoppedEvent,
  ColumnPinnedEvent,
  IServerSideGetRowsRequest,
  IServerSideSelectionState,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { RefObject, forwardRef, useMemo, useState } from 'react';
import { useTableStore } from 'store';
import { PaginationController } from './pagination-controller';
import { Pagination } from './pagination';
import { ColumnsMenu } from './columns-menu';
import { LicenseManager } from 'ag-grid-enterprise';
import { RefreshGridButton } from './refresh-grid';
import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Button, Card, CardBody } from '@chakra-ui/react';
import { GridHeader } from './grid-header';

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY || '');

export const parseFilterModel = (filterModel: IServerSideGetRowsRequest['filterModel']) => {
  if (!filterModel) return null;

  const parsedFilter: {
    field: string;
    filterType: string;
    operator: string | null;
    conditions: {
      type: string;
      filter: string | number | string[];
      dateFrom?: string;
      dateTo?: string;
    };
  }[] = [];

  Object.keys(filterModel).forEach((key) => {
    let conditions = [];
    if (filterModel[key]?.filterType === 'date') {
      if (filterModel[key]?.conditions) {
        conditions = filterModel[key].conditions.map((condition: any) => {
          return { type: condition.type, dateTo: condition.dateTo, dateFrom: condition.dateFrom };
        });
      } else {
        conditions = [
          {
            type: filterModel[key].type,
            dateFrom: filterModel[key].dateFrom,
            dateTo: filterModel[key].dateTo,
          },
        ];
      }
    }
    if (filterModel[key]?.filterType === 'text' || filterModel[key]?.filterType === 'number') {
      if (filterModel[key]?.conditions) {
        conditions = filterModel[key].conditions.map((condition: any) => {
          return { type: condition.type, filter: condition.filter };
        });
      } else {
        conditions = [
          {
            type: filterModel[key].type,
            filter: filterModel[key].filter,
          },
        ];
      }
    }
    if (filterModel[key]?.filterType === 'set') {
      if (filterModel[key]?.values) {
        conditions = [
          {
            type: filterModel[key].type,
            filter: filterModel[key].values,
          },
        ];
      }
    }

    parsedFilter.push({
      field: key,
      filterType: filterModel[key].filterType,
      conditions,
      operator: filterModel[key].operator,
    });
  });

  return JSON.stringify(parsedFilter);
};

export function getSelectedRows<T>(gridRef: React.RefObject<AgGridReact<T>>): T[] {
  if (!gridRef?.current?.api) return [];
  const serverSideSelectionState = gridRef?.current!.api?.getServerSideSelectionState();
  if ((serverSideSelectionState as IServerSideSelectionState).selectAll) {
    const selectedRows: T[] = [];
    gridRef?.current!.api?.forEachNode((node) => {
      if (node.isSelected()) {
        selectedRows.push(node.data as T);
      }
    });
    return selectedRows;
  }
  return gridRef?.current!.api?.getSelectedRows() as T[];
}

export const getServerSideDatasource: (
  server: any,
  additionalParams: Record<string, any>,
) => IServerSideDatasource = (server: any, additionalParams: any) => {
  return {
    getRows: async (params: IServerSideGetRowsParams) => {
      const { request } = params;

      const per = (request.endRow || 0) - (request.startRow || 0);
      const page = (request.endRow || 0) / per - 1;
      const sortModel = request.sortModel[0];
      const filterModel = parseFilterModel(request.filterModel);

      const response = await server({
        page,
        per,
        sort: sortModel?.colId,
        direction: sortModel?.sort,
        filterModel: additionalParams?.q ? null : filterModel,
        ...additionalParams,
      });

      if (response) {
        params.success({
          rowData: response.results || [],
          rowCount: response.total,
        });
      } else {
        params.fail();
      }
    },
  };
};

type Actions = {
  action: () => void;
  label: string;
  isHidden: boolean;
}[];

interface CustomAgGridProps extends AgGridReactProps {
  dataSource: IServerSideDatasource;
  tableId: string;
  viewEntity?: string;
  height: string;
  setSearchQuery?: (value: React.SetStateAction<string>) => void;
  actions?: (selected: any[]) => Actions;
  defaultFilterModel?: Record<string, any>;
  selected?: 'views' | 'status';
  onViewSelect?: () => void;
}

export const CustomAgGrid = forwardRef(function CustomAgGrid<T>(
  props: CustomAgGridProps,
  gridRef: React.Ref<AgGridReact<T>>,
) {
  const {
    onSelectionChanged,
    actions,
    viewEntity,
    setSearchQuery,
    columnDefs,
    dataSource,
    tableId,
    height,
    selected,
    onViewSelect,
    ...rest
  } = props;
  const tableStore = useTableStore(tableId);
  const [actionsButtons, setActionsButtons] = useState<Actions>([]);

  const customOnSelectionChanged = (input: SelectionChangedEvent) => {
    const selectedRows = getSelectedRows(gridRef as RefObject<AgGridReact<T>>);
    setActionsButtons(actions?.(selectedRows) || []);
    onSelectionChanged?.(input);
  };

  const leftStatusPanel = (params: IStatusPanelParams<any, any>) => {
    return (
      <HStack my={3} spacing={1}>
        <Box>{PaginationController(params, tableId)}</Box>
        <Box>{ColumnsMenu(params)}</Box>
        <Box>{RefreshGridButton(params)}</Box>
      </HStack>
    );
  };

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        { statusPanel: (params: IStatusPanelParams<any, any>) => leftStatusPanel(params), align: 'left' },
        { statusPanel: Pagination, align: 'right' },
      ],
    };
  }, []);

  /**
   * Runs when the grid has initialised and is ready for most api calls.
   * It may not be fully rendered yet.
   */
  const onGridReady = (params: GridReadyEvent) => {
    rest.onGridReady?.(params);
    params.columnApi.applyColumnState({ state: tableStore.columnConfiguration, applyOrder: true });
  };

  /**
   * Runs when the client has updated data for the grid by either by:
   * a) setting new Row Data or
   * b) Applying a Row Transaction
   */
  const onRowDataUpdated = (params: RowDataUpdatedEvent) => {
    rest.onRowDataUpdated?.(params);
  };

  const gridOptions = {
    onColumnVisible: (event: ColumnVisibleEvent) =>
      tableStore.setColumnConfiguration(event.columnApi.getColumnState()),
    onDragStopped: (event: DragStoppedEvent) =>
      tableStore.setColumnConfiguration(event.columnApi.getColumnState()),
    onColumnPinned: (event: ColumnPinnedEvent) =>
      tableStore.setColumnConfiguration(event.columnApi.getColumnState()),
  };

  return (
    <Card>
      <CardBody p="0">
        <VStack gap={0} alignItems={{ base: 'start', md: 'start' }} direction={{ base: 'column', md: 'row' }}>
          <GridHeader
            viewEntity={viewEntity}
            setSearchQuery={setSearchQuery}
            ref={gridRef}
            selected={selected}
            onViewSelect={onViewSelect}
          />
          <Box>
            {actionsButtons.map((button: any, index) => (
              <Button
                key={index}
                size="xs"
                hidden={button.isHidden}
                variant="outline"
                onClick={button.action}
                mb={4}
                ml={2}
              >
                {button.label}
              </Button>
            ))}
          </Box>
        </VStack>
        <Box>
          <div className="ag-theme-alpine" style={{ width: '100%', height: height }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              serverSideDatasource={dataSource}
              statusBar={statusBar}
              gridOptions={gridOptions}
              pagination
              blockLoadDebounceMillis={100}
              maintainColumnOrder
              paginationPageSize={tableStore.pageSize || 12}
              cacheBlockSize={tableStore.pageSize || 12}
              suppressRowClickSelection
              rowModelType="serverSide"
              rowSelection="multiple"
              suppressPaginationPanel
              defaultColDef={{
                resizable: true,
                floatingFilter: false,
                menuTabs: ['filterMenuTab', 'generalMenuTab'],
                cellStyle: {
                  display: 'flex',
                  overflow: 'hidden',
                },
              }}
              onSelectionChanged={customOnSelectionChanged}
              {...rest}
              onRowDataUpdated={onRowDataUpdated}
              onGridReady={onGridReady}
            />
          </div>
        </Box>
      </CardBody>
    </Card>
  );
});
