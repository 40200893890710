import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  Textarea,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GetOrdersResponse } from 'api/orders/get';
import { PhoneInput } from 'components/PhoneInput';
import { ProductsList } from 'components/new/products-list';
import { ZoneTag } from 'components/new/zone-tag';
import { TagInput } from 'components/tag-input';
import { HAZARDOUS_MATERIALS } from 'constants/hazardous-materials';
import { useNotification } from 'contexts/notification.context';
import { isBoolean } from 'lodash';
import { CountrySelect } from 'pages/(app)/ship/_components/CountrySelect';
import { StateSelect } from 'pages/(app)/ship/_components/StateSelect';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller, FieldErrorsImpl, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { BsInputCursorText, BsCalculator } from 'react-icons/bs';
import { FiAlertCircle, FiCheck, FiCheckCircle, FiEdit2, FiPackage, FiXCircle } from 'react-icons/fi';
import { HiOutlineTruck, HiPlus } from 'react-icons/hi';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { LuPenSquare } from 'react-icons/lu';
import { useNavigate } from 'react-router';
import { getCountryNames, ozToLbsOz } from 'utils/misc';
import { z } from 'zod';
import { EditLineItemsModal } from '../edit-line-items-modal';
import { useGetQuery, usePutMutation } from 'api/client';
import { paths } from 'api/types';
import { NonUndefined } from 'react-hook-form';
import { queryClient } from 'config/query-client';

type UpdateOrderInput = NonUndefined<
  paths['/api/v3/orders/{id}']['put']['requestBody']
>['content']['application/json'] & {
  id: number;
};

type Order = ArrayElement<GetOrdersResponse['results']>;
export interface Props {
  order: Order;
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;

  /**
   * Fields to validate when the drawer is opened.
   * This is useful if you want to direct the user to
   * update a certain missing field (for ex.: missing weight)
   */
  validateFields?: Array<keyof FormValues>;
}

const invalid_type_error = 'Must be a number';

const schema = z
  .object({
    weightOz: z.coerce.number({ invalid_type_error }).min(0).max(1900),
    weightLbs: z.coerce.number({ invalid_type_error }).min(0).max(120),
    length: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    width: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    height: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    insuranceAmount: z.coerce.number({ invalid_type_error }).min(0).max(1000),
    isMediaMail: z.boolean(),
    isIrregularPackage: z.boolean(),
    hazmat: z.string().nullable(),
    deliveryConfirmation: z.string().optional(),
    predefinedPackage: z.string().optional(),
    status: z.string().optional(),
    notes: z
      .array(
        z.object({
          id: z.number().optional(),
          content: z.string().nonempty('Required'),
          author: z.string().optional(),
        }),
      )
      .optional(),
    skipAutomations: z.boolean().optional(),
    tags: z.array(z.string()).optional(),
    fromId: z.string().nullable(),
    isShippingAddressVerified: z.boolean(),
    shippingAddress: z.object({
      name: z.string().nonempty('Required'),
      company: z.string().nullable(),
      line1: z.string().nonempty('Required'),
      line2: z.string().nullable(),
      city: z.string().nonempty('Required'),
      state: z.string().nonempty('Required'),
      postal: z.string().nonempty('Required'),
      phone: z.string().nullable(),
      country: z.string(),
      residential: z.boolean().optional(),
    }),
    from: z
      .object({
        name: z.string().nonempty('Required'),
        company: z.string().optional().nullable(),
        line1: z.string().nonempty('Required'),
        line2: z.string().nullable().optional(),
        city: z.string().nonempty('Required'),
        state: z.string().nonempty('Required'),
        postal: z.string().nonempty('Required'),
        phone: z.string().optional(),
      })
      .optional(),
  })
  .refine((data) => data.weightLbs > 0 || data.weightOz > 0, {
    path: ['weight'],
  })
  .refine(
    (data) =>
      [data.length, data.width, data.height].every((d) => d && d > 0) ||
      [data.length, data.width, data.height].every((d) => d === 0),
    { path: ['dimensions'], message: 'Required' },
  );

type FormValues = z.infer<typeof schema>;

export const EditOrderDrawer: FC<Props> = (props) => {
  const { order = null, isOpen, onClose, validateFields, onEdit } = props;

  const [isBoxModeEnabled, setIsBoxModeEnabled] = useState<boolean>(false);
  const [editRecipientAddress, setEditRecipientAddress] = useState<boolean>(false);
  const [editSenderAddress, setEditSenderAddress] = useState<boolean>(false);
  const getAddresses = useGetQuery('/api/v3/origin-addresses', {
    params: {
      query: {
        per: 9999,
        page: 0,
      },
    },
  });
  const updateOrder = usePutMutation('/api/v3/orders/{id}');
  const getBoxes = useGetQuery('/api/v3/boxes', {
    params: {
      query: {
        includeSystem: true,
        sort: 'isFavorite',
        direction: 'desc',
      },
    },
  });
  const getOrder = useGetQuery('/api/v3/orders/{id}', {
    params: {
      path: {
        id: String(order?.id),
      },
    },
  });

  const editLineItemsModal = useDisclosure();

  const { notifySuccess } = useNotification();
  const navigate = useNavigate();

  const products = order?.lineItems?.map((li) => ({ ...li.product, quantity: li.quantity })) || [];
  const isShippingAddressVerified = order?.isShippingAddressVerified;
  const to = order?.shippingAddress || null;
  const from = order?.from || null;
  const parcel = order?.parcel || null;

  const weightLbsOz = ozToLbsOz(order?.parcel?.weight || order?.totalWeightOz || 0);

  const form = useForm<FormValues>({
    shouldUnregister: false,
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      // Parcel
      weightOz: weightLbsOz.ounces || undefined,
      weightLbs: Math.floor(weightLbsOz.lbs || 0) || undefined,
      length: parcel?.length || undefined,
      width: parcel?.width || undefined,
      height: parcel?.height || undefined,
      predefinedPackage: parcel?.predefinedPackage || undefined,

      // Options
      insuranceAmount: order?.insuranceCents ? order.insuranceCents / 100 : undefined,
      isIrregularPackage: order?.options?.machinable === false,
      isMediaMail: order?.options?.specialRatesEligibility === 'USPS.MEDIAMAIL',
      hazmat: order?.options?.hazmat || undefined,
      deliveryConfirmation: order?.options?.deliveryConfirmation || undefined,
      status: undefined,

      notes: order?.notes || [],

      // Addresses
      fromId: null,
      // @ts-expect-error: Order types are more flexible (more nullable properties)
      // than what the form requires
      shippingAddress: order?.shippingAddress || undefined,
      isShippingAddressVerified,

      tags: order?.tags?.map((t) => t.name) || [],
    },
  });

  const { register, formState, setValue, getValues, control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notes',
  });

  const errors = formState.errors as typeof formState.errors &
    FieldErrorsImpl<{
      weight?: { message: string };
      dimensions?: { message: string };
    }>;

  const onSubmit = async (values: FormValues) => {
    if (!order?.id) return;
    const {
      weightOz,
      weightLbs,
      length,
      width,
      height,
      insuranceAmount,
      fromId,
      isMediaMail,
      isIrregularPackage,
      hazmat,
      deliveryConfirmation,
      predefinedPackage = null,
      skipAutomations,
      shippingAddress,
      notes,
      status,
      isShippingAddressVerified: isShippingAddrVerified,
    } = values;

    const payload: UpdateOrderInput = { id: order.id };

    // Parcel
    const weight = Number(weightLbs) * 16 + Number(weightOz);
    const dimensions = [length, width, height].every((d) => d && d > 0)
      ? { length, width, height }
      : { length: null, width: null, height: null };

    payload.parcel = { ...dimensions, weight, predefinedPackage };

    // From
    if (fromId) {
      const fromAddress = getAddresses?.data?.results?.find((a) => a.id === fromId);
      if (fromAddress) {
        payload.from = {
          name: fromAddress.name ?? '',
          company: fromAddress.company,
          line1: fromAddress.line1 ?? '',
          line2: fromAddress.line2,
          city: fromAddress.city ?? '',
          state: fromAddress.state ?? '',
          postal: fromAddress.postal ?? '',
          country: fromAddress.country ?? '',
          phone: fromAddress.phone,
        };
      }
    }

    // To (shippingAddress)
    if (shippingAddress) payload.shippingAddress = shippingAddress;
    payload.isShippingAddressVerified = isShippingAddrVerified;

    // Options
    payload.insuranceCents = Number(insuranceAmount) * 100;
    payload.skipAutomations = !!skipAutomations;
    payload.options = {};
    payload.tags = values.tags;

    payload.notes = notes;
    if (isMediaMail) payload.options.specialRatesEligibility = 'USPS.MEDIAMAIL';
    if (isIrregularPackage) payload.options.machinable = false;
    if (hazmat) payload.options.hazmat = hazmat;
    if (deliveryConfirmation) payload.options.deliveryConfirmation = deliveryConfirmation;

    if (status) payload.status = status as UpdateOrderInput['status'];

    updateOrder.mutate(
      {
        body: payload,
        params: {
          path: {
            id: String(payload.id),
          },
        },
      },
      {
        onSuccess: () => {
          onEdit();
          onClose();
          notifySuccess('Order saved successfully');
        },
      },
    );
  };

  useEffect(() => {
    if (!validateFields?.length) return;
    validateFields.forEach((field) => form.trigger(field));
  }, [validateFields]);

  useEffect(() => {
    if (formState.isSubmitted) setIsBoxModeEnabled(false);
  }, [formState.isSubmitted]);

  const onBoxSelect = (ev: ChangeEvent<HTMLSelectElement>) => {
    const value = ev.target.value;
    const [boxType, id] = value.split('-') as ['system' | 'custom', string];

    const customBoxes = getBoxes.data?.custom ?? [];
    const systemBoxes = getBoxes.data?.system ?? [];

    const box =
      boxType === 'custom'
        ? customBoxes.find((b) => b.id === Number(id))
        : systemBoxes.find((b) => b.id === Number(id));

    if (box?.length === 0 && box?.width === 0 && box?.height === 0) {
      setValue('length', 0);
      setValue('width', 0);
      setValue('height', 0);
    } else {
      setValue('length', box?.length || null);
      setValue('width', box?.width || null);
      const height = box?.height === 0 ? 0.1 : box?.height;
      setValue('height', height || null);
    }

    setValue('predefinedPackage', box?.predefinedPackage || undefined);
  };

  const addressViewMarkup = (address: Record<string, any>, onEditAddress: () => void) => (
    <HStack alignItems="start" justify="space-between">
      <Box>
        <chakra.address color="gray.600" fontStyle="normal">
          <Text>{address.name ?? ''}</Text>
          <Text>{address.company ?? ''}</Text>
          <Text>{`${address.line1 ?? ''} ${address.line2 ?? ''}`.trim()}</Text>
          <Text>{`${address.city ?? ''} ${address.state ?? ''} ${address.postal ?? ''}`.trim()}</Text>
          <Text>{getCountryNames(address.country ?? '')}</Text>
        </chakra.address>
      </Box>
      <Button ml="auto" size="xs" variant="outline" leftIcon={<FiEdit2 />} onClick={onEditAddress}>
        Edit
      </Button>
    </HStack>
  );

  type Type = 'invalid' | 'correction' | 'valid' | 'special';
  interface Footnote {
    message: string;
    type: Type;
    field?: string | undefined;
    suggestion?: string | null | undefined;
  }
  const icons: { [key in Type]: () => JSX.Element } = {
    invalid: () => <Icon as={FiXCircle} h={4} w={4} textColor="red.500" />,
    correction: () => <Icon as={FiAlertCircle} h={4} w={4} textColor="yellow.500" />,
    valid: () => <Icon as={FiCheckCircle} h={4} w={4} textColor="green.500" />,
    special: () => <Icon as={FiAlertCircle} h={4} w={4} textColor="blue.500" />,
  };

  const recalculateParcelWeightFromLineItems = async (input: {
    parcel: {
      weight: number | null;
      length: number;
      width: number;
      height: number;
      predefinedPackage: string;
    };
    id?: number;
  }) => {
    const { parcel, id } = input;

    try {
      await updateOrder.mutateAsync(
        {
          body: { parcel },
          params: {
            path: {
              id: String(id),
            },
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['/api/v3/orders/{id}', { id: String(id) }]);
          },
        },
      );
      const updatedOrder = await getOrder.refetch();
      const recalculatedWeight = ozToLbsOz(updatedOrder?.data?.totalWeightOz || 0);
      setValue('weightOz', recalculatedWeight.ounces ?? 0);
      setValue('weightLbs', Math.floor(recalculatedWeight.lbs ?? 0));

      // Notify success after the whole process
      notifySuccess('Parcel weight recalculated successfully');
    } catch (error) {
      // Handle error if needed
      console.error('Error recalculating parcel weight:', error);
    }
  };

  const addressValidationAlerts = (footnote: Footnote) => {
    const color = {
      invalid: 'red.50',
      correction: 'yellow.50',
      valid: 'green.50',
      special: 'blue.50',
    };

    if (footnote.type === 'valid') return null;

    const background = color[footnote?.type as Type] || 'gray.50';

    return (
      <HStack alignContent="center" background={background} borderRadius={4}>
        <VStack alignItems="flex-start" p="2">
          <HStack>
            <Flex>{icons[footnote?.type as Type]?.()}</Flex>
            <Text fontSize="sm" fontWeight="light">
              {footnote.message}
            </Text>
          </HStack>
          {footnote?.suggestion ? (
            <VStack alignItems="flex-start" paddingLeft={6}>
              <Text fontSize="sm" fontWeight="light" color="gray.600">
                {footnote.suggestion.split('/').length > 1
                  ? 'Suggestions for correction:'
                  : 'Suggestion for correction:'}
              </Text>
              {footnote.suggestion.split('/').map((s: string) => (
                <Text key={s} fontSize="sm" fontWeight="light" color="gray.500">
                  {s}
                </Text>
              ))}
            </VStack>
          ) : null}
        </VStack>
      </HStack>
    );
  };

  const recipientAddressEditMarkup = (
    <Stack spacing="3.5">
      <FormControl variant="floating-active" isRequired isDisabled>
        <CountrySelect isDisabled value={to?.country} />
        <FormLabel>Country</FormLabel>
      </FormControl>
      <FormControl isInvalid={!!formState.errors?.shippingAddress?.name} variant="floating" isRequired>
        <Input {...register('shippingAddress.name')} placeholder=" " />
        <FormLabel>Name</FormLabel>
      </FormControl>
      <FormControl variant="floating" isInvalid={!!formState.errors?.shippingAddress?.company}>
        <Input {...register('shippingAddress.company')} placeholder=" " />
        <FormLabel>Company</FormLabel>
      </FormControl>
      <FormControl
        variant={to?.line1?.length ? 'floating-active' : 'floating'}
        isRequired
        isInvalid={!!formState.errors?.shippingAddress?.line1}
      >
        <Input {...register('shippingAddress.line1')} placeholder=" " />
        <FormLabel>Address 1</FormLabel>
      </FormControl>

      <FormControl variant="floating">
        <Input {...register('shippingAddress.line2')} placeholder=" " />
        <FormLabel>Address 2</FormLabel>
      </FormControl>

      <FormControl variant="floating" isRequired isInvalid={!!formState.errors?.shippingAddress?.city}>
        <Input {...register('shippingAddress.city')} placeholder=" " />
        <FormLabel>City</FormLabel>
      </FormControl>

      <SimpleGrid columns={2} spacing="2">
        <FormControl
          variant={to?.state ? 'floating-active' : 'floating'}
          isRequired
          isInvalid={!!formState.errors?.shippingAddress?.postal}
        >
          <Controller
            control={form.control}
            name="shippingAddress.state"
            render={({ field: { ref, onChange, value } }) => {
              if (to?.country !== 'US') {
                return <Input ref={ref} value={value} onChange={onChange} placeholder=" " />;
              }

              return <StateSelect ref={ref} value={value} onChange={onChange} />;
            }}
          />
          <FormLabel>State</FormLabel>
        </FormControl>
        <FormControl variant="floating" isRequired isInvalid={!!formState.errors?.shippingAddress?.postal}>
          <Input {...register('shippingAddress.postal')} placeholder=" " />
          <FormLabel>Zip</FormLabel>
        </FormControl>
      </SimpleGrid>
      <FormControl variant="floating" isInvalid={!!formState.errors?.shippingAddress?.phone}>
        <PhoneInput placeholder=" " {...register('shippingAddress.phone')} country={to?.country} />
        <FormLabel>Phone</FormLabel>
      </FormControl>
      <Checkbox {...register('shippingAddress.residential')} defaultChecked={!!to?.residential}>
        Residential
      </Checkbox>
      <Checkbox {...register('isShippingAddressVerified')} defaultChecked={!!isShippingAddressVerified}>
        Set Address as verified
      </Checkbox>
    </Stack>
  );

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="sm">
      <chakra.form onSubmit={form.handleSubmit(onSubmit)} h="full" fontSize="sm">
        <FormProvider {...form}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <HStack>
                <Box>Edit Order</Box>
                {order?.id && (
                  <Button
                    size="xs"
                    variant="outline"
                    title="Open in Quick Ship"
                    onClick={() => navigate(`/ship?ref=orders`, { state: { order } })}
                  >
                    <HiOutlineTruck />
                  </Button>
                )}
              </HStack>
            </DrawerHeader>
            <DrawerBody p="0">
              <Flex flexDir="column" justify="space-between" h="full">
                <Accordion defaultIndex={[0, 2, 3]} allowMultiple>
                  {/* Products */}
                  <AccordionItem>
                    <AccordionButton>
                      <Text fontWeight="medium" fontSize="md" py="1" flex="1" textAlign="left">
                        Products
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel py={4}>
                      <Stack spacing="2" px="2">
                        <ProductsList products={products} />
                      </Stack>

                      <Button mt={2} size="sm" variant="outline" gap={1} onClick={editLineItemsModal.onOpen}>
                        <LuPenSquare />
                        Edit
                      </Button>
                    </AccordionPanel>
                  </AccordionItem>

                  {/* Sender Address */}
                  <AccordionItem>
                    <AccordionButton>
                      <HStack>
                        <Text py="1" fontSize="md" fontWeight="medium">
                          Sender
                        </Text>
                      </HStack>
                      <AccordionIcon ml="auto" />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <FormControl isDisabled={updateOrder.isLoading}>
                        {editSenderAddress ? (
                          <Select {...register('fromId')}>
                            <option value="">-- Select an option --</option>
                            {getAddresses?.data?.results?.map((address) => (
                              <option value={address.id} key={address.id}>
                                {address.line1}, {address.city}, {address.state}{' '}
                                {address.primary && '(Primary)'}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          addressViewMarkup(from || {}, () => setEditSenderAddress(true))
                        )}
                      </FormControl>
                    </AccordionPanel>
                  </AccordionItem>

                  {/* Recipient address */}
                  <AccordionItem>
                    <AccordionButton>
                      <HStack>
                        <Text py="1" fontSize="md" fontWeight="medium">
                          Recipient
                        </Text>
                      </HStack>
                      <AccordionIcon ml="auto" />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Stack>
                        {editRecipientAddress ? (
                          recipientAddressEditMarkup
                        ) : (
                          <Stack>
                            {addressViewMarkup(to || {}, () => setEditRecipientAddress(true))}

                            <Flex gap="1">
                              {from?.postal && to?.postal && (
                                <ZoneTag fromZip={from?.postal} toZip={to?.postal} />
                              )}
                              {to?.country && (
                                <Tag title={to.country}>{to.country === 'US' ? '🇺🇸' : '🌎'}</Tag>
                              )}
                              {isBoolean(to?.residential) && (
                                <Tag title={to?.residential ? 'Residential' : 'Commercial'}>
                                  {to?.residential ? '🏠' : '🏬'}
                                </Tag>
                              )}
                            </Flex>
                            <Flex mt={3}>
                              {to?.footnotes && (
                                <Stack spacing="2" width="full" paddingTop="2">
                                  {((to?.footnotes?.length > 0 &&
                                    to?.footnotes?.every((footnote) => footnote?.type === 'valid')) ||
                                    (to as any)?.isVerified) && (
                                    <HStack background="green.50">
                                      <Flex>
                                        <Tag colorScheme="green" title="Address is valid">
                                          <Icon as={FiCheck} />
                                        </Tag>
                                      </Flex>
                                      <Text fontSize="sm" fontWeight="light">
                                        Address is valid
                                      </Text>
                                    </HStack>
                                  )}
                                  {to?.footnotes?.map((footnote) =>
                                    addressValidationAlerts(footnote as Footnote),
                                  )}
                                </Stack>
                              )}
                            </Flex>
                          </Stack>
                        )}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>

                  {/* Package */}
                  <AccordionItem>
                    <AccordionButton>
                      <Text fontWeight="medium" fontSize="md" py="1" flex="1" textAlign="left">
                        Package
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel py="4">
                      <Stack>
                        <Flex alignItems="center" justifyContent="space-between">
                          <FormLabel>Dimensions </FormLabel>
                          <IconButton
                            title={`Toggle Box mode ${isBoxModeEnabled ? 'off' : 'on'}`}
                            size="xs"
                            aria-label="Remove"
                            icon={isBoxModeEnabled ? <BsInputCursorText /> : <FiPackage />}
                            onClick={() => setIsBoxModeEnabled(!isBoxModeEnabled)}
                          />
                        </Flex>

                        <Stack hidden={isBoxModeEnabled} direction="row" spacing="2">
                          <FormControl
                            isInvalid={!!errors.length || !!errors.dimensions}
                            isDisabled={updateOrder.isLoading}
                          >
                            <Input {...register('length')} placeholder="Length" />
                            <FormErrorMessage>{errors.length?.message}</FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={!!errors.width || !!errors.dimensions}
                            isDisabled={updateOrder.isLoading}
                          >
                            <Input {...register('width')} placeholder="Width" />
                            <FormErrorMessage>
                              {errors.width?.message || errors.dimensions?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={!!errors.height || !!errors.dimensions}
                            isDisabled={updateOrder.isLoading}
                          >
                            <Input {...register('height')} placeholder="Height" />
                            <FormErrorMessage>{errors.height?.message}</FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <Box hidden={!isBoxModeEnabled}>
                          <Select defaultValue="" onChange={onBoxSelect} disabled={updateOrder.isLoading}>
                            <option disabled value="">
                              -- Select an option --
                            </option>
                            <optgroup label="Custom Boxes">
                              {getBoxes.data?.custom?.map((box) => (
                                <option key={box.id} value={`custom-${box.id}`}>
                                  {box.name} {box.predefinedPackage === 'SoftPack' ? '(Soft Pack, ' : '('}
                                  {box.length} x {box.width} x {box.height})
                                </option>
                              ))}
                            </optgroup>
                            <optgroup label="Pre-defined Boxes">
                              {getBoxes.data?.system?.map((box) => (
                                <option key={box.id} value={`system-${box.id}`}>
                                  {box.carrier} - {box.name}{' '}
                                  {box.length > 0 && `(${box.length} x ${box.width} x ${box.height})`}
                                </option>
                              ))}
                            </optgroup>
                          </Select>
                        </Box>

                        <FormLabel>Weight</FormLabel>
                        <Stack direction="row" spacing="2" alignItems="center">
                          <FormControl
                            isInvalid={!!errors.weightLbs || !!errors.weight}
                            isDisabled={updateOrder.isLoading}
                          >
                            <InputGroup>
                              <Input {...register('weightLbs')} placeholder="Weight" />
                              <InputRightElement>lbs</InputRightElement>
                            </InputGroup>
                          </FormControl>
                          <FormControl
                            isInvalid={!!errors.weightOz || !!errors.weight}
                            isDisabled={updateOrder.isLoading}
                          >
                            <InputGroup>
                              <Input {...register('weightOz')} placeholder="Weight" />
                              <InputRightElement>oz</InputRightElement>
                            </InputGroup>
                          </FormControl>
                          <IconButton
                            title={'Calculate Weight From Line Items'}
                            size="md"
                            aria-label="Calculate Weight"
                            icon={<BsCalculator />}
                            onClick={() =>
                              recalculateParcelWeightFromLineItems({
                                parcel: {
                                  weight: null,
                                  length: getValues('length') || 0,
                                  width: getValues('width') || 0,
                                  height: getValues('height') || 0,
                                  predefinedPackage: getValues('predefinedPackage') || '',
                                },
                                id: order?.id,
                              })
                            }
                          />
                        </Stack>
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>

                  {/* Options */}
                  <AccordionItem>
                    <AccordionButton>
                      <Text fontWeight="medium" fontSize="md" py="1" flex="1" textAlign="left">
                        Options
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Stack spacing="3.5">
                        <FormControl isInvalid={!!errors.insuranceAmount} isDisabled={updateOrder.isLoading}>
                          <FormLabel>Insurance</FormLabel>
                          <InputGroup>
                            <InputLeftElement>$</InputLeftElement>
                            <Input {...register('insuranceAmount')} placeholder="0" />
                            <InputRightElement>USD</InputRightElement>
                          </InputGroup>
                        </FormControl>
                        <FormControl isInvalid={!!errors.hazmat} isDisabled={updateOrder.isLoading}>
                          <FormLabel>Hazardous Materials</FormLabel>
                          <Select {...register('hazmat')}>
                            <option value="">-- Select an option --</option>
                            {HAZARDOUS_MATERIALS.map(({ label, value }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl isInvalid={!!errors.tags} isDisabled={updateOrder.isLoading}>
                          <FormLabel>Tags</FormLabel>
                          <Controller
                            name="tags"
                            control={form.control}
                            render={({ field: { ref } }) => (
                              <TagInput
                                ref={ref}
                                value={getValues('tags')?.map((tag) => ({ value: tag, label: tag })) || []}
                                onChange={(data) =>
                                  setValue(
                                    'tags',
                                    data.map((d) => d.value),
                                  )
                                }
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl isInvalid={!!errors.deliveryConfirmation}>
                          <FormLabel>Signature Confirmation</FormLabel>
                          <Select {...register('deliveryConfirmation')}>
                            <option value="">No signature</option>
                            <option value="SIGNATURE">Signature required</option>
                            <option value="ADULT_SIGNATURE">Adult signature required</option>
                            <option value="ADULT_SIGNATURE_RESTRICTED">Adult signature restricted</option>
                            <option value="SIGNATURE_RESTRICTED">Signature restricted</option>
                          </Select>
                        </FormControl>
                        <FormControl isInvalid={!!errors.isMediaMail} isDisabled={updateOrder.isLoading}>
                          <HStack>
                            <Checkbox {...register('isMediaMail')}>
                              <Text>Media Mail (USPS)</Text>
                              <Text fontSize="xs" fontWeight="normal">
                                USPS Media Mail is an affordable shipping option for sending educational and
                                media-related items, such as books and CDs.
                              </Text>
                            </Checkbox>
                          </HStack>
                        </FormControl>
                        <FormControl
                          isInvalid={!!errors.isIrregularPackage}
                          isDisabled={updateOrder.isLoading}
                        >
                          <HStack>
                            <Checkbox {...register('isIrregularPackage')}>
                              <Text>Irregular Package</Text>
                              <Text fontSize="xs" fontWeight="normal">
                                Shipments that deviate from standard size, shape, or weight may require
                                additional handling or surcharges during postal delivery.
                              </Text>
                            </Checkbox>
                          </HStack>
                        </FormControl>
                        <FormControl
                          isInvalid={!!errors.isIrregularPackage}
                          isDisabled={updateOrder.isLoading}
                        >
                          <HStack>
                            <Checkbox {...register('skipAutomations')}>
                              <Text>Skip Automations</Text>
                              <Text fontSize="xs" fontWeight="normal">
                                By default, all automations will be applied to this order. Check this box to
                                skip all automations.
                              </Text>
                            </Checkbox>
                          </HStack>
                        </FormControl>

                        <FormControl hidden={!['custom', 'manual'].includes(order?.platform || '')}>
                          <FormLabel>Change Status</FormLabel>
                          <Select {...register('status')}>
                            <option value="">-- Select an option --</option>
                            <option value="open">Open</option>
                            <option value="on_hold">On Hold</option>
                            <option value="completed">Completed</option>
                            <option value="canceled">Canceled</option>
                          </Select>
                        </FormControl>
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <Text fontWeight="medium" fontSize="md" py="1" flex="1" textAlign="left">
                        Notes
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Stack spacing="3.5">
                        {fields.map((note, index: number) => {
                          return (
                            <Box position="relative" key={index}>
                              <FormControl
                                isInvalid={!!errors.notes?.[index]?.content}
                                isDisabled={updateOrder.isLoading}
                              >
                                <Textarea
                                  resize="none"
                                  {...register(`notes.${index}.content`)}
                                  defaultValue={note.content}
                                  isInvalid={!!errors.notes?.[index]?.content}
                                />
                              </FormControl>
                              <IconButton
                                icon={<IoIosRemoveCircleOutline color="red" size={15} />}
                                size="xs"
                                position="absolute"
                                variant="ghost"
                                right="3px"
                                top="3px"
                                aria-label="remove note"
                                onClick={() => remove(index)}
                              />
                            </Box>
                          );
                        })}
                        <IconButton
                          icon={<HiPlus />}
                          aria-label="add new note"
                          variant="outline"
                          width={15}
                          onClick={() => append({ content: '' })}
                        />
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                <Flex p="4" justify="end" gap="2">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={onClose}
                    isDisabled={updateOrder.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button colorScheme="brand" type="submit" isLoading={updateOrder.isLoading}>
                    Save
                  </Button>
                </Flex>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </FormProvider>
      </chakra.form>

      {order?.id && editLineItemsModal.isOpen && (
        <EditLineItemsModal {...editLineItemsModal} orderId={order.id} />
      )}
    </Drawer>
  );
};
