import { paths } from 'api/types';
import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

/**
 * Create user invitation
 */
export type InviteUserInput = paths['/api/v3/user/invitation']['post']['requestBody']['content']['application/json'];
export type InviteUserResponse =
  paths['/api/v3/user/invitation']['post']['responses']['200']['content']['application/json'];

export const useInviteUser = (opts?: UseMutationOptions<InviteUserResponse, any, InviteUserInput>) =>
  useMutation<InviteUserResponse, any, InviteUserInput>(async (vars: InviteUserInput) => {
    const result = await axios.post('/v3/user/invitation', vars);
    return result?.data;
  }, opts);

/**
 * Accept user invitation
 */
type AcceptInvitationInput =
  paths['/api/v3/user/invitation/accept']['post']['requestBody']['content']['application/json'];

export const useAcceptInvitation = (
  opts?: UseMutationOptions<{}, AxiosError<{ message: string }>, AcceptInvitationInput>,
) =>
  useMutation<{}, AxiosError<{ message: string }>, AcceptInvitationInput>(async (vars: AcceptInvitationInput) => {
    const result = await axios.post('/v3/user/invitation/accept', vars);
    return result?.data;
  }, opts);

/**
 * Get user invitations
 */
type UserInvitationsResponse =
  paths['/api/v3/user/invitation']['get']['responses']['200']['content']['application/json'];
export const USER_INVITATIONS_QUERY = 'userInvitations';

export const useUserInvitations = (
  opts?: UseQueryOptions<{}, AxiosError<{ message: string }>, UserInvitationsResponse>,
) =>
  useQuery<{}, AxiosError<{ message: string }>, UserInvitationsResponse>(
    [USER_INVITATIONS_QUERY],
    async () => {
      const result = await axios.get('/v3/user/invitation');
      return result?.data;
    },
    opts,
  );
