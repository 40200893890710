import { Input } from '@chakra-ui/react';
import { Combobox } from '@headlessui/react';
import { LookupResponse, useSmartyLookup } from 'api/smarty-streets/lookup';
import useDebounce from 'hooks/use-debounce';
import { noop } from 'lodash';
import { forwardRef, useMemo } from 'react';
import { ComboboxOption, ComboboxOptions } from 'pages/(app)/ship/_components/util';
import { ArrayElement } from 'types/common';

type LineOneInputProps = {
  onSelected?: (value: ArrayElement<LookupResponse>['value']) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | string[];
  inputValue: string;
};

export const LineOneInput = forwardRef((props: LineOneInputProps, ref: any) => {
  const { onSelected = noop, onChange = noop, value, inputValue } = props;

  const q = useMemo(() => inputValue || '', [inputValue]);

  const debouncedQ = useDebounce(q, 500);

  const lookupAddress = useSmartyLookup({ q: debouncedQ }, { enabled: !!q, refetchOnMount: false });

  const isLoading = lookupAddress.isLoading || debouncedQ !== q;

  return (
    <Combobox
      as="div"
      value={value || ''}
      onChange={(v: any) => onSelected(v as ArrayElement<LookupResponse>['value'])}
    >
      <Combobox.Input as={Input} ref={ref} _focus={{ border: '2px', borderColor: 'brand.500' }} onChange={onChange} />
      <Combobox.Options as={ComboboxOptions} isLoading={isLoading} style={{ listStyle: 'none' }}>
        {lookupAddress.data?.map((a, index) => (
          <Combobox.Option key={index} value={a.value}>
            {({ selected, active }) => <ComboboxOption selected={selected || active}>{a.label}</ComboboxOption>}
          </Combobox.Option>
        ))}

        {value?.length > 0 && (
          <Combobox.Option value={{ line1: value }}>
            {({ selected, active }) => (
              <ComboboxOption selected={selected || active}>Use &quot;{value}&quot;...</ComboboxOption>
            )}
          </Combobox.Option>
        )}
      </Combobox.Options>
    </Combobox>
  );
});
