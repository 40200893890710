import { useQuery } from 'react-query';
import axios from 'config/axios';

export type BoxType = {
  custom: Array<{
    id: number;
    name: string;
    length: number;
    width: number;
    height: number;
    isEnabled: boolean;
    createdAt: string;
    updatedAt: string;
    predefinedPackage: 'SoftPack' | 'Parcel';
    isFavorite: boolean;
    weight: number | null;
  }>;
  system: Array<{
    id: number;
    name: string;
    carrier: string;
    length: number;
    width: number;
    height: number;
    isEnabled: boolean;
    predefinedPackage: string;
    isFavorite: boolean;
    weight: number | null;
  }>;
};

export const getBoxes = async () => {
  // This call returns both system and custom boxes.
  // A seperate call will need to be made if the user wants to call either box type individually.
  const { data } = await axios.get('/v3/boxes?includeSystem=true&sort=isFavorite&direction=desc');
  return data;
};

export const GET_BOXES_QUERY = 'boxes';

const useGetBoxes = () => useQuery<BoxType>(GET_BOXES_QUERY, getBoxes);

export default useGetBoxes;
