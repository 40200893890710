import { Box, VStack, Text, Flex, Spacer, Divider } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/react';
import { ReactNode } from 'react';

export type Item = {
  title: ReactNode;
  details?: Array<string>;
  value: number;
};

interface Props {
  items?: Item[];
}

export function CostBreakdown({ items }: Props) {
  let total = 0;
  items?.forEach((item) => {
    total += item.value;
  });

  return (
    <>
      <VStack spacing={6} align="start" alignContent="space-between">
        {items?.map((item, index) => {
          return (
            <Flex key={index} w="full" gap={2} justifyContent="space-between" flexWrap="wrap">
              <>{item.title}</>
              {item.details?.map((detail, detailIndex) => {
                if (detail)
                  return (
                    <Tag minWidth="max-content" textColor="muted" key={detailIndex}>
                      {detail}
                    </Tag>
                  );
                return null;
              })}
              <Text fontWeight="semibold">${item.value.toFixed(2)}</Text>
            </Flex>
          );
        })}
      </VStack>
      <Box w="full" py={6}>
        <Divider borderColor="gray.200" />
      </Box>
      <Flex w="full">
        <Box>
          <Text fontWeight="semibold">Total</Text>
        </Box>
        <Spacer />
        <Box>
          <Text fontWeight="semibold">${total.toFixed(2)}</Text>
        </Box>
      </Flex>
    </>
  );
}
