import Big, { BigSource } from 'big.js';

// @ts-expect-error issues typing this
export const roundToXDecimalPlaces = (numberOfPlaces: number, n: BigSource) => Number(n.toFixed(numberOfPlaces));

export const roundToZeroDecimalPlaces = (n: BigSource) => roundToXDecimalPlaces(0, n);

export const roundToTwoDecimalPlaces = (n: BigSource) => roundToXDecimalPlaces(2, n);

export const numberWithCommas = (number: BigSource) => new Big(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const centsToDollars = (cents: number) => Number(cents / 100).toFixed(2);

export const centsToDollarsWithCommas = (cents: number) => centsToDollars(cents).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const dollarsToCents = (dollars: number | string) => roundToZeroDecimalPlaces(new Big(dollars).times(100));

export const formatCentsToDollars = (cents: number) =>
  cents < 99999
    ? `${cents < 0 ? '-' : ''}$${Math.abs(Number(numberWithCommas(centsToDollars(cents)))).toFixed(2)}`
    : `${cents < 0 ? '-' : ''}$${numberWithCommas(centsToDollars(cents))}`;

export const toUSD = (value: string | number) =>
  parseFloat(typeof value === 'number' ? `${value}` : value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
