import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { GET_AUTOMATIONS_QUERY, useGetAutomation, useRemoveAutomation } from 'api/automation';
import { queryClient } from 'config/query-client';

type Props = {
  automation: any;
  onClose: () => void;
  isOpen: boolean;
};

export const DeleteAutomationModal = (props: Props) => {
  const { automation, onClose, isOpen } = props;

  const removeAutomation = useRemoveAutomation();
  const getAutomation = useGetAutomation({ id: automation.id }, { enabled: !!automation.id });

  const onRemoveAutomation = () => {
    const onSuccess = () => {
      queryClient.invalidateQueries(GET_AUTOMATIONS_QUERY);
      onClose();
    };

    removeAutomation.mutate({ id: automation.id }, { onSuccess });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Automation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="muted">
            Are you sure you want to delete the &quot;
            <chakra.span fontFamily="mono" fontWeight="bold">
              {getAutomation.data?.name}
            </chakra.span>
            &quot; automation?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button disabled={removeAutomation.isLoading} variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={removeAutomation.isLoading} colorScheme="red" onClick={onRemoveAutomation}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
