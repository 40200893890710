/* eslint-disable no-nested-ternary */
import {
  HStack,
  Text,
  Image,
  Icon,
  Tag,
  VStack,
  PopoverContent,
  Button,
  PopoverTrigger,
  PopoverBody,
  Popover,
  useDisclosure,
  Flex,
  Center,
} from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { GET_PRODUCTS_QUERY, GetProductsResponse } from 'api/products/get';
import { FiPackage } from 'react-icons/fi';
import { StoreLogo } from 'components/store-logo';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { useNavigate } from 'react-router';
import { useDeleteProduct } from 'api/products/delete';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { EditOrderDrawer } from './edit-product-drawer';
import { useUpdateProduct } from 'api/products/update';
import { StorePlatform } from 'types/enums';
import { StoresPopover } from './stores-popover';
import { isProductEditingDisabled } from 'utils/misc';
import { LuBoxes } from 'react-icons/lu';
import { EditInventoryDrawer } from './edit-inventory-drawer';

type Product = ArrayElement<GetProductsResponse['results']>;
const columnHelper = createColumnHelper<Product>();

export const columns = [
  columnHelper.accessor('platforms', {
    header: 'Platforms',
    cell: (props) => (
      <Flex gap={2}>
        {props.getValue()?.map((platform) => <StoreLogo platform={platform as StorePlatform} size={20} />)}
      </Flex>
    ),
  }),

  columnHelper.accessor('stores', {
    header: 'Stores',
    cell: (props) => {
      if (!props.getValue()) return null;

      const stores = props?.getValue() as string[];

      return (
        <VStack alignItems="flex-start">
          <StoresPopover stores={stores} />
        </VStack>
      );
    },
  }),

  columnHelper.accessor('name', {
    header: 'Product',
    meta: { sortId: 'name' },
    cell: (props) => {
      const { isOpen, onClose, onOpen } = useDisclosure();

      return (
        <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} placement="right">
          <PopoverTrigger>
            <Button variant="link" fontWeight="normal" gap="1">
              {props.getValue()}
              {isOpen ? <FaCaretUp /> : <FaCaretDown />}
            </Button>
          </PopoverTrigger>
          <PopoverContent w="max-content">
            <PopoverBody>
              {props.row.original?.imageUrl ? (
                <Image
                  w="20"
                  h="20"
                  minW="20"
                  rounded="md"
                  alt="No Image"
                  objectFit="cover"
                  src={props.row.original?.imageUrl ?? undefined}
                />
              ) : (
                <Center w="20" h="20" bg="gray.100">
                  <Icon title="no image" boxSize={10} color="white" as={FiPackage} />
                </Center>
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    },
  }),

  columnHelper.accessor('sku', {
    meta: { sortId: 'sku' },
    header: 'sku',
    cell: (props) => <Text>{props.getValue()}</Text>,
  }),

  columnHelper.accessor('lengthIn', {
    meta: { sortId: 'lengthIn' },
    header: 'Dimension (LxWxH)',
    cell: (props) =>
      props.getValue() ? (
        <Text>{`${props.getValue()} x ${props.row.original?.widthIn} x ${props.row.original?.heightIn}`}</Text>
      ) : null,
  }),

  columnHelper.accessor('weightOz', {
    header: 'Weight',
    meta: { sortId: 'weightOz' },
    cell: (props) => <Text>{props.getValue() ? `${props.getValue()} oz` : null}</Text>,
  }),

  columnHelper.accessor('priceCents', {
    header: 'Price',
    meta: { sortId: 'priceCents' },
    cell: ({ getValue }) => {
      const value = getValue();
      return <Text>{value !== null ? `$${(value / 100).toFixed(2)}` : null}</Text>;
    },
  }),

  columnHelper.accessor('location', {
    header: 'Location',
    meta: { sortId: 'location' },
    cell: ({ getValue }) => {
      const value = getValue();
      return <Text>{value}</Text>;
    },
  }),

  columnHelper.accessor('tags', {
    header: 'Tags',
    cell: (props) => {
      const colors = ['gray'];

      function getRandomColor() {
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
      }

      const { isOpen, onClose, onOpen } = useDisclosure();

      return props.getValue().length > 0 ? (
        props.getValue().length >= 2 ? (
          <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
            <PopoverTrigger>
              <Button variant="link" fontWeight="normal" gap="1">
                {props
                  .getValue()
                  .slice(0, 1)
                  .map((tag) => (
                    <Tag key={tag?.id} size="sm" colorScheme={getRandomColor()}>
                      {tag?.name}
                    </Tag>
                  ))}
                {isOpen ? <FaCaretUp /> : <FaCaretDown />}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody m="2">
                <HStack flexFlow="wrap" alignItems="flex-start">
                  {props.getValue().map((tag) => (
                    <Tag key={tag?.id} size="sm" colorScheme={getRandomColor()} mb="2">
                      {tag?.name}
                    </Tag>
                  ))}
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <VStack spacing={1} alignItems="flex-start">
            {props.getValue().map((tag) => (
              <Tag key={tag?.id} size="sm" colorScheme={getRandomColor()}>
                {tag?.name}
              </Tag>
            ))}
          </VStack>
        )
      ) : null;
    },
  }),
  columnHelper.display({
    header: 'Actions',
    cell: (props) => {
      const navigate = useNavigate();
      const { notifySuccess } = useNotification();

      useUpdateProduct({
        onSuccess: () => {
          queryClient.invalidateQueries([GET_PRODUCTS_QUERY]);
          notifySuccess('Your product has been successfully updated');
          editDrawer.onClose();
        },
      });

      const product = props.row.original as Product;
      if (!product?.id) return null;

      const multipleRowsSelected = props.table.getIsSomeRowsSelected();

      const editDrawer = useDisclosure();
      const inventoryDrawer = useDisclosure();
      const deleteProductModal = useDisclosure();

      const deleteProduct = useDeleteProduct({
        onSuccess: () => {
          queryClient.invalidateQueries([GET_PRODUCTS_QUERY]);
          notifySuccess('Your product has been successfully deleted');
          navigate('/products');
        },
      });

      const handleDelete = async (id: string) => {
        deleteProduct.mutate({ productId: id });
      };

      const isDisabled = isProductEditingDisabled(props.row.original?.platforms);

      return (
        <>
          <HStack>
            <Button title="Maange Inventory" onClick={inventoryDrawer.onOpen} variant="outline" size="xs">
              <LuBoxes />
            </Button>
            <Button
              onClick={editDrawer.onOpen}
              variant="outline"
              size="xs"
              isDisabled={isDisabled || multipleRowsSelected}
            >
              Edit
            </Button>
            <Button
              size="xs"
              variant="outline"
              onClick={deleteProductModal.onOpen}
              isDisabled={isDisabled || multipleRowsSelected}
            >
              Delete
            </Button>
          </HStack>

          <ConfirmationModal
            header="Delete product"
            description="Are you sure you want to delete this product?"
            type="delete"
            isOpen={deleteProductModal.isOpen}
            onClose={deleteProductModal.onClose}
            onConfirm={() => handleDelete(String(props.row.original?.id))}
          />

          {editDrawer.isOpen && <EditOrderDrawer {...editDrawer} product={product} />}
          {inventoryDrawer.isOpen && <EditInventoryDrawer {...inventoryDrawer} product={product} />}
        </>
      );
    },
  }),
] as ColumnDef<Product>[];
