import { useMutation, UseMutationOptions } from 'react-query';
import axios from 'config/axios';
import { StorePlatform } from 'types/enums';

type DeleteStore = {
  storeId: number;
  platform: StorePlatform;
};

export const deleteStore = async (vars: DeleteStore) => {
  const { data } = await axios.delete(`/v3/stores/${vars.platform}/${vars.storeId}`);
  return data;
};

const useDeleteStore = (opts?: UseMutationOptions<{}, {}, DeleteStore>) =>
  useMutation<{}, {}, DeleteStore>(deleteStore, opts);

export default useDeleteStore;
