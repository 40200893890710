import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { GET_INVOICES_QUERY, useAddFunds, useGetPaymentMethods } from 'api/payments';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiInfo } from 'react-icons/fi';
import { Link as ReactRouterLink } from 'router';
import { z } from 'zod';
import { queryClient } from '../../../../config/query-client';
import { useNotification } from '../../../../contexts/notification.context';
import { Invoice, PaymentCard } from '../../../../types/payment';

interface Props {
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  invoice: Invoice;
}

const schema = z.object({
  payAmount: z.coerce
    .number({ required_error: '', invalid_type_error: '' })
    .min(100, { message: 'Amount must be at least $100' }),
});

type FormValues = z.infer<typeof schema>;

export function PayInvoiceModal({ isOpen, onClose, invoice }: Props) {
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: { payAmount: parseFloat(invoice.amountDue) },
  });
  const { handleSubmit, formState, register, watch } = form;
  const { notifySuccess, notifyError } = useNotification();
  const [paymentOptions, setPaymentOptions] = useState<PaymentCard[]>([]);
  const [primaryPaymentMethod, setPrimaryPaymentMethod] = useState<PaymentCard>();

  const watchPayAmount = watch('payAmount');

  const addFunds = useAddFunds({
    onSuccess: () => {
      onClose();
      notifySuccess(`$${Number(watchPayAmount).toFixed(2)} applied to invoice!`);
      queryClient.invalidateQueries(GET_INVOICES_QUERY);
    },
  });

  const { data: paymentMethods } = useGetPaymentMethods();

  useEffect(() => {
    if (!paymentMethods) return;
    setPaymentOptions(paymentMethods);
    setPrimaryPaymentMethod(paymentOptions.find((x: { isPrimary: boolean }) => x.isPrimary === true));
  }, [paymentMethods, paymentOptions]);

  const onSubmit = (e: FormValues) => {
    const amountCents = Math.round(e.payAmount * 100);
    const returnURL = 'https://fitshipper.com';
    const invoiceId = invoice.id;
    const paymentMethodId = primaryPaymentMethod?.id;

    if (paymentMethodId === 'none' || !paymentMethodId) {
      notifyError('Please select a payment method');
      return;
    }

    addFunds.mutate({ paymentMethodId, amountCents, returnURL, invoiceId });
  };

  // const methods = useForm();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="320px">
        <ModalHeader>Pay Invoice {invoice.id}</ModalHeader>
        <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
          <ModalBody>
            <VStack>
              <Stack divider={<hr />} rounded="md" border="1px" borderColor="gray.300" bg="gray.50" width="100%">
                <Flex justify="space-between" p="3">
                  <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                    Period
                  </Text>
                  <Text color="muted">
                    {invoice.from} to {invoice.to}
                  </Text>
                </Flex>

                <Flex justify="space-between" p="3">
                  <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                    Outstanding
                  </Text>
                  <Text color="muted">${invoice.amountDue}</Text>
                </Flex>
              </Stack>
              <br />
              <FormControl isInvalid={!!formState.errors.payAmount}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                    $
                  </InputLeftElement>
                  <Input disabled={addFunds.isLoading || !primaryPaymentMethod} {...register('payAmount')} />
                  <InputRightElement color="muted" mr={2}>
                    USD
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formState?.errors?.payAmount?.message}</FormErrorMessage>
              </FormControl>
              <HStack spacing={1}>
                {primaryPaymentMethod ? (
                  <>
                    <Icon as={FiInfo} color="muted" />
                    <Text color="muted" fontSize="xs">
                      This will use{' '}
                      <Link textDecoration="underline" p={0} as={ReactRouterLink} color="muted" to="/billing">
                        {primaryPaymentMethod?.card.brand?.toUpperCase() ||
                          primaryPaymentMethod?.card.issuer?.toUpperCase()}{' '}
                        **
                        {primaryPaymentMethod?.card.lastFour}
                      </Link>
                    </Text>
                  </>
                ) : (
                  <Text color="red.500" fontSize="xs">
                    No primary payment method.{' '}
                    <Link textDecoration="underline" p={0} as={ReactRouterLink} color="red" to="/billing">
                      Add one here
                    </Link>
                  </Text>
                )}
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button disabled={addFunds.isLoading} variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand"
                disabled={addFunds.isLoading}
                isLoading={addFunds.isLoading}
                loadingText="Making payment..."
              >
                Pay Invoice
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </chakra.form>
      </ModalContent>
    </Modal>
  );
}
