import axios from 'config/axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetMeResponse } from 'types/api-types';

export const getMe = async () => {
  const { data } = await axios.get<GetMeResponse>('/v3/me');

  return data;
};

export const GET_ME_QUERY = 'getMe';

export const useGetMe = (opts?: UseQueryOptions<GetMeResponse>) =>
  useQuery<GetMeResponse>([GET_ME_QUERY], () => getMe(), opts ?? {});

export default useGetMe;
