import { useRefundShipment } from 'api/shipments';
import {
  Button,
  ButtonGroup,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  ListItem,
  UnorderedList,
  Box,
} from '@chakra-ui/react';
import { useNotification } from 'contexts/notification.context';
import { noop } from 'lodash';
import { FC } from 'react';
import { FiInfo } from 'react-icons/fi';

interface Props {
  isOpen: boolean;
  shipmentId: number;
  onClose: () => void;
  onSuccess?: () => void;
}

export const RefundLabelModal: FC<Props> = (props) => {
  const { shipmentId, isOpen, onClose, onSuccess = noop } = props;

  const refundShipment = useRefundShipment();

  const { notifySuccess } = useNotification();

  const handleRefund = async () => {
    const id = `${shipmentId}`;
    refundShipment.mutate(
      { id },
      {
        onError: onClose,
        onSuccess: () => {
          notifySuccess('Refund submitted successfully');
          onSuccess();
          onClose();
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Refund Label</ModalHeader>
        <ModalBody>
          <Text pb={2} textColor="muted">
            Are you sure you want to refund this label?
          </Text>
          <Box backgroundColor="gray.50" p={4} borderRadius="md" border="1px solid" borderColor="slate.200">
            <HStack>
              <FiInfo />
              <Text as="b">Important</Text>
            </HStack>
            <UnorderedList p={2}>
              <ListItem>
                <Text textColor="muted">
                  {`Canceling and voiding shipping labels is dependent on the carrier. You'll have to comply by your
                carrier's label voiding policies.`}
                </Text>
              </ListItem>
              <ListItem>
                <Text textColor="muted">
                  It can take up to 30 days to process a refund request and credit your account.
                </Text>
              </ListItem>
              <ListItem>
                <Text textColor="muted">
                  <b>For USPS:</b> You have 30 days to request a refund for any postage purchased from USPS, with the
                  exception of some Flat rates. If the postage enters the mailstream, you may no longer request a refund
                  for it.
                </Text>
              </ListItem>
            </UnorderedList>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button type="button" variant="outline" onClick={onClose} disabled={refundShipment.isLoading}>
              Cancel
            </Button>
            <Button type="button" onClick={handleRefund} colorScheme="brand" isLoading={refundShipment.isLoading}>
              Confirm
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
