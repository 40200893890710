import { paths } from 'api/types';
import { axios } from 'config/axios';
import { UseMutationOptions, useMutation } from 'react-query';

export type ImportProductsInput =
  paths['/api/v3/products/imports']['post']['requestBody']['content']['application/json'];

export type ImportProducts =
  paths['/api/v3/products/imports']['post']['responses']['200']['content']['application/json'];

export const useImportProducts = (opts?: UseMutationOptions<ImportProducts, Error, ImportProductsInput>) =>
  useMutation<ImportProducts, Error, ImportProductsInput>(async (input) => {
    const response = await axios.post(`/v3/products/imports`, input, {});
    return response?.data;
  }, opts);
