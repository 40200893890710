import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateAuthToken } from 'api/auth';
import { useRegister } from 'api/auth/register';
import { PhoneInput } from 'components/PhoneInput';
import { PasswordChecklist, PasswordInput } from 'components/password-input';
import { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CountrySelect } from 'pages/(app)/ship/_components/CountrySelect';
import { StateSelect } from 'pages/(app)/ship/_components/StateSelect';
import { z } from 'zod';

const validationSchema = z.object({
  firstName: z.string().nonempty({ message: 'Required' }),
  lastName: z.string().nonempty({ message: 'Required' }),
  email: z.string().email({ message: 'Must be a valid email' }).nonempty({ message: 'Required' }),
  password: z.string().min(8).nonempty({ message: 'Required' }),
  company: z.string().optional(),
  phone: z.string().nonempty({ message: 'Required' }),
  address: z.object({
    address1: z.string().nonempty({ message: 'Required' }),
    address2: z.string().optional(),
    city: z.string().nonempty({ message: 'Required' }),
    state: z.string().nonempty({ message: 'Required' }),
    zip: z.string().nonempty({ message: 'Required' }),
    country: z.string().nonempty({ message: 'Required' }),
  }),
});

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  company: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  phone: string;
}

export default function () {
  const [firstPage, setFirstPage] = useState<boolean>(true);
  const [validPassword, setValidPassword] = useState<boolean | null>(false);

  const register = useRegister();
  const signIn = useCreateAuthToken();
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      company: '',
      phone: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
    },
    resolver: zodResolver(validationSchema),
  });

  const {
    formState,
    formState: { isValid, errors },
    control,
    handleSubmit,
  } = methods;
  const { password } = methods.getValues();
  const { address } = useWatch({ control });

  const [params] = useSearchParams();

  const onSubmit = async (values: FormValues) => {
    const signupChannel = params.get('ref') || undefined;

    register.mutate(
      { ...values, signupChannel },
      {
        onSuccess() {
          signIn.mutate(values, {
            onSuccess(signInResponse) {
              if (signInResponse.user.isPhoneVerified === true) {
                navigate('/login?userRegistered=true');
              } else {
                navigate('/phone-validation', {
                  state: { ...signInResponse },
                });
              }
            },
          });
        },
      },
    );
  };

  return (
    <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
      {firstPage && (
        <>
          <VStack spacing={3.5}>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input placeholder=" " {...methods.register('email')} />
              <FormErrorMessage>{formState?.errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <Controller
                name="password"
                control={control}
                render={({ field }) => <PasswordInput {...field} />}
              />
              <PasswordChecklist onChange={setValidPassword} value={password} />
            </FormControl>
            <Button
              type="button"
              colorScheme="brand"
              onClick={() => setFirstPage(false)}
              isDisabled={!!errors.email || !!errors.password || !validPassword}
              w="full"
            >
              Next
            </Button>
          </VStack>
        </>
      )}
      {!firstPage && (
        <>
          <VStack spacing={3.5}>
            <Text
              letterSpacing="tight"
              fontSize="lg"
              fontWeight="semibold"
              textColor="muted"
              textAlign="center"
            >
              Personal Information
            </Text>
            <Text fontSize="sm" pb={6} color="muted" textAlign="center">
              We will use this information to create your account. You will be able to edit this information
              later.
            </Text>
            <FormControl isRequired variant="floating" isInvalid={!!errors.firstName}>
              <Input placeholder=" " {...methods.register('firstName')} />
              <FormLabel>First Name</FormLabel>
              <FormErrorMessage>{formState?.errors?.firstName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired variant="floating" isInvalid={!!errors.lastName}>
              <Input placeholder=" " {...methods.register('lastName')} />
              <FormLabel>Last Name</FormLabel>
              <FormErrorMessage>{formState?.errors?.lastName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl variant="floating" isInvalid={!!errors.company}>
              <Input placeholder=" " {...methods.register('company')} />
              <FormLabel>Company</FormLabel>
              <FormErrorMessage>{formState?.errors?.company?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired
              variant={address?.country ? 'floating-active' : 'floating'}
              isInvalid={!!errors.address?.country}
            >
              <Controller
                control={control}
                name="address.country"
                render={({ field: { ref, onChange, value } }) => (
                  <CountrySelect ref={ref} value={value} onChange={onChange} />
                )}
              />
              <FormLabel>Country</FormLabel>
            </FormControl>

            <FormControl isRequired variant="floating" isInvalid={!!errors.phone}>
              <PhoneInput placeholder=" " country={address?.country} {...methods.register('phone')} />
              <FormLabel>Phone Number</FormLabel>
              <FormErrorMessage>{formState?.errors?.phone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl variant="floating" isInvalid={!!errors.address?.address1}>
              <Input placeholder=" " {...methods.register('address.address1')} />
              <FormLabel>Address 1</FormLabel>
              <FormErrorMessage>{formState?.errors?.address?.address1?.message}</FormErrorMessage>
            </FormControl>
            <FormControl variant="floating" isInvalid={!!errors.address?.address2}>
              <Input placeholder=" " {...methods.register('address.address2')} />
              <FormLabel>Address 2</FormLabel>
              <FormErrorMessage>{formState?.errors?.address?.address2?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired variant="floating" isInvalid={!!errors.address?.city}>
              <Input placeholder=" " {...methods.register('address.city')} />
              <FormLabel>City</FormLabel>
              <FormErrorMessage>{formState?.errors?.address?.city?.message}</FormErrorMessage>
            </FormControl>

            <HStack w="full">
              <FormControl
                isRequired
                variant={address?.state ? 'floating-active' : 'floating'}
                isInvalid={!!errors.address?.state}
              >
                {address?.country !== 'US' ? (
                  <Input {...methods.register('address.state')} placeholder=" " />
                ) : (
                  <Controller
                    control={control}
                    name="address.state"
                    render={({ field: { ref, onChange, value } }) => (
                      <StateSelect ref={ref} value={value} onChange={onChange} />
                    )}
                  />
                )}
                <FormLabel>State</FormLabel>
              </FormControl>
              <FormControl isRequired variant="floating" isInvalid={!!errors.address?.zip}>
                <Input placeholder=" " {...methods.register('address.zip')} />
                <FormLabel>{address?.country !== 'US' ? 'Postal Code' : 'Zip'}</FormLabel>
              </FormControl>
            </HStack>

            <ButtonGroup w="full" justifyContent="center" mt={2}>
              <Button
                isDisabled={register.isLoading || signIn.isLoading}
                type="button"
                variant="outline"
                onClick={() => setFirstPage(true)}
                w="30%"
              >
                Back
              </Button>
              <Button
                type="submit"
                colorScheme="brand"
                isDisabled={!isValid}
                isLoading={register.isLoading || signIn.isLoading}
                loadingText="Registering..."
                w="70%"
              >
                Register
              </Button>
            </ButtonGroup>
          </VStack>
        </>
      )}
    </chakra.form>
  );
}
