import {
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useConnectEbayStore } from 'api/stores/connect';
import { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SiEbay } from 'react-icons/si';
import { useNavigate } from 'react-router';
import { z } from 'zod';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
      {children}
    </Stack>
  );
};

const validationSchema = z.object({
  name: z.string().nonempty('Required'),
  storeUrl: z.string().nonempty('Required'),
});

type FormValues = z.infer<typeof validationSchema>;

export const ConnectEbayForm: FC<{}> = () => {
  const connectEbayStore = useConnectEbayStore();
  const navigate = useNavigate();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: '', storeUrl: '' },
    resolver: zodResolver(validationSchema),
  });

  const { formState, register } = methods;

  const onSubmit = async (values: FormValues) => {
    const { name, storeUrl } = values;
    connectEbayStore.mutate(
      { name, url: storeUrl },
      {
        onSuccess: (result) => {
          window.open(result.url, '_blank');
          navigate('/stores');
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <>
        <Stack>
          <Flex justify="center" alignItems="center" bg="zinc.200" rounded="full" boxSize={14}>
            <Icon as={SiEbay} boxSize={8} />
          </Flex>
          <Stack spacing={0}>
            <Text fontSize="lg" fontWeight="semibold">
              Ebay Store
            </Text>
            <Text color="muted">Connect Vesyl to your Ebay store to import your orders.</Text>
          </Stack>
        </Stack>

        <Divider my={6} />

        <chakra.form onSubmit={methods.handleSubmit((e) => onSubmit(e))}>
          <Stack spacing="3.5" divider={<StackDivider />}>
            <FormControl isRequired isInvalid={!!formState.errors?.name}>
              <Section>
                <FormLabel variant="inline">Name</FormLabel>
                <Input {...register('name')} placeholder="e.g. My Ebay Store" />
              </Section>
            </FormControl>
            <FormControl isRequired isInvalid={!!formState.errors?.storeUrl}>
              <Section>
                <FormLabel variant="inline">URL</FormLabel>
                <Input {...register('storeUrl')} placeholder="e.g. https://www.ebay.com/usr/your-store" />
              </Section>
            </FormControl>
          </Stack>
          <Flex justify="end" pt="6">
            <Button type="submit" colorScheme="brand" isLoading={connectEbayStore.isLoading}>
              Connect
            </Button>
          </Flex>
        </chakra.form>
      </>
    </FormProvider>
  );
};
