import {
  Button,
  chakra,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface Props {
  stores: string[];
}

export const StoresPopover: FC<Props> = React.memo(({ stores }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  if (!stores) return null;

  return (
    <Popover onClose={onClose} isOpen={isOpen} isLazy onOpen={onOpen} closeOnBlur closeOnEsc closeDelay={0}>
      <PopoverTrigger>
        <Button variant="link" gap="1">
          <Text fontWeight="normal">{stores[0]}</Text>
          {stores.length > 1 ? isOpen ? <FaCaretUp /> : <FaCaretDown /> : null}
        </Button>
      </PopoverTrigger>
      <PopoverContent variants={{}} w="max-content">
        <PopoverBody p="5">
          <chakra.address fontSize="sm" fontStyle="normal" display="flex" flexDir="column">
            <Stack>{stores.length > 1 ? stores.map((item) => <Text>{item}</Text>) : null}</Stack>
          </chakra.address>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
