import {
  Button,
  Box as ChakraBox,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Switch,
  Text,
  useToken,
} from '@chakra-ui/react';
import { BoxType, useDeleteBox, useUpdateBox, useUpdateSystemBox } from 'api/boxes';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { useDisclosure } from 'hooks/use-disclosure';
import { FC, useState } from 'react';
import { FiPackage, FiStar } from 'react-icons/fi';
import { ArrayElement } from 'types/common';
import { CarrierLogo } from '../../../../components/CarrierLogo';

interface PropTypes {
  box: ArrayElement<BoxType['custom']> | ArrayElement<BoxType['system']>;
  isEditable: boolean;
  onEdit?: () => void;
  refetch?: () => void;
}

export const BoxCard: FC<PropTypes> = ({ box, isEditable, onEdit, refetch }) => {
  const { mutate: deleteBox } = useDeleteBox({
    onSuccess: () => {
      if (refetch) refetch();
    },
  });
  const { mutate: updateSystemBox } = useUpdateSystemBox();
  const { mutate: updateCustomBox } = useUpdateBox({
    onSuccess: () => {
      if (refetch) refetch();
    },
    onError: () => {
      setFavorite((prevState) => !prevState);
    },
  });

  const [isEnabled, setIsEnabled] = useState<boolean>(box?.isEnabled ?? false);
  const [isFavorite, setFavorite] = useState<boolean>(box.isFavorite);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [brand500, gray500] = useToken('colors', ['brand.500', 'gray.500']);

  const description = [box.length, box.width, box.height ?? 0].join(' x ');
  const isSoftPack = box.predefinedPackage?.toLowerCase() === 'softpack';

  const handleDeleteBox = async () => {
    await deleteBox({ id: box.id });
  };

  const toggleFavorite = async () => {
    setFavorite((prevState) => !prevState);
    updateCustomBox({ id: box.id, isFavorite: !box.isFavorite });
  };

  return (
    <Card>
      <CardHeader pb="5">
        <HStack>
          {isEditable ? (
            <FiPackage color="gray" size="1.5em" />
          ) : (
            <CarrierLogo carrier={(box as ArrayElement<BoxType['system']>).carrier} />
          )}
          <Text fontSize="md">
            {box.name} {isSoftPack ? '(Soft Pack)' : ''}
          </Text>
          <Spacer />
          {isEditable ? (
            <IconButton
              icon={<FiStar size={18} fill={isFavorite ? brand500 : 'white'} color={isFavorite ? brand500 : gray500} />}
              size="sm"
              variant="ghost"
              onClick={toggleFavorite}
              aria-label="Add to favorites"
            />
          ) : (
            <Switch
              colorScheme="brand"
              isChecked={isEnabled}
              onChange={(e) => {
                setIsEnabled(e.target.checked);
                updateSystemBox(
                  { id: `${box.id}`, isEnabled: e.target.checked },
                  { onError: () => setIsEnabled(!e.target.checked) },
                );
              }}
            />
          )}
        </HStack>
      </CardHeader>
      {isEditable && (
        <CardBody pt="1" pb="4">
          <Text color="muted">{description} in.</Text>
          <ChakraBox h="6">
            <Flex justify="end" gap="2">
              <ButtonGroup size="xs">
                <Button variant="link" onClick={onOpen}>
                  Delete
                </Button>
                <Button variant="outline" onClick={onEdit}>
                  Edit
                </Button>
              </ButtonGroup>
            </Flex>
          </ChakraBox>
          <ConfirmationModal
            header="Delete Box"
            description={`Are you sure you want to delete box "${box.name}"?`}
            type="delete"
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={handleDeleteBox}
          />
        </CardBody>
      )}
    </Card>
  );
};
