import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react';
import { Combobox } from '@headlessui/react';
import { useGetAddresses } from 'api/addresses';
import { useSmartyLookup } from 'api/smarty-streets/lookup';
import { Select as ReactSelect } from 'chakra-react-select';
import { PhoneInput } from 'components/PhoneInput';
import { ALL_STATES as allStates } from 'constants/states';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { useLocation } from 'react-router';
import { useQuickShipStore } from 'store';
import { chakraReactSelectStyles } from 'theme';
import { z } from 'zod';
import { AddressPreview, ComboboxOption, ComboboxOptions } from './util';
import { returnAddressSchema } from './ReturnAddress';

const defaultFrom = { line1: '', line2: '', city: '', state: '', postal: '', phone: '', name: '', company: '' };

export const fromAddressSchema = z.object({
  from: z.object({
    name: z.string().nonempty('Required'),
    company: z.string().optional(),
    line1: z.string().nonempty('Required'),
    line2: z.string().optional(),
    city: z.string().nonempty('Required'),
    state: z.string().nonempty('Required'),
    postal: z.string().nonempty('Required'),
    phone: z.string().nonempty('Required'),
    residential: z.boolean().optional(),
  }),
});

type FieldValues = z.infer<typeof fromAddressSchema> & z.infer<typeof returnAddressSchema>;

export function FromAddress() {
  const location = useLocation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Record<string, any> | null>(null);

  const methods = useFormContext<FieldValues>();
  const { register, control, setValue, trigger, formState, reset, getValues } = methods;
  const { fromAddressId, showReturnAddress, patch } = useQuickShipStore();

  const from = useWatch({ control, name: 'from' });

  const addresses = useGetAddresses();
  const lookupAddress = useSmartyLookup({ q: from?.line1 || '' }, {});

  const formRef = useRef(null);

  const hasErrors = !isEmpty(formState.errors?.from);

  useOutsideClick({
    ref: formRef,
    handler: useCallback(async () => {
      const valid = await trigger('from');
      if (isEditMode && valid) setIsEditMode(false);
    }, [isEditMode, trigger]),
  });

  // Selects initial address, either the primary address or what's saved in the store
  useEffect(() => {
    const primary = addresses.data?.results?.find((a) => a.primary);
    const preferred = addresses.data?.results?.find((a) => a.id === fromAddressId);
    const shipmentFrom = location?.state?.shipment?.from;

    if (shipmentFrom) {
      populateAddress(shipmentFrom);
    } else if (preferred) {
      populateAddress(preferred);
      setSelectedAddress(preferred);
    } else if (primary) {
      populateAddress(primary);
      setSelectedAddress(primary);
    }
  }, [addresses.data]);

  // Updates value in store
  useEffect(() => {
    if (selectedAddress?.id) {
      patch({ fromAddressId: selectedAddress.id });
    }
  }, [selectedAddress]);

  const populateAddress = async (address: {
    line1?: string;
    line2?: string | null;
    city?: string;
    state?: string;
    postal?: string;
    name?: string;
    company?: string;
    phone?: string;
    residential?: boolean;
  }) => {
    if (!address) return;

    setValue('from', { ...defaultFrom });

    if (address.line1) setValue('from.line1', address.line1 || '');
    if (address.line2) setValue('from.line2', address.line2 || '');
    if (address.city) setValue('from.city', address.city || '');
    if (address.state) setValue('from.state', address.state || '');
    if (address.postal) setValue('from.postal', address.postal || '');
    if (address.name) setValue('from.name', address.name || '');
    if (address.company) setValue('from.company', address.company || '');
    if (address.phone) setValue('from.phone', address.phone || '');
    if (address.residential) setValue('from.residential', address.residential || null);

    const valid = await trigger('from');
    if (!valid) setIsEditMode(true);
  };

  const data = useWatch({ control, name: 'from' });

  const formMarkup = (
    <Stack spacing="3.5">
      <FormControl isInvalid={!!formState.errors?.from?.name} variant="floating" isRequired>
        <Input {...register('from.name')} placeholder=" " />
        <FormLabel>Name</FormLabel>
      </FormControl>
      <FormControl variant="floating" isInvalid={!!formState.errors?.from?.company}>
        <Input {...register('from.company')} placeholder=" " />
        <FormLabel>Company</FormLabel>
      </FormControl>
      <FormControl
        variant={from?.line1?.length ? 'floating-active' : 'floating'}
        isRequired
        isInvalid={!!formState.errors?.from?.line1}
      >
        <Controller
          control={control}
          name="from.line1"
          render={({ field: { ref, onChange, value } }) => (
            <Combobox as="div" value={value || ''} onChange={(v: any) => populateAddress(v)}>
              <Combobox.Input
                as={Input}
                ref={ref}
                _focus={{ border: '2px', borderColor: 'brand.500' }}
                onChange={onChange}
              />
              <Combobox.Options as={ComboboxOptions} isLoading={lookupAddress.isLoading}>
                {lookupAddress.data?.map((a, index) => (
                  <Combobox.Option key={index} value={a.value}>
                    {({ selected, active }) => <ComboboxOption selected={selected || active}>{a.label}</ComboboxOption>}
                  </Combobox.Option>
                ))}

                {value?.length > 0 && (
                  <Combobox.Option value={{ line1: value }}>
                    {({ selected, active }) => (
                      <ComboboxOption selected={selected || active}>Use &quot;{value}&quot;...</ComboboxOption>
                    )}
                  </Combobox.Option>
                )}
              </Combobox.Options>
            </Combobox>
          )}
        />
        <FormLabel>Address 1</FormLabel>
      </FormControl>

      <FormControl variant="floating">
        <Input {...register('from.line2')} placeholder=" " />
        <FormLabel>Address 2</FormLabel>
      </FormControl>

      <FormControl variant="floating" isRequired isInvalid={!!formState.errors?.from?.city}>
        <Input {...register('from.city')} placeholder=" " />
        <FormLabel>City</FormLabel>
      </FormControl>

      <SimpleGrid columns={2} spacing="2">
        <FormControl
          variant={data?.state ? 'floating-active' : 'floating'}
          isRequired
          isInvalid={!!formState.errors?.from?.state}
        >
          <Controller
            control={control}
            name="from.state"
            render={({ field: { ref, onChange, value } }) => (
              <ReactSelect
                ref={ref}
                placeholder=" "
                useBasicStyles
                options={allStates}
                chakraStyles={chakraReactSelectStyles}
                onChange={(e: any) => onChange(e.value)}
                value={allStates.find((o) => o.value === value) || ''}
                components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
              />
            )}
          />
          <FormLabel>State</FormLabel>
        </FormControl>
        <FormControl variant="floating" isRequired isInvalid={!!formState.errors?.from?.postal}>
          <Input {...register('from.postal')} placeholder=" " />
          <FormLabel>Zip</FormLabel>
        </FormControl>
      </SimpleGrid>
      <FormControl variant="floating" isRequired isInvalid={!!formState.errors?.from?.phone}>
        <PhoneInput placeholder=" " {...register('from.phone')} />
        <FormLabel>Phone</FormLabel>
      </FormControl>

      <VStack alignItems={'flex-start'}>
        <Checkbox
          checked={showReturnAddress}
          onChange={() => {
            patch({ showReturnAddress: !showReturnAddress });
            setValue('return', null);
          }}
        >
          <Text>Return address is the same</Text>
        </Checkbox>
        <Checkbox {...register('from.residential')}>
          <Text>Residential</Text>
        </Checkbox>
      </VStack>
    </Stack>
  );

  const viewMarkup = <AddressPreview onEdit={() => setIsEditMode(true)} address={from} hasErrors={hasErrors} />;

  return (
    <Stack spacing="4" ref={formRef}>
      <Flex alignItems="center" gap="2">
        <Icon as={GoLocation} />
        <Text as="h3" fontSize="md" fontWeight="medium">
          Sender Address
        </Text>

        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                ml="auto"
                variant="outline"
                size="xs"
                as={Button}
                rightIcon={<FiChevronDown />}
              >
                <Text isTruncated maxW="24" fontWeight="semibold" fontSize="xs">
                  {selectedAddress?.nickname || 'Select'}
                </Text>
              </MenuButton>
              <MenuList zIndex="20">
                {addresses.data?.results?.map((a) => (
                  <MenuItem
                    key={a.id}
                    fontSize="sm"
                    onClick={() => {
                      populateAddress(a as any);
                      setSelectedAddress(a);
                    }}
                  >
                    {a.nickname} {a.primary && '(Primary)'}
                  </MenuItem>
                ))}
                <MenuItem
                  fontSize="sm"
                  onClick={() => {
                    reset({ ...getValues(), from: { ...defaultFrom } });
                    setSelectedAddress(null);
                    setIsEditMode(true);
                  }}
                >
                  New Address...
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>

      {isEditMode ? formMarkup : viewMarkup}
    </Stack>
  );
}
