import React from 'react';
import { useLocation } from 'react-router';

/**
 * Returns query parameters from an URL
 */
export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
