import { AgGridReact } from 'ag-grid-react';
import { useGetManifestsAxios } from 'api/manifests';
import { CustomAgGrid, getServerSideDatasource } from 'components/ag-grid';
import { useMemo, useRef } from 'react';
import { manifestsColumns as columns } from './helpers';

export const Manifests = () => {
  const tableId = 'manifests-list';
  const gridRef = useRef<AgGridReact>(null);

  const dataSource = useMemo(() => getServerSideDatasource(useGetManifestsAxios, {}), []);
  const manifestsColumns = useMemo(() => columns(), []);

  return (
    <CustomAgGrid
      height="calc(100vh - 330px)"
      tableId={tableId}
      ref={gridRef}
      columnDefs={manifestsColumns}
      dataSource={dataSource}
    />
  );
};
