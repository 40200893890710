import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const inputTheme = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'gray',
  },
  sizes: {
    sm: definePartsStyle({ field: { rounded: 'sm' }, addon: { rounded: 'sm' } }),
    md: definePartsStyle({ field: { fontSize: 'sm', rounded: 'sm' }, addon: { rounded: 'sm' } }),
    lg: definePartsStyle({ field: { rounded: 'sm' }, addon: { rounded: 'sm' } }),
  },
});
