import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { StorePlatform } from 'types/enums';
import { StoreSyncInterval } from './get';

export type ShopifyStore = {
  id: number;
  platform: StorePlatform.Shopify;
  name?: string;
  lastSyncAt: Date;
  syncInterval: StoreSyncInterval;
  url: string;
  fulfillmentLocationId: string | null;
};

export type EtsyStore = {
  id: number;
  url: string;
  name?: string;
  lastSyncAt: Date;
  syncInterval: StoreSyncInterval;
  platform: StorePlatform.Etsy;
};

export type EbayStore = {
  id: number;
  url: string;
  name?: string;
  lastSyncAt: Date;
  syncInterval: StoreSyncInterval;
  platform: StorePlatform.Ebay;
};

export type Ebay2Store = {
  id: number;
  url: string;
  name?: string;
  lastSyncAt: Date;
  platform: StorePlatform.Ebay;
};

export type ShipStationStore = {
  id: number;
  name?: string;
  lastSyncAt: Date;
  platform: StorePlatform.ShipStation;
};

export type WooCommerceStore = {
  id: number;
  url: string;
  name?: string;
};

type GetStore = {
  platform: StorePlatform;
  storeId: string;
};

export type ExtensivStore = {
  id: number;
  name?: string;
  userLoginName?: string;
  lastSyncAt?: Date | null;
};

type GetStoreResponse = ShopifyStore | EbayStore | EtsyStore | WooCommerceStore | ShipStationStore | ExtensivStore;

export const getStore = async (input: GetStore) => {
  const { storeId, platform } = input;
  const { data } = await axios.get<GetStoreResponse>(`/v3/stores/${platform}/${storeId}`);
  return data;
};

const useGetStores = (opts?: UseMutationOptions<GetStoreResponse, {}, GetStore>) =>
  useMutation<GetStoreResponse, {}, GetStore>(getStore, opts);

export default useGetStores;
