import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetSettings, useUpdateSettings } from 'api/settings';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  storeSyncIntervalMinutes: z.coerce
    .number()
    .nullable()
    .transform((val) => (val === 0 ? null : val)),
});

type FormValues = z.infer<typeof schema>;

export function StoreSettings() {
  const updateSettings = useUpdateSettings();
  const settings = useGetSettings();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: {
      storeSyncIntervalMinutes: settings?.data?.storeSyncIntervalMinutes || null,
    },
  });

  const onSubmit = async (values: FormValues) => {
    updateSettings.mutate(values);
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Card>
        <CardHeader>
          <Text fontWeight="semibold" fontSize="lg">
            Store Settings
          </Text>
          <Text fontSize="sm" color="muted">
            Manage and configure your store settings.
          </Text>
        </CardHeader>
        <CardBody maxW="lg">
          <FormControl>
            <FormLabel>Auto sync stores</FormLabel>
            <Select {...form.register('storeSyncIntervalMinutes')}>
              <option value="">Disabled</option>
              <option value={10}>Every 10 minutes</option>
              <option value={60}>Every hour</option>
            </Select>

            <FormHelperText>
              Automatically fetch orders from all stores at a given interval. You will still be able to perform manual
              syncs.
            </FormHelperText>
          </FormControl>
        </CardBody>
        <CardFooter maxW="lg">
          <Button
            isLoading={updateSettings.isLoading}
            ml="auto"
            colorScheme="brand"
            type="submit"
            isDisabled={isEmpty(form.formState.dirtyFields)}
          >
            Save
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}
