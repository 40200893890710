import { Flex, InputProps, Spinner } from '@chakra-ui/react';
import { useGetTags } from 'api/tags';
import { CreatableSelect, MultiValue } from 'chakra-react-select';
import { isFinite, isString } from 'lodash';
import { forwardRef } from 'react';
import { chakraReactSelectStyles } from 'theme';

export interface TagInputProps extends Pick<InputProps, 'value' | 'placeholder'> {
  onChange: (e: MultiValue<any>) => void;
  value: MultiValue<any>;
}

/**
 * @example
 *
 * ```ts
 * <Controller
 *  name="tags"
 *  control={form.control}
 *  render={({ field: { ref } }) => (
 *    <TagInput
 *      ref={ref}
 *      value={getValues('tags')?.map((tag) => ({ value: tag, label: tag })) || []}
 *      onChange={(data) =>
 *        setValue(
 *          'tags',
 *          data.map((d) => d.value),
 *        )
 *      }
 *    />
 *  )}
 * />
 * ```
 */
export const TagInput = forwardRef((props: TagInputProps, ref: any) => {
  const { placeholder = 'Select tags...', value: valueProps, onChange, ...rest } = props;

  const getTags = useGetTags();

  if (getTags.isLoading) {
    return (
      <Flex>
        <Spinner size="sm" mx="auto" />
      </Flex>
    );
  }

  const options = getTags?.data?.map((tag) => ({ value: tag.name, label: tag.name })) || [];

  // This resolves the values passed to this component into tag names for display.
  // This is done to keep compatibility with the backend, which sends tag ids.
  const value = valueProps?.map((v) => {
    const id = v.value;

    // If it's a string, it's already using the tag name
    if (isString(v.value)) return v;

    // If it's a number, it's using the tag id.
    // We need to find the tag name and use it as the value.
    if (isFinite(id)) {
      const tag = getTags?.data?.find((t) => t.id === id);
      if (tag) return { value: tag.name, label: tag.name };
    }

    return v;
  });

  return (
    <CreatableSelect
      isMulti
      ref={ref}
      value={value}
      options={options}
      onChange={onChange}
      maxMenuHeight={100}
      name="global-tag-variant"
      placeholder={placeholder}
      chakraStyles={chakraReactSelectStyles}
      {...rest}
    />
  );
});
