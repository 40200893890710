import { paths } from 'api/types';
import axios from 'config/axios';
import { useMutation, UseMutationOptions, useQuery } from 'react-query';

/**
 * Update Settings
 */
export type UpdateSettingsInput = NonNullable<
  paths['/api/v3/user/settings']['put']['requestBody']
>['content']['application/json'];
export type UpdateSettingsResponse =
  paths['/api/v3/user/settings']['put']['responses']['200']['content']['application/json'];

export const useUpdateSettings = (opts?: UseMutationOptions<UpdateSettingsResponse, any, UpdateSettingsInput>) =>
  useMutation<UpdateSettingsResponse, any, UpdateSettingsInput>(async (vars: UpdateSettingsInput) => {
    const result = await axios.put('/v3/user/settings', vars);
    return result?.data;
  }, opts);

/**
 * Get Settings
 */

export type GetSettingsResponse =
  paths['/api/v3/user/settings']['get']['responses']['200']['content']['application/json'];

export const GET_SETTINGS_QUERY = 'getSettings';

export const useGetSettings = () =>
  useQuery<GetSettingsResponse>([GET_SETTINGS_QUERY], async () => {
    const result = await axios.get(`/v3/user/settings`);
    return result?.data;
  });
