import { useMutation, UseMutationOptions } from 'react-query';
import axios from '../../config/axios';
import { paths } from 'api/types';
import { AxiosError } from 'axios';

export type GenerateReportResponse =
  paths['/api/v3/create-report']['post']['responses']['200']['content']['application/json'];

export type GenerateReport = {
  startDate: string;
  endDate: string;
  type: string;
  sendEmail?: boolean;
  sendEmailAddress?: string;
};

type ErrorResponse = AxiosError<{ message?: string; error?: string }>;

export const generateReport = async ({
  startDate,
  endDate,
  type,
  sendEmail,
  sendEmailAddress,
}: GenerateReport) => {
  const { data } = await axios.post(`/v3/create-report`, {
    startDate,
    endDate,
    type,
    sendEmail,
    sendEmailAddress,
  });
  return data;
};

const useGenerateReport = (
  opts?: UseMutationOptions<GenerateReportResponse, ErrorResponse, GenerateReport>,
) => useMutation<GenerateReportResponse, ErrorResponse, GenerateReport>(generateReport, opts);

export default useGenerateReport;
