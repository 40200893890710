import { Image, ImageProps } from '@chakra-ui/react';
import { startCase } from 'lodash';

export interface LogoProps extends ImageProps {
  // @TODO: Add support for center-stacked
  variant?: 'logomark' | 'wordmark' | 'horizontal';

  /**
   * (!) Some color combinations are not available depending on the variant.
   */
  color?:
    | 'black'
    | 'blue'
    | 'white'
    | 'yellow'
    | 'black-white'
    | 'blue-black'
    | 'green-black'
    | 'green-white'
    | 'yellow-black'
    | 'yellow-white';
}

export const Logo = (props: LogoProps) => {
  const { variant = 'horizontal', color = 'yellow-black', ...rest } = props;

  return <Image w="xs" src={`/logos/Vesyl-${startCase(variant)}-${startCase(color)}.png`} {...rest} />;
};
