import {
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetSettings, useUpdateSettings } from 'api/settings';
import { useNotification } from 'contexts/notification.context';
import { useForm } from 'react-hook-form';
import { GetCarrierAccountsResponse } from 'types/api-types';
import { z } from 'zod';

const schema = z.object({
  uspsIncludeMediaMail: z.boolean().optional(),
});

export const CarrierSettingsModal = (
  props: Omit<ModalProps, 'children'> & { carrier: GetCarrierAccountsResponse[0] },
) => {
  const { onClose, carrier } = props;

  const updateSettings = useUpdateSettings();
  const settings = useGetSettings();

  const notify = useNotification();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    values: {
      uspsIncludeMediaMail: settings?.data?.uspsIncludeMediaMail || false,
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    updateSettings.mutate(values, {
      onSuccess: () => {
        settings.refetch();
        onClose();
        notify.success('Settings updated successfully');
      },
    });
  });

  const isUPS = carrier.type.toLowerCase() === 'usps';

  return (
    <Modal {...props}>
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalHeader>{carrier?.readable} Settings</ModalHeader>

          <ModalBody>
            {isUPS && (
              <>
                <FormControl>
                  <Checkbox {...form.register('uspsIncludeMediaMail')}>
                    <Text>Include Media Mail rates</Text>
                    <Text mt="1" fontSize="xs" fontWeight="normal" color={'muted'}>
                      Always include Media Mail rates when available. Media Mail is a cost-effective way to send
                      educational materials.
                    </Text>
                  </Checkbox>
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter gap="2">
            <Button type="button" variant="ghost" onClick={onClose}>
              Cancel
            </Button>

            <Button type="submit" colorScheme="brand">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
