import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { PutLoginsLoginIdBody } from 'types/api-types';

type Input = PutLoginsLoginIdBody & { id: number };
export const action = async (input: Input) => {
  const { id, ...payload } = input;

  const response = await axios.put(`/v3/logins/${id}`, payload);
  return response?.data;
};

export const useUpdateLogin = (opts?: UseMutationOptions<{}, any, Input>) => useMutation<{}, any, Input>(action, opts);
