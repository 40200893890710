import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { paths } from 'api/types';
import axios from '../../config/axios';

export type PutProductBody = paths['/api/v3/products/{productId}']['put']['requestBody']['content']['application/json'];
export type PutProductParams = paths['/api/v3/products/{productId}']['put']['parameters']['path'];
export type PutProductResponse =
  paths['/api/v3/products/{productId}']['put']['responses']['200']['content']['application/json'];
export type Variables = Partial<PutProductBody & PutProductParams>;
type Error = AxiosError<{}>;

export const useUpdateProduct = (opts?: UseMutationOptions<PutProductResponse, Error, Variables>) =>
  useMutation<PutProductResponse, Error, Variables>(async (input) => {
    const { productId, ...payload } = input;

    const response = await axios.put<PutProductResponse>(`/v3/products/${productId}`, payload);
    return response?.data;
  }, opts);
