import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useGetBoxes } from 'api/boxes';
import { ChangeEvent, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { BsInputCursorText } from 'react-icons/bs';
import { FiBox, FiPackage } from 'react-icons/fi';
import { LuChevronDown, LuChevronUp, LuInfo, LuPlus, LuTrash2 } from 'react-icons/lu';
import { z } from 'zod';

const invalid_type_error = 'Must be a number';

export const defaultParcel = {
  predefinedPackage: undefined,
  weightOz: '' as any,
  weightLbs: undefined as any,
  weight: undefined,
  width: null,
  length: null,
  height: null,
  boxId: null,
  showDimensions: true,
};

const parcelObjectSchema = z
  .object({
    weightOz: z.coerce.number({ invalid_type_error }).min(0).max(1900),
    weightLbs: z.coerce.number({ invalid_type_error }).min(0).max(120).default(0),
    width: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    length: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    height: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    predefinedPackage: z.string().optional(),
    boxId: z.string().nullable().optional(),
    // Error controls. Need to be declared here because of TS.
    dimensions: z.any().optional(),
    weight: z.any().optional(),
    // Local state, used to toggle on/off Box mode
    showDimensions: z.any().optional(),
  })
  .refine((data) => data.weightLbs > 0 || data.weightOz > 0, {
    path: ['weight'],
    message: 'Required',
  })
  .refine(
    (data) =>
      [data.width, data.length, data.height].every((d) => d && d > 0) ||
      [data.width, data.length, data.height].every((d) => d === null),
    { path: ['dimensions'], message: 'Required' },
  );

export const parcelSchema = z.object({
  parcels: z.array(parcelObjectSchema).optional(),
});

type FieldValues = z.infer<typeof parcelSchema>;

export function Package() {
  const { register, control, setValue, formState, clearErrors, getValues } = useFormContext<FieldValues>();
  const parcelForm = useFieldArray({ control, name: 'parcels' });

  const [isMultiParcel, setIsMultiParcel] = useState<boolean>(false);

  const parcels = useWatch({ control, name: 'parcels' });

  const getBoxes = useGetBoxes();

  useEffect(() => {
    if (!parcels) return;

    for (const [index, p] of parcels.entries()) {
      if (p?.predefinedPackage === 'SoftPack') {
        if (p.height !== 0.1) setValue(`parcels.${index}.height`, 0.1);
        if (formState.errors?.parcels?.[index]?.dimensions) clearErrors(`parcels.${index}.dimensions`);
      }
    }
  }, [parcels]);

  const resetParcelDims = (index: number) => {
    setValue(`parcels.${index}.length`, null);
    setValue(`parcels.${index}.width`, null);
    setValue(`parcels.${index}.height`, null);
    setValue(`parcels.${index}.predefinedPackage`, undefined);
    setValue(`parcels.${index}.boxId`, null);
  };

  const onBoxSelect = (ev: ChangeEvent<HTMLSelectElement>, index: number) => {
    const value = ev.target.value;
    const [boxType, id] = value.split('-') as ['system' | 'custom', string];

    const customBoxes = getBoxes.data?.custom ?? [];
    const systemBoxes = getBoxes.data?.system ?? [];

    const box =
      boxType === 'custom'
        ? customBoxes.find((b) => b.id === Number(id))
        : systemBoxes.find((b) => b.id === Number(id));

    if (value && box?.id) setValue(`parcels.${index}.boxId`, value);
    if (box?.length === 0 && box?.width === 0 && box?.height === 0) {
      setValue(`parcels.${index}.length`, null);
      setValue(`parcels.${index}.width`, null);
      setValue(`parcels.${index}.height`, null);
    } else {
      setValue(`parcels.${index}.length`, box?.length || null);
      setValue(`parcels.${index}.width`, box?.width || null);
      const height = box?.height === 0 ? 0.1 : box?.height;
      setValue(`parcels.${index}.height`, height || null);
    }
    setValue(`parcels.${index}.predefinedPackage`, box?.predefinedPackage || undefined);
  };

  return (
    <Stack spacing="2">
      <Flex alignItems="center" gap="2">
        <Icon as={FiBox} />
        <Text as="h3" fontSize="md" fontWeight="medium">
          Package
        </Text>

        <Menu closeOnSelect closeOnBlur>
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                size="xs"
                ml="auto"
                variant="outline"
                rightIcon={isOpen ? <LuChevronUp /> : <LuChevronDown />}
              >
                {isMultiParcel ? 'Multi-Package' : 'Single'}
              </MenuButton>
              <MenuList zIndex="popover" w="fit-content" fontSize="sm">
                <MenuOptionGroup type="radio" defaultValue="0" onChange={(v) => setIsMultiParcel(v !== '0')}>
                  <MenuItemOption value="0">Single</MenuItemOption>
                  <MenuItemOption value="1">Multi-Package</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
      <Stack gap="3">
        {parcelForm.fields.map((p, index) => {
          const showDims = !!parcels?.[index]?.showDimensions;
          const errors = formState.errors?.parcels?.[index];

          const boxId = getValues(`parcels.${index}.boxId`);
          const boxWeight = getBoxes.data?.custom?.find((b) => boxId === `custom-${b.id}`)?.weight || null;

          return (
            <Box
              border="1px solid"
              borderColor={index > 0 ? 'zinc.200' : 'transparent'}
              bg={index > 0 ? 'zinc.50' : undefined}
              py={index > 0 ? '3' : undefined}
              px="3"
              rounded="lg"
              key={p.id}
            >
              <Flex>
                {index >= 1 && (
                  <IconButton
                    mr={-1}
                    mt={-1}
                    variant="ghost"
                    ml="auto"
                    borderColor="red.200"
                    color="red.500"
                    size="xs"
                    icon={<LuTrash2 />}
                    aria-label="Remove"
                    onClick={() => parcelForm.remove(index)}
                  />
                )}
              </Flex>
              <Stack spacing="2">
                <HStack hidden={showDims} mt="2">
                  <FormControl variant="floating">
                    <Select defaultValue="" onChange={(v) => onBoxSelect(v, index)}>
                      <option disabled value="">
                        - Select an option -
                      </option>
                      <optgroup label="Custom Boxes">
                        {getBoxes.data?.custom?.map((box) => (
                          <option key={box.id} value={`custom-${box.id}`}>
                            {box.name} {box.predefinedPackage === 'SoftPack' ? '(Soft Pack, ' : '('}
                            {box.length} x {box.width} x {box.height})
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label="Pre-defined Boxes">
                        {getBoxes.data?.system?.map((box) => (
                          <option key={box.id} value={`system-${box.id}`}>
                            {box.carrier} - {box.name}{' '}
                            {box.length > 0 && `(${box.length} x ${box.width} x ${box.height})`}
                          </option>
                        ))}
                      </optgroup>
                    </Select>
                    <FormLabel>Box</FormLabel>
                  </FormControl>
                  <Tooltip label="Switch to Dimensions" placement="top">
                    <IconButton
                      title="Toggle Box mode off"
                      size="xs"
                      variant="outline"
                      aria-label="Toggle Box mode off"
                      icon={<BsInputCursorText />}
                      onClick={() => {
                        resetParcelDims(index);
                        setValue(`parcels.${index}.showDimensions`, !showDims);
                        setValue(`parcels.${index}.predefinedPackage`, undefined);
                      }}
                    />
                  </Tooltip>
                </HStack>

                <HStack hidden={!showDims} mt="2">
                  <HStack>
                    <FormControl variant="floating" isInvalid={!!errors?.length || !!errors?.dimensions}>
                      <Input {...register(`parcels.${index}.length`)} placeholder=" " />
                      <FormLabel>Length</FormLabel>
                    </FormControl>
                    <FormControl variant="floating" isInvalid={!!errors?.width || !!errors?.dimensions}>
                      <Input {...register(`parcels.${index}.width`)} placeholder=" " />
                      <FormLabel>Width</FormLabel>
                    </FormControl>
                    <FormControl
                      variant="floating"
                      isDisabled={p?.predefinedPackage === 'SoftPack'}
                      isInvalid={!!errors?.height || !!errors?.dimensions}
                    >
                      <Input {...register(`parcels.${index}.height`)} placeholder=" " />
                      <FormLabel>Height</FormLabel>
                    </FormControl>
                  </HStack>
                  <Tooltip label="Switch to Box" placement="top">
                    <IconButton
                      title="Toggle Box mode on"
                      size="xs"
                      variant="outline"
                      aria-label="Toggle Box mode on"
                      icon={<FiPackage />}
                      onClick={() => {
                        resetParcelDims(index);
                        setValue(`parcels.${index}.showDimensions`, !showDims);
                      }}
                    />
                  </Tooltip>
                </HStack>
                <FormControl isDisabled={!p.showDimensions}>
                  <Checkbox
                    onChange={(e) =>
                      setValue(`parcels.${index}.predefinedPackage`, e.target.checked ? 'SoftPack' : undefined)
                    }
                  >
                    <Text fontSize="xs">Poly mailer / soft pack?</Text>
                  </Checkbox>
                </FormControl>
              </Stack>

              {/* Weight */}
              <HStack spacing="2" mt="2">
                <FormControl variant="floating" isRequired isInvalid={!!errors?.weightLbs || !!errors?.weight}>
                  <Input {...register(`parcels.${index}.weightLbs`)} placeholder=" " />
                  <FormLabel>Weight (lbs)</FormLabel>
                </FormControl>
                <FormControl variant="floating" isRequired isInvalid={!!errors?.weightOz || !!errors?.weight}>
                  <Input {...register(`parcels.${index}.weightOz`)} placeholder=" " />
                  <FormLabel>Weight (oz)</FormLabel>
                </FormControl>
              </HStack>
              <HStack ml="auto" spacing="1" mt="1.5" hidden={!boxWeight}>
                <LuInfo />
                <Text color="muted" fontWeight="normal" fontSize="xs">
                  {boxWeight} oz will be added from the box weight
                </Text>
              </HStack>
            </Box>
          );
        })}
      </Stack>
      <Button
        size="xs"
        w="fit-content"
        ml="auto"
        hidden={!isMultiParcel}
        variant="outline"
        leftIcon={<LuPlus />}
        onClick={() => {
          parcelForm.append(defaultParcel);
        }}
      >
        Add Parcel
      </Button>
    </Stack>
  );
}
