import { Box, Flex, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { FiSearch } from 'react-icons/fi';

import { AgGridReact } from 'ag-grid-react';
import { Views } from 'components/views';

interface GridHeaderProps {
  setSearchQuery?: (value: React.SetStateAction<string>) => void;
  viewEntity?: string;
  defaultFilterModel?: Record<string, any>;
  selected?: 'views' | 'status';
  onViewSelect?: () => void;
}

export const GridHeader = forwardRef<AgGridReact, GridHeaderProps>((props, ref) => {
  const { setSearchQuery, viewEntity, selected, onViewSelect } = props;

  return (
    <Flex w={'100%'} px={2} py={1}>
      {viewEntity && (
        <Views viewEntity={viewEntity} ref={ref} selected={selected} onViewSelect={onViewSelect} />
      )}
      <Box p={1} ml="auto">
        {setSearchQuery && (
          <InputGroup size={'sm'} maxW="sm">
            <InputLeftAddon>
              <FiSearch />
            </InputLeftAddon>
            <Input type="text" placeholder="Search" onChange={(e) => setSearchQuery(e.target.value)} />
          </InputGroup>
        )}
      </Box>
    </Flex>
  );
});
