import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type ConnectEtsyResult = { url: string };
type ConnectEtsyInput = { url: string; name: string };

const connect = async (input: ConnectEtsyInput) => {
  if (!input) return null;
  const { name: storeName, url: storeUrl } = input;

  const result = await axios.get(`/v3/stores/etsy/connect`, { params: { storeName, storeUrl } });
  return result?.data;
};

export const useConnectEtsyStore = (opts?: UseMutationOptions<ConnectEtsyResult, {}, ConnectEtsyInput>) =>
  useMutation<ConnectEtsyResult, {}, ConnectEtsyInput>(connect, opts);
