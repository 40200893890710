import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  PaginationState as TSTPaginationState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isNil, noop, sortedUniq } from 'lodash';
import startCase from 'lodash/startCase';
import * as React from 'react';
import { FiChevronDown, FiChevronUp, FiEye } from 'react-icons/fi';
import { useTableStore } from 'store';

function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & React.InputHTMLAttributes<HTMLInputElement>) {
  const ref: any = React.useRef(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} {...rest} style={{ borderRadius: '0.25rem', borderColor: '#c6c6c6' }} />;
}

function getPaginationPages(pageRange: number, pageIndex: number) {
  if (pageRange < 4) {
    return Array.from({ length: pageRange }, (_, i) => i + 1);
  }
  const currentPages = [pageIndex, pageIndex + 1, pageIndex + 2].filter((p) => p > 0 && p <= pageRange);
  const uniquePages: (number | string)[] = sortedUniq([1, ...currentPages, pageRange]);
  if (pageIndex > 2) {
    uniquePages.splice(1, 0, '...');
  }
  if (pageIndex < pageRange - 2) {
    uniquePages.splice(uniquePages.length - 1, 0, '...');
  }

  return uniquePages;
}

interface DataTableProps<T> {
  /**
   * Unique ID for the table. Must be unique across all tables in the app.
   */
  id: string;

  /**
   * Data to display in the table.
   */
  data: T[];

  /**
   * Columns to display in the table.
   */
  columns: ColumnDef<T>[];

  /**
   * Pagination state.
   */
  pagination: { page: number; per: number };

  /**
   * Total number of rows in the table.
   */
  rowCount: number;

  /**
   * Page sizes to display in the page size select. (optional)
   */
  pageSizes?: number[];

  /**
   * Whether the table is loading. (optional)
   */
  isLoading?: boolean;

  /**
   * Whether the table is selectable. (optional)
   * @default true
   */
  isSelectable?: boolean;

  /**
   * Whether the row is disabled for selection. (optional)
   */
  isRowDisabled?: { hasCondition: boolean; field: string; callback: (...args: any) => boolean };

  /**
   * Callback for when the pagination state changes. (optional)
   */
  onPaginationChange?: (p: { page: number; per: number }) => void;

  /**
   * Callback for when rows are selected. (optional)
   */
  onRowSelect?: (rows: T[]) => void;

  /**
   * Callback for when the sorting state changes. (optional)
   */
  onSortingChange?: (s: SortingState) => void;
}

export interface PaginationState {
  page: number;
  per: number;
}

export function DataTable<T>({
  id,
  data,
  columns,
  rowCount = 0,
  onRowSelect = noop,
  pagination: paginationState,
  onPaginationChange: onPaginationStateChange = noop,
  onSortingChange = noop,
  pageSizes = [10, 50, 100, 250, 500],
  isLoading = false,
  isSelectable = true,

  isRowDisabled = { hasCondition: false, field: '', callback: () => false },
}: DataTableProps<T>) {
  const baseColumns: ColumnDef<any>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          disabled={isRowDisabled.hasCondition ? isRowDisabled.callback(row.original[isRowDisabled.field]) : false}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
  ];

  const tableStore = useTableStore(id);

  const visibleColumns: VisibilityState =
    Object.keys(tableStore.columnVisibility).length > 0
      ? tableStore.columnVisibility
      : columns
          .filter((c) => !isNil(c.id))
          .reduce((obj, col) => ({ ...obj, [col.id as string]: !col.meta?.isHidden }), {});

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(visibleColumns);
  const [rowSelection, setRowSelection] = React.useState({});
  const [goToPage, setGoToPage] = React.useState<number>(0);

  const [{ pageIndex, pageSize }, setPagination] = React.useState<TSTPaginationState>({
    pageIndex: paginationState.page,
    pageSize: paginationState.per,
  });

  const pagination = React.useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

  const defaultData = React.useMemo(() => [], []);
  const table = useReactTable({
    data: data ?? defaultData,
    columns: [
      // Do not include checkbox column if table is not selectable
      ...(isSelectable ? baseColumns : []),
      ...columns,
    ],
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    manualPagination: true,
    debugTable: true,
    manualSorting: true,
    pageCount: Math.ceil(rowCount / pageSize),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
  });

  React.useEffect(() => {
    tableStore.setColumnVisibility(columnVisibility);
  }, [columnVisibility]);

  React.useEffect(() => {
    tableStore.setPageSize(pageSize);
  }, [pageSize]);

  React.useEffect(() => {
    if (!onRowSelect) return;
    const rs = rowSelection as Record<string, boolean>;
    const selectedIndexes = Object.keys(rs).filter((key) => rs[key] === true);
    const selected = [...data].filter((_d, i) => selectedIndexes.includes(i.toString()));
    onRowSelect(selected);
  }, [rowSelection]);

  React.useEffect(() => {
    onPaginationStateChange({ page: pageIndex, per: pageSize });
  }, [pagination]);

  React.useEffect(() => {
    const ids = table.getAllLeafColumns().map((col) => ({
      id: col.id,
      sortId: col.columnDef.meta?.sortId,
    }));

    // Calls onSortingChange with `meta.sortId` instead of regular column ID.
    const state: SortingState = sorting.map((s) => ({
      ...s,
      id: ids.find((i) => i.id === s.id)?.sortId || '',
    }));

    onSortingChange(state);
  }, [sorting]);

  const rowHeight = 41;

  const noDataMarkup = (
    <Tr>
      <Td colSpan={columns.length} h={rowHeight * pagination.pageSize} textAlign="center">
        No results.
      </Td>
    </Tr>
  );

  const loadingMarkup = isLoading && (
    <Tr>
      <Td colSpan={columns.length + 1} h={rowHeight * pageSizes[0]} textAlign="center">
        <Spinner opacity={0.6} />
      </Td>
    </Tr>
  );

  const dataMarkup = table.getRowModel().rows?.length
    ? table.getRowModel().rows.map((row) => (
        <Tr h={rowHeight} key={row.id} data-state={row.getIsSelected() && 'selected'} color="muted">
          {row.getVisibleCells().map((cell) => (
            <Td {...(isLoading && { opacity: 0.4 })} key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </Tr>
      ))
    : null;

  return (
    <div>
      <Box overflow="auto">
        <Table size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <HStack
                          cursor={header.column.getCanSort() ? 'pointer' : ''}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <>{flexRender(header.column.columnDef.header, header.getContext())}</>

                          {{
                            asc: <FiChevronUp />,
                            desc: <FiChevronDown />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </HStack>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{loadingMarkup || dataMarkup || noDataMarkup}</Tbody>
        </Table>
      </Box>

      <Flex p="4" alignItems="center" gap="2">
        <HStack>
          <Select
            size="sm"
            maxW="32"
            value={pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </Select>

          <Popover>
            <PopoverTrigger>
              <Button size="sm" gap="2" px="4" variant="outline">
                <Icon as={FiEye} /> Show / Hide Columns
              </Button>
            </PopoverTrigger>
            <PopoverContent p="4" maxW="60">
              {table
                .getAllLeafColumns()
                .filter((col) => col.getCanHide())
                .map((col) => {
                  return (
                    <Box key={col.id} px="1">
                      <Checkbox
                        {...{
                          isChecked: col.getIsVisible(),
                          onChange: col.getToggleVisibilityHandler(),
                        }}
                      >
                        {startCase(col.id)}
                      </Checkbox>
                    </Box>
                  );
                })}
            </PopoverContent>
          </Popover>
          <Text w={200}>
            Showing from {table.getState().pagination.pageIndex * table.getState().pagination.pageSize} to{' '}
            {rowCount < table.getState().pagination.pageSize || !table.getCanNextPage()
              ? rowCount
              : (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize}
          </Text>
        </HStack>

        <HStack ml="auto">
          {table.getPageCount() > 1 && (
            <HStack>
              <Text>Go to</Text>
              <Input
                size="sm"
                w={16}
                textAlign="center"
                onChange={(e) => setGoToPage(Number(e.target.value))}
                onKeyDown={(event) => {
                  if (event.key !== 'Enter') return;
                  if (goToPage < 1 || Number.isNaN(goToPage)) return;
                  table.setPageIndex(Number(goToPage) - 1);
                }}
              />
              <Text pr={6}>page</Text>
            </HStack>
          )}
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </Button>
          {getPaginationPages(table.getPageCount(), table.getState().pagination.pageIndex).map((page, index) => (
            <Button
              key={index}
              variant="outline"
              size="sm"
              isDisabled={page === '...'}
              onClick={() => {
                if (typeof page === 'string') return;
                table.setPageIndex(page - 1);
              }}
              bg={table.getState().pagination.pageIndex + 1 === page ? 'gray.100' : 'white'}
            >
              {page}
            </Button>
          ))}
          <Button variant="outline" size="sm" onClick={() => table.nextPage()} isDisabled={!table.getCanNextPage()}>
            {'>'}
          </Button>
        </HStack>
      </Flex>
    </div>
  );
}
