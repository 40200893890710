import { useQuery } from 'react-query';
import { PaymentLog } from 'types/payment';

import axios from '../../config/axios';

export type GetPaymentLogsResponse = { results: PaymentLog[]; total: number };

export type GetPaymentLogs = {
  per?: number;
  page?: number;
  fromDate?: string;
  toDate?: string;
  type?: string;
};

export const getPaymentLogs = async ({ per, page, fromDate, toDate, type }: GetPaymentLogs) => {
  let apiType;
  if (type === 'refund') {
    apiType = 'refund_credit, refund_success, refund_fail, refund_reverse';
  } else if (type === 'credit_card') {
    apiType = 'cc_charge, cc_charge_success, cc_charge_fail, cc_fee';
  } else if (type === 'bank_account') {
    apiType = 'ach_charge, ach_charge_success, ach_charge_fail';
  } else {
    apiType = type;
  }
  const { data } = await axios.get(`/v3/payments`, {
    params: {
      per,
      page,
      fromDate,
      toDate,
      type: apiType,
    },
  });
  return data;
};

export const GET_PAYMENT_LOGS_QUERY = 'payment-logs';

const useGetPaymentLogs = ({ per, page, fromDate, toDate, type }: GetPaymentLogs) =>
  useQuery<GetPaymentLogsResponse>(
    [GET_PAYMENT_LOGS_QUERY, { per, page, fromDate, toDate, type }],
    () => getPaymentLogs({ per, page, fromDate, toDate, type }),
    {},
  );
export default useGetPaymentLogs;
