import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

import type { Component } from '../smarty-streets/types';

type ExtractAddress = { address: string };
type Result = {
  line1: string;
  city: string;
  postal: string;
  state: string;
  company: string;
  name: string;
  country: string;
};
export type ExtractAddressError = AxiosError<{ message: string }>;

export const extractAddress = async (vars: ExtractAddress) => {
  const result = await axios.post('/v3/address/extract', vars);

  if (!result.data) return [];

  return result?.data?.map((a: Component) => ({
    line1: a.deliveryLine1 || '',
    city: a.cityName || '',
    postal: a.zipcode || '',
    state: a.stateAbbreviation || '',
    company: a.company || '',
    name: a.name || '',
    // This endpoint is for US-only addresses.
    // We're returning the country here for convenience
    country: 'US',
  }));
};

export const useExtractAddress = (opts?: UseMutationOptions<Array<Result>, ExtractAddressError, ExtractAddress>) =>
  useMutation<Array<Result>, ExtractAddressError, ExtractAddress>(extractAddress, opts);
