import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';

type CreateBox = {
  length: number;
  width: number;
  height: number;
  name: string;
};
type CreateBoxResponse = {
  id: number;
  length: number;
  width: number;
  height: number;
  isEnabled: boolean;
  createdAt: string;
  updatedAt: string;
};
type CreateBoxError = AxiosError<{ errors: { msg: string; param: string }[] }>;

export const createBox = async ({ ...vars }: CreateBox) => {
  const { data } = await axios.post(`/v3/boxes/`, vars);
  return data;
};

const useCreateBox = (opts?: UseMutationOptions<CreateBoxResponse, CreateBoxError, CreateBox>) =>
  useMutation<CreateBoxResponse, CreateBoxError, CreateBox>(createBox, opts);

export default useCreateBox;
