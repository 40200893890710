import {
  Button,
  Icon,
  IconProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { FaEbay, FaEtsy, FaShopify } from 'react-icons/fa';
import { FaGear } from 'react-icons/fa6';
import { SiWoo, SiFauna } from 'react-icons/si';
import { useNavigate } from 'router';
import { useUserStore } from 'store';
import { StorePlatform } from 'types/enums';

const stores: Array<{
  id: StorePlatform;
  name: string;
  icon: any;
  disabled: boolean;
  externalUrl: string | null;
  onConnect?: () => void;
  iconProps?: IconProps;
}> = [
  {
    disabled: false,
    id: StorePlatform.Ebay2,
    name: 'eBay',
    icon: FaEbay,
    externalUrl: null,
  },
  {
    disabled: false,
    id: StorePlatform.Shopify,
    name: 'Shopify',
    externalUrl: null,
    onConnect: () => {},
    icon: FaShopify,
  },
  {
    disabled: false,
    id: StorePlatform.WooCommerce,
    name: 'WooCommerce',
    externalUrl: null,
    icon: SiWoo,
  },
  {
    disabled: false,
    id: StorePlatform.Etsy,
    name: 'Etsy',
    externalUrl: null,
    icon: FaEtsy,
  },
  {
    disabled: true,
    id: StorePlatform.Ebay,
    name: 'eBay Legacy',
    icon: FaEbay,
    externalUrl: null,
  },
  {
    disabled: false,
    id: StorePlatform.ShipStation,
    name: 'ShipStation',
    icon: FaGear,
    externalUrl: null,
    iconProps: { boxSize: 10 },
  },
  {
    disabled: false,
    id: StorePlatform.Extensiv,
    name: 'Extensiv',
    icon: SiFauna,
    externalUrl: null,
    iconProps: { boxSize: 10 },
  },
];

export const ConnectStoreModal: FC<Omit<ModalProps, 'children'>> = (props) => {
  const { onClose, ...rest } = props;

  const navigate = useNavigate();
  const [selected, setSelected] = useState<StorePlatform | null>(null);
  const authToken = useUserStore((state) => state.authToken);

  return (
    <>
      <Modal onClose={onClose} {...rest}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Connect a store</ModalHeader>
          <ModalBody>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={2}>
              {stores?.map((item) => (
                <Button
                  p="5"
                  mx="0"
                  w="full"
                  minH="28"
                  rounded="lg"
                  flexDir="column"
                  justifyContent="space-between"
                  key={item.id}
                  isDisabled={item.disabled}
                  onClick={() => setSelected(item.id)}
                  variant={selected === item.id ? 'solid' : 'outline'}
                >
                  <Icon boxSize={12} as={item.icon} {...(item.iconProps || {})} />
                  <Text>{item.name}</Text>
                </Button>
              ))}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter gap="2">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
            <Button
              variant="primary"
              isDisabled={!selected}
              onClick={() => {
                const item = stores.find((x) => x.id === selected);

                if (!item) return;

                if (item.id === StorePlatform.Shopify) {
                  // When connecting to a Shopify store, we need to set a cookie in the API
                  // to identify the user during the Shopify OAuth flow. The URL below takes care of
                  // that _and_ redirects the user to the Shopify store to install the app.
                  window.open(`${import.meta.env.VITE_BASE_URL}/v3/stores/shopify/identify?t=${authToken}`);
                } else if (item.externalUrl) {
                  window.open(item.externalUrl, '_blank');
                } else navigate(`/stores/:platform/connect`, { params: { platform: item.id } });
              }}
            >
              Connect
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
