import { paths } from 'api/types';
import axios from 'config/axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';

/**
 * Get all carrier accounts
 */

const getCarrierAccounts = async () => {
  const result = await axios.get(`/v3/carrier-accounts`, {});
  return result?.data;
};

export const GET_CARRIER_ACCOUNTS_QUERY = 'getCarrierAccounts';
type GetCarrierAccountsResponse =
  paths['/api/v3/carrier-accounts']['get']['responses']['200']['content']['application/json'];

export const useGetCarrierAccounts = (opts?: UseQueryOptions<any>) =>
  useQuery<GetCarrierAccountsResponse>([GET_CARRIER_ACCOUNTS_QUERY], () => getCarrierAccounts(), opts ?? {});

/**
 * Get a carrier account
 */

type GetCarrierAccountResponse =
  paths['/api/v3/carrier-accounts/{id}']['get']['responses']['200']['content']['application/json'];

export const GET_CARRIER_ACCOUNT_QUERY = 'getCarrierAccount';

const getCarrierAccount = async (input: { id: string }) => {
  const { data } = await axios.get(`/v3/carrier-accounts/${input.id}`);
  return data;
};

export const useGetCarrierAccount = (id: string, opts?: UseQueryOptions<any>) =>
  useQuery<GetCarrierAccountResponse>([GET_CARRIER_ACCOUNT_QUERY, id], () => getCarrierAccount({ id }), opts);

/**
 * Create a carrier account
 */

type CreateCarrierAccountInput =
  paths['/api/v3/carrier-accounts']['post']['requestBody']['content']['application/json'];
type CreateCarrierAccountResponse =
  paths['/api/v3/carrier-accounts']['post']['responses']['200']['content']['application/json'];

export const useCreateCarrierAccount = (
  opts?: UseMutationOptions<CreateCarrierAccountResponse, Error, CreateCarrierAccountInput>,
) =>
  useMutation<CreateCarrierAccountResponse, Error, CreateCarrierAccountInput>(async (input) => {
    const result = await axios.post(`/v3/carrier-accounts`, input);
    return result?.data;
  }, opts);

/**
 * Update a carrier account
 */

type UpdateCarrierAccountInput =
  paths['/api/v3/carrier-accounts/{id}']['put']['requestBody']['content']['application/json'] & { id: string };
type UpdateCarrierAccountResponse =
  paths['/api/v3/carrier-accounts/{id}']['put']['responses']['200']['content']['application/json'];

export const useUpdateCarrierAccount = (
  opts?: UseMutationOptions<UpdateCarrierAccountResponse, Error, UpdateCarrierAccountInput>,
) =>
  useMutation<UpdateCarrierAccountResponse, Error, UpdateCarrierAccountInput>(async (input) => {
    const result = await axios.put(`/v3/carrier-accounts/${input.id}`, input);
    return result?.data;
  }, opts);

/**
 * Remove a carrier account
 */

export const useRemoveCarrierAccount = () =>
  useMutation(async (id: string) => {
    const result = await axios.delete(`/v3/carrier-accounts/${id}`);
    return result?.data;
  });
