import { defineStyleConfig } from '@chakra-ui/react';

export const drawerTheme = defineStyleConfig({
  defaultProps: {},
  sizes: {
    sm: { rounded: 'sm' },
    md: { fontSize: 'sm', rounded: 'sm' },
    lg: { rounded: 'sm' },
  },
  baseStyle: (props) => ({
    dialog: {
      transition: 'all cubic-bezier(0.23, 1, 0.32, 1) .3s',
      transform: props.isOpen ? 'translateX(0)' : 'translateX(-100%)',
      opacity: props.isOpen ? 1 : 0,
      borderRadius: 'md',
      maxH: '98vh',
      mx: '1vh',
      my: 'auto',
    },
  }),
});
