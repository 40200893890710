import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import axios from '../../config/axios';

export interface UpdateMeter {
  rechargeAmountCents?: number;
  rechargeThresholdCents?: number;
  primaryPaymentMethodId?: string | null;
  secondaryPaymentMethodId?: string | null;
  isActive?: boolean;
}

export interface UpdateMeterResponse {
  id: number;
  sellerId: number;
  rechargeAmountCents: number;
  rechargeThresholdCents: number;
  isActive: boolean;
  primaryPaymentMethod: {
    id: string | null;
    type: string | null;
    card: {
      lastFour: string | null;
      brand: string | null;
      expirationMonth: number | null;
      expirationYear: number | null;
    };
  };
  secondaryPaymentMethod: {
    id: string | null;
    type: string | null;
    card: {
      lastFour: string | null;
      brand: string | null;
      expirationMonth: number | null;
      expirationYear: number | null;
    };
  };
}

type ErrorResponse = AxiosError<{ message?: string; error?: string }>;

const updateMeter = async (vars: UpdateMeter) => {
  const { data } = await axios.put('/v3/user/meters', vars);
  return data;
};

const useUpdateMeter = (opts?: UseMutationOptions<UpdateMeterResponse, ErrorResponse, UpdateMeter>) =>
  useMutation<UpdateMeterResponse, ErrorResponse, UpdateMeter>(updateMeter, opts);

export default useUpdateMeter;
