import { Box, HStack, Icon, Tag } from '@chakra-ui/react';
import { useGetPaymentMethods } from 'api/payments';
import { chakraComponents, Select } from 'chakra-react-select';
import { FC, useEffect, useState } from 'react';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import { FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa, FaLandmark } from 'react-icons/fa';
import { chakraReactSelectStyles } from 'theme';
import { PaymentCard } from 'types/payment';

interface PaymentSelectProps {
  selectPaymentMethodId: (methodId: string) => void;
}

const customComponents = {
  Option: ({ children, ...props }: any) => (
    <chakraComponents.Option {...props}>
      <HStack justify="space-between" w="full">
        <Box>
          <Icon as={props.data.icon} mr={2} h={5} w={5} />
          {children}
        </Box>
        <Box>
          {props.data.isPrimary && (
            <Tag size="sm" colorScheme="green">
              Primary
            </Tag>
          )}
          {props.data.isSecondary && (
            <Tag size="sm" colorScheme="blue">
              Secondary
            </Tag>
          )}
        </Box>
      </HStack>
    </chakraComponents.Option>
  ),
};

const getCardIcon = (paymentCard: PaymentCard | null) => {
  if (paymentCard?.type === 'BankAccount') {
    return FaLandmark;
  }
  switch (paymentCard?.card?.brand?.toLowerCase()) {
    case 'amex':
      return FaCcAmex;
    case 'discover':
      return FaCcDiscover;
    case 'mastercard':
      return FaCcMastercard;
    case 'visa':
      return FaCcVisa;
    default:
      return BsFillCreditCard2FrontFill;
  }
};

export const PaymentSelect: FC<PaymentSelectProps> = ({ selectPaymentMethodId }) => {
  const { data: paymentMethods } = useGetPaymentMethods();
  const [paymentOptions, setPaymentOptions] = useState<PaymentCard[]>([]);
  const [value, setValue] = useState({ value: '', label: '' });

  useEffect(() => {
    if (!paymentMethods || !paymentMethods?.length) return;
    setPaymentOptions(paymentMethods);
    setValue({
      value: paymentMethods[0].id,
      label: `${paymentMethods[0].card.brand?.toUpperCase() || paymentMethods[0].card.issuer?.toUpperCase()} *${
        paymentMethods[0].card.lastFour
      }`,
    });
    selectPaymentMethodId(paymentMethods[0].id);
  }, [paymentMethods]);

  return (
    <Select
      placeholder="Select..."
      useBasicStyles
      isSearchable={false}
      components={customComponents}
      value={value}
      chakraStyles={chakraReactSelectStyles}
      options={paymentOptions.map((paymentMethod) => ({
        label: `${
          paymentMethod.card.brand?.toUpperCase() || paymentMethod.card.issuer?.toUpperCase()?.substring(0, 10)
        } *${paymentMethod.card.lastFour}`,
        value: paymentMethod.id,
        isPrimary: paymentMethod.isPrimary,
        isSecondary: paymentMethod.isSecondary,
        icon: getCardIcon(paymentMethod),
      }))}
      onChange={(option: any) => {
        selectPaymentMethodId(option.value);
        setValue({ value: option.value, label: option.label });
      }}
    />
  );
};
