import {
  Button,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tag,
  useDisclosure,
  VStack,
  Text,
  HStack,
  Center,
  PopoverBody,
} from '@chakra-ui/react';
import { noop, range } from 'lodash';
import { FC, useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

// USPS Zones
const zones = range(1, 10);

type Props = {
  selectedZones: number[];
  onSelect: (selectedZones: number[]) => void;
};

export const ZoneSelectPopover: FC<Props> = (props) => {
  const { onSelect = noop, selectedZones: s = [] } = props;
  const [selectedZones, setSelectedZones] = useState<number[]>(s);
  const { isOpen } = useDisclosure();

  const onSelectZone = (zone: number, checked: boolean) => {
    let selected = [...selectedZones];

    if (checked) selected.push(zone);
    else selected = [...selected.filter((z) => z !== zone)];

    setSelectedZones([...selected]);
    onSelect(selected);
  };

  const onClearSelected = () => {
    setSelectedZones([]);
    onSelect([]);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="outline" size="sm" rightIcon={isOpen ? <FaCaretUp /> : <FaCaretDown />}>
          {selectedZones.length > 0 && (
            <Tag size="sm" mr={1}>
              {selectedZones.length}
            </Tag>
          )}
          Zone
        </Button>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverBody p={5}>
          <VStack>
            {zones.map((z) => (
              <HStack key={z}>
                <Checkbox
                  value={z}
                  isChecked={selectedZones.includes(z)}
                  onChange={(event) => onSelectZone(z, event.target.checked)}
                />
                <Text>Zone {z}</Text>
              </HStack>
            ))}
          </VStack>
          <Center mt={3}>
            <Button isDisabled={!selectedZones.length} variant="outline" size="sm" onClick={onClearSelected}>
              Clear
            </Button>
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
