import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  chakra,
  Text,
} from '@chakra-ui/react';
import { GetFileMappingsResponse, useCreateFileMapping } from 'api/file-mappings';
import { useGetOrderFile } from 'api/orders';
import { PageBody, PageHeader } from 'components/Page';
import { useNotification } from 'contexts/notification.context';
import { useState } from 'react';
import { FiArrowRight, FiCheck, FiChevronDown, FiFile } from 'react-icons/fi';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'router';

const fields = [
  // Order
  { name: 'Order Number', value: 'orderNumber', required: true },

  // "From" is disabled for now. We are using the user's primary address.
  // From
  // { name: 'From - Name', value: 'fromName', required: false },
  // { name: 'From - Company', value: 'fromCompany', required: false },
  // { name: 'From - Line 1', value: 'fromLine1', required: false },
  // { name: 'From - Line 2', value: 'fromLine2', required: false },
  // { name: 'From - City', value: 'fromCity', required: false },
  // { name: 'From - State', value: 'fromState', required: false },
  // { name: 'From - Zip', value: 'fromZip', required: false },
  // { name: 'From - Phone', value: 'fromPhone', required: false },
  // { name: 'From - Email', value: 'fromEmail', required: false },

  // To
  { name: 'To - Name', value: 'toName', required: true },
  { name: 'To - Company', value: 'toCompany', required: false },
  { name: 'To - Line 1', value: 'toLine1', required: true },
  { name: 'To - Line 2', value: 'toLine2', required: false },
  { name: 'To - City', value: 'toCity', required: true },
  { name: 'To - State', value: 'toState', required: true },
  { name: 'To - Zip', value: 'toZip', required: true },
  // @TODO: Add support for Intl?
  // { name: 'To - Country', value: 'toCountry', required: true },
  { name: 'To - Phone', value: 'toPhone', required: false },
  { name: 'To - Email', value: 'toEmail', required: false },

  // Parcel
  { name: 'Parcel - Weight (oz)', value: 'parcelWeight', required: false },
  { name: 'Parcel - Width', value: 'parcelWidth', required: false },
  { name: 'Parcel - Length', value: 'parcelLength', required: false },
  { name: 'Parcel - Height', value: 'parcelHeight', required: false },

  // Shipment
  { name: 'Carrier', value: 'carrier', required: false },
  { name: 'Service', value: 'service', required: false },
  { name: 'Insurance', value: 'insurance', required: false },
  { name: 'Label Date', value: 'labelDate', required: false },

  // Product
  { name: 'Product - Name', value: 'productName', required: false },
  { name: 'Product - SKU', value: 'productSku', required: false },
  { name: 'Product - Quantity', value: 'productQuantity', required: false },

  // Options
  { name: 'Options - Label Format', value: 'labelFormat', required: false, description: `Available options: PDF, ZPL` },
];

export default function () {
  const params = useParams('/orders/files/:id/mapping');
  const location = useLocation();
  const navigate = useNavigate();

  const { notifySuccess } = useNotification();

  // Pass the mapping in the state to load this page in "edit mode"
  const m = location?.state?.mapping as GetFileMappingsResponse[0] | undefined;

  const [mapping, setMapping] = useState<{ [key: string]: string }>(m?.data || {});

  const [mappingName, setMappingName] = useState<string>('');
  const [separator, setSeparator] = useState<string>(m?.separator || ';');

  const addMapping = (header: string, field: string) => {
    const newMapping = { ...mapping };
    Object.keys(newMapping).forEach((key) => {
      if (mapping[key] === field && key !== header) delete newMapping[key];
    });
    newMapping[header] = field;
    setMapping(newMapping);
  };

  const isMapped = (field: string) => {
    return !!Object.values(mapping).find((ma) => ma === field);
  };

  const orderFile = useGetOrderFile({ id: Number(params.id), separator }, { enabled: !!params!.id });
  const createFileMapping = useCreateFileMapping();

  const isValid = () => {
    const isNewMapping = !m;
    const hasAllRequired = fields
      .filter((field) => field.required)
      .map((field) => field.value)
      .every(isMapped);

    return isNewMapping ? hasAllRequired && !!mappingName : hasAllRequired;
  };

  const onSave = () => {
    createFileMapping.mutate(
      { name: mappingName, separator, mapping },
      {
        onSuccess: () => {
          notifySuccess('Mapping created successfully');
          navigate('/orders/import');
        },
      },
    );
  };

  const loadingMarkup = orderFile.isFetching && (
    <Card h="lg">
      <CardBody>
        <Center h="full">
          <Spinner />
        </Center>
      </CardBody>
    </Card>
  );

  const defaultMarkup = (
    <Card>
      <CardHeader>
        <SimpleGrid columns={3}>
          <HStack>
            <FiFile color="gray" />
            <Text fontWeight="semibold">{orderFile.data?.name}</Text>
          </HStack>
          <Center>
            <FiArrowRight color="gray" />
          </Center>
          <Text mx="auto" fontWeight="semibold">
            VESYL
          </Text>
        </SimpleGrid>
      </CardHeader>
      <Divider />
      <CardBody>
        <Stack>
          {orderFile.data?.headers?.map((header, index) => {
            return (
              <FormControl key={index}>
                <SimpleGrid rounded="lg" spacing="0" columns={3} bg={!!mapping[header.name] ? 'green.50' : undefined}>
                  <Stack pl="2" py="2">
                    <HStack spacing="1">
                      <FormLabel marginInlineEnd="0" mb="0">
                        {header.name}
                      </FormLabel>
                      {!!mapping[header.name] && <Icon as={FiCheck} color="green.500" />}
                    </HStack>
                    <Text fontStyle="italic" fontSize="sm" color="gray.500">
                      {header.examples.filter(Boolean).slice(0, 3).join(', ') || '-'}
                    </Text>
                  </Stack>

                  <Center>
                    <FiArrowRight color="gray" />
                  </Center>

                  <Flex alignItems="center" pr="2">
                    <Menu closeOnSelect={false} closeOnBlur placement="bottom" isLazy>
                      <MenuButton
                        w="full"
                        mx="auto"
                        size="sm"
                        as={Button}
                        color={mapping[header.name] ? 'green.500' : undefined}
                        variant="outline"
                        fontWeight="normal"
                        rightIcon={<FiChevronDown />}
                      >
                        {fields.find((f) => f.value === mapping[header.name])?.name || 'Select field'}
                      </MenuButton>
                      <MenuList transform="none !important" fontSize="sm" w="fit-content" maxH="xs" overflow="auto">
                        <Text py="1" px="4" fontWeight="semibold">
                          Required
                        </Text>
                        {fields
                          .filter((field) => field.required)
                          .map((field) => (
                            <MenuItemOption
                              isDisabled={isMapped(field.value)}
                              key={field.value}
                              value={field.value}
                              onClick={() => addMapping(header.name, field.value)}
                            >
                              {field.name}
                            </MenuItemOption>
                          ))}
                        <Text py="1" px="4" fontWeight="semibold">
                          Optional
                        </Text>
                        {fields
                          .filter((field) => !field.required)
                          .map((field) => (
                            <MenuItemOption
                              key={field.value}
                              value={field.value}
                              isDisabled={isMapped(field.value)}
                              onClick={() => addMapping(header.name, field.value)}
                            >
                              <div> {field.name}</div>
                              {field.description && (
                                <div>
                                  <chakra.small color="muted">{field.description}</chakra.small>
                                </div>
                              )}
                            </MenuItemOption>
                          ))}
                      </MenuList>
                    </Menu>
                  </Flex>
                </SimpleGrid>
              </FormControl>
            );
          })}
        </Stack>

        <Box border="1px" borderColor="gray.200" bg="gray.50" rounded="md" p="4" mt="6" hidden={!!m}>
          <FormControl>
            <FormLabel>Mapping name</FormLabel>
            <Input
              onChange={(e) => setMappingName(e.target.value)}
              value={mappingName || ''}
              placeholder="e.g. Daily Import"
            />
            <FormHelperText>
              Use this name to identify this mapping in the future. It will be displayed in the list of mappings.
            </FormHelperText>
          </FormControl>
        </Box>
      </CardBody>
      <CardFooter>
        <Button
          isLoading={createFileMapping.isLoading}
          ml="auto"
          colorScheme="brand"
          onClick={onSave}
          isDisabled={!isValid()}
        >
          Save
        </Button>
      </CardFooter>
    </Card>
  );

  return (
    <>
      <PageHeader>
        <Heading as="h1">New Mapping</Heading>
      </PageHeader>
      <PageBody>
        {orderFile.data?.headers && orderFile.data?.headers?.length <= 2 && (
          <Alert status="warning" mb="4">
            <Stack spacing="1">
              <AlertTitle mr={2}>Potential separator issue</AlertTitle>
              <AlertDescription>
                It looks like you are using the wrong separator character ({separator}). Try choosing a different
                separator below.
              </AlertDescription>
            </Stack>
          </Alert>
        )}
        <Card mb="4">
          <CardBody>
            <HStack>
              <FormLabel>Separator</FormLabel>
              <Select maxW="40" value={separator} onChange={(e) => setSeparator(e.target.value)}>
                {[
                  { name: 'Comma', value: ',' },
                  { name: 'Semicolon', value: ';' },
                  { name: 'Pipe', value: '|' },
                  { name: 'Tab', value: '\t' },
                ].map((s) => (
                  <option key={s.value} value={s.value}>
                    {s.name} ({s.value})
                  </option>
                ))}
              </Select>
            </HStack>
          </CardBody>
        </Card>

        {loadingMarkup || defaultMarkup}
      </PageBody>
    </>
  );
}
