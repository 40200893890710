import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { paths } from 'api/types';
import axios from '../../config/axios';

export type CreateProductsProductBody = paths['/api/v3/products']['post']['requestBody']['content']['application/json'];
export type CreateProductResponse =
  paths['/api/v3/products']['post']['responses']['201']['content']['application/json'];

type Error = AxiosError<{}>;

export const useCreateProduct = (opts?: UseMutationOptions<CreateProductResponse, Error, CreateProductsProductBody>) =>
  useMutation<CreateProductResponse, Error, CreateProductsProductBody>(async (input) => {
    const payload = input;

    const response = await axios.post<CreateProductResponse>(`/v3/products`, payload);
    return response?.data;
  }, opts);
