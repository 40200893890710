import startCase from 'lodash/startCase';
import { ActionType, BooleanCondition, NumberCondition, SelectCondition, StringCondition } from 'types/automation';

export function parseOperator(operator: string) {
  switch (operator) {
    case 'eq':
      return 'Equals...';
    case 'neq':
      return 'Does not equal...';
    case 'gt':
      return 'Greater than...';
    case 'gte':
      return 'Greater than or equal to...';
    case 'lt':
      return 'Less than...';
    case 'lte':
      return 'Less than or equal to...';
    case 'contains':
      return 'Contains...';
    case 'not_contains':
      return 'Does not contain...';
    case 'starts_with':
      return 'Starts with...';
    case 'not_starts_with':
      return 'Does not start with...';
    case 'ends_with':
      return 'Ends with...';
    case 'in':
      return 'In...';
    case 'not_in':
      return 'Not in...';
    default:
      return '';
  }
}

export function parseConditionField(
  field: NumberCondition | StringCondition | SelectCondition | BooleanCondition | 'custom',
) {
  switch (field) {
    case NumberCondition.AmountLineItems:
      return '# of Line Items';
    case NumberCondition.WeightLineItems:
      return 'Total Line Item Weight (oz)';
    case NumberCondition.AmountProducts:
      return 'Product Quantity';
    case NumberCondition.OrderTotal:
      return 'Order Total';
    case NumberCondition.OrderValue:
      return 'Order Value';
    case NumberCondition.OrderWeightOz:
      return 'Total Order Weight (oz)';
    case NumberCondition.USPSZone:
      return 'USPS Zone';
    case StringCondition.ProductName:
      return 'Product Name';
    case StringCondition.CustomerName:
      return 'Customer Name';
    case StringCondition.ProductSKU:
      return 'Product SKU';
    case StringCondition.ToCity:
      return 'City';
    case BooleanCondition.IsDomestic:
      return 'Is Domestic';
    case SelectCondition.Platform:
      return 'Platform (Marketplace)';
    case SelectCondition.StoreName:
      return 'Store';
    case SelectCondition.ToCountry:
      return 'Country';
    case SelectCondition.ToState:
      return 'State';
    case SelectCondition.AllTags:
      return 'Has All Tags';
    case SelectCondition.SomeTags:
      return 'Has Some Tags';
    case SelectCondition.SomeProductsTags:
      return 'Has Some Products Tags';
    case SelectCondition.OrderStatus:
      return 'Status';
    case BooleanCondition.HasSelectedRate:
      return 'Has Rate Selected';
    case BooleanCondition.HasVerifiedToAddress:
      return 'Has Verified Destination Address';
    default:
      return startCase(field);
  }
}

export function parseAction(action: ActionType) {
  switch (action) {
    case ActionType.SetInsuranceAmount:
      return 'Set Insurance...';
    case ActionType.SetDimensions:
      return 'Set Dimensions...';
    case ActionType.SetWeight:
      return 'Set Weight...';
    case ActionType.SkipPlatformNotification:
      return 'Do Not Notify Customer';
    case ActionType.SkipImport:
      return 'Do Not Import';
    case ActionType.SetBox:
      return 'Set Box...';
    case ActionType.SetCustomBox:
      return 'Set Custom Box...';
    case ActionType.SetFromAddress:
      return 'Set Recipient Address...';
    case ActionType.SetRate:
      return 'Set Rate...';
    case ActionType.SetLowestRate:
      return 'Set Lowest Rate...';
    case ActionType.SetTags:
      return 'Set Tags...';
    case ActionType.SetResidential:
      return 'Set Verified Destination Address As Residential...';
    default:
      return startCase(action);
  }
}
