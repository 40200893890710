import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { DeleteLoginsLoginIdParams } from 'types/api-types';

type Data = {};
type Variables = DeleteLoginsLoginIdParams;
type Error = AxiosError<{}>;

export const useRemoveLogin = (opts?: UseMutationOptions<Data, Error, Variables>) =>
  useMutation<Data, Error, Variables>(async (input) => {
    const { loginId } = input;
    const response = await axios.delete(`/v3/logins/${loginId}`);
    return response?.data;
  }, opts);
