import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';

type UpdateSystemBox = {
  id: string;
  isEnabled: boolean;
};
export type UpdateSystemBoxResponse = {
  id: number;
  name: string;
  carrier: string;
  length: number;
  width: number;
  height: number;
  isEnabled: boolean;
};
export type UpdateSystemBoxError = AxiosError<{ message: string }>;

export const updateSystemBox = async ({ id, ...vars }: UpdateSystemBox) => {
  const { data } = await axios.put(`/v3/boxes/system/${id}`, vars);
  return data;
};

const useUpdateSystemBox = (
  opts?: UseMutationOptions<UpdateSystemBoxResponse, UpdateSystemBoxError, UpdateSystemBox>,
) => useMutation<UpdateSystemBoxResponse, UpdateSystemBoxError, UpdateSystemBox>(updateSystemBox, opts);

export default useUpdateSystemBox;
