import { paths } from 'api/types';
import axios from 'config/axios';
import { UseMutationOptions, useMutation, useQuery } from 'react-query';

/**
 * Get Open Manifests
 */
export type GetOpenManifestsResponse =
  paths['/api/v3/manifests/open']['get']['responses']['200']['content']['application/json'];
export type GetOpenManifestsInput = paths['/api/v3/manifests/open']['get']['parameters']['query'];

export const GET_OPEN_MANIFESTS_QUERY = 'getOpenManifests';

export const useGetOpenManifests = (input: GetOpenManifestsInput) =>
  useQuery<GetOpenManifestsResponse>([GET_OPEN_MANIFESTS_QUERY, input], async () => {
    const response = await axios.get(`/v3/manifests/open`, { params: input });
    return response.data;
  });

/**
 * Get  Manifests
 */
export type GetManifestsResponse = paths['/api/v3/manifests']['get']['responses']['200']['content']['application/json'];
export type GetManifestsQuery = paths['/api/v3/manifests']['get']['parameters']['query'];

export const GET_MANIFESTS_QUERY = 'getManifests';

export const useGetManifests = () =>
  // @ts-ignore
  useQuery<GetManifestsResponse>([GET_MANIFESTS_QUERY], async () => {
    const result = await axios.get(`/v3/manifests`);
    return result?.data;
  });

export const useGetManifestsAxios = async (input?: GetManifestsQuery) => {
  if (!input) return null;

  const { data } = await axios.get(`/v3/manifests`, { params: { ...input } });
  return data;
};

/**
 * Create Manifest
 */

export type CreateManifestInput = paths['/api/v3/manifests']['post']['requestBody']['content']['application/json'];
export type CreateManifestResponse =
  paths['/api/v3/manifests']['post']['responses']['200']['content']['application/json'];

export const useCreateManifest = (opts?: UseMutationOptions<CreateManifestResponse, Error, CreateManifestInput>) =>
  // @ts-ignore
  useMutation<CreateManifestResponse, Error, CreateManifestInput>(async (input) => {
    return axios.post(`/v3/manifests`, input).then((res) => res?.data);
  }, opts);
