import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetQuery } from 'api/client';
import { PageBody, PageHeader } from 'components/Page';
import { countBy, orderBy, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { HiOutlineTruck } from 'react-icons/hi';
import {
  LuClipboard,
  LuClipboardCheck,
  LuDollarSign,
  LuEye,
  LuPencil,
  LuPrinter,
  LuXCircle,
} from 'react-icons/lu';
import useClipboard from 'react-use-clipboard';
import { useNavigate, useParams } from 'router';
import { toUSD } from 'utils/currency';
import { dayjs } from 'utils/dates';
import { PrintLabelModal } from '../../../../components/print-label-modal';
import { RefundLabelModal } from '../../shipments/_components/refund-modal';
import { EditBatchModal } from '../_components/edit-batch-modal';

const batchStatusColors = {
  completed: 'green',
  completed_with_errors: 'green',
  failed: 'red',
  pending: 'gray',
  default: 'gray',
};

export default function () {
  const navigate = useNavigate();
  const params = useParams('/orders/batches/:id');
  const batchId = Number(params.id);

  const refundModal = useDisclosure();
  const printLabelModal = useDisclosure();
  const editBatchModal = useDisclosure();

  const batch = useGetQuery('/api/v3/batch/{id}', {
    params: { path: { id: String(batchId) } },
    rq: { refetchInterval: 4000 },
  });

  const [copiedText, setCopiedText] = useState('');
  const [selectedShipmentId, setSelectedShipmentId] = useState<number | null>(null);

  const [isCopied, setCopied] = useClipboard(copiedText, { successDuration: 1400 });

  const grouped = countBy(batch.data?.jobs, 'status');
  const zpl = batch.data?.jobs?.some((job) => job?.order?.shipments?.[0]?.isZplLabel) || false;
  const shipmentIds =
    (batch.data?.jobs?.map((job) => job?.order?.shipments?.[0]?.id || null).filter(Boolean) as number[]) ||
    [];

  const handleRetryQuickShip = (order: { id: number }) => {
    navigate(`/ship`, { state: { order } });
  };

  // Required to trigger copying the invitation link
  useEffect(() => {
    if (!copiedText) return;
    setCopied();
  }, [copiedText]);

  useEffect(() => {
    if (!editBatchModal.isOpen) batch.refetch();
  }, [editBatchModal.isOpen]);

  return (
    <>
      <PageHeader>
        <Heading as="h1">{batch?.data?.displayName}</Heading>

        <HStack ml="auto">
          <Button variant={'outline'} gap={2} onClick={editBatchModal.onOpen}>
            <LuPencil /> Edit
          </Button>
          <Button leftIcon={<LuPrinter />} variant="primary" onClick={printLabelModal.onOpen}>
            Print {grouped.completed || ''} Labels
          </Button>
        </HStack>
      </PageHeader>

      <PageBody>
        <Card mb="4">
          <CardBody>
            <HStack gap="20" alignItems="start" pr="8" w="full">
              {/* Created At */}
              <Stack>
                <Text fontWeight="semibold">Created</Text>
                <Text fontSize="sm" color="muted">
                  {dayjs(batch?.data?.createdAt).fromNow()}
                </Text>
              </Stack>

              <Stack>
                <Text fontWeight="semibold">Status</Text>
                {/* @ts-ignore */}
                <Tag size="sm" colorScheme={batchStatusColors[(batch.data?.status as string) || 'default']}>
                  {startCase(batch.data?.status || '---')}
                </Tag>
              </Stack>

              {/* # of labels purchased */}
              <Stack ml="auto">
                <Text fontWeight="semibold">Labels</Text>
                <HStack>
                  <Text fontSize="xl">{grouped.completed || '-'}</Text>
                  <Text fontSize="xs" color="muted">
                    / {batch.data?.jobs?.length}
                  </Text>
                </HStack>
              </Stack>

              {/* Total $ */}
              <Stack>
                <Text fontWeight="semibold">Total</Text>
                <Text fontSize="sm" color="muted">
                  {batch?.data?.totalCents ? toUSD(batch?.data?.totalCents / 100) : '---'}
                </Text>
              </Stack>
            </HStack>
          </CardBody>
        </Card>

        <TableContainer rounded="lg" outline="1px solid" outlineColor="gray.200">
          <Table size="sm" my={-0.5}>
            <Thead>
              <Tr>
                {['Order', 'To', 'Tracking Code', 'Status', 'Actions'].map((label) => (
                  <Th py="3" key={label}>
                    {label}
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {orderBy(batch?.data?.jobs, 'status', 'desc')?.map((job) => {
                const order = job.order;
                const shipment = job.order.shipments[0];
                const hasError = job.errors.length > 0;

                if (!shipment) return null;

                const isTrackingCopied = isCopied && copiedText === shipment?.trackingCode;

                return (
                  <Tr key={job.id}>
                    <Td>{order.platformId}</Td>

                    {/* To Address */}
                    <Td>
                      <Stack>
                        <Text fontWeight="medium">{order.to.name}</Text>
                        <Text fontSize="xs" maxW="52" color="muted" isTruncated>
                          {order.to.line1}, {order.to.city}, {order.to.state}
                        </Text>
                      </Stack>
                    </Td>

                    {/* Tracking Code */}
                    <Td>
                      <HStack hidden={!shipment?.trackingCode}>
                        <Text color={isTrackingCopied ? 'green.500' : undefined}>
                          {shipment?.trackingCode || ''}
                        </Text>
                        <Button
                          variant="outline"
                          size="xs"
                          onClick={() => setCopiedText(shipment?.trackingCode || '')}
                          color={isTrackingCopied ? 'green.500' : 'gray.500'}
                        >
                          {isTrackingCopied ? <LuClipboardCheck /> : <LuClipboard />}
                        </Button>
                      </HStack>
                    </Td>

                    {/* Status */}
                    <Td>
                      <Popover isLazy>
                        <PopoverTrigger>
                          <Tag
                            _hover={{ cursor: 'pointer' }}
                            size="sm"
                            colorScheme={
                              {
                                completed: 'green',
                                failed: 'red',
                                pending: 'gray',
                                default: 'gray',
                              }[(job.status as string) || 'default']
                            }
                          >
                            {startCase(job.status)}
                          </Tag>
                        </PopoverTrigger>
                        {job.errors.length > 0 && (
                          <PopoverContent w="min-content">
                            <PopoverBody pb="4">
                              <Stack>
                                <Text fontWeight="semibold">Errors</Text>
                                {job.errors.map((err, index) => (
                                  <HStack spacing={1} mt="2" key={index}>
                                    <Icon as={LuXCircle} color="red.600" />
                                    <Text color="muted">{err.message}</Text>
                                  </HStack>
                                ))}
                              </Stack>
                            </PopoverBody>
                          </PopoverContent>
                        )}
                      </Popover>
                    </Td>

                    {/* Actions */}
                    <Td>
                      {/* Failed imports */}
                      <HStack hidden={!hasError}>
                        <Button
                          title="Retry on Quick Ship"
                          isDisabled={!shipment?.id}
                          size="xs"
                          variant="outline"
                          onClick={() => handleRetryQuickShip(order)}
                        >
                          <HiOutlineTruck />
                        </Button>
                      </HStack>

                      {/* Successful imports */}
                      <HStack hidden={hasError}>
                        <Button
                          title="Refund Label"
                          isDisabled={!shipment.canRefund}
                          size="xs"
                          variant="outline"
                          onClick={() => {
                            setSelectedShipmentId(shipment.id);
                            refundModal.onOpen();
                          }}
                        >
                          <LuDollarSign />
                        </Button>
                        <Button
                          title="View Shipment"
                          variant="outline"
                          size="xs"
                          onClick={() => navigate(`/shipments/:id`, { params: { id: `${shipment.id}` } })}
                        >
                          <LuEye />
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </PageBody>

      {editBatchModal.isOpen && batch.data && <EditBatchModal {...editBatchModal} batch={batch.data} />}

      {printLabelModal.isOpen && (
        <PrintLabelModal
          zpl={zpl}
          isOpen={printLabelModal.isOpen}
          onClose={printLabelModal.onClose}
          shipmentIds={shipmentIds || []}
        />
      )}

      {selectedShipmentId && (
        <RefundLabelModal
          {...refundModal}
          shipmentId={selectedShipmentId}
          onSuccess={() => setSelectedShipmentId(null)}
        />
      )}
    </>
  );
}
