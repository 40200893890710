import { Box, Input } from '@chakra-ui/react';
import { Combobox } from '@headlessui/react';
import { ALL_STATES as allStates } from 'constants/states';
import { forwardRef, useState } from 'react';
import { ComboboxOption, ComboboxOptions } from './util';

interface StateSelectProps {
  onChange: (value: any) => void;
  value: any;
}

export const StateSelect = forwardRef((props: StateSelectProps, ref: any) => {
  const { onChange, value } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const val =
    allStates.find((o) => o.value === value) ||
    allStates.find((o) => o.label.toLowerCase() === value?.toLowerCase()) ||
    '';

  return (
    <Combobox as="div" value={val} onChange={(v: { value: string; label: string }) => onChange(v.value)}>
      <Combobox.Input
        ref={ref}
        as={Input}
        placeholder=""
        onChange={(e) => setSearchQuery(e.target.value)}
        displayValue={(state: any) => (state ? state.label : '')}
      />
      <Combobox.Options as={ComboboxOptions} style={{ listStyle: 'none' }}>
        {allStates
          .filter(
            (c) =>
              c.label.toLowerCase().includes((searchQuery || '').toLowerCase()) ||
              c.value.toLowerCase().includes((searchQuery || '').toLowerCase()),
          )
          .map((state) => (
            <Box key={state.value}>
              <Combobox.Option value={state}>
                {({ selected, active }) => (
                  <ComboboxOption selected={selected || active}>{state.label}</ComboboxOption>
                )}
              </Combobox.Option>
            </Box>
          ))}
      </Combobox.Options>
    </Combobox>
  );
});
