import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';

export type GetZoneResponse = {
  zone: number | null;
};

export type GetZoneInput = {
  fromZip: string;
  toZip: string;
};

export const getZone = async (input?: GetZoneInput) => {
  if (!input) return null;

  const { fromZip, toZip } = input;
  const result = await axios.get(`/v3/usps/zone`, { params: { fromZip, toZip } });
  return result?.data ?? null;
};

export const useGetZone = (input?: GetZoneInput, opts?: UseQueryOptions<any>) =>
  useQuery<GetZoneResponse>(['getZone', input], () => getZone(input), opts ?? {});
