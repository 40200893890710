import { FC } from 'react';
import { toUSD } from 'utils/currency';
import { CarrierLogo } from 'components/CarrierLogo';
import { parseCarrier } from 'utils/misc';
import { Card, CardBody, Flex, HStack, VStack, Text, Box } from '@chakra-ui/react';

type Props = {
  carrier: string;
  service: string;
  details?: Array<string>;
  price: string;
};

export const CarrierRate: FC<Props> = ({ carrier, details, price, service }) => {
  return (
    <Card>
      <CardBody>
        <Flex justifyContent="space-between">
          <Box>
            <HStack spacing={3}>
              <CarrierLogo carrier={carrier} />
              <VStack align="start" spacing={0} gap={0}>
                <Text fontWeight="medium">{service}</Text>
                <Flex>
                  <Text textColor="muted">{parseCarrier(carrier)}</Text>
                  {Array.isArray(details) ? (
                    <Flex>
                      {details.map((detail, index) => (
                        <HStack ml={1} spacing={1} key={index}>
                          <Text textColor="muted">·</Text>
                          <Text textColor="muted">{detail}</Text>
                        </HStack>
                      ))}
                    </Flex>
                  ) : null}
                </Flex>
              </VStack>
            </HStack>
          </Box>
          <Box>
            <Text fontWeight="medium">{toUSD(price)}</Text>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};
