import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { WooCommerceStore } from '../read';

type UpdateStore = {
  storeId: number;
  name?: string;
};

type UpdateStoreResponse = Partial<WooCommerceStore>;

export const updateStore = async (input: UpdateStore) => {
  const { storeId, name } = input;
  const { data } = await axios.put(`/v3/stores/woocommerce/${storeId}`, { name });

  return data;
};

export const useUpdateWooCommerceStore = (opts?: UseMutationOptions<UpdateStoreResponse, {}, UpdateStore>) =>
  useMutation<UpdateStoreResponse, {}, UpdateStore>(updateStore, opts);
