import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type createQRcodeBody = { shipmentId: number };
export type CreateQRcodeResponse = { url: string };
export type CreateQRcodeError = AxiosError<{ message: string }>;

export const createQRcode = async ({ shipmentId }: createQRcodeBody) => {
  const { data } = await axios.post('/v3/shipments/label-qrcode', { shipmentId });
  return data;
};

const useCreateQRcode = (opts?: UseMutationOptions<CreateQRcodeResponse, CreateQRcodeError, createQRcodeBody>) =>
  useMutation<CreateQRcodeResponse, CreateQRcodeError, createQRcodeBody>(createQRcode, opts);

export default useCreateQRcode;
