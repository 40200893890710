import { Badge, Box, Heading, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';

interface StatProps {
  label: string;
  value: string | number;
  delta?: {
    value: string;
    isUpwardsTrend: boolean;
  };
}
export const Stat = (props: StatProps) => {
  const { label, value, delta, ...boxProps } = props;
  return (
    <Box
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
      bg="white"
      borderRadius="lg"
      border="1px"
      borderColor="gray.200"
      {...boxProps}
    >
      <Stack>
        <HStack justify="space-between">
          <Text textStyle="sm" color="fg.muted">
            {label}
          </Text>
          {/* <Icon as={FiMoreVertical} boxSize="5" color="fg.muted" /> */}
        </HStack>
        <HStack justify="space-between">
          <Heading size={{ base: 'sm', md: 'md' }}>{value}</Heading>
          {delta && (
            <Badge variant="pill" colorScheme={delta.isUpwardsTrend ? 'green' : 'red'}>
              <HStack spacing="1">
                <Icon as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight} />
                <Text>{delta.value}</Text>
              </HStack>
            </Badge>
          )}
        </HStack>
      </Stack>
    </Box>
  );
};
