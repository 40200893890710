import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { GetLoginsResponse } from 'types/api-types';

export const getLogins = async () => {
  const result = await axios.get(`/v3/logins`, {});
  return result?.data;
};

export const GET_LOGINS_QUERY = 'getLogins';

export const useGetLogins = (opts?: UseQueryOptions<any>) =>
  useQuery<GetLoginsResponse>([GET_LOGINS_QUERY], () => getLogins(), opts ?? {});
