import { useQuery } from 'react-query';

import axios from '../../config/axios';

export const getPaymentMethods = async () => {
  const { data } = await axios.get(`/v3/user/payment-methods`);
  return data;
};

export const GET_PAYMENT_METHODS_QUERY = 'payment_methods';

const useGetPaymentMethods = () => useQuery(GET_PAYMENT_METHODS_QUERY, getPaymentMethods);

export default useGetPaymentMethods;
