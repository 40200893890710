import { StyleConfig } from '@chakra-ui/react';

const baseStyle = {
  color: 'brand.500',
  _hover: { color: 'brand.600' },
};

export const linkTheme: StyleConfig = {
  baseStyle,
};
