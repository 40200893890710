import { paths } from 'api/types';
import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type CreateLabelZPLBody = paths['/api/v3/shipments/label-zpl']['post']['requestBody']['content']['application/json'];
export type CreateLabelZPLResponse = { url: string };
export type CreateLabelZPLError = AxiosError<{ message: string }>;

export const useCreateLabelZPL = (
  opts?: UseMutationOptions<CreateLabelZPLResponse, CreateLabelZPLError, CreateLabelZPLBody>,
) =>
  useMutation<CreateLabelZPLResponse, CreateLabelZPLError, CreateLabelZPLBody>(
    async ({ shipmentIds, packingList }: CreateLabelZPLBody) => {
      const { data } = await axios.post('/v3/shipments/label-zpl', { shipmentIds, packingList });
      return data;
    },
    opts,
  );
