import { paths } from 'api/types';
import axios from 'config/axios';
import { UseQueryOptions, useQuery } from 'react-query';

export type GetAnalyticsResponse = paths['/api/v3/analytics']['get']['responses']['200']['content']['application/json'];
export type GetAnalyticsQuery = paths['/api/v3/analytics']['get']['parameters']['query'];

export const GET_ANALYTICS_QUERY = 'getAnalytics';

export const useGetAnalytics = (input: GetAnalyticsQuery, opts: UseQueryOptions<GetAnalyticsResponse>) =>
  useQuery<GetAnalyticsResponse>(
    [GET_ANALYTICS_QUERY, input],
    async () => {
      const result = await axios.get(`/v3/analytics`, { params: input });
      return result?.data;
    },
    opts,
  );
