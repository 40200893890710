import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GET_ME_QUERY } from 'api/auth';
import { useGetMeterBalance } from 'api/meters';
import { GET_PAYMENT_LOGS_QUERY, useAddFunds, useGetPaymentMethods } from 'api/payments';
import { PaymentSelect } from 'components/new/payment-dropdown/PaymentSelect';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiPlus, FiX } from 'react-icons/fi';
import { centsToDollarsWithCommas } from 'utils/currency';
import { getStripeRadarSession } from 'utils/stripe';
import { z } from 'zod';

const schema = z.object({
  rechargeAmount: z.coerce
    .number({ required_error: '', invalid_type_error: '' })
    .min(20, { message: 'Amount must be at least $20' }),
});

type FormValues = z.infer<typeof schema>;

interface PropTypes {
  balance: any;
  defaultToForm?: boolean;
}

export const BalanceTotal: FC<PropTypes> = ({ balance, defaultToForm = false }) => {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>('');
  const { data: paymentMethods } = useGetPaymentMethods();

  const [showAddFundsForm, setShowAddFundsForm] = useState<boolean>(defaultToForm);

  const { refetch: refetchMeter } = useGetMeterBalance();

  const { notifySuccess } = useNotification();
  const [radarSessionId, setRadarSessionId] = useState<string>();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: { rechargeAmount: 25 },
  });
  const { handleSubmit, formState, register, setValue, watch, reset } = form;
  const watchRechargeAmount = watch('rechargeAmount');

  useEffect(() => {
    const getRadarSession = async () => {
      const radarSession = await getStripeRadarSession();
      setRadarSessionId(radarSession?.id);
    };
    getRadarSession();
  }, []);

  useEffect(() => {
    if (!showAddFundsForm) reset();
  }, [showAddFundsForm]);

  const addFunds = useAddFunds({
    onSuccess: () => {
      refetchMeter();
      queryClient.invalidateQueries(GET_PAYMENT_LOGS_QUERY);
      notifySuccess(`$${Number(watchRechargeAmount).toFixed(2)} added to balance`);
      setShowAddFundsForm(false);
    },
  });

  const onSubmit = (e: FormValues) => {
    if (!selectedPaymentMethodId || selectedPaymentMethodId === '') return;
    const amountCents = e.rechargeAmount * 100;
    const returnURL = 'https://app.vesyl.com';
    addFunds.mutate(
      { paymentMethodId: selectedPaymentMethodId, amountCents, returnURL, session: radarSessionId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_ME_QUERY);
        },
      },
    );
  };

  return (
    <Box w="full" justifyContent="center">
      {showAddFundsForm ? (
        <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
          <VStack align="left" spacing={2}>
            <HStack justify="space-between" pb="2">
              <Text fontSize="lg" fontWeight="semibold">
                Add funds
              </Text>
              <IconButton
                fontSize="lg"
                variant="ghost"
                size="sm"
                aria-label="Close"
                icon={<FiX />}
                onClick={() => {
                  setShowAddFundsForm(false);
                }}
              />
            </HStack>
            <FormControl isInvalid={!!formState.errors.rechargeAmount}>
              <InputGroup>
                <InputLeftElement>$</InputLeftElement>
                <Input type="number" disabled={addFunds.isLoading} {...register('rechargeAmount')} />
                <InputRightElement color="muted" mr={2}>
                  USD
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formState?.errors?.rechargeAmount?.message}</FormErrorMessage>
            </FormControl>
            <ButtonGroup isAttached variant="outline">
              {[25, 50, 100, 200].map((amount) => (
                <Button
                  key={amount}
                  px={5}
                  w="full"
                  colorScheme="gray"
                  onClick={() => setValue('rechargeAmount', amount)}
                  bg={watchRechargeAmount === amount ? 'gray.100' : 'white'}
                  disabled={addFunds.isLoading}
                >
                  ${amount}
                </Button>
              ))}
            </ButtonGroup>
            <PaymentSelect selectPaymentMethodId={setSelectedPaymentMethodId} />
            <Button
              isDisabled={addFunds.isLoading || !selectedPaymentMethodId}
              variant="solid"
              type="submit"
              colorScheme="brand"
            >
              Confirm (${watchRechargeAmount})
            </Button>
          </VStack>
        </chakra.form>
      ) : (
        <Box justifyContent="center">
          <VStack spacing={6}>
            <HStack>
              <Text color="muted" fontSize="lg" fontWeight={600}>
                $
              </Text>
              <HStack spacing={0}>
                <Text
                  fontFamily="heading"
                  fontSize={String(Math.round(balance / 100)).length > 4 ? '3xl' : '5xl'}
                  fontWeight={800}
                >
                  {centsToDollarsWithCommas(balance)}
                </Text>
              </HStack>
              <Text color="muted" fontSize="lg" fontWeight={600} isTruncated>
                USD
              </Text>
            </HStack>
            <Button
              type="button"
              colorScheme="brand"
              mt={5}
              w="full"
              leftIcon={<FiPlus />}
              isDisabled={paymentMethods?.length === 0}
              onClick={() => setShowAddFundsForm(true)}
            >
              Add Funds
            </Button>
          </VStack>
        </Box>
      )}
    </Box>
  );
};
