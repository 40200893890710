import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

import type { OriginAddress } from 'types/address.d';
import axios from 'config/axios';

type DeleteAddress = { id: string };
export type DeleteAddressResponse = { address: OriginAddress };
export type DeleteAddressError = AxiosError<{ message: string }>;

export const deleteAddress = async ({ id }: DeleteAddress) => {
  const { data } = await axios.delete(`/v3/origin-addresses/${id}`);
  return data;
};

const useDeleteAddress = (opts?: UseMutationOptions<DeleteAddressResponse, DeleteAddressError, DeleteAddress>) =>
  useMutation<DeleteAddressResponse, DeleteAddressError, DeleteAddress>(deleteAddress, opts);

export default useDeleteAddress;
