import {
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useGetCarrierAccounts } from 'api/carrier-accounts';
import { useQuickShipStore } from 'store';
import { Logo } from 'components/Logo';

export function SettingsDrawer(props: Omit<ModalProps, 'children'>) {
  const { onClose } = props;
  const { patch, carrierAccountIds } = useQuickShipStore();

  const carrierAccounts = useGetCarrierAccounts();

  // When carrierAccountIds is null, it means the filter hasn't been set yet.
  // In this case, we show all carriers as checked.
  const defaultChecked =
    carrierAccountIds === null ? carrierAccounts?.data?.map((ca) => ca.id) : carrierAccountIds;

  return (
    <Drawer {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>Quick Ship Settings</DrawerHeader>
        <DrawerBody>
          <Stack spacing="3.5">
            <FormControl>
              <FormLabel>Carriers</FormLabel>
              <CheckboxGroup
                colorScheme="brand"
                defaultValue={defaultChecked}
                onChange={(value: Array<string>) => {
                  patch({ carrierAccountIds: value });
                }}
              >
                <Stack spacing={[1, 2]} direction="column">
                  {carrierAccounts?.data?.map((ca) => (
                    <Checkbox key={ca.id} value={ca.id} isChecked={carrierAccountIds === null}>
                      <HStack>
                        <Text>{ca.readable}</Text>
                        {ca.billingType === 'vesyl' ? (
                          <Logo variant="logomark" color="black" w={4} />
                        ) : (
                          ca.description && <Text>({ca.description})</Text>
                        )}
                      </HStack>
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
