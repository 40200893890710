import {
  Button,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  Tag,
} from '@chakra-ui/react';
import { useGetQuery } from 'api/client';
import { useUser } from 'contexts/auth-context';
import {
  BiBarChartAlt,
  BiCart,
  BiCog,
  BiDollar,
  BiPackage,
  BiPurchaseTagAlt,
  BiStore,
  BiTable,
} from 'react-icons/bi';
import { BsMagic } from 'react-icons/bs';
import { CgPushChevronLeft, CgPushChevronRight } from 'react-icons/cg';
import { FiCheckCircle, FiCircle, FiSettings } from 'react-icons/fi';
import { HiOutlineRefresh, HiOutlineTruck } from 'react-icons/hi';
import { TiContacts } from 'react-icons/ti';
import { useUserStore } from 'store';
import { toUSD } from 'utils/currency';
import { Link as RouterLink } from '../../router';
import { Logo } from '../Logo';
import { NavGroup } from './NavGroup';
import { NavItem, NavItemProps } from './NavItem';
import { UserProfile } from './UserProfile';
import { AddFundsPopover } from './add-funds-popover';
import { OrderStatus } from 'types/enums';

const getMenus = (
  user: ReturnType<typeof useUser>,
): Record<string, Array<NavItemProps & { canView: boolean }>> => {
  const isAdmin = !!user?.isAdmin;
  const permissions = user?.permissions;
  const statusOrdersCount = useGetQuery('/api/v3/orders/count', {
    params: {
      query: { filterModel: '', groupBy: 'status' },
    },
  });
  const readyToShipCount = statusOrdersCount.data?.find(
    (status) => status.name === OrderStatus.ReadyToShip,
  )?.count;

  return {
    shipments: [
      {
        path: '/ship',
        label: 'Quick Ship',
        icon: <HiOutlineTruck />,
        canView: permissions?.viewShip || isAdmin,
      },
      {
        path: '/shipments',
        label: 'Shipments',
        icon: <BiTable />,
        canView: permissions?.viewShipments || isAdmin,
      },
      {
        path: '/orders',
        label: 'Orders',
        rightIcon:
          readyToShipCount && readyToShipCount > 0 ? <Tag size={'sm'}>{readyToShipCount}</Tag> : undefined,
        icon: <BiCart />,
        canView: permissions?.viewOrders || isAdmin,
        children: [
          { path: '/orders/import', label: 'Import ' },
          { path: '/orders/batches', label: 'Batches' },
        ],
      },
    ],
    stores: [
      {
        path: '/stores',
        label: 'Stores',
        icon: <BiStore />,
        canView: permissions?.viewStores || isAdmin,
      },
      {
        path: '/addresses',
        label: 'Addresses',
        icon: <TiContacts />,
        canView: permissions?.viewAddresses || isAdmin,
      },
      {
        path: '/boxes',
        label: 'Boxes',
        icon: <BiPackage />,
        canView: permissions?.viewBoxes || isAdmin,
      },
      {
        path: '/products',
        label: 'Products',
        icon: <BiPurchaseTagAlt />,
        canView: permissions?.viewProducts || isAdmin,
      },
      {
        path: '/automations',
        label: 'Automations',
        icon: <BiCog />,
        canView: permissions?.viewAutomations || isAdmin,
      },
    ],
    billing: [
      {
        path: '/reporting/',
        label: 'Reporting',
        icon: <BiBarChartAlt />,
        canView: permissions?.viewBilling || isAdmin,
      },
      {
        path: '/billing/',
        label: 'Billing',
        icon: <BiDollar />,
        canView: permissions?.viewBilling || isAdmin,
      },
    ],
  };
};

export const Sidebar = (props: FlexProps) => {
  const isSidebarCollapsed = useUserStore((state) => state.isSidebarCollapsed);
  const patchUserStore = useUserStore((state) => state.patch);

  const onboardingPopover = useDisclosure();

  const user = useUser();
  const meter = useGetQuery('/api/v3/user/meters', {});

  const fullName = [user?.firstName, user?.lastName].filter(Boolean).join(' ');
  const email = user?.companyName ?? user?.email ?? '';

  const menus = getMenus(user);

  const hasAddress = user?.hasOriginAddress === true;
  const hasPaymentMethod = user?.hasPaymentMethod === true;
  const isBalanceVisible = user?.billingType !== 'invoicing';
  const hasBalance = meter?.data?.balance && meter.data?.balance > 0 && hasPaymentMethod;
  const isOnboardingVisible = user?.isAdmin && (!hasAddress || !hasPaymentMethod || !hasBalance);

  const balanceMarkup = isBalanceVisible && !isSidebarCollapsed && (
    <HStack justify="space-between" px="3">
      <Stack spacing={0}>
        <Text fontWeight="semibold" color="zinc.200">
          Balance
        </Text>
        <Text color="zinc.200" display="flex" alignItems="center" gap="1">
          {toUSD(meter.data?.balance || 0)}
          {meter.data?.isActive && (
            <HiOutlineRefresh title="Balance auto-recharge is turned on" size="11px" />
          )}
        </Text>
      </Stack>
      {user?.permissions?.viewBilling && <AddFundsPopover />}
    </HStack>
  );

  const onboardingPopoverMarkup = !isSidebarCollapsed && isOnboardingVisible && (
    <Popover placement="right-start" {...onboardingPopover}>
      <PopoverTrigger>
        <Button
          color="white"
          size="sm"
          _hover={{ bgGradient: 'linear(to-l, orange.400, orange.600, orange.800)', opacity: '0.8' }}
          bgGradient="linear(to-l, brand.400, brand.600, brand.800)"
          leftIcon={<BsMagic />}
        >
          Get started
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody p="6">
          <Stack spacing="2">
            <Text fontWeight="semibold" fontSize="md">
              Welcome to VESYL 👋
            </Text>
            <Text color="muted">
              We&rsquo;re excited to have you on board. Here are some quick links to get you started.
            </Text>
            <Stack spacing="2">
              <HStack color="green.700">
                <FiCheckCircle />
                <Text fontWeight="medium">Sign up</Text>
              </HStack>
              <HStack>
                <Icon as={hasAddress ? FiCheckCircle : FiCircle} color={hasAddress ? 'green.700' : 'muted'} />
                <Link
                  as={RouterLink}
                  fontWeight="medium"
                  to="/addresses"
                  onClick={onboardingPopover.onClose}
                  color={hasAddress ? 'green.700' : 'muted'}
                >
                  Add a primary address
                </Link>
              </HStack>
              <HStack>
                <Icon
                  as={hasPaymentMethod ? FiCheckCircle : FiCircle}
                  color={hasPaymentMethod ? 'green.700' : 'muted'}
                />
                <Link
                  as={RouterLink}
                  fontWeight="medium"
                  to="/billing"
                  onClick={onboardingPopover.onClose}
                  color={hasPaymentMethod ? 'green.700' : 'muted'}
                >
                  Set up a payment method
                </Link>
              </HStack>
              <HStack>
                <Icon as={hasBalance ? FiCheckCircle : FiCircle} color={hasBalance ? 'green.700' : 'muted'} />
                <Link
                  as={RouterLink}
                  fontWeight="medium"
                  to="/billing"
                  onClick={onboardingPopover.onClose}
                  color={hasBalance ? 'green.700' : 'muted'}
                >
                  Add funds to your account
                </Link>
              </HStack>
            </Stack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return (
    <Flex
      h="full"
      zIndex="10"
      boxShadow="sm"
      bg="brandBlack"
      position="fixed"
      w={isSidebarCollapsed ? '24' : '64'}
      {...props}
    >
      <Stack
        w="full"
        spacing="1"
        fontSize="sm"
        overflowY="auto"
        justify="space-between"
        px={{ base: '4', sm: '6' }}
        mt={{ base: '6', sm: '8' }}
        mb={{ base: '5', sm: '3' }}
      >
        <Stack spacing="5" shouldWrapChildren>
          <Logo
            w="32"
            {...(isSidebarCollapsed
              ? { variant: 'logomark', color: 'yellow' }
              : { variant: 'horizontal', color: 'yellow-white' })}
          />

          {user && (
            <Stack spacing="5">
              {onboardingPopoverMarkup}
              {balanceMarkup}

              {Object.entries(menus).map(([group, items]) => (
                <NavGroup key={group}>
                  {items
                    .filter((item) => item.canView)
                    .map((item) => (
                      <NavItem
                        key={item.path}
                        icon={item.icon}
                        path={item.path}
                        label={item.label}
                        children={item.children}
                        rightIcon={item.rightIcon}
                      />
                    ))}
                </NavGroup>
              ))}
            </Stack>
          )}
        </Stack>
        <Spacer />

        <Stack spacing={{ base: '5', sm: '3' }}>
          <Button
            variant="ghost"
            w="full"
            color="white"
            rounded="md"
            _hover={{ bg: 'zinc.600' }}
            _active={{ bg: 'zinc.600' }}
            justifyContent="start"
            onClick={() => patchUserStore({ isSidebarCollapsed: !isSidebarCollapsed })}
          >
            {isSidebarCollapsed ? <CgPushChevronRight /> : <CgPushChevronLeft />}
          </Button>
          <Stack hidden spacing="1">
            {user?.permissions?.viewSettings && (
              <NavItem path="/settings" label="Settings" icon={<FiSettings />} />
            )}
          </Stack>
          <Divider borderColor="zinc.500" />
          <UserProfile isCollapsed={isSidebarCollapsed} name={fullName} email={email} />
        </Stack>
      </Stack>
    </Flex>
  );
};
