import { paths } from 'api/types';
import axios from 'config/axios';
import { useQuery } from 'react-query';

type GetShipmentsIdResponse = paths['/api/v3/shipments/{id}']['get']['responses']['200']['content']['application/json'];

export const readShipment = async (id: number) => {
  const { data } = await axios.get<GetShipmentsIdResponse>(`/v3/shipments/${id}`);

  return data;
};

const useReadShipment = (id: number) => useQuery<GetShipmentsIdResponse>(['shipmentId', id], () => readShipment(id));

export default useReadShipment;
