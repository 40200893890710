import { formatDate } from 'utils/dates';
import { groupBy } from 'lodash';
import { FC } from 'react';
import { Box, Divider, Flex, List, ListItem, Tag, Text, VStack } from '@chakra-ui/react';

interface Props {
  events: Array<{
    id: number;
    title: string;
    description: string;
    date: string;
    datetime: string;
    icon: any;
  }>;
}

const Events = (props: Props) => {
  const { events } = props;

  return (
    <List>
      {events.map((ev, index) => {
        return (
          <ListItem key={index}>
            <Flex gap={3} flexWrap="wrap" justifyContent="space-between">
              <Flex flexWrap="nowrap">
                <VStack spacing={0} marginRight={2}>
                  <Box display="inline">
                    <Tag borderRadius="full" p={3}>
                      {ev.icon()}
                    </Tag>
                  </Box>
                  <Box h={6}>{index !== events.length - 1 ? <Divider orientation="vertical" /> : null}</Box>
                </VStack>
                <VStack align="start" spacing={0}>
                  <Text fontWeight="semibold">{ev.title}</Text>
                  <Text textColor="muted">{ev.description}</Text>
                </VStack>
              </Flex>
              <Text textColor="muted">{formatDate(ev.datetime, 'h:mma')}</Text>
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );
};

export const Timeline: FC<Props> = (props) => {
  const { events } = props;

  if (!events?.length) {
    return null;
  }

  const grouped = groupBy(events, 'date');

  return (
    <>
      {Object.entries(grouped).map(([date, items], index) => {
        return (
          <VStack align="stretch" spacing={4} mb={6} key={index}>
            <Text fontWeight="semibold">{formatDate(date, 'MMM DD, YYYY')}</Text>
            <Events events={items} />
          </VStack>
        );
      })}
    </>
  );
};
