import * as Sentry from '@sentry/react';
import { useGetMe } from 'api/auth';
import React, { FC, createContext, useContext, useEffect } from 'react';
import { useUserStore } from 'store';
import { GetMeResponse } from 'types/api-types';

export interface Context {
  user: GetMeResponse | null | undefined;
}

const AuthContext = createContext<Context>({ user: null } as unknown as Context);

export const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = React.useState<Context['user']>(null);

  const { authToken } = useUserStore();
  const getMe = useGetMe({ enabled: !!authToken });

  useEffect(() => {
    if (getMe.data) {
      const me = getMe.data;
      setUser(me);

      Sentry.setUser({
        email: me.email,
        id: String(me.id),
        username: [me.firstName, me.lastName].join(' '),
      });
    }

    if (getMe.isError) setUser(null);
    // Use "undefined" to keep track of the loading state
    // and avoid adding another variable to the context.
    if (getMe.isLoading) setUser(undefined);
  }, [getMe.data]);

  useEffect(() => {
    if (!authToken) Sentry.setUser(null);
  }, [authToken]);

  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

const useUser = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a AuthProvider');
  }
  return context?.user;
};

export { useUser };
