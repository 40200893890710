import {
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import { invalidateQueries } from 'api/client';
import { GET_METER_QUERY, useUpdateMeter } from 'api/meters';
import { GET_PAYMENT_METHODS_QUERY, useDeletePaymentMethod } from 'api/payments';
import { queryClient } from 'config/query-client';
import { FC } from 'react';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import { FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa, FaCheck, FaLandmark } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { PaymentCard } from 'types/payment';

interface PaymentCardProps {
  paymentCard: PaymentCard;
}

const getCardIcon = (paymentCard: PaymentCard | null) => {
  if (paymentCard?.type === 'BankAccount') {
    return <Icon color="muted" as={FaLandmark} boxSize={25} />;
  }
  switch (paymentCard?.card?.brand?.toLowerCase()) {
    case 'amex':
      return <Icon color="muted" as={FaCcAmex} boxSize={25} />;
    case 'discover':
      return <Icon color="muted" as={FaCcDiscover} boxSize={25} />;
    case 'mastercard':
      return <Icon color="muted" as={FaCcMastercard} boxSize={25} />;
    case 'visa':
      return <Icon color="muted" as={FaCcVisa} boxSize={25} />;
    default:
      return <Icon color="muted" as={BsFillCreditCard2FrontFill} boxSize={25} />;
  }
};

export const PaymentDetails: FC<PaymentCardProps> = ({ paymentCard }) => {
  const updateMeter = useUpdateMeter();
  const deletePaymentMethod = useDeletePaymentMethod();

  const handleSetPrimary = () => {
    updateMeter.mutate(
      { primaryPaymentMethodId: paymentCard.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_PAYMENT_METHODS_QUERY);
          // queryClient.invalidateQueries(GET_METER_QUERY);
          invalidateQueries('/api/v3/user/meters');
        },
      },
    );
  };

  const handleSetSecondary = () => {
    updateMeter.mutate(
      { secondaryPaymentMethodId: paymentCard.isSecondary ? null : paymentCard.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_PAYMENT_METHODS_QUERY);
        },
      },
    );
  };

  const handleDelete = async () => {
    if (paymentCard.id === '' || !paymentCard.id) return;

    deletePaymentMethod.mutate(
      { id: paymentCard.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(GET_PAYMENT_METHODS_QUERY);
          queryClient.invalidateQueries(GET_METER_QUERY);
        },
      },
    );
  };

  return (
    <Card>
      <CardBody>
        <Stack h="full" spacing={1}>
          <HStack h="full" justify="space-between">
            <HStack>
              {getCardIcon(paymentCard)}
              <Text fontWeight="medium" fontSize="md">
                {paymentCard.card.brand?.toUpperCase() || paymentCard.card.issuer?.toUpperCase()} ****{' '}
                {paymentCard.card.lastFour}
              </Text>
            </HStack>
          </HStack>

          <Text pl="8" fontSize="sm">
            {paymentCard.type === 'CreditCard'
              ? `Expires ${paymentCard.card.expirationMonth}/${paymentCard.card.expirationYear}`
              : ''}
          </Text>
          <Text pl="8" fontSize="xs" color="muted">
            {paymentCard.additionalFeePercentage && paymentCard.additionalFeePercentage !== '0.0'
              ? paymentCard.additionalFeePercentage + `% additional fee applied to credit card payments`
              : ''}
          </Text>

          <Flex justify="end" pt="6" gap="2">
            {paymentCard.isPrimary && <Tag colorScheme="green">Primary</Tag>}
            {paymentCard.isSecondary && <Tag colorScheme="blue">Secondary</Tag>}

            {!paymentCard.isVerified && paymentCard.verifyNextAction?.type && (
              <>
                <Link
                  href={paymentCard.verifyNextAction?.verifyWithMicrodeposits?.hostedVerificationUrl}
                  isExternal
                >
                  <Button
                    float="right"
                    variant="outline"
                    colorScheme="brand"
                    rightIcon={<Icon as={FaCheck} />}
                  >
                    Verify
                  </Button>
                </Link>
              </>
            )}
            {paymentCard.isVerified && (
              <Menu>
                <MenuButton as={Button} variant="outline" size="xs">
                  Options <Icon ml={1} as={IoIosArrowDown} />
                </MenuButton>
                <MenuList>
                  {!paymentCard.isPrimary && (
                    <MenuItem fontSize="xs" onClick={handleSetPrimary}>
                      Set Primary
                    </MenuItem>
                  )}
                  <MenuItem fontSize="xs" onClick={handleSetSecondary}>
                    {paymentCard.isSecondary ? 'Remove Secondary' : 'Set Secondary'}
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={handleDelete}>
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  );
};
