import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GET_ME_QUERY, useCreateResetToken } from 'api/auth';
import { useUpdateUser } from 'api/settings/user';
import { PhoneInput } from 'components/PhoneInput';
import { queryClient } from 'config/query-client';
import { useUser } from 'contexts/auth-context';
import { useNotification } from 'contexts/notification.context';
import { useDisclosure } from 'hooks/use-disclosure';
import { FC, PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { formatPhone } from 'utils/misc';
import { z } from 'zod';

const Section = (props: PropsWithChildren<StackProps>) => {
  const { children, ...rest } = props;

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '1.5', md: '8' }}
      justify="space-between"
      {...rest}
    >
      {children}
    </Stack>
  );
};

const schema = z.object({
  firstName: z.string().nonempty().optional(),
  lastName: z.string().nonempty().optional(),
  phone: z.string().nonempty().optional(),
  companyName: z.string().optional(),
});

type FormValues = z.infer<typeof schema>;

export const AccountSettings: FC<{}> = () => {
  const { mutate: createResetToken } = useCreateResetToken();
  const passwordResetModal = useDisclosure(false);
  const { notifySuccess } = useNotification();
  const user = useUser();

  const updateUser = useUpdateUser({});

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: { ...user, phone: formatPhone(user?.phone) || undefined },
  });

  if (!user) return null;

  const onSubmit = async (data: FormValues) => {
    const onSuccess = async () => {
      notifySuccess('Account settings updated.');
      await queryClient.refetchQueries(GET_ME_QUERY);
      await queryClient.invalidateQueries(GET_ME_QUERY);
    };

    const payload = {
      firstName: data.firstName || user.firstName,
      lastName: data.lastName || user.lastName,
      phone: data.phone || user.phone,
      companyName: data.companyName ?? user.companyName,
    };

    await updateUser.mutate(payload, { onSuccess });
  };

  const onPasswordReset = async () => {
    const onSuccess = () => {
      passwordResetModal.onClose();
      notifySuccess(`Email successfully sent to ${user.email}`);
    };

    await createResetToken({ email: user.email }, { onSuccess });
  };

  const { handleSubmit, formState } = methods;

  return (
    <Card>
      <CardHeader>
        <Text fontWeight="semibold" fontSize="lg">
          Personal information
        </Text>
        <Text fontSize="sm" color="muted">
          Update your account information and reset your password.
        </Text>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)} noValidate id="accountSettings">
          <Stack spacing="3.5" divider={<StackDivider />}>
            <FormControl isRequired isInvalid={!!formState.errors?.firstName}>
              <Section>
                <FormLabel variant="inline">First name</FormLabel>
                <Input {...methods.register('firstName')} />
              </Section>
            </FormControl>
            <FormControl isRequired isInvalid={!!formState.errors?.lastName}>
              <Section>
                <FormLabel variant="inline">Last name</FormLabel>
                <Input {...methods.register('lastName')} />
              </Section>
            </FormControl>
            <FormControl isDisabled>
              <Section>
                <FormLabel variant="inline">Email address</FormLabel>
                <Input value={user?.email} />
              </Section>
            </FormControl>
            <FormControl>
              <Section justify="start">
                <FormLabel variant="inline">Password</FormLabel>
                <Button type="button" variant="outline" onClick={passwordResetModal.onOpen}>
                  Reset password
                </Button>
              </Section>
            </FormControl>

            <FormControl isInvalid={!!formState.errors?.companyName}>
              <Section>
                <FormLabel variant="inline">Company name</FormLabel>
                <Input {...methods.register('companyName')} />
              </Section>
            </FormControl>
            <FormControl>
              <Section>
                <FormLabel variant="inline">Phone</FormLabel>
                <PhoneInput country="US" value={user?.phone || ''} disabled />
              </Section>
            </FormControl>

            {/* Uncomment to test Dark mode */}
            {/* <FormControl>
              <Section justify="start">
                <FormLabel variant="inline">Theme</FormLabel>
                <Button type="button" variant="outline" onClick={toggleColorMode}>
                  {colorMode === 'dark' ? 'Light mode' : 'Dark mode'}
                </Button>
              </Section>
            </FormControl> */}
          </Stack>
        </form>

        <Modal isOpen={passwordResetModal.isOpen} onClose={passwordResetModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text fontWeight="semibold" fontSize="lg">
                Reset Password
              </Text>
            </ModalHeader>
            <ModalBody>
              <Text color="muted">
                An email will be sent to <b>{user.email}</b> to help you create a new password for your account.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button type="button" variant="outline" onClick={passwordResetModal.onClose}>
                Cancel
              </Button>
              <Button ml="2" type="button" colorScheme="brand" onClick={onPasswordReset}>
                Send
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </CardBody>
      <CardFooter>
        <Button form="accountSettings" ml="auto" type="submit" colorScheme="brand" isLoading={updateUser.isLoading}>
          Save
        </Button>
      </CardFooter>
    </Card>
  );
};
