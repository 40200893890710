import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  StackDivider,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { Controller, useForm, useWatch, FieldErrorsImpl } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TagInput } from 'components/tag-input';
import { CountrySelect } from 'pages/(app)/ship/_components/CountrySelect';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '1.5', md: '8' }}
      justify="space-between"
      justifyContent="flex-start"
    >
      {children}
    </Stack>
  );
};

const invalid_type_error = 'Must be a number';

const schema = z
  .object({
    name: z.string().nonempty(),
    sku: z.string().nonempty(),
    description: z.string().nullable(),
    weightOz: z.coerce.number({ invalid_type_error }).min(0).max(1900),
    weightLbs: z.coerce.number({ invalid_type_error }).min(0).max(120),
    heightIn: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    widthIn: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    lengthIn: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    price: z.coerce.number({ invalid_type_error }),
    imageUrl: z.string().nullable(),
    originCountry: z.string().nullable(),
    hsTariffCode: z.string().nullable(),
    tags: z.array(z.string()).nullable(),
    location: z.string().nullable(),
  })
  .refine(
    (data) =>
      [data.lengthIn, data.widthIn, data.heightIn].every((d) => d && d > 0) ||
      [data.lengthIn, data.widthIn, data.heightIn].every((d) => d === 0) ||
      [data.lengthIn, data.widthIn, data.heightIn].every((d) => Number.isNaN(Number(d))),
    { path: ['dimensions'], message: 'Required' },
  );

export type FormValues = z.infer<typeof schema>;
interface Props {
  onSubmit: (values: FormValues) => void;
  isLoading?: boolean;
}

export const CreateProduct = ({ onSubmit, isLoading }: Props) => {
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: undefined,
      sku: undefined,
      description: undefined,
      weightOz: undefined,
      weightLbs: undefined,
      heightIn: undefined,
      widthIn: undefined,
      lengthIn: undefined,
      price: undefined,
      imageUrl: undefined,
      originCountry: null,
      hsTariffCode: undefined,
      location: undefined,
      tags: [],
    },
    resolver: zodResolver(schema),
  });

  const { handleSubmit, register, setValue, getValues, control, formState } = methods;
  const errors = formState.errors as typeof formState.errors &
    FieldErrorsImpl<{
      dimensions?: { message: string };
    }>;

  const heightIn = useWatch({ control, name: 'heightIn' });
  const lengthIn = useWatch({ control, name: 'lengthIn' });
  const widthIn = useWatch({ control, name: 'widthIn' });

  // Check if any of the dimensions have a value (not NaN or '') and if so, require all dimensions
  const hasDimension = [heightIn, lengthIn, widthIn].some((dim) => Number(dim) !== 0 && !Number.isNaN(Number(dim)));

  return (
    <>
      <Stack>
        <Stack spacing={0}>
          <Text fontSize="lg" fontWeight="semibold">
            Create new product
          </Text>
          <Text color="muted">Use this form to add a product in your store.</Text>
        </Stack>
      </Stack>

      <Divider my={6} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="3.5" divider={<StackDivider />}>
          <FormControl>
            <Section>
              <FormLabel variant="inline">Basic information</FormLabel>
              <VStack w="100%" spacing={4}>
                <FormControl isRequired isInvalid={!!errors.name}>
                  <FormLabel variant="outline">Name</FormLabel>
                  <Input maxW={600} {...register('name', { required: true })} />
                  <FormErrorMessage>{formState?.errors?.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!errors.sku}>
                  <FormLabel variant="outline">SKU</FormLabel>
                  <Input maxW={600} {...register('sku', { required: true })} />
                  <FormErrorMessage>{formState?.errors?.sku?.message}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel variant="outline">Description</FormLabel>
                  <Textarea maxW={600} {...register('description')} />
                </FormControl>
                <FormControl>
                  <FormLabel variant="outline">Image URL</FormLabel>
                  <Input maxW={600} {...register('imageUrl', { setValueAs: (value) => value.trim() })} />
                </FormControl>
              </VStack>
            </Section>
          </FormControl>

          <FormControl>
            <Section>
              <FormLabel variant="inline">Characteristics</FormLabel>
              <VStack spacing={4}>
                <HStack w="full">
                  <FormControl isInvalid={!!errors.price}>
                    <FormLabel variant="outline">Price</FormLabel>
                    <InputGroup maxW="200px">
                      <InputLeftElement>$</InputLeftElement>
                      <Input {...register('price')} placeholder="0" />
                      <InputRightElement color="muted" mr={2}>
                        USD
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{formState?.errors?.price?.message}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack w="full">
                  <FormControl>
                    <FormLabel variant="outline">Weight</FormLabel>
                    <HStack>
                      <InputGroup>
                        <Input {...register('weightLbs')} placeholder="Weight" />
                        <InputRightElement>lbs</InputRightElement>
                      </InputGroup>

                      <InputGroup>
                        <Input {...register('weightOz')} placeholder="Weight" />
                        <InputRightElement>oz</InputRightElement>
                      </InputGroup>
                    </HStack>
                  </FormControl>
                </HStack>
                <HStack w="full">
                  <FormControl
                    isRequired={hasDimension}
                    isInvalid={
                      !!errors.lengthIn || !!errors.widthIn || !!errors.heightIn || !!errors.dimensions || hasDimension
                    }
                  >
                    <FormLabel variant="outline">Dimensions</FormLabel>
                    <HStack>
                      <InputGroup>
                        <FormControl
                          isInvalid={hasDimension && (!getValues('lengthIn') || Number(getValues('lengthIn')) === 0)}
                        >
                          <HStack>
                            <Input {...register('lengthIn')} type="number" placeholder="Length" />
                            <FormErrorMessage>{formState?.errors?.lengthIn?.message}</FormErrorMessage>
                          </HStack>
                        </FormControl>
                      </InputGroup>
                      <InputGroup>
                        <FormControl
                          isInvalid={hasDimension && (!getValues('widthIn') || Number(getValues('widthIn')) === 0)}
                        >
                          <HStack>
                            <Input {...register('widthIn')} type="number" placeholder="Width" />
                            <FormErrorMessage>{formState?.errors?.widthIn?.message}</FormErrorMessage>
                          </HStack>
                        </FormControl>
                      </InputGroup>
                      <InputGroup>
                        <FormControl
                          isInvalid={hasDimension && (!getValues('heightIn') || Number(getValues('heightIn')) === 0)}
                        >
                          <HStack>
                            <Input {...register('heightIn')} type="number" placeholder="Height" />
                            <FormErrorMessage>{formState?.errors?.heightIn?.message}</FormErrorMessage>
                          </HStack>
                        </FormControl>
                      </InputGroup>
                    </HStack>
                  </FormControl>
                </HStack>
                <FormControl>
                  <FormLabel variant="outline">Tags</FormLabel>
                  <Flex w="100%">
                    <Box w="100%" maxWidth={400}>
                      <Controller
                        name="tags"
                        control={control}
                        render={({ field: { ref } }) => (
                          <TagInput
                            ref={ref}
                            value={getValues('tags')?.map((tag) => ({ value: tag, label: tag })) || []}
                            onChange={(data) =>
                              setValue(
                                'tags',
                                data.map((d) => d.value),
                              )
                            }
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                </FormControl>
                <HStack w="full">
                  <FormControl isInvalid={!!errors.location}>
                    <FormLabel variant="outline">Location</FormLabel>
                    <InputGroup maxW="400px">
                      <Input {...register('location')} />
                    </InputGroup>
                    <FormErrorMessage>{formState?.errors?.location?.message}</FormErrorMessage>
                  </FormControl>
                </HStack>
              </VStack>
            </Section>
          </FormControl>
          <FormControl>
            <Section>
              <FormLabel variant="inline">Customs Info</FormLabel>
              <HStack spacing={4}>
                <FormControl>
                  <FormLabel>Country</FormLabel>
                  <Controller
                    name="originCountry"
                    control={control}
                    render={({ field: { ref, value, onChange } }) => (
                      <CountrySelect value={value} ref={ref} onChange={onChange} />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel variant="outline">HS tariff</FormLabel>
                  <Input {...register('hsTariffCode')} maxW={200} />
                </FormControl>
              </HStack>
            </Section>
          </FormControl>
        </Stack>
        <Flex justify="end" pt="6">
          <Button type="submit" colorScheme="brand" isLoading={isLoading}>
            Create
          </Button>
        </Flex>
      </form>
    </>
  );
};
