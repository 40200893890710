import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Link as ChakraLink,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Logo } from 'components/Logo';
import { Pattern } from 'components/pattern';
import { useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Link, useNavigate } from 'router';

const privacyUrl = 'https://vesyl.com/privacy-policy';
const termsUrl = 'https://vesyl.com/terms';

export default function () {
  const intercom = useIntercom();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const userRegistered = params.get('userRegistered') || undefined;

  useEffect(() => {
    intercom.boot();
  }, [intercom]);

  const footerMarkup = (
    <CardFooter
      p="0"
      py="4"
      px="6"
      borderTop="1px"
      textAlign="center"
      bg={useColorModeValue('zinc.100', 'gray.900')}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
    >
      <Text textAlign="center" mx="auto" color="muted" fontSize="xs">
        {pathname.includes('/register') ? (
          <>
            By signing up, you agree to our <ChakraLink href={termsUrl}>Terms</ChakraLink> and{' '}
            <ChakraLink href={privacyUrl}>Privacy Policy</ChakraLink>.
            <br />
            Need help? <ChakraLink onClick={() => intercom.show()}>Contact us</ChakraLink>.
          </>
        ) : (
          <>
            Read our <ChakraLink href={termsUrl}>Terms of Service</ChakraLink> and{' '}
            <ChakraLink href={privacyUrl}>Privacy Policy</ChakraLink>. <br />
            Need help? <ChakraLink onClick={() => intercom.show()}>Contact us</ChakraLink>.
          </>
        )}
      </Text>
    </CardFooter>
  );

  // eslint-disable-next-line no-nested-ternary
  const topMarkup =
    pathname.includes('/login') && userRegistered === 'true' ? (
      <Text color="zinc.200">{`Almost done, please login with your new account.`}</Text>
    ) : pathname.includes('/login') ? (
      <Text color="zinc.200">
        {`Don't have an account? `}
        <Link to="/register">
          <Button type="button" colorScheme="brand" variant="link">
            Create one
          </Button>
          .
        </Link>
      </Text>
    ) : pathname.includes('/register') ? (
      <Text color="zinc.200">
        {`Already registered? `}
        <Link to="/login">
          <Button type="button" colorScheme="brand" variant="link">
            Sign in
          </Button>
          .
        </Link>
      </Text>
    ) : null;

  const bottomMarkup = pathname.includes('/login') ? (
    <Flex>
      <Button pt="4" ml="auto" colorScheme="brand" variant="link" onClick={() => navigate('/reset-password')}>
        Forgot password?
      </Button>
    </Flex>
  ) : null;

  return (
    <Flex alignItems="center" justify="center" minH="100vh" bg="brandBlack" zIndex="0">
      <Stack alignItems="center" gap="3">
        <Logo w="60" color="yellow-white" zIndex={10} />
        {topMarkup}
        <Box position="relative">
          <Pattern
            variant="triangle"
            opacity={0.3}
            zIndex="-1"
            color="yellow"
            w="md"
            position="absolute"
            bottom="-50px"
            right="-150px"
          />
          <Card p="0" w="md" shadow="lg" position="relative">
            <CardBody p="10">
              <Outlet />
              {bottomMarkup}
            </CardBody>
            {footerMarkup}
          </Card>
        </Box>
      </Stack>
    </Flex>
  );
}
