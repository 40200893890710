import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from '../../config/axios';

export interface AddPaymentMethodResponse {
  id: string;
  redirectUrl: string;
}

export interface AddPaymentMethod {
  returnUrl: string;
  number: string;
  expirationMonth: number;
  expirationYear: number;
  cvc: string;
  billingAddress?: {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    state?: string;
    postalCode?: string;
  };
  makePrimary: boolean;
  session?: string | null;
}

type ErrorResponse = AxiosError<{ message?: string; error?: string }>;

const addPaymentMethod = async (vars: AddPaymentMethod) => {
  const { data } = await axios.post('/v3/user/payment-methods/card', vars);
  return data;
};

const useAddPaymentMethod = (opts?: UseMutationOptions<AddPaymentMethodResponse, ErrorResponse, AddPaymentMethod>) =>
  useMutation<AddPaymentMethodResponse, ErrorResponse, AddPaymentMethod>(addPaymentMethod, opts);

export default useAddPaymentMethod;
