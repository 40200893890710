import * as Sentry from '@sentry/react';
import { Notification } from 'components/notification';
import sample from 'lodash/sample';
import toast from 'react-hot-toast';
import { MutationCache, QueryCache, QueryClient } from 'react-query';

const errorMessages = [
  "We're sorry, something went wrong on our end. Please try again later.",
  'There was a problem processing your request. Please try again later.',
];

const handleError = (error: any) => {
  // Known errors are not sent to Sentry
  const isKnownError = [422, 401].includes(error?.response?.status);

  // Send error to Sentry
  if (isKnownError) {
    // Do nothing
  } else if (error?.response) {
    const url = error?.response?.config?.url;
    const method = error?.response?.config?.method;
    const data = error?.response?.data;
    const status = error?.response?.status;
    Sentry.captureException(error, { extra: { url, method, data, status } });
  } else {
    Sentry.captureException(error);
  }

  // Different from other errors, 401s will
  // delete the current user session and redirect
  // them to the login page. No toast notification is displayed.
  if (error?.response?.status === 401) {
    localStorage.removeItem('vsyl_user');
    window.location.href = '/login';

    return;
  }

  let errorMessage = '';
  const isAPIError = !!error?.response?.status;

  // Only display server messages from 422 errors
  const displayServerMessage = isAPIError && error?.response?.status === 422;

  if (!isAPIError) {
    errorMessage = 'An error has occurred. Please try again later.';
  } else if (displayServerMessage) {
    errorMessage = error?.response?.data?.message;
  } else {
    errorMessage = sample(errorMessages) as string;
  }

  toast.custom((t) => (
    <Notification
      title="Error"
      variant="error"
      description={errorMessage}
      onClose={() => toast.dismiss(t.id)}
    />
  ));
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({ onError: (error) => handleError(error) }),
  mutationCache: new MutationCache({ onError: (error) => handleError(error) }),
  defaultOptions: {
    mutations: {},
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
