import { paths } from 'api/types';
import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type PostUserBody = paths['/api/v3/user']['post']['requestBody']['content']['application/json'];
type PostUserResponse = paths['/api/v3/user']['post']['responses']['200']['content']['application/json'];

const register = async (vars: PostUserBody) => {
  const { data } = await axios.post('/v3/user', vars);
  return data;
};

export const useRegister = (opts?: UseMutationOptions<PostUserResponse, any, PostUserBody>) =>
  useMutation<PostUserResponse, any, PostUserBody>(register, opts);
