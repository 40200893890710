import { useGetOrders } from 'api/orders/get';
import { useUpdateOrder } from 'api/orders/update';
import { createContext, useContext } from 'react';

export const OrderContext = createContext<{
  updateOrder?: ReturnType<typeof useUpdateOrder>;
  getOrders?: ReturnType<typeof useGetOrders>;
}>({});

export const useOrderContext = () => useContext(OrderContext);
