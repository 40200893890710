import { useQuery } from 'react-query';

import axios from '../../config/axios';

export interface MeterResponse {
  id: number;
  selledId: number;
  rechargeAmountCents: number;
  rechargeThresholdCents: number;
  isActive: boolean;
  primaryPaymentMethod: {
    id: string;
    type: string;
    card: {
      lastFour: string;
      brand: string;
      expirationMonth: number;
      expirationYear: number;
    };
    isPrimary: boolean;
    isSecondary: boolean;
  };
  secondaryPaymentMethod: {
    id: string;
    type: string;
    card: {
      lastFour: string;
      brand: string;
      expirationMonth: number;
      expirationYear: number;
    };
    isPrimary: boolean;
    isSecondary: boolean;
  };
}

export const getMeter = async () => {
  const { data } = await axios.get<MeterResponse>(`/v3/user/meters`);
  return data;
};

export const GET_METER_QUERY = 'meter';

const useGetMeter = () => useQuery<MeterResponse>(GET_METER_QUERY, getMeter);

export default useGetMeter;
