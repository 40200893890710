import { Box, chakra, Flex, Hide, Show } from '@chakra-ui/react';
import { CommandMenu } from 'components/command-menu';
import { Navbar } from 'components/Navbar';
import { Sidebar } from 'components/Navbar/Sidebar';
import { OIDCBanner } from 'components/oidc-banner';
import { useUser } from 'contexts/auth-context';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useUserStore } from 'store';

export default function AppLayout() {
  const { boot, shutdown } = useIntercom();

  const user = useUser();
  const authToken = useUserStore((state) => state.authToken);

  const isSidebarCollapsed = useUserStore((state) => state.isSidebarCollapsed);
  const sidebarWidth = isSidebarCollapsed ? '24' : '64';

  // Reboot Intercom after login with user data
  useEffect(() => {
    shutdown();

    if (!user) return;

    boot({
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      userId: `${user.id}`,
    });
  }, [user, boot, shutdown]);

  // @CASE: Not logged in, redirect to login
  if (!authToken) return <Navigate to="/login" replace />;

  return (
    <>
      <OIDCBanner />
      <CommandMenu />
      <Box>
        <Hide above="lg">
          <Navbar />

          <chakra.main py="10">
            <Box px="4">
              <Outlet />
            </Box>
          </chakra.main>
        </Hide>

        {/* Desktop */}
        <Show above="lg">
          <Flex insetY="0" flexDir="row">
            <Box h="full" borderRight="1px">
              <Sidebar />
            </Box>
            <Box ml={sidebarWidth} sx={{ width: `calc(100% - var(--chakra-space-${sidebarWidth}) - 1px)` }}>
              <chakra.main mx="auto" maxW={{ base: 'full', '3xl': '2100px' }} px="8" h="100vh" py={6}>
                <Outlet />
              </chakra.main>
            </Box>
          </Flex>
        </Show>
      </Box>
    </>
  );
}
