import axios from 'config/axios';
import { UseMutationOptions, useMutation } from 'react-query';

export type ConnectShipstationResponse = any;
export type ConnectShipstationInput = any;

export const useConnectShipstation = (
  opts?: UseMutationOptions<ConnectShipstationResponse, {}, ConnectShipstationInput>,
) =>
  useMutation<ConnectShipstationResponse, {}, ConnectShipstationInput>(async (input: ConnectShipstationInput) => {
    const response = await axios.post('/v3/stores/shipstation/connect', input);
    return response.data;
  }, opts);
