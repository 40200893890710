import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { HAZARDOUS_MATERIALS } from 'constants/hazardous-materials';
import { LABEL_FORMATS } from 'constants/label-formats';
import { PAYMENT_OPTIONS } from 'constants/payment-method';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FiSliders } from 'react-icons/fi';
import { useQuickShipStore } from 'store';
import { addDays, isSame } from 'utils/dates';
import { z } from 'zod';
import { CountrySelect } from './CountrySelect';
import { useGetCarrierAccounts } from 'api/carrier-accounts';

const invalid_type_error = 'Must be a number';

const PaymentSchema = z
  .object({
    type: z.union([z.literal('SENDER'), z.literal('RECEIVER'), z.literal('THIRD_PARTY')]),
    account: z.string().optional(),
    postalCode: z.string().optional(),
    country: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.type === 'THIRD_PARTY') {
      // For THIRD_PARTY, all fields must be defined
      if (!data.account || !data.postalCode || !data.country) {
        ctx.addIssue({
          code: 'custom',
          path: ['account'],
          message: 'Account is required for THIRD_PARTY type.',
        });
        ctx.addIssue({
          code: 'custom',
          path: ['postalCode'],
          message: 'Postal Code is required for THIRD_PARTY type.',
        });
        ctx.addIssue({
          code: 'custom',
          path: ['country'],
          message: 'Country is required for THIRD_PARTY type.',
        });
      }
    }

    if (data.type === 'RECEIVER') {
      // For RECEIVER, only account and postalCode must be defined
      if (!data.account) {
        ctx.addIssue({
          code: 'custom',
          path: ['account'],
          message: 'Account is required for RECEIVER type.',
        });
      }
      if (!data.postalCode) {
        ctx.addIssue({
          code: 'custom',
          path: ['postalCode'],
          message: 'Postal Code is required for RECEIVER type.',
        });
      }
    }
  });

export const optionsSchema = z.object({
  options: z.object({
    labelDate: z.date().optional(),
    insuranceAmount: z.coerce.number({ invalid_type_error }).min(0).max(5000).optional(),
    deliveryConfirmation: z.string().optional(),
    isMediaMail: z.boolean(),
    isIrregularPackage: z.boolean(),
    saturdayDelivery: z.boolean(),
    carbonNeutral: z.boolean(),
    hazmat: z.string().optional(),
    reference: z.string().optional(),
    labelFormat: z.string().optional(),
    labelRequestSource: z.string().optional(),
    isReturn: z.boolean().optional(),
    additionalHandling: z.boolean().optional(),
    payment: PaymentSchema.optional(),
  }),
});

type FieldValues = z.infer<typeof optionsSchema>;

export function Options() {
  const { carrierAccountIds, showMoreOptions, patch } = useQuickShipStore();

  const { register, control, setValue, formState } = useFormContext<FieldValues>();
  const options = useWatch({ control, name: 'options' });
  const carrierAccounts = useGetCarrierAccounts();

  const isUPSDAP =
    carrierAccounts?.data?.find(
      (ca) => ca.type === 'UPSDAP' && (carrierAccountIds?.includes(ca.id) || carrierAccountIds === null),
    ) !== undefined;

  return (
    <Stack spacing="4">
      <Flex alignItems="center" gap="2">
        <Icon as={FiSliders} />
        <Text as="h3" fontSize="md" fontWeight="medium">
          Options
        </Text>
      </Flex>

      <Stack pt="0.5" spacing="3.5">
        <HStack>
          <FormControl
            variant={options?.labelDate ? 'floating-active' : 'floating'}
            isInvalid={!!formState.errors?.options?.labelDate}
          >
            <Controller
              name="options.labelDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  minDate={new Date()}
                  customInput={<Input placeholder=" " />}
                  selected={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <FormLabel>Label date</FormLabel>
          </FormControl>
          <ButtonGroup>
            <Button
              size="xs"
              variant={
                options?.labelDate && isSame(options?.labelDate, new Date(), 'days') ? 'solid' : 'outline'
              }
              onClick={() => setValue('options.labelDate', new Date())}
            >
              Today
            </Button>
            <Button
              size="xs"
              variant={
                options?.labelDate && isSame(options?.labelDate, addDays(new Date(), 1), 'days')
                  ? 'solid'
                  : 'outline'
              }
              onClick={() => setValue('options.labelDate', addDays(new Date(), 1))}
            >
              Tomorrow
            </Button>
          </ButtonGroup>
        </HStack>

        <FormControl variant="floating-active" isInvalid={!!formState.errors?.options?.deliveryConfirmation}>
          <Select {...register('options.deliveryConfirmation')}>
            <option value="">No signature</option>
            <option value="SIGNATURE">Signature required</option>
            <option value="ADULT_SIGNATURE">Adult signature required</option>
            <option value="ADULT_SIGNATURE_RESTRICTED">Adult signature restricted</option>
            <option value="SIGNATURE_RESTRICTED">Signature restricted</option>
          </Select>
          <FormLabel>Signature Confirmation</FormLabel>
        </FormControl>

        <FormControl variant="floating-active" isInvalid={!!formState.errors?.options?.insuranceAmount}>
          <HStack spacing="0">
            <Input
              {...register('options.insuranceAmount')}
              placeholder=" "
              defaultValue="0"
              roundedTopRight="0"
              roundedBottomRight="0"
            />
            <ButtonGroup fontWeight="normal" spacing="0" variant="outline" isAttached>
              {[100, 250].map((amount, index) => (
                <Button
                  key={amount}
                  h="10"
                  type="button"
                  onClick={() => setValue('options.insuranceAmount', amount)}
                  fontWeight="medium"
                  bg={amount === options?.insuranceAmount ? 'gray.100' : undefined}
                  ml={index === 0 ? '-1px' : undefined}
                  rounded="sm"
                >
                  ${amount}
                </Button>
              ))}
            </ButtonGroup>
          </HStack>
          <FormLabel>{isUPSDAP ? 'Declared Value' : 'Insurance'}</FormLabel>
        </FormControl>

        <FormControl variant="floating" isInvalid={!!formState.errors?.options?.reference}>
          <Input {...register('options.reference')} placeholder="" />
          <FormLabel>Reference</FormLabel>
        </FormControl>

        <Stack hidden={!showMoreOptions} gap="3.5">
          <FormControl variant="floating-active" isInvalid={!!formState.errors?.options?.labelFormat}>
            <Select {...register('options.labelFormat')} value={options?.labelFormat || 'PNG'}>
              {LABEL_FORMATS.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
            <FormLabel>Label Format</FormLabel>
          </FormControl>

          <FormControl variant="floating-active" isInvalid={!!formState.errors?.options?.hazmat}>
            <Select {...register('options.hazmat')}>
              <option color="blue" value="">
                - Select an option -
              </option>
              {HAZARDOUS_MATERIALS.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
            <FormLabel>Hazardous Materials</FormLabel>
          </FormControl>

          <Box
            {...(options?.payment?.type !== 'SENDER' && {
              border: '1px solid',
              borderColor: 'zinc.200',
              bg: 'zinc.50',
              py: '4',
              px: '3',
              rounded: 'lg',
            })}
          >
            <Stack spacing="4" mb="4">
              <FormControl variant="floating-active" isInvalid={!!formState.errors?.options?.payment?.type}>
                <Select {...register('options.payment.type')}>
                  {PAYMENT_OPTIONS.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
                <FormLabel>Payor</FormLabel>
              </FormControl>
            </Stack>

            {(options?.payment?.type === 'RECEIVER' || options?.payment?.type === 'THIRD_PARTY') && (
              <Stack spacing="4">
                <FormControl
                  variant="floating-active"
                  isInvalid={!!formState.errors?.options?.payment?.account}
                  isRequired
                >
                  <Input {...register('options.payment.account')} placeholder="" />
                  <FormLabel>Account</FormLabel>
                </FormControl>
                <FormControl
                  variant="floating-active"
                  isInvalid={!!formState.errors?.options?.payment?.postalCode}
                  isRequired
                >
                  <Input {...register('options.payment.postalCode')} placeholder="" />
                  <FormLabel>Postal Code</FormLabel>
                </FormControl>
                <FormControl
                  variant="floating-active"
                  isInvalid={!!formState.errors?.options?.payment?.country}
                  isRequired={options?.payment?.type === 'THIRD_PARTY'}
                >
                  <Controller
                    name="options.payment.country"
                    control={control}
                    render={({ field: { ref, value, onChange } }) => (
                      <CountrySelect value={value} ref={ref} onChange={onChange} />
                    )}
                  />
                  <FormLabel>Country</FormLabel>
                </FormControl>
              </Stack>
            )}
          </Box>

          <Stack>
            <Controller
              name="options.isMediaMail"
              control={control}
              render={({ field }) => (
                <Checkbox onChange={field.onChange} ref={field.ref} isChecked={field.value}>
                  <Text fontSize="sm">Media Mail</Text>
                </Checkbox>
              )}
            />

            <Controller
              name="options.isIrregularPackage"
              control={control}
              render={({ field }) => (
                <Checkbox onChange={field.onChange} ref={field.ref} isChecked={field.value}>
                  <Text fontSize="sm">Irregular Package</Text>
                </Checkbox>
              )}
            />

            <Controller
              name="options.saturdayDelivery"
              control={control}
              render={({ field }) => (
                <Checkbox onChange={field.onChange} ref={field.ref} isChecked={field.value}>
                  <Text fontSize="sm">Saturday Delivery</Text>
                </Checkbox>
              )}
            />

            <Controller
              name="options.carbonNeutral"
              control={control}
              render={({ field }) => (
                <Checkbox onChange={field.onChange} ref={field.ref} isChecked={field.value}>
                  <Text fontSize="sm">Carbon Neutral</Text>
                </Checkbox>
              )}
            />

            <Controller
              name="options.isReturn"
              control={control}
              render={({ field }) => (
                <Checkbox onChange={field.onChange} ref={field.ref} isChecked={field.value}>
                  <Text fontSize="sm">Return Label</Text>
                </Checkbox>
              )}
            />

            <Controller
              name="options.additionalHandling"
              control={control}
              render={({ field }) => (
                <Checkbox onChange={field.onChange} ref={field.ref} isChecked={field.value}>
                  <Text fontSize="sm">Additional Handling</Text>
                </Checkbox>
              )}
            />
          </Stack>
        </Stack>

        <Flex>
          <Button variant="outline" size="xs" onClick={() => patch({ showMoreOptions: !showMoreOptions })}>
            Show {showMoreOptions ? 'fewer' : 'more'} options...
          </Button>
        </Flex>
      </Stack>
    </Stack>
  );
}
