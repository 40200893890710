import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { isString } from 'lodash';
import startCase from 'lodash/startCase';
import { FC, ReactNode } from 'react';

interface PropTypes {
  header: string;
  description: string | ReactNode;
  type: 'delete' | 'confirm';
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  isLoading?: boolean;
}

export const ConfirmationModal: FC<PropTypes> = ({
  isOpen,
  onClose,
  onConfirm,
  type,
  header,
  description,
  isLoading,
}) => {
  const handleConfirm = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <ModalBody>{isString(description) ? <Text color="muted">{description}</Text> : <>{description}</>}</ModalBody>
          <ModalFooter>
            <Button isDisabled={isLoading} variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              ml="2"
              isLoading={isLoading}
              onClick={handleConfirm}
              colorScheme={type === 'delete' ? 'red' : 'brand'}
            >
              {startCase(type)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
