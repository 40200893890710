import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { Store, StoreSyncInterval } from '../get';

type UpdateStore = {
  storeId: number;
  name?: string;
  syncInterval?: StoreSyncInterval;
  fulfillmentLocationId?: string;
};

type UpdateStoreResponse = Store;

export const updateStore = async (input: UpdateStore) => {
  const { storeId, name, syncInterval, fulfillmentLocationId } = input;

  const { data } = await axios.put(`/v3/stores/shopify/${storeId}`, {
    name,
    syncInterval,
    fulfillmentLocationId,
  });

  return data;
};

const useUpdateShopifyStore = (opts?: UseMutationOptions<UpdateStoreResponse, {}, UpdateStore>) =>
  useMutation<UpdateStoreResponse, {}, UpdateStore>(updateStore, opts);

export default useUpdateShopifyStore;
