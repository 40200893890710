import axios from 'config/axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { Action, Condition, ConditionFilter, SelectConditionValue } from 'types/automation';
import { paths } from '../types';

/**
 * Create Automation
 */
export type CreateAutomationInput = {
  conditions: Condition[];
  actions: Action[];
  name: string;
  isActive?: boolean;
};
export type CreateAutomationResponse =
  paths['/api/v3/automations']['post']['responses']['200']['content']['application/json'];
export const useCreateAutomation = (
  opts?: UseMutationOptions<CreateAutomationResponse, Error, CreateAutomationInput>,
) =>
  useMutation<CreateAutomationResponse, Error, CreateAutomationInput>(async (input) => {
    return axios.post(`/v3/automations`, input).then((res) => res?.data);
  }, opts);

/**
 * Get Automations
 */
export const GET_AUTOMATIONS_QUERY = 'getAutomations';
export type GetAutomationsResponse =
  paths['/api/v3/automations']['get']['responses']['200']['content']['application/json'];

export const useGetAutomations = (opts?: UseQueryOptions<any>) =>
  useQuery<GetAutomationsResponse>(
    [GET_AUTOMATIONS_QUERY],
    async () => axios.get(`/v3/automations`, {}).then((res) => res?.data),
    opts ?? {},
  );

/**
 * Get Automation
 */
export const GET_AUTOMATION_QUERY = 'getAutomation';
export type GetAutomationInput = { id: number };
export type GetAutomationResponse = {
  id: number;
  name: string;
  conditions: Array<Condition>;
  actions: Array<Action>;
  priority: number;
  createdAt: string;
  isActive: boolean;
};
export const useGetAutomation = (
  input: GetAutomationInput,
  opts?: UseQueryOptions<GetAutomationInput, Error, GetAutomationResponse>,
) =>
  useQuery<GetAutomationInput, Error, GetAutomationResponse>(
    [GET_AUTOMATION_QUERY],
    async () => axios.get(`/v3/automations/${input.id}`).then((res) => res?.data),
    opts ?? {},
  );

/**
 * Get Automation Filters
 */
export type GetAutomationFiltersResponse = {
  actions: Array<Omit<Action, 'data'> & { options: SelectConditionValue[] }>;
  conditions: ConditionFilter;
};
export const useGetAutomationFilters = (opts?: UseQueryOptions<GetAutomationFiltersResponse>) =>
  useQuery<GetAutomationFiltersResponse>(
    [],
    async () => axios.get(`/v3/automations/filters`).then((res) => res?.data),
    opts ?? {},
  );

/**
 * Update Automation
 */
export type UpdateAutomationInput = Partial<CreateAutomationInput> & { id: number };
export type UpdateAutomationResponse = CreateAutomationResponse;
export const useUpdateAutomation = (
  opts?: UseMutationOptions<UpdateAutomationResponse, Error, UpdateAutomationInput>,
) =>
  useMutation<UpdateAutomationResponse, Error, UpdateAutomationInput>(async (input) => {
    const { id, ...payload } = input;
    const response = await axios.put(`/v3/automations/${id}`, payload);
    return response?.data;
  }, opts);

/**
 * Run Automations
 */
export type RunAutomationsInput = { orderIds?: number[] };
export const useRunAutomations = (opts?: UseMutationOptions<any, Error, RunAutomationsInput>) =>
  useMutation<any, Error, RunAutomationsInput>((input) => {
    const payload = { orderIds: input.orderIds ?? [] };
    return axios.post(`/v3/automations/run`, payload).then((res) => res?.data);
  }, opts);

/**
 * Delete Automation
 */
export type RemoveAutomationInput = { id: number };
export const useRemoveAutomation = (opts?: UseMutationOptions<Response, Error, RemoveAutomationInput>) =>
  useMutation<Response, Error, RemoveAutomationInput>((input) => {
    return axios.delete(`/v3/automations/${input.id}`);
  }, opts);

/**
 * Reorder Automations
 */
export type ReorderAutomationsInput = Array<{ id: number; priority: number }>;
export const useReorderAutomations = (opts?: UseMutationOptions<Response, Error, ReorderAutomationsInput>) =>
  useMutation<Response, Error, ReorderAutomationsInput>((input) => {
    return axios.post(`/v3/automations/reorder`, input).then((res) => res?.data);
  }, opts);
