import { useQuery, UseQueryOptions } from 'react-query';
import axios from '../../config/axios';

export interface MeterBalance {
  amountCents: number;
}

export const getMeterBalance = async () => {
  const { data } = await axios.get<MeterBalance>(`/v3/user/balance`);
  return data;
};

export const GET_BALANCE_QUERY = 'meter_balance';

const useGetMeterBalance = (options?: UseQueryOptions<MeterBalance>) =>
  useQuery<MeterBalance>(GET_BALANCE_QUERY, getMeterBalance, options);

export default useGetMeterBalance;
