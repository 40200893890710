import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import axios from '../../config/axios';
import { paths } from 'api/types';

export type Response = paths['/api/v3/batch/orders']['post']['responses']['200']['content']['application/json'];

export type Input = {
  orderIds: number[];
};

type Error = AxiosError<{ message?: string; error?: string }>;

const createBatchOrder = async (input: Input) => {
  const { data } = await axios.post<Response>(`/v3/batch/orders`, input);
  return data;
};

export const useCreateBatchOrders = (opts?: UseMutationOptions<Response, Error, Input>) =>
  useMutation<Response, Error, Input>(createBatchOrder, opts);
