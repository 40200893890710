import { Box, Stack } from '@chakra-ui/react';
import * as React from 'react';

interface NavGroupProps {
  children: React.ReactNode;
}

export const NavGroup = (props: NavGroupProps) => {
  const { children } = props;

  // When the user has no permissions to view a certain group of menus,
  // the NavGroup will have [] as children. This condition prevents it
  // from rendering an empty group.
  if (Array.isArray(children) && children.length === 0) return null;

  return (
    <Box>
      <Stack spacing="0.5">{children}</Stack>
    </Box>
  );
};
