import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  ButtonGroup,
  chakra,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { EmptyState } from 'components/EmptyState';
import { queryClient } from 'config/query-client';
import noop from 'lodash/noop';
import uniq from 'lodash/uniq';
import { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FiEdit, FiAlertTriangle } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { z } from 'zod';
import { GET_PRODUCTS_QUERY, GetProductsResponse } from 'api/products/get';
import { max, min, startCase } from 'lodash';
import { useUpdateProduct, Variables as PutProductBody } from 'api/products/update';
import { TagInput } from 'components/tag-input';

type Product = ArrayElement<GetProductsResponse['results']>;

interface Props {
  onClose: () => void;
  isOpen: boolean;
  products: Product[];
  handleBulkEdit: () => void;
}

type Field = 'dimensions' | 'weight' | 'tags';

const invalid_type_error = 'Must be a number';

const Schema = z.object({
  weightOz: z.coerce.number({ invalid_type_error }).min(0).max(1900).optional(),
  weightLbs: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  length: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  width: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  height: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  tags: z.array(z.string()),
});

type FormValues = z.infer<typeof Schema>;

export const BulkEditDrawer: FC<Props> = (props) => {
  const { isOpen, onClose, products, handleBulkEdit } = props;

  const [visibleFields, setVisibleFields] = useState<Field[]>([]);

  const isVisible = (field: Field) => visibleFields.includes(field);
  const toggleField = (field: Field) =>
    visibleFields.includes(field)
      ? setVisibleFields(uniq(visibleFields.filter((f) => f !== field)))
      : setVisibleFields(uniq([...visibleFields, field]));

  const maxWeight = max(products.map((p) => p?.weightOz || 0));
  const minWeight = min(products.map((p) => p?.weightOz || 0));
  const platforms = uniq(
    products
      .map((p) => p?.platforms)
      .flat()
      .map((name) => startCase(name ?? 'wos')),
  );

  const weightMarkup = maxWeight === minWeight ? `${maxWeight} oz` : `${minWeight} - ${maxWeight} oz`;

  const productsTags = products
    .filter((product) => product?.tags?.length)
    .map((product) => product?.tags)
    .flat()
    .map((tag) => tag?.name) as string[];

  const methods = useForm({
    shouldUnregister: false,
    resolver: zodResolver(Schema),
    defaultValues: {
      length: undefined,
      width: undefined,
      height: undefined,
      weightOz: undefined,
      weightLbs: undefined,
      weight: undefined,
      dimensions: undefined,
      tags: productsTags,
    },
  });

  const { register, formState, setError, getValues, setValue } = methods;
  const { errors } = formState;

  const updateProduct = useUpdateProduct();

  const onSubmit = async (values: FormValues): Promise<void> => {
    let hasError = false;

    if (isVisible('weight')) {
      if (!values.weightOz && !values.weightLbs) {
        setError('weight', { message: 'Required' });
        hasError = true;
      }
    }

    if (isVisible('dimensions')) {
      const { length, width, height } = values;

      const hasAllDims = [length, width, height].every((d) => d && d > 0);
      const hasNoDims = [length, width, height].every((d) => d === 0);

      if (!hasAllDims && !hasNoDims) {
        setError('dimensions', { message: 'Required' });
        hasError = true;
      }
    }

    if (hasError) return;

    await Promise.allSettled(
      products.map((product) => {
        if (!product) return;

        const { length = null, width = null, height = null, tags = [] } = values;

        const weight = isVisible('weight') ? Number(values.weightOz) + Number(values.weightLbs) * 16 : product.weightOz;

        if (!weight) {
          throw new Error('Weight is required');
        }

        const payload: PutProductBody = {
          productId: String(product?.id),
          sku: product.sku,
          heightIn: isVisible('dimensions') ? height : product.heightIn,
          lengthIn: isVisible('dimensions') ? length : product.lengthIn,
          widthIn: isVisible('dimensions') ? width : product.widthIn,
          weightOz: isVisible('weight') ? weight : product.weightOz,
          description: product.description,
          name: product.name,
          priceCents: product.priceCents,
          location: product.location,
          tags,
        };
        // eslint-disable-next-line consistent-return
        return updateProduct.mutate(payload, { onError: noop });
      }),
    );

    queryClient.invalidateQueries(GET_PRODUCTS_QUERY);
    handleBulkEdit();
    onClose();
  };

  if (!products?.length) return null;

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <chakra.form onSubmit={methods.handleSubmit(onSubmit)} h="full" fontSize="sm">
        <FormProvider {...methods}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>Bulk Edit Products ({products.length})</DrawerHeader>
            <DrawerBody p="0">
              <DrawerFooter fontSize="sm">
                <HStack alignItems="flex-start">
                  <FiAlertTriangle size={32} color="gray" fontSize="1.5em" />
                  <Text color="gray.500" paddingTop={1}>
                    {' '}
                    Attention! Only products created within the platform can be edited.
                  </Text>
                </HStack>
              </DrawerFooter>
              <Accordion defaultIndex={[0, 1]} allowMultiple>
                <AccordionItem>
                  <AccordionButton>
                    <Text fontWeight="medium" fontSize="md" py="3" flex="1" textAlign="left">
                      Overview
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Stack spacing="2">
                      <Stack spacing="2">
                        <Flex justify="space-between">
                          <Text fontWeight="medium">Weight</Text>
                          <Text color="muted">{weightMarkup}</Text>
                        </Flex>
                        <Flex justify="space-between">
                          <Text fontWeight="medium">Platform</Text>
                          <Text color="muted">{platforms.join(', ')}</Text>
                        </Flex>
                      </Stack>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Text fontWeight="medium" fontSize="md" py="3" flex="1" textAlign="left">
                      Bulk Edit
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb="6" pt="0">
                    <Stack spacing="2">
                      <ButtonGroup size="xs" isAttached>
                        <Button
                          variant={isVisible('dimensions') ? 'primary-on-accent' : 'outline'}
                          onClick={() => toggleField('dimensions')}
                        >
                          Dimensions
                        </Button>
                        <Button
                          variant={isVisible('weight') ? 'primary-on-accent' : 'outline'}
                          onClick={() => toggleField('weight')}
                        >
                          Weight
                        </Button>
                        <Button
                          variant={isVisible('tags') ? 'primary-on-accent' : 'outline'}
                          onClick={() => toggleField('tags')}
                        >
                          Tags
                        </Button>
                      </ButtonGroup>

                      {isVisible('dimensions') && (
                        <Stack spacing="1">
                          <Flex alignItems="center" justify="space-between">
                            <FormLabel>Dimensions</FormLabel>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              aria-label="Remove"
                              icon={<HiOutlineTrash color="indianred" />}
                              onClick={() => toggleField('dimensions')}
                            />
                          </Flex>
                          <Stack direction="row" spacing="2">
                            <FormControl
                              isInvalid={!!errors.length || !!errors.dimensions}
                              isDisabled={updateProduct.isLoading}
                            >
                              <Input {...register('length')} placeholder="Length" />
                              <FormErrorMessage>{errors.length?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={!!errors.width || !!errors.dimensions}
                              isDisabled={updateProduct.isLoading}
                            >
                              <Input {...register('width')} placeholder="Width" />
                              <FormErrorMessage>{errors.width?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={!!errors.height || !!errors.dimensions}
                              isDisabled={updateProduct.isLoading}
                            >
                              <Input {...register('height')} placeholder="Height" />
                              <FormErrorMessage>{errors.height?.message}</FormErrorMessage>
                            </FormControl>
                          </Stack>
                        </Stack>
                      )}

                      {isVisible('weight') && (
                        <Stack spacing="1">
                          <Flex alignItems="center" justify="space-between">
                            <FormLabel>Weight</FormLabel>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              aria-label="Remove"
                              icon={<HiOutlineTrash color="indianred" />}
                              onClick={() => toggleField('weight')}
                            />
                          </Flex>
                          <Stack direction="row" spacing="2">
                            <FormControl
                              isInvalid={!!errors.weightLbs || !!errors.weight}
                              isDisabled={updateProduct.isLoading}
                            >
                              <InputGroup>
                                <Input {...register('weightLbs')} placeholder="Weight" />
                                <InputRightElement>lbs</InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>{errors.weightLbs?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={!!errors.weightOz || !!errors.weight}
                              isDisabled={updateProduct.isLoading}
                            >
                              <InputGroup>
                                <Input {...register('weightOz')} placeholder="Weight" />
                                <InputRightElement>oz</InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>{errors.weightOz?.message}</FormErrorMessage>
                            </FormControl>
                          </Stack>
                        </Stack>
                      )}

                      {isVisible('tags') && (
                        <Stack spacing="1" h="300px">
                          <FormControl isInvalid={!!errors.tags} isDisabled={updateProduct.isLoading}>
                            <Flex alignItems="center" justify="space-between">
                              <FormLabel>Tags</FormLabel>
                              <IconButton
                                size="xs"
                                variant="ghost"
                                aria-label="Remove"
                                icon={<HiOutlineTrash color="indianred" />}
                                onClick={() => toggleField('tags')}
                              />
                            </Flex>
                            <Controller
                              name="tags"
                              control={methods.control}
                              render={({ field: { ref } }) => (
                                <TagInput
                                  ref={ref}
                                  value={getValues('tags')?.map((tag) => ({ value: tag, label: tag })) || []}
                                  onChange={(data) =>
                                    setValue(
                                      'tags',
                                      data.map((d) => d.value),
                                    )
                                  }
                                />
                              )}
                            />
                          </FormControl>
                        </Stack>
                      )}
                    </Stack>

                    {visibleFields.length === 0 && (
                      <EmptyState
                        my="4"
                        py="14"
                        px="4"
                        border="1px"
                        borderColor="gray.100"
                        bg="gray.50"
                        rounded="md"
                        header="No fields selected"
                        icon={FiEdit}
                      >
                        Choose one of the fields above to bulk edit your selected products.
                      </EmptyState>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </DrawerBody>

            <DrawerFooter>
              <Button
                disabled={visibleFields.length === 0}
                colorScheme="brand"
                type="submit"
                isLoading={updateProduct.isLoading}
              >
                Submit
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </FormProvider>
      </chakra.form>
    </Drawer>
  );
};
