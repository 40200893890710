import {
  Button,
  ButtonGroup,
  chakra,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetAddresses } from 'api/addresses';
import { usePostMutation } from 'api/client';
import { GetOrdersResponse } from 'api/orders/get';
import { EmptyState } from 'components/EmptyState';
import { TagInput } from 'components/tag-input';
import { isNil } from 'lodash';
import isNumber from 'lodash/isNumber';
import max from 'lodash/max';
import min from 'lodash/min';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FiEdit } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { z } from 'zod';

type Order = ArrayElement<GetOrdersResponse['results']>;

interface Props {
  onEdit: () => void;
  onClose: () => void;
  isOpen: boolean;
  orders: Order[];
}

type Field = 'dimensions' | 'weight' | 'insurance' | 'from' | 'tags' | 'shippingMethod';

const invalid_type_error = 'Must be a number';

const Schema = z.object({
  weightOz: z.coerce.number({ invalid_type_error }).min(0).max(1900).optional(),
  weightLbs: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  length: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  width: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  height: z.coerce.number({ invalid_type_error }).min(0).max(120).optional(),
  fromId: z.string().uuid().optional(),
  insuranceAmount: z.coerce.number({ invalid_type_error }).min(0).max(1000).optional(),
  tags: z.array(z.string()).optional(),
  shippingMethod: z.string().min(1, 'Required').optional(),
});

type FormValues = z.infer<typeof Schema>;

export const BulkEditDrawer: FC<Props> = (props) => {
  const { isOpen, onClose, orders, onEdit } = props;

  const getAddresses = useGetAddresses();

  const [visibleFields, setVisibleFields] = useState<Field[]>([]);

  const isVisible = (field: Field) => visibleFields.includes(field);
  const toggleField = (field: Field) =>
    visibleFields.includes(field)
      ? setVisibleFields(uniq(visibleFields.filter((f) => f !== field)))
      : setVisibleFields(uniq([...visibleFields, field]));

  const zones = orders.map((order) => order.uspsZone).filter(isNumber);
  const maxWeight = max(orders.map((o) => o.parcel?.weight || o.totalWeightOz || 0)) || 0;
  const minWeight = min(orders.map((o) => o.parcel?.weight || o.totalWeightOz || 0)) || 0;
  const platforms = uniq(orders.map((o) => startCase(o.platform)));
  const rawTags = orders.map((order) => order.tags).flat();
  const tags = [...new Map(rawTags.map((tag) => [tag?.id, tag])).values()];

  const methods = useForm({
    shouldUnregister: false,
    resolver: zodResolver(Schema),
    defaultValues: {
      length: undefined,
      width: undefined,
      height: undefined,
      weightOz: undefined,
      weightLbs: undefined,
      fromId: undefined,
      insuranceAmount: undefined,
      weight: undefined,
      dimensions: undefined,
      shippingMethod: undefined,
      tags: tags?.filter((tag) => !isNil(tag)).map((tag) => tag!.name) || [],
    },
  });

  const { register, formState, getValues, setValue, setError } = methods;
  const { errors } = formState;

  const bulkUpdateOrders = usePostMutation('/api/v3/orders/bulk-edit');

  const onSubmit = async (values: FormValues) => {
    if (visibleFields.length === 0) return;

    const validWeight = isVisible('weight') ? (values.weightLbs || 0) + (values.weightOz || 0) > 0 : true;
    const validDims = isVisible('dimensions')
      ? [values.height, values.length, values.width].every((d) => d && d > 0)
      : true;

    if (!validWeight) return setError('weightOz', { message: 'Required' });
    if (!validDims) return setError('dimensions', { message: 'Required' });

    type Input = Parameters<typeof bulkUpdateOrders.mutate>[0]['body']['data'];

    let parcel: Input['parcel'] = {};
    let tags: Input['tags'] = undefined;
    let notes: Input['notes'] = undefined;
    let insuranceCents: Input['insuranceCents'] = undefined;
    let from: Input['from'] = undefined;
    let shippingMethod: Input['shippingMethod'] = undefined;

    const { length = null, width = null, height = null, fromId, insuranceAmount } = values;

    if (isVisible('weight') && (values.weightOz || values.weightLbs)) {
      const oz = Number(values.weightOz || 0);
      const lbs = Number(values.weightLbs || 0);
      parcel.weight = oz + lbs * 16;
    }

    if (isVisible('dimensions')) {
      if (isNumber(length)) parcel.length = length;
      if (isNumber(width)) parcel.width = width;
      if (isNumber(height)) parcel.height = height;
    }

    if (isVisible('tags')) tags = values.tags;
    if (isVisible('insurance')) insuranceCents = Number(insuranceAmount) * 100;
    if (isVisible('from')) from = getAddresses.data?.results?.find((a) => a.id === fromId);
    if (isVisible('shippingMethod')) shippingMethod = values.shippingMethod;

    bulkUpdateOrders.mutate(
      {
        body: {
          data: { parcel, insuranceCents, notes, tags, from, shippingMethod },
          orderIds: orders.map((order) => order.id),
        },
      },
      {
        onSuccess: async () => {
          onEdit();
          onClose();
        },
      },
    );
  };

  const weightMarkup =
    maxWeight === minWeight
      ? `${maxWeight.toFixed(1)} oz`
      : `${minWeight.toFixed(1)} - ${maxWeight.toFixed(1)} oz`;

  if (!orders?.length) return null;

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="md">
      <chakra.form onSubmit={methods.handleSubmit(onSubmit)} h="full" fontSize="sm">
        <FormProvider {...methods}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>Bulk Edit Orders ({orders.length})</DrawerHeader>
            <DrawerBody>
              <Stack p={4} bg="zinc.50" border="1px" borderColor="zinc.200" rounded="lg">
                <Text fontWeight="semibold" fontSize="md" flex="1" textAlign="left">
                  Overview
                </Text>
                <Stack spacing="2">
                  <Stack spacing="2">
                    <Flex justify="space-between">
                      <Text fontWeight="medium">Zones</Text>
                      <Text color="muted">{uniq(zones).join(', ')}</Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text fontWeight="medium">Weight</Text>
                      <Text color="muted">{weightMarkup}</Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text fontWeight="medium">Platform</Text>
                      <Text color="muted">{platforms.join(', ')}</Text>
                    </Flex>
                  </Stack>
                </Stack>
              </Stack>

              <Stack spacing="3.5" mt={6}>
                <ButtonGroup size="sm" isAttached>
                  <Button
                    variant={isVisible('tags') ? 'solid' : 'outline'}
                    onClick={() => toggleField('tags')}
                  >
                    Tags
                  </Button>
                  <Button
                    variant={isVisible('from') ? 'solid' : 'outline'}
                    onClick={() => toggleField('from')}
                  >
                    From
                  </Button>
                  <Button
                    variant={isVisible('dimensions') ? 'solid' : 'outline'}
                    onClick={() => toggleField('dimensions')}
                  >
                    Dimensions
                  </Button>
                  <Button
                    variant={isVisible('weight') ? 'solid' : 'outline'}
                    onClick={() => toggleField('weight')}
                  >
                    Weight
                  </Button>
                  <Button
                    variant={isVisible('insurance') ? 'solid' : 'outline'}
                    onClick={() => toggleField('insurance')}
                  >
                    Insurance
                  </Button>
                  <Button
                    variant={isVisible('shippingMethod') ? 'solid' : 'outline'}
                    onClick={() => toggleField('shippingMethod')}
                  >
                    Ship. Method
                  </Button>
                </ButtonGroup>

                {isVisible('tags') && (
                  <FormControl isInvalid={!!errors.tags} isDisabled={bulkUpdateOrders.isLoading}>
                    <Flex alignItems="center" justify="space-between">
                      <FormLabel>Tags</FormLabel>
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Remove"
                        icon={<HiOutlineTrash color="indianred" />}
                        onClick={() => toggleField('tags')}
                      />
                    </Flex>
                    <Controller
                      name="tags"
                      control={methods.control}
                      render={({ field: { ref } }) => (
                        <TagInput
                          ref={ref}
                          value={getValues('tags')?.map((tag) => ({ value: tag, label: tag })) || []}
                          onChange={(data) =>
                            setValue(
                              'tags',
                              data.map((d) => d.value),
                            )
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}

                {isVisible('from') && (
                  <FormControl isDisabled={bulkUpdateOrders.isLoading}>
                    <Flex alignItems="center" justify="space-between">
                      <FormLabel>From</FormLabel>
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Remove"
                        icon={<HiOutlineTrash color="indianred" />}
                        onClick={() => toggleField('from')}
                      />
                    </Flex>
                    <Select {...register('fromId')}>
                      {getAddresses?.data?.results?.map((address) => (
                        <option value={address.id} key={address.id}>
                          {address.line1}, {address.city}, {address.state} {address.primary && '(Primary)'}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {isVisible('dimensions') && (
                  <Stack spacing="1">
                    <Flex alignItems="center" justify="space-between">
                      <FormLabel>Dimensions</FormLabel>
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Remove"
                        icon={<HiOutlineTrash color="indianred" />}
                        onClick={() => toggleField('dimensions')}
                      />
                    </Flex>
                    <Stack direction="row" spacing="2">
                      <FormControl
                        isInvalid={!!errors.length || !!errors.dimensions}
                        isDisabled={bulkUpdateOrders.isLoading}
                      >
                        <Input {...register('length')} placeholder="Length" />
                        <FormErrorMessage>{errors.length?.message}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.width || !!errors.dimensions}
                        isDisabled={bulkUpdateOrders.isLoading}
                      >
                        <Input {...register('width')} placeholder="Width" />
                        <FormErrorMessage>
                          {errors.width?.message || errors.dimensions?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.height || !!errors.dimensions}
                        isDisabled={bulkUpdateOrders.isLoading}
                      >
                        <Input {...register('height')} placeholder="Height" />
                        <FormErrorMessage>{errors.height?.message}</FormErrorMessage>
                      </FormControl>
                    </Stack>
                  </Stack>
                )}

                {isVisible('weight') && (
                  <Stack spacing="1">
                    <Flex alignItems="center" justify="space-between">
                      <FormLabel>Weight</FormLabel>
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Remove"
                        icon={<HiOutlineTrash color="indianred" />}
                        onClick={() => toggleField('weight')}
                      />
                    </Flex>
                    <Stack direction="row" spacing="2">
                      <FormControl
                        isInvalid={!!errors.weightLbs || !!errors.dimensions}
                        isDisabled={bulkUpdateOrders.isLoading}
                      >
                        <InputGroup>
                          <Input {...register('weightLbs')} placeholder="Weight" />
                          <InputRightElement>lbs</InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {errors.weightLbs?.message || errors.dimensions?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.weightOz || !!errors.dimensions}
                        isDisabled={bulkUpdateOrders.isLoading}
                      >
                        <InputGroup>
                          <Input {...register('weightOz')} placeholder="Weight" />
                          <InputRightElement>oz</InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.weightOz?.message}</FormErrorMessage>
                      </FormControl>
                    </Stack>
                  </Stack>
                )}

                {isVisible('insurance') && (
                  <FormControl isInvalid={!!errors.insuranceAmount} isDisabled={bulkUpdateOrders.isLoading}>
                    <Flex alignItems="center" justify="space-between">
                      <FormLabel>Insurance</FormLabel>
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Remove"
                        icon={<HiOutlineTrash color="indianred" />}
                        onClick={() => toggleField('insurance')}
                      />
                    </Flex>
                    <InputGroup>
                      <InputLeftElement>$</InputLeftElement>
                      <Input {...register('insuranceAmount')} placeholder="0" />
                      <InputRightElement>USD</InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}

                {isVisible('shippingMethod') && (
                  <FormControl isInvalid={!!errors.shippingMethod} isDisabled={bulkUpdateOrders.isLoading}>
                    <HStack justifyContent="space-between">
                      <FormLabel>Shipping Method</FormLabel>
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Remove"
                        icon={<HiOutlineTrash color="indianred" />}
                        onClick={() => toggleField('shippingMethod')}
                      />
                    </HStack>
                    <Input {...register('shippingMethod')} />
                  </FormControl>
                )}
              </Stack>

              {visibleFields.length === 0 && (
                <EmptyState
                  my="4"
                  py="14"
                  px="4"
                  border="1px"
                  borderColor="gray.100"
                  bg="gray.50"
                  rounded="md"
                  header="No fields selected"
                  icon={FiEdit}
                >
                  Choose one of the fields above to bulk edit your selected orders.
                </EmptyState>
              )}
            </DrawerBody>

            <DrawerFooter>
              <Button
                type="submit"
                colorScheme="brand"
                isLoading={bulkUpdateOrders.isLoading}
                isDisabled={visibleFields.length === 0}
              >
                Submit
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </FormProvider>
      </chakra.form>
    </Drawer>
  );
};
