import { ReactChild, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
  children: ReactChild | ReactChild[] | ReactNode;
}
const ContainerLayout = ({ children }: Props) => {
  return (
    <Box marginX="auto" w="full">
      <Box borderRadius="2xl" height="auto" backgroundColor="transparent">
        {children}
      </Box>
    </Box>
  );
};

export default ContainerLayout;
