import { Spinner } from '@chakra-ui/spinner';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useGetAddresses } from 'api/addresses';
import useGetMe from 'api/auth/me';
import { useState } from 'react';

export const PaymentElementContainer = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: `${import.meta.env.VITE_PUBLIC_URL || 'https://app.vesyl.com'}/billing`,
        },
      })
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message || 'Unknown error');
        }
      });
  };

  const { data: getMeData, status: getMeStatus } = useGetMe();
  const { data: getAddressData, status: getAddressStatus } = useGetAddresses();
  const primaryAddress = getAddressData?.results?.find((a) => a.primary === true);
  let paymentElementOptions: StripePaymentElementOptions = {};
  if (getMeStatus === 'success' && getAddressStatus === 'success') {
    paymentElementOptions = {
      terms: { usBankAccount: 'always' },
      defaultValues: {
        billingDetails: {
          name: `${getMeData?.firstName} ${getMeData?.lastName}`,
          email: getMeData?.email,
          phone: getMeData?.phone,
          address: {
            line1: primaryAddress?.line1,
            line2: primaryAddress?.line2 || '',
            city: primaryAddress?.city,
            state: primaryAddress?.state,
            postal_code: primaryAddress?.postal,
            country: primaryAddress?.country,
          },
        },
      },
    };
  }

  return (
    <>
      {paymentElementOptions.defaultValues ? (
        <form onSubmit={handleSubmit} id="stripe-add-payment">
          {errorMessage && <p> {errorMessage} </p>}
          <PaymentElement options={paymentElementOptions} />
        </form>
      ) : (
        <Spinner />
      )}
    </>
  );
};
