import { Box, BoxProps, Button, ButtonProps, Flex, Icon, IconProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons/lib';

interface Props extends BoxProps {
  header: string;
  children: React.ReactNode;
  primaryAction?: ButtonProps;
  secondaryAction?: ButtonProps;
  icon: IconType;
  iconProps?: IconProps;
}

export const EmptyState: FC<Props> = (props) => {
  const { header, children, primaryAction, secondaryAction, icon, iconProps, ...rest } = props;

  const primaryActionMarkup = primaryAction && (
    <Button colorScheme="brand" {...primaryAction}>
      {primaryAction.children}
    </Button>
  );

  const secondaryActionMarkup = secondaryAction && (
    <Button {...secondaryAction}>{secondaryAction?.children}</Button>
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDir="column" {...rest}>
      <Icon as={icon} h="8" w="8" mb="1" color="muted" {...iconProps} />
      <Text mt="2" fontWeight="semibold">
        {header}
      </Text>
      <Box mt="1" color="muted" textAlign="center">
        {children}
      </Box>
      {(primaryAction || secondaryAction) && (
        <Flex mt="4" justifyContent="center" gap="2">
          {secondaryActionMarkup}
          {primaryActionMarkup}
        </Flex>
      )}
    </Box>
  );
};
