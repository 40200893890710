export enum StorePlatform {
  Ebay = 'ebay',
  Ebay2 = 'ebay2',
  Shopify = 'shopify',
  WooCommerce = 'woocommerce',
  Etsy = 'etsy',
  ShipStation = 'shipstation',
  Extensiv = 'extensiv',
}

export enum OrderStatus {
  Open = 'open',
  ReadyToShip = 'ready_to_ship',
  Completed = 'completed',
  Canceled = 'canceled',
  Unknown = 'unknown',
  UnverifiedAddress = 'unverified_address',
  OnHold = 'on_hold',
  Archived = 'archived',
}

export enum PhoneVerificationStatus {
  Pending = 'pending',
  Approved = 'approved',
  Canceled = 'canceled',
}

export enum Carrier {
  CDL = 'CDL',
  EPG = 'RRDonnelley',
  FEDEX = 'FedEx',
  FEDEXSMARTPOST = 'FedExSmartPost',
  LASERSHIP = 'LaserShip',
  OSM = 'OSMWorldwide',
  UDS = 'UDS',
  UPS = 'UPSDAP',
  UPS2 = 'UPS',
  UPSMAILINNOVATIONS = 'UPSMailInnovations',
  UPSSUREPOST = 'UPSSurePost',
  USPS = 'USPS',
  VEHO = 'Veho',
  AXLEHIRE = 'Axlehire',
}
