import axios from 'config/axios';
import { useMutation, UseMutationOptions } from 'react-query';

type ConnectExtensivResult = { isSuccess: boolean };
type ConnectExtensivInput = { login: string; name: string };

const connect = async (input: ConnectExtensivInput) => {
  if (!input) return null;
  const { name, login: userLoginName } = input;

  const result = await axios.get(`/v3/stores/extensiv/connect`, { params: { name, userLoginName } });
  return result?.data;
};

export const useConnectExtensivStore = (opts?: UseMutationOptions<ConnectExtensivResult, {}, ConnectExtensivInput>) =>
  useMutation<ConnectExtensivResult, {}, ConnectExtensivInput>(connect, opts);
