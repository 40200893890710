import { paths } from 'api/types';
import { axios } from 'config/axios';
import { UseQueryOptions, useQuery } from 'react-query';

export type GetProductFilesResponse =
  paths['/api/v3/products/files']['get']['responses']['200']['content']['application/json'];

export const GET_PRODUCT_FILES_QUERY = 'get_product_files';

export const useGetProductFiles = (input?: any, opts?: UseQueryOptions<any>) =>
  useQuery<GetProductFilesResponse>(
    [GET_PRODUCT_FILES_QUERY, input],
    async () => {
      const result = await axios.get(`/v3/products/files`);
      return result?.data;
    },
    opts ?? {},
  );
