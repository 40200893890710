import {
  Box,
  Button,
  Center,
  FormLabel,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useGetQuery, usePostMutation } from 'api/client';
import { Dropzone } from 'components/dropzone';
import { axios } from 'config/axios';
import { useState } from 'react';
import { FiArrowRight, FiCheck, FiChevronDown } from 'react-icons/fi';
import { useMutation } from 'react-query';
import { useNavigate } from 'router';

interface PurchaseOrderImportModalProps extends Omit<ModalProps, 'children'> {}

export function PurchaseOrderImportModal(props: PurchaseOrderImportModalProps) {
  const { ...rest } = props;

  const fields = [
    { name: 'SKU', value: 'sku' },
    { name: 'Quantity', value: 'quantity' },
    { name: 'Lot Number (Optional)', value: 'lotNumber' },
  ];

  const navigate = useNavigate();

  const [fileId, setFileId] = useState<number | null>(null);
  const [mapping, setMapping] = useState<{ [key: string]: string }>({});

  const addMapping = (header: string, field: string) => {
    const newMapping = { ...mapping };
    Object.keys(newMapping).forEach((key) => {
      if (mapping[key] === field && key !== header) delete newMapping[key];
    });
    newMapping[header] = field;
    setMapping(newMapping);
  };

  const isMapped = (field: string) => {
    return !!Object.values(mapping).find((ma) => ma === field);
  };

  const importPo = usePostMutation('/api/v3/purchase-orders/import');
  const poFile = useGetQuery('/api/v3/purchase-orders/files/{id}', {
    params: { path: { id: fileId! } },
    rq: { enabled: !!fileId },
  });

  // Custom hook, RQ hooks don't work well with multi-part uploads
  const createPoFile = useMutation({
    mutationFn: async (input: File) => {
      const formData = new FormData();
      formData.append('file', input);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const url = `/v3/purchase-orders/files`;
      const { data } = await axios.post(url, formData, { headers });
      return data;
    },
  });

  const onFileDrop = async (files: File[]) => {
    const file = await createPoFile.mutateAsync(files[0]);
    setFileId(file.id);
  };

  const handlePOImport = () => {
    if (!fileId) return;

    importPo.mutate(
      { body: { fileId, mapping } },
      {
        onSuccess: (result) => {
          if (!result?.id) return;
          props.onClose();
          navigate('/inventories/receiving/:poId', {
            params: { poId: String(result.id) },
          });
        },
      },
    );
  };

  return (
    <Modal {...rest} size={poFile.data ? '3xl' : 'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Purchase Orders</ModalHeader>
        <ModalBody>
          {!createPoFile.isSuccess && !createPoFile.isLoading && (
            <Dropzone dropzoneOptions={{ onDrop: onFileDrop }} minH="xs" />
          )}

          {createPoFile.isLoading && (
            <Stack minH="xs" alignItems={'center'} justifyContent={'center'}>
              <Spinner />
              <Text>Uploading...</Text>
            </Stack>
          )}

          {poFile.data && (
            <Stack>
              {poFile.data.headers.map((header) => (
                <Box
                  rounded="lg"
                  key={header.name}
                  p={2}
                  bg={!!mapping[header.name] ? 'green.50' : undefined}
                >
                  <SimpleGrid key={header.name} rounded="lg" spacing="0" columns={3}>
                    <Stack pl="2" py="2">
                      <HStack spacing="1">
                        <FormLabel marginInlineEnd="0" mb="0">
                          {header.name}
                        </FormLabel>
                        {!!mapping[header.name] && <Icon as={FiCheck} color="green.500" />}
                      </HStack>
                      <Text fontStyle="italic" fontSize="xs" color="gray.500">
                        {header.examples.filter(Boolean).slice(0, 3).join(', ') || '-'}
                      </Text>
                    </Stack>

                    <Center>
                      <FiArrowRight color="gray" />
                    </Center>

                    <Menu closeOnSelect={false} closeOnBlur placement="bottom" isLazy>
                      <MenuButton
                        m="auto"
                        w="full"
                        size="sm"
                        as={Button}
                        color={mapping[header.name] ? 'green.500' : undefined}
                        variant="outline"
                        fontWeight="normal"
                        rightIcon={<FiChevronDown />}
                      >
                        {fields.find((f) => f.value === mapping[header.name])?.name || 'Select field'}
                      </MenuButton>
                      <MenuList
                        transform="none !important"
                        fontSize="sm"
                        w="fit-content"
                        maxH="xs"
                        overflow="auto"
                      >
                        {fields.map((field) => (
                          <MenuItemOption
                            key={field.value}
                            value={field.value}
                            isDisabled={isMapped(field.value)}
                            onClick={() => addMapping(header.name, field.value)}
                          >
                            {field.name}
                          </MenuItemOption>
                        ))}
                      </MenuList>
                    </Menu>
                  </SimpleGrid>
                </Box>
              ))}
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant={'ghost'}>Cancel</Button>
          <Button colorScheme="brand" onClick={handlePOImport}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
