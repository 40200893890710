import { Button, ChakraProvider, Flex, Stack, Text } from '@chakra-ui/react';
import '@fontsource/inter/variable.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Fonts } from 'components/fonts';
import { queryClient } from 'config/query-client';
import { AuthProvider } from 'contexts/auth-context';
import { NotificationProvider } from 'contexts/notification.context';
import { StrictMode, useEffect } from 'react';
import 'react-day-picker/dist/style.css';
import { LuArrowLeft } from 'react-icons/lu';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet, useSearchParams } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { useNavigate } from 'router';
import { useUserStore } from 'store';
import 'styles/ag-grid-theme.css';
import 'styles/globals.css';
import { theme } from 'theme';

const isProduction = import.meta.env.PROD;
const intercomAppId = isProduction ? 'fh68jelt' : '';
const enableReactQueryDevTools = !Boolean(import.meta.env.VITE_DISABLE_REACT_QUERY_DEVTOOLS);

export default function App() {
  const [searchParams] = useSearchParams();

  // Users who signup from the OAuth flow have this param set
  // We use it to display a special banner (<OIDCBanner />) with
  // instructions on how to set up payment.
  const oidcClient = searchParams.get('oidc_client');
  const patch = useUserStore((state) => state.patch);

  useEffect(() => {
    // Saves the oidcClient to the store so we can display the banner
    if (oidcClient && patch) patch({ oidcClient });
  }, [oidcClient, patch]);

  return (
    <StrictMode>
      <ChakraProvider theme={theme} resetCSS>
        <Fonts />
        <QueryClientProvider client={queryClient}>
          <IntercomProvider appId={intercomAppId}>
            <NotificationProvider>
              <AuthProvider>
                <Outlet />
              </AuthProvider>
            </NotificationProvider>
          </IntercomProvider>
          {enableReactQueryDevTools ? <ReactQueryDevtools /> : null}
        </QueryClientProvider>
      </ChakraProvider>
    </StrictMode>
  );
}

export const Catch = () => {
  const navigate = useNavigate();

  return (
    <ChakraProvider theme={theme} resetCSS>
      <Fonts />
      <Flex alignItems="center" justifyContent="center" h="100vh">
        <Stack gap={2} textAlign="center">
          <Text fontSize="xl" fontWeight="semibold">
            Something went wrong :(
          </Text>
          <Text color="muted">Please refresh the page or try again later.</Text>

          <Button
            w="fit-content"
            mx="auto"
            mt={4}
            variant="link"
            leftIcon={<LuArrowLeft />}
            onClick={() => navigate('/ship')}
          >
            Go back
          </Button>
        </Stack>
      </Flex>
    </ChakraProvider>
  );
};
