import { Center, HStack, Icon, Image, Stack, StackProps, Tag, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { FiPackage } from 'react-icons/fi';

export interface ProductsListProps {
  products: {
    id: number;
    name: string;
    sku: string;
    imageUrl: string | null;
    priceCents: number | null;
    quantity: number;
  }[];
}

export const ProductsList: FC<ProductsListProps & StackProps> = (props) => {
  const { products, ...rest } = props;
  return (
    <Stack {...rest}>
      {products?.map((product, index) => {
        return (
          <HStack key={index} alignItems="start" spacing="4">
            <HStack pos="relative">
              {product.imageUrl ? (
                <Image w="20" h="20" minW="20" rounded="md" alt="No Image" objectFit="cover" src={product.imageUrl} />
              ) : (
                <Center w="20" h="20" bg="gray.100">
                  <Icon boxSize={10} color="white" as={FiPackage} />
                </Center>
              )}
              <Tag border="1px" fontWeight="semibold" borderColor="gray.400" pos="absolute" top={-1} right={-3}>
                x {product.quantity}
              </Tag>
            </HStack>
            <Stack spacing="0">
              <Text pb="1" fontWeight="semibold" whiteSpace="normal">
                {product.name}
              </Text>
              <Text color="muted">{product.sku || '-'}</Text>
              <Text color="muted">{product.priceCents ? `$${(product.priceCents / 100).toFixed(2)}` : '-'}</Text>
            </Stack>
          </HStack>
        );
      })}
    </Stack>
  );
};
