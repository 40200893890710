import { paths } from 'api/types';
import axios from 'config/axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';

/**
 * Create File Mapping
 */
export type CreateFileMappingInput =
  paths['/api/v3/file-mappings']['post']['requestBody']['content']['application/json'];

export type CreateFileMappingResponse = any;
export const useCreateFileMapping = (
  opts?: UseMutationOptions<CreateFileMappingResponse, Error, CreateFileMappingInput>,
) =>
  useMutation<CreateFileMappingResponse, Error, CreateFileMappingInput>(
    async (input) =>
      axios.post(`/v3/file-mappings`, input, { headers: { 'X-No-Transform': 'true' } }).then((res) => res?.data),
    opts,
  );

/**
 * Get File Mappings
 */
export const GET_FILE_MAPPINGS_QUERY = 'getFileMappings';
export type GetFileMappingsResponse =
  paths['/api/v3/file-mappings']['get']['responses']['200']['content']['application/json'];

export const useGetFileMappings = (opts?: UseQueryOptions<any>) =>
  useQuery<GetFileMappingsResponse>(
    [GET_FILE_MAPPINGS_QUERY],
    async () => axios.get(`/v3/file-mappings`).then((res) => res?.data),
    opts ?? {},
  );
