import { Box, Flex, SimpleGrid, StackDivider, VStack, Text } from '@chakra-ui/react';
import { EmptyState } from 'components/EmptyState';
import { MdOutlineCreditCardOff } from 'react-icons/md';
import { useEffect, useMemo, useState } from 'react';
import { useGetMeterBalance } from 'api/meters';
import { useGetPaymentMethods } from 'api/payments';
import { PaymentCard } from 'types/payment';
import { BalanceTotal } from './balance-total';
import { BalanceMeter } from './balance-meter';
import { PaymentDetails } from './payment-details';
import { AddStripePaymentMethod } from './add-stripe-payment-method';
import { useUser } from '../../../../contexts/auth-context';

const Payment = () => {
  const { data: meterBalance } = useGetMeterBalance();
  const { data: paymentMethods } = useGetPaymentMethods();

  const user = useUser();
  const isTopUpVisible = user?.billingType !== 'invoicing';

  const [paymentOptions, setPaymentOptions] = useState<PaymentCard[]>([]);

  useEffect(() => {
    if (!paymentMethods) return;
    setPaymentOptions(paymentMethods);
  }, [paymentMethods]);

  const balance = useMemo(() => {
    return meterBalance?.amountCents || 0;
  }, [meterBalance]);

  return (
    <Box w="full">
      <Flex flexDirection={['column', 'column', 'row']} gap="6" align="top">
        {isTopUpVisible && (
          <VStack
            maxW={{ sm: 'full', lg: '80' }}
            border="1px"
            borderColor="gray.200"
            borderRadius={8}
            backgroundColor="white"
            p="8"
            spacing="6"
            divider={<StackDivider borderColor="gray.200" />}
          >
            <BalanceTotal balance={balance} />
            <BalanceMeter />
          </VStack>
        )}
        {paymentOptions.length > 0 ? (
          <SimpleGrid w="full" h="fit-content" gap="6" columns={{ sm: 1, xl: 2 }}>
            {paymentOptions.map((card: PaymentCard) => (
              <PaymentDetails key={card.id} paymentCard={card} />
            ))}
          </SimpleGrid>
        ) : (
          <Box w="full">
            <EmptyState minH="lg" header="No payment methods found" icon={MdOutlineCreditCardOff}>
              <VStack spacing={6}>
                <Text>Add your credit card or bank account to Vesyl to start buying labels</Text>
                <AddStripePaymentMethod />
              </VStack>
            </EmptyState>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default Payment;
