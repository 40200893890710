import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    background: 'white',
  },
});

const sizes = {
  md: { field: { fontSize: 'sm', rounded: 'sm', py: 'auto' } },
  sm: { field: { fontSize: 'sm', rounded: 'sm', py: 'auto' } },
  xs: { field: { rounded: 'md', py: '0' } },
};

export const selectTheme = defineMultiStyleConfig({ baseStyle, sizes });
