import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(formAnatomy.keys);

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-20px)',
};

const labelStyles = {
  mx: 3,
  px: 1,
  my: 2,
  top: 0,
  left: 0,
  zIndex: 1,
  position: 'absolute',
  pointerEvents: 'none',
  transformOrigin: 'left top',
};

// See more: https://chakra-ui.com/community/recipes/floating-labels

export const formTheme = defineMultiStyleConfig({
  variants: {
    'floating-active': ({ colorMode }) => ({
      container: {
        label: {
          ...labelStyles,
          ...activeLabelStyles,
          backgroundColor: colorMode === 'light' ? 'white' : 'gray.700',
        },
      },
    }),
    floating: ({ colorMode }) => ({
      container: {
        _focusWithin: { label: { ...activeLabelStyles } },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label, .fsl-multiselect + label':
          { ...activeLabelStyles },
        label: { ...labelStyles, backgroundColor: colorMode === 'light' ? 'white' : 'gray.700' },
      },
    }),
  },
  baseStyle: definePartsStyle({
    helperText: defineStyle({
      color: 'zinc.500',
      fontSize: 'xs',
    }),
    container: {
      label: {
        color: 'muted',
      },
    },
  }),
});
