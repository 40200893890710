import { Button, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { Store, useDeleteStore, useGetStores } from 'api/stores';
import { EmptyState } from 'components/EmptyState';
import { PageBody, PageHeader } from 'components/Page';
import { useNotification } from 'contexts/notification.context';
import { useDisclosure } from 'hooks/use-disclosure';
import { useQuery } from 'hooks/use-query';
import { FC, useEffect } from 'react';
import { FaStoreAltSlash } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'router';
import StoreCard from './_components/store-card';
import { ConnectStoreModal } from './_components/connect-store-modal';

export default function () {
  const navigate = useNavigate();
  const query = useQuery();

  const deleteStore = useDeleteStore();

  const { data: stores, refetch: refetchStores } = useGetStores();
  const { notifySuccess } = useNotification();
  const connectStoreModal = useDisclosure();

  const handleDelete = async (store: Store) => {
    deleteStore.mutate(
      { storeId: store.id, platform: store.platform },
      {
        onSuccess: (response) => {
          if (response) {
            notifySuccess('Your store has been successfully deleted');
            refetchStores();
          }
        },
      },
    );
  };

  useEffect(() => {
    if (query.get('new')) connectStoreModal.onOpen();
  }, [query, connectStoreModal.onOpen]);

  const isEmpty = stores?.length === 0;

  return (
    <>
      <PageHeader justifyContent="space-between">
        <Heading as="h1">Stores</Heading>
        <Button type="button" colorScheme="brand" leftIcon={<FiPlus />} onClick={connectStoreModal.onOpen}>
          Add Store
        </Button>
      </PageHeader>
      <PageBody>
        {isEmpty ? (
          <EmptyState
            py="60"
            icon={FaStoreAltSlash}
            header="No stores found"
            primaryAction={{
              children: 'Connect Store',
              onClick: connectStoreModal.onOpen,
            }}
          >
            <Text>Get started by connecting your store to Vesyl</Text>
          </EmptyState>
        ) : (
          <SimpleGrid columns={[1, 2, 3]} gap={3}>
            {stores?.map((item, index) => (
              <StoreCard
                key={index}
                store={item}
                onDelete={() => handleDelete(item)}
                onEdit={() =>
                  navigate(`/stores/:platform/:id`, {
                    params: { platform: item.platform, id: String(item.id) },
                  })
                }
              />
            ))}
          </SimpleGrid>
        )}
      </PageBody>
      {connectStoreModal.isOpen && <ConnectStoreModal {...connectStoreModal} />}
    </>
  );
}
