import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { RadioGroup } from '@headlessui/react';
import { GET_BALANCE_QUERY, GET_METER_QUERY } from 'api/meters';
import { CarrierLogo } from 'components/CarrierLogo';
import { EmptyState } from 'components/EmptyState';
import { PrintLabelModal } from 'components/print-label-modal';
import { queryClient } from 'config/query-client';
import { useDisclosure } from 'hooks/use-disclosure';
import { isEmpty, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  FiCheck,
  FiCheckCircle,
  FiChevronDown,
  FiClipboard,
  FiPrinter,
  FiSearch,
  FiXCircle,
} from 'react-icons/fi';
import { LuInfo } from 'react-icons/lu';
import { MdSearchOff } from 'react-icons/md';
import { useLocation } from 'react-router';
import useClipboard from 'react-use-clipboard';
import { useQuickShipStore } from 'store';
import { Order } from 'types';
import { toUSD } from 'utils/currency';
import { CustomsDrawer } from './CustomsDrawer';
import { usePostMutation } from 'api/client';
import { paths } from 'api/types';

type BuyShipmentInput = paths['/api/v3/shipments/buy']['post']['requestBody']['content']['application/json'];

type Props = { createShipment: ReturnType<typeof usePostMutation<'/api/v4/shipments'>> };

const DEFAULT_GROUPING_FILTERS = ['Economy', 'Standard', 'Expedited'];

export function Rates(props: Props) {
  const { createShipment } = props;
  const { control, resetField, getValues, formState, setValue } = useFormContext();
  const { patch, carrierAccountIds } = useQuickShipStore();

  const printLabelModal = useDisclosure();
  const customsDrawer = useDisclosure();

  const location = useLocation();
  const order = location.state?.order as Order | null;

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [selectedRate, setSelectedRate] = useState<any>(null);
  const [groupingFilter, setGroupingFilter] = useState<string[]>(DEFAULT_GROUPING_FILTERS);

  const values = useWatch({ control, name: ['parcels', 'from', 'to', 'return', 'options'] });

  const buyShipment = usePostMutation('/api/v3/shipments/buy');

  const [isCopied, setCopied] = useClipboard(buyShipment?.data?.trackingCode || '', {
    successDuration: 1600,
  });

  const customs = useWatch({ control, name: 'customs' });
  const needsCustoms = createShipment?.data?.isCustomsFormRequired && !customs?.items?.length;

  useEffect(() => {
    if (buyShipment.isSuccess || buyShipment.isError) {
      if (!isEmpty(formState.dirtyFields)) {
        buyShipment.reset();
      }
    }
  }, [formState]);

  useEffect(() => {
    if (createShipment.isSuccess || createShipment.isError) {
      createShipment.reset();
      setSelectedRate(null);
      resetField('customs');
      resetField('customs.items');
    }
  }, [values]);

  const rates = orderBy(createShipment.data?.rates || [], ['price'], ['asc'])
    .filter((r) => (r?.delivery?.grouping ? groupingFilter?.includes(r?.delivery?.grouping) : true))
    .filter((r) => (carrierAccountIds === null ? true : carrierAccountIds.includes(r.carrierAccountId)));

  const getRatesMarkup = createShipment.isIdle && buyShipment.isIdle && (
    <Flex h="56" rounded="lg" px="6" alignItems="center" justify="center">
      <EmptyState
        icon={FiSearch}
        header="No shipping rates yet"
        secondaryAction={{ size: 'sm', type: 'submit', colorScheme: 'brand', children: 'Get rates' }}
      >
        <Text fontSize="sm" color="muted">
          Fill in the address and package details to fetch rates.
        </Text>
      </EmptyState>
    </Flex>
  );

  const loadingRatesMarkup = createShipment.isLoading && (
    <Stack w="full" spacing="1.5" px="2">
      {[0, 0, 0, 0, 0].map((_, index) => (
        <HStack justify="space-between" key={index} p="4" border="1px" borderColor="slate.200" rounded="lg">
          <Stack>
            <Skeleton w="40" height="15px" />
            <Skeleton w="32" height="15px" />
          </Stack>
          <Stack w="10" alignSelf="start">
            <Skeleton height="15px" />
          </Stack>
        </HStack>
      ))}
    </Stack>
  );

  const ratesMarkup = createShipment.isSuccess &&
    rates?.length > 0 &&
    (buyShipment.isIdle || buyShipment.isLoading) && (
      <RadioGroup
        disabled={buyShipment.isLoading}
        as={Stack}
        value={selectedRate || ''}
        onChange={setSelectedRate}
      >
        <Stack spacing="1.5" px="1.5">
          {rates.map((rate, index) => (
            <RadioGroup.Option key={index} value={rate} defaultChecked={index === 0}>
              {({ checked }) => (
                <HStack
                  spacing="2"
                  p="3"
                  bg="white"
                  margin={checked ? '0' : '1px'}
                  border={checked ? '2px' : '1px'}
                  borderColor={checked ? 'brand.400' : 'slate.200'}
                  borderRadius="md"
                  cursor="pointer"
                  _hover={{ bg: 'slate.50' }}
                  _active={{ bg: 'slate.100' }}
                >
                  <CarrierLogo carrier={rate.carrier} />
                  <Stack spacing="0" flexGrow={1}>
                    <Text fontWeight="medium">{rate.name}</Text>
                    <Text color="zinc.500">{rate.details?.join(' · ')}</Text>
                  </Stack>
                  <Stack justify="space-between">
                    <Text
                      ml="auto"
                      alignSelf="start"
                      alignItems="center"
                      display="inline-flex"
                      gap="1"
                      justifySelf="end"
                      fontSize="sm"
                      color="slate.600"
                    >
                      {toUSD(rate.price || 0)}
                      {rate.surcharges && rate.surcharges?.length > 0 && (
                        <Tooltip
                          label={
                            <Stack gap="2">
                              <p>Surcharges:</p>
                              <div>
                                {rate.surcharges?.map(({ amount, type }) => (
                                  <div key={type}>
                                    - {type} (${amount})
                                  </div>
                                ))}
                              </div>
                              <p>Total: ${rate.totalSurcharges}</p>
                            </Stack>
                          }
                          placement="bottom-end"
                        >
                          <Flex>
                            <Icon my="auto" as={LuInfo} />
                          </Flex>
                        </Tooltip>
                      )}
                    </Text>
                  </Stack>
                </HStack>
              )}
            </RadioGroup.Option>
          ))}
        </Stack>
      </RadioGroup>
    );

  const successMarkup = buyShipment.isSuccess && (
    <Flex className="slide-in-down" flexDirection="column" alignItems="center" h="56" pt="8" justify="center">
      <EmptyState
        iconProps={{ color: 'brandGreen' }}
        header="Label purchased successfuly"
        icon={FiCheckCircle}
        secondaryAction={{
          size: 'sm',
          children: 'Print',
          variant: 'outline',
          leftIcon: <FiPrinter />,
          onClick: printLabelModal.onOpen,
          type: 'button',
        }}
      >
        <Stack pt="1" spacing="3">
          <Text size="xs" color="muted">
            {successMessage}
          </Text>

          <HStack spacing="0" px="4">
            <Input
              size="sm"
              bg="gray.50"
              value={buyShipment?.data?.trackingCode || ''}
              onChange={() => {}}
              roundedBottomRight="0"
              roundedTopRight="0"
              mr="-px"
            />
            <Tooltip label="Copy tracking code" placement="top">
              <Button
                roundedBottomLeft="0"
                roundedTopLeft="0"
                size="sm"
                variant="outline"
                colorScheme="gray"
                type="button"
                onClick={setCopied}
              >
                {isCopied ? <FiCheck color="green" /> : <FiClipboard />}
              </Button>
            </Tooltip>
          </HStack>
        </Stack>
      </EmptyState>
    </Flex>
  );

  const errorMarkup = buyShipment.isError && (
    <Flex flexDirection="column" alignItems="center" h="56" justify="center">
      <EmptyState
        header="Error"
        icon={FiXCircle}
        secondaryAction={{
          children: 'Restart',
          variant: 'outline',
          size: 'sm',
          onClick: () => {
            resetField('to');
            resetField('parcel');
            resetField('parcels');
            resetField('options');
            patch({ showToEditMode: true });
            buyShipment.reset();
          },
        }}
      >
        <Text size="xs" color="muted">
          We couldn&lsquo;t purchase the label. Please try again or contact us.
        </Text>
      </EmptyState>
    </Flex>
  );

  const getRatesErrorMarkup = createShipment.isError && (
    <Flex flexDirection="column" alignItems="center" h="56" justify="center">
      <EmptyState header="No rates found" icon={MdSearchOff}>
        <Text size="xs" color="muted">
          No rates were found for this address and configuration. If you believe this is an error, please
          contact us.
        </Text>
      </EmptyState>
    </Flex>
  );

  return (
    <>
      <Stack spacing="3.5">
        <Flex alignItems="center" gap="2">
          <Icon as={FiCheckCircle} />
          <Text as="h3" fontSize="md" fontWeight="medium">
            Purchase
          </Text>
          <Menu closeOnSelect={false} closeOnBlur>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  isDisabled={!createShipment.isSuccess}
                  ml="auto"
                  variant="outline"
                  size="xs"
                  as={Button}
                  rightIcon={<FiChevronDown />}
                >
                  Filters
                </MenuButton>
                <MenuList zIndex="popover">
                  <MenuOptionGroup
                    title="Grouping"
                    type="checkbox"
                    defaultValue={groupingFilter}
                    onChange={(value: any) => setGroupingFilter(value || [])}
                  >
                    {DEFAULT_GROUPING_FILTERS.map((value: string) => (
                      <MenuItemOption value={value} key={value} fontSize="sm">
                        {value}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
        <Box minH="sm" maxH="sm" overflowY="auto">
          {getRatesMarkup ||
            loadingRatesMarkup ||
            ratesMarkup ||
            successMarkup ||
            getRatesErrorMarkup ||
            errorMarkup}
        </Box>

        {createShipment.isSuccess && rates?.length > 0 && (buyShipment.isIdle || buyShipment.isLoading) && (
          <>
            {needsCustoms && !customs ? (
              <Stack spacing="1">
                <Button colorScheme="orange" isDisabled={!selectedRate?.id} onClick={customsDrawer.onOpen}>
                  Fill Customs Form
                </Button>
                <Text ml="auto" fontSize="xs" color="muted">
                  A customs form is required for international shipments.
                </Text>
              </Stack>
            ) : null}

            {!needsCustoms || (needsCustoms && customs) ? (
              <Stack spacing="1">
                {customs && (
                  <Alert status="success" mb="2">
                    <AlertIcon />
                    <Stack spacing="0">
                      <AlertTitle>Customs form completed</AlertTitle>
                      <AlertDescription>
                        <Button variant="link" onClick={customsDrawer.onOpen}>
                          Click here to edit
                        </Button>
                      </AlertDescription>
                    </Stack>
                  </Alert>
                )}

                <Button
                  isLoading={buyShipment.isLoading}
                  isDisabled={!selectedRate?.id}
                  colorScheme="brand"
                  onClick={() => {
                    if (!createShipment.data?.id) return;

                    const paymentOptions =
                      getValues('options.payment').type !== 'SENDER'
                        ? { payment: getValues('options.payment') }
                        : {};

                    const payload: BuyShipmentInput = {
                      rateId: selectedRate?.id,
                      shipmentId: createShipment.data?.id,
                      orderId: order?.id || null,
                      labelRequestSource: location.state?.origin || 'QUICK_SHIP',
                      isReturn: getValues('options.isReturn') || false,
                      additionalHandling: getValues('options.additionalHandling') || false,
                      ...paymentOptions,
                    };

                    if (createShipment?.data?.isCustomsFormRequired) {
                      payload.customsInfo = {
                        ...customs,
                        customsItems: customs.items,
                        incoterm: customs?.deliveryDutyPaid === true ? 'DDP' : undefined,
                      };
                    }

                    buyShipment.mutate(
                      {
                        body: payload,
                      },
                      {
                        onSuccess: () => {
                          setSuccessMessage(`${selectedRate?.name} purchased for ${getValues('to.name')}.`);
                          queryClient.invalidateQueries(GET_METER_QUERY);
                          queryClient.invalidateQueries(GET_BALANCE_QUERY);
                          resetField('from', { defaultValue: values[1] });
                          resetField('to');
                          resetField('options');
                          resetField('parcels');
                          setValue('customs', undefined);
                          setValue('options.isReturn', false);
                          setValue('options.additionalHandling', false);
                          patch({ showToEditMode: true });
                        },
                      },
                    );
                  }}
                >
                  Purchase for {toUSD(selectedRate?.price || 0)}
                </Button>

                {createShipment.data?.insurancePriceCents && (
                  <Text ml="auto" fontSize="xs" color="muted">
                    + {toUSD(createShipment.data?.insurancePriceCents)} for insurance.
                  </Text>
                )}
              </Stack>
            ) : null}
          </>
        )}
      </Stack>

      <CustomsDrawer
        parcels={createShipment.data?.parcels || []}
        selectedRate={selectedRate}
        {...customsDrawer}
      />

      {buyShipment.data?.id && (
        <PrintLabelModal
          shipmentIds={[buyShipment.data?.id]}
          {...printLabelModal}
          zpl={!!buyShipment.data.isZplLabel}
        />
      )}
    </>
  );
}
