import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Stack,
  StackProps,
  Tag,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetApiKeys } from 'api/api-keys';
import { updateOAuthClient, useGetOAuthClient } from 'api/oauth-client';
import { SecretInput } from 'components/secret-input';
import { useNotification } from 'contexts/notification.context';
import { FC, PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import { z } from 'zod';

const Section = (props: PropsWithChildren<StackProps>) => {
  const { children, ...rest } = props;

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '1.5', md: '8' }}
      justify="space-between"
      {...rest}
    >
      {children}
    </Stack>
  );
};

const oauthSchema = z.object({
  appName: z.string().nonempty(),
  redirectUrls: z.string(),
});

export const DeveloperSettings: FC<{}> = () => {
  const { notifySuccess } = useNotification();

  const apiKeys = useGetApiKeys();
  const updateOAuth = updateOAuthClient();
  const oauthClient = useGetOAuthClient();

  const defaultKey = apiKeys?.data?.[0] || null;

  const oauthForm = useForm<z.infer<typeof oauthSchema>>({
    resolver: zodResolver(oauthSchema),
    values: {
      appName: oauthClient.data?.appName || '',
      redirectUrls: (oauthClient.data?.redirectUrls || []).join('\n'),
    },
  });

  const onSaveOAuthSettings = (data: z.infer<typeof oauthSchema>) => {
    const payload = {
      ...data,
      redirectUrls: data.redirectUrls
        .split('\n')
        .map((url) => url.trim())
        .filter(Boolean),
    };

    updateOAuth.mutate(payload, {
      onSuccess: () => {
        oauthClient.refetch();
        notifySuccess('OAuth settings saved successfully');
      },
    });
  };

  return (
    <Stack gap="3.5" maxW="2xl">
      <Card>
        <CardHeader>
          <Text fontWeight="semibold" fontSize="lg">
            API Keys
          </Text>
          <Text fontSize="sm" color="muted">
            Manage your API keys.
          </Text>
        </CardHeader>
        <CardBody minH="52">
          <FormControl>
            <Section>
              <Stack>
                <FormLabel variant="inline">
                  Secret API Key{' '}
                  <Tag size="sm" ml="1">
                    Production
                  </Tag>
                </FormLabel>
                <Text color="muted" fontSize="xs">
                  Your API key is used to authenticate your requests to the Vesyl API. This is a secret key, so be sure
                  not to share it publicly or with anyone.
                </Text>
              </Stack>
              <Flex h="10" w="full">
                <SecretInput clipboard buttonProps={{ ml: 'auto' }} value={defaultKey?.key} />
              </Flex>
            </Section>
          </FormControl>
        </CardBody>
        <CardFooter as={Stack}>
          <Button
            onClick={() => {
              window.open('https://orders-api.fitshipper.com/docs', '_blank');
            }}
            size="sm"
            variant="outline"
            rightIcon={<FiExternalLink />}
            ml="auto"
          >
            View API Documentation
          </Button>
          <Flex>
            <HStack spacing="1" px="2" ml="auto" mt="1">
              <Text color="muted" fontSize="xs">
                For questions, feedback or support regarding the Vesyl API, contact us at <b>developers@vesyl.com</b>
              </Text>
            </HStack>
          </Flex>
        </CardFooter>
      </Card>

      <form onSubmit={oauthForm.handleSubmit(onSaveOAuthSettings)}>
        <Card>
          <CardHeader>
            <Text fontWeight="semibold" fontSize="lg">
              OAuth Settings
            </Text>
            <Text fontSize="sm" color="muted">
              Set up OAuth for your application.
            </Text>
          </CardHeader>

          <CardBody>
            <Stack gap="3.5" maxW="2xl">
              <FormControl isInvalid={!!oauthForm.formState?.errors?.appName}>
                <FormLabel>App Name</FormLabel>
                <Input {...oauthForm.register('appName')} />
                <FormHelperText>This will identify your app to users when asked to approve access.</FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Allowed redirection URL(s)</FormLabel>
                <Textarea {...oauthForm.register('redirectUrls')} placeholder="Place URLs on separate lines" />
                <FormHelperText>
                  You must include at least one redirect URL before making your app public. Users are redirected to
                  these allowed URLs after the app is installed.
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={!oauthClient.data}>
                <FormLabel>Client ID</FormLabel>
                <Input value={oauthClient?.data?.clientId} />
              </FormControl>

              <FormControl isDisabled={!oauthClient.data}>
                <FormLabel>Client Secret</FormLabel>
                <SecretInput buttonProps={{ isDisabled: !oauthClient.data }} value={oauthClient?.data?.clientSecret} />
              </FormControl>
            </Stack>
          </CardBody>

          <CardFooter>
            <Button type="submit" ml="auto" variant="primary">
              Save
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Stack>
  );
};
