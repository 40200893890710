import { Image, ImageProps } from '@chakra-ui/react';
import { startCase } from 'lodash';

interface PatternProps extends ImageProps {
  variant?: 'arrow' | 'diamond' | 'square' | 'triangle';
  color?: 'black' | 'blue' | 'white' | 'yellow';
}

export const Pattern = (props: PatternProps) => {
  const { variant = 'arrow', color = 'black', ...rest } = props;

  return (
    <Image
      width="xs"
      h="xs"
      src={`/patterns/Vesyl-Pattern-${startCase(variant)}-${startCase(color)}.svg`}
      alt=""
      {...rest}
    />
  );
};
