import axios from 'config/axios';
import { UseMutationOptions, useMutation } from 'react-query';

export type ConnectEbay2Response = any;
export type ConnectEbay2Input = any;

export const useConnectEbay2 = (opts?: UseMutationOptions<ConnectEbay2Response, {}, ConnectEbay2Input>) =>
  useMutation<ConnectEbay2Response, {}, ConnectEbay2Input>(async (input: ConnectEbay2Input) => {
    const response = await axios.post('/v3/stores/ebay2/connect', input);
    return response.data;
  }, opts);
