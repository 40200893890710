import { useQuery } from 'react-query';

import axios from '../../config/axios';
import { paths } from 'api/types';

export type GetReportsResponse = paths['/api/v3/reports']['get']['responses']['200']['content']['application/json'];
export type GetReportsQuery = paths['/api/v3/reports']['get']['parameters']['query'];

export const getReports = async (input: GetReportsQuery) => {
  const { per, page, type, ...rest } = input;
  const { data } = await axios.get(`/v3/reports`, {
    params: {
      per,
      page,
      type,
      ...rest,
    },
  });
  return data;
};

export const GET_REPORTS_QUERY = 'reports';

const useGetReports = ({ per, page, type, sort, direction, filterModel }: GetReportsQuery) =>
  useQuery<GetReportsResponse>(
    [GET_REPORTS_QUERY, { per, page, type, sort, direction, filterModel }],
    () => getReports({ per, page, type, sort, direction, filterModel }),
    {},
  );
export default useGetReports;
