import { loadStripe } from '@stripe/stripe-js/pure';

export const getStripeRadarSession = async () => {
  try {
    if (!import.meta.env.VITE_STRIPE_PUBLIC_KEY) {
      return null;
    }
    const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
    const radarSession = (await stripe?.createRadarSession())?.radarSession || null;

    return radarSession;
  } catch (error) {
    return null;
  }
};
