import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { BoxType, GET_BOXES_QUERY, useCreateBox, useGetBoxes, useUpdateBox } from 'api/boxes';
import { PageBody, PageHeader } from 'components/Page';
import { queryClient } from 'config/query-client';
import { useDisclosure } from 'hooks/use-disclosure';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { z } from 'zod';
import { BoxCard } from './_components/box-card';

const boxSchema = z
  .object({
    name: z.string({ required_error: 'Required' }),
    length: z.coerce.number({ required_error: 'Required' }).positive({ message: 'Must be a positive number' }),
    width: z.coerce.number({ required_error: 'Required' }).positive({ message: 'Must be a positive number' }),
    height: z.coerce.number().nonnegative({ message: 'Must be a positive number' }),
    isSoftPack: z.boolean(),
    weight: z.coerce.number().nonnegative().nullable().default(null),
  })
  .refine((data) => !(data.isSoftPack === false && data.height === undefined), { message: 'Height is required' });

type FormValues = z.infer<typeof boxSchema>;

const SaveBoxModal = (
  props: Omit<ModalProps, 'children'> & {
    box: ArrayElement<BoxType['custom']> | null;
  },
) => {
  const { box, ...modalProps } = props;
  const { id, weight, height, length, width, name } = box || {};

  const createBox = useCreateBox();
  const updateBox = useUpdateBox();

  const methods = useForm<FormValues>({
    shouldUnregister: false,
    mode: 'onSubmit',
    resolver: zodResolver(boxSchema),
    defaultValues: {
      weight: weight || undefined,
      height: height || undefined,
      length: length || undefined,
      width: width || undefined,
      name: name || '',
      isSoftPack: height === 0,
    },
  });

  const { setValue, handleSubmit, watch, formState, register } = methods;

  const formData = watch();

  useEffect(() => {
    if (formData.isSoftPack === true) setValue('height', 0);
  }, [formData.isSoftPack]);

  const onSubmit = (data: FormValues) => {
    const onSuccess = () => {
      queryClient.invalidateQueries([GET_BOXES_QUERY]);
      modalProps.onClose();
    };

    if (id) updateBox.mutate({ id, ...data }, { onSuccess });
    else createBox.mutate({ ...data }, { onSuccess });
  };

  const isLoading = updateBox.isLoading || createBox.isLoading;

  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Box</ModalHeader>
        <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
          <ModalBody>
            <VStack align="left" spacing={2.5} w="full">
              <FormControl isRequired isInvalid={!!formState.errors.name}>
                <FormLabel>Name</FormLabel>
                <Input placeholder=" " {...register('name')} />
              </FormControl>
              <FormControl isRequired isInvalid={!!formState.errors.length}>
                <FormLabel>Length</FormLabel>
                <Input placeholder=" " {...register('length')} />
              </FormControl>
              <FormControl isRequired isInvalid={!!formState.errors.width}>
                <FormLabel>Width</FormLabel>
                <Input placeholder=" " {...register('width')} />
              </FormControl>
              <FormControl isRequired isInvalid={!!formState.errors.height}>
                <FormLabel>Height</FormLabel>
                <Input placeholder=" " {...register('height')} disabled={formData.isSoftPack} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.isSoftPack}>
                <HStack>
                  <Checkbox {...register('isSoftPack')} />
                  <FormLabel>Softpack</FormLabel>
                </HStack>
              </FormControl>
              <FormControl isInvalid={!!formState.errors.weight}>
                <FormLabel>Box Weight</FormLabel>
                <InputGroup>
                  <Input placeholder="" {...register('weight')} />
                  <InputRightAddon>oz</InputRightAddon>
                </InputGroup>
                <FormErrorMessage>{formState.errors.weight?.message}</FormErrorMessage>
              </FormControl>

              {!!watch('weight') && (
                <Alert status="info">
                  <AlertIcon />
                  <AlertDescription>
                    <Text fontSize="sm">
                      The box weight will be added to the Parcel weight every time you use this box.
                    </Text>
                  </AlertDescription>
                </Alert>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button disabled={isLoading} type="button" variant="outline" onClick={modalProps.onClose}>
                Cancel
              </Button>
              <Button isLoading={isLoading} type="submit" colorScheme="brand">
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </chakra.form>
      </ModalContent>
    </Modal>
  );
};

export default function () {
  const saveBoxModal = useDisclosure(false);
  const boxes = useGetBoxes();
  const { refetch } = useQuery('boxes');

  const [selectedBox, setSelectedBox] = useState<ArrayElement<BoxType['custom']> | null>(null);

  return (
    <>
      <PageHeader justifyContent="space-between">
        <Heading as="h1">Boxes</Heading>
        <Button
          type="button"
          colorScheme="brand"
          leftIcon={<FiPlus />}
          onClick={() => {
            setSelectedBox(null);
            saveBoxModal.onOpen();
          }}
        >
          Add Box
        </Button>
      </PageHeader>
      <PageBody>
        <VStack align="left" gap={10}>
          <Box>
            <Text fontWeight="semibold" fontSize="lg">
              Custom Boxes
            </Text>
            <Text textColor="muted">Your boxes</Text>
            <SimpleGrid mt={5} columns={[1, 2, 3]} gap={3}>
              {boxes?.data?.custom &&
                boxes?.data?.custom.map((box) => (
                  <BoxCard
                    key={`c_${box.id}`}
                    box={box}
                    isEditable
                    refetch={refetch}
                    onEdit={() => {
                      setSelectedBox(box);
                      saveBoxModal.onOpen();
                    }}
                  />
                ))}
            </SimpleGrid>
          </Box>
          <Box>
            <Text fontWeight="semibold" fontSize="lg">
              USPS Boxes
            </Text>
            <Text textColor="muted">Branded USPS boxes</Text>
            <SimpleGrid mt={5} columns={[1, 2, 3]} gap={3}>
              {boxes?.data?.system &&
                boxes?.data.system.map(
                  (box) => box.carrier === 'USPS' && <BoxCard key={`s_${box.id}`} box={box} isEditable={false} />,
                )}
            </SimpleGrid>
          </Box>
          <Box>
            <Text fontWeight="semibold" fontSize="lg">
              UPS Boxes
            </Text>
            <Text textColor="muted">Branded UPS boxes</Text>
            <SimpleGrid mt={5} columns={[1, 2, 3]} gap={3}>
              {boxes?.data?.system &&
                boxes?.data.system.map(
                  (box) => box.carrier === 'UPS' && <BoxCard key={`s_${box.id}`} box={box} isEditable={false} />,
                )}
            </SimpleGrid>
          </Box>
          <Box>
            <Text fontWeight="semibold" fontSize="lg">
              FedEx Boxes
            </Text>
            <Text textColor="muted">Branded FedEx boxes</Text>
            <SimpleGrid mt={5} columns={[1, 2, 3]} gap={3}>
              {boxes?.data?.system &&
                boxes?.data.system.map(
                  (box) => box.carrier === 'FedEx' && <BoxCard key={`s_${box.id}`} box={box} isEditable={false} />,
                )}
            </SimpleGrid>
          </Box>
        </VStack>
      </PageBody>
      {saveBoxModal.isOpen && <SaveBoxModal box={selectedBox || null} {...saveBoxModal} />}
    </>
  );
}
