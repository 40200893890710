import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetMeter, useUpdateMeter } from 'api/meters';
import { useGetPaymentMethods } from 'api/payments';
import { useNotification } from 'contexts/notification.context';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PaymentCard } from 'types/payment';
import { z } from 'zod';

const schema = z
  .object({
    rechargeThreshold: z.coerce
      .number({ required_error: '', invalid_type_error: '' })
      .min(20, { message: 'Amount must be at least $20' }),
    rechargeAmount: z.coerce
      .number({ required_error: '', invalid_type_error: '' })
      .min(20, { message: 'Amount must be at least $20' }),
  })
  .refine((data) => data.rechargeThreshold < data.rechargeAmount, {
    message: 'Recharge amount must be greater than recharge threshold',
    path: ['rechargeAmount'],
  });

export const BalanceMeter: FC<{}> = () => {
  const { data: paymentMethods } = useGetPaymentMethods();
  const [primaryPaymentOption, setPrimaryPaymentOption] = useState<PaymentCard>();
  const { data: meter, refetch: refetchMeter } = useGetMeter();
  const { notifySuccess } = useNotification();

  useEffect(() => {
    if (!paymentMethods) return;
    setPrimaryPaymentOption(paymentMethods.find((p: PaymentCard) => p.isPrimary));
  }, [paymentMethods]);

  type FormValues = z.infer<typeof schema>;

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });
  const { handleSubmit, formState, register, watch } = form;

  const updateMeter = useUpdateMeter({
    onSuccess: () => {
      refetchMeter();
    },
  });

  useEffect(() => {
    if (!meter) return;
    const defaultValues = {
      rechargeThreshold: meter.rechargeThresholdCents / 100.0,
      rechargeAmount: meter.rechargeAmountCents / 100.0,
    };
    form.reset(defaultValues);
  }, [meter]);

  const autoUpdateEnabled = useMemo(() => {
    if (!meter) return false;
    return meter.isActive;
  }, [meter]);

  const handleAutoUpdateChange = async () => {
    updateMeter.mutate({ isActive: !autoUpdateEnabled });
  };

  const onSubmit = async (e: FormValues) => {
    const vars = { rechargeThresholdCents: e.rechargeThreshold * 100, rechargeAmountCents: e.rechargeAmount * 100 };
    updateMeter.mutate(vars, { onSuccess: () => notifySuccess('The auto recharge settings have been updated') });
  };

  return (
    <Box justifyContent="center" w="full">
      <VStack align="left" spacing={4}>
        <HStack justify="space-between" mb={4}>
          <Text fontSize="lg" fontWeight={600}>
            Auto recharge
          </Text>
          <Switch isChecked={autoUpdateEnabled} colorScheme="brand" onChange={handleAutoUpdateChange} />
        </HStack>
      </VStack>
      <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
        <VStack align="left" spacing={4}>
          <FormControl isInvalid={!!formState.errors.rechargeThreshold}>
            <FormLabel>Recharge threshold</FormLabel>
            <InputGroup>
              <InputLeftElement>$</InputLeftElement>
              <Input {...register('rechargeThreshold')} isDisabled={!autoUpdateEnabled} type="number" />
              <InputRightElement color="muted" mr={2}>
                USD
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{formState?.errors?.rechargeThreshold?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!formState?.errors?.rechargeAmount}>
            <FormLabel>Recharge amount</FormLabel>
            <InputGroup>
              <InputLeftElement>$</InputLeftElement>
              <Input {...register('rechargeAmount')} isDisabled={!autoUpdateEnabled} type="number" />
              <InputRightElement color="muted" mr={2}>
                USD
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{formState?.errors?.rechargeAmount?.message}</FormErrorMessage>
          </FormControl>
          <VStack spacing={2}>
            <Button
              isDisabled={updateMeter.isLoading || !autoUpdateEnabled}
              variant="solid"
              type="submit"
              colorScheme="brand"
              w="full"
            >
              Save
            </Button>
            <Text fontSize="xs" color={autoUpdateEnabled ? 'muted' : 'zinc.400'}>
              When your meter balance falls below <b>${watch('rechargeThreshold')}</b>, we will automatically charge
              your payment method ending in <b>{primaryPaymentOption?.card.lastFour}</b> for{' '}
              <b>${watch('rechargeAmount')}</b>.
            </Text>
          </VStack>
        </VStack>
      </chakra.form>
    </Box>
  );
};
