import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeSetupIntent } from 'api/payments/stripe-setup-intent';
import { useState } from 'react';
import { PaymentElementContainer } from './payment-element-container';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

export const AddStripePaymentMethod = () => {
  const openModal = () => {
    StripeSetupIntent().then((data) => {
      setClientSecret(data.stripeClientSecret);
    });
    return true;
  };

  const { isOpen, onOpen, onClose } = useDisclosure({ onOpen: openModal });
  // const [radarSessionId, setRadarSessionId] = useState<string>();
  const [clientSecret, setClientSecret] = useState('');

  const closeAndReset = () => {
    onClose();
  };

  const stripeOptions = { clientSecret };

  return (
    <>
      <Button float="right" variant="solid" colorScheme="brand" onClick={onOpen}>
        Add Payment Method
      </Button>

      <Modal isOpen={isOpen} onClose={closeAndReset}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Payment Method</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Please enter your payment information below. This data is captured and stored securely by
              Stripe.
            </p>
            <br />
            {clientSecret ? (
              <Elements options={stripeOptions} stripe={stripePromise}>
                <PaymentElementContainer />
              </Elements>
            ) : (
              <Spinner />
            )}
            <br />
          </ModalBody>

          <ModalFooter display="flex" gap="2">
            <ButtonGroup>
              <Button type="button" variant="outline" onClick={closeAndReset}>
                Cancel
              </Button>
              <Button form="stripe-add-payment" type="submit" colorScheme="brand">
                Save Payment Method
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
