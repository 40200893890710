import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

export const cardTheme = defineMultiStyleConfig({
  baseStyle: ({ colorMode }) => ({
    container: {
      border: '1px',
      borderColor: colorMode === 'light' ? 'gray.200 !important' : 'gray.700 !important',
      boxShadow: 'none',
      shadow: 'none',
    },
  }),
});
