import axios from 'config/axios';
import * as qs from 'querystring';
import { useMutation, UseMutationOptions } from 'react-query';
import { StorePlatform } from 'types/enums';

type Input = { name: string; url: string; platform: StorePlatform };
type Response = { url: string };

export const connectStore = async (vars: Input) => {
  const { platform, name: storeName, url: storeUrl } = vars;
  const q = qs.stringify({ storeName, storeUrl });

  const data = await axios.get<Response>(`/v3/stores/oauth/${platform}?${q}`);

  return data?.data;
};

export const useConnectOAuthStore = (opts?: UseMutationOptions<Response, {}, Input>) =>
  useMutation<Response, {}, Input>(connectStore, opts);
