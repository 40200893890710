import { Icon, IconButton } from '@chakra-ui/react';
import { IStatusPanelParams } from 'ag-grid-community';
import { LuRotateCcw } from 'react-icons/lu';

export const RefreshGridButton = (props: IStatusPanelParams) => {
  const { api } = props;

  return (
    <IconButton
      size="xs"
      icon={<Icon as={LuRotateCcw} boxSize={3} />}
      aria-label="refresh page"
      onClick={() => {
        api?.refreshServerSide();
        api?.deselectAll();
      }}
      variant={'outline'}
    />
  );
};
