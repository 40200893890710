import axiosClient, { AxiosRequestTransformer } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import { useUserStore } from 'store';

const baseUrl = import.meta.env.VITE_BASE_URL;

const instance = axiosClient.create({
  baseURL: baseUrl,
  transformRequest: [
    (data, headers) => {
      // File uploads (FormData) should not be transformed
      if (headers?.['Content-Type'] === 'multipart/form-data') return data;
      if (headers?.['X-No-Transform'] === 'true') return data;

      return camelCaseKeys(data ?? {}, { deep: true });
    },
    ...(axiosClient.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});

instance.interceptors.request.use(
  (config) => {
    const state = useUserStore.getState();

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: state.authToken ? `Bearer ${state.authToken}` : '',
      },
    };
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use((response) => response);

export default instance;

export const axios = instance;
