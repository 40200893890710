import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react';
import {
  GET_CARRIER_ACCOUNTS_QUERY,
  GET_CARRIER_ACCOUNT_QUERY,
  useCreateCarrierAccount,
  useGetCarrierAccount,
  useUpdateCarrierAccount,
} from 'api/carrier-accounts';
import { useGetCarrierTypes } from 'api/carrier-types';
import { CarrierLogo } from 'components/CarrierLogo';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { camelCase, startCase } from 'lodash';
import { useState } from 'react';
import { LuArrowRight } from 'react-icons/lu';

export const EditCarrierModal = (
  props: Omit<ModalProps, 'children'> & { carrierId?: string; selectedCarrier?: string },
) => {
  const { onClose, carrierId = null, selectedCarrier: initialSelectedCarrier = null } = props;

  const mode = carrierId ? 'edit' : 'create';

  const notify = useNotification();

  const [selectedCarrier, setSelectedCarrier] = useState<string | null>(initialSelectedCarrier);
  const [showCarrierForm, setShowCarrierForm] = useState<boolean>(mode === 'edit');

  const carrierTypes = useGetCarrierTypes({});
  const createCarrierAccount = useCreateCarrierAccount();
  const updateCarrierAccount = useUpdateCarrierAccount();
  const carrierAccount = useGetCarrierAccount(carrierId as string, { enabled: !!carrierId });

  const selectedCarrierReadable = carrierTypes.data?.find((ct) => ct.type === selectedCarrier)?.readable;
  const selectedCarrierFields = carrierTypes.data?.find((ct) => ct.type === selectedCarrier)?.fields as any;

  const isLoading = createCarrierAccount.isLoading || updateCarrierAccount.isLoading;
  const isUPS = selectedCarrier?.toLowerCase() === 'upsdap';

  const handleSaveCarrier = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { consent1, consent2, consent3, consent4, ...credentials } = Object.fromEntries(
      new FormData(e.currentTarget).entries(),
    );

    if (isUPS && mode === 'create' && [consent1, consent2, consent3, consent4].some((c) => c === undefined)) {
      notify.error('Please agree to all terms and conditions before proceeding');
      return;
    }

    if (!selectedCarrier) return;

    if (mode === 'create') {
      createCarrierAccount.mutate(
        { type: selectedCarrier, credentials },
        {
          onSuccess: () => {
            notify.success('Carrier account created successfully');
            queryClient.invalidateQueries([GET_CARRIER_ACCOUNTS_QUERY]);

            onClose();
          },
        },
      );
    } else if (mode === 'edit') {
      updateCarrierAccount.mutate(
        { id: carrierId as string, type: selectedCarrier, credentials },
        {
          onSuccess: () => {
            notify.success('Carrier account updated successfully');
            queryClient.invalidateQueries([GET_CARRIER_ACCOUNTS_QUERY]);
            queryClient.invalidateQueries([GET_CARRIER_ACCOUNT_QUERY, carrierId]);
            onClose();
          },
        },
      );
    }
  };

  return (
    <Modal {...props} size={showCarrierForm ? '2xl' : 'md'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={'flex'} gap={4} alignItems="center">
          {showCarrierForm && selectedCarrier && (
            <CarrierLogo carrier={selectedCarrier} width={64} height={64} />
          )}
          {showCarrierForm && selectedCarrier
            ? `${startCase(mode)} ${selectedCarrierReadable}`
            : 'Choose carrier'}
        </ModalHeader>

        <ModalBody>
          {/* Carrier selection */}
          <Box hidden={showCarrierForm}>
            <SimpleGrid columns={[3]} gap={2}>
              {carrierTypes.data?.map((ct) => (
                <Button
                  key={ct.type}
                  variant={'outline'}
                  rounded="md"
                  h="32"
                  w="32"
                  p="7"
                  bg={selectedCarrier === ct.type ? 'zinc.200' : undefined}
                  onClick={() =>
                    selectedCarrier === ct.type ? setSelectedCarrier(null) : setSelectedCarrier(ct.type)
                  }
                >
                  <Stack alignItems="center" gap="1" bg="" h="full">
                    <CarrierLogo carrier={ct.type} height={54} width={54} />
                    <Text fontWeight="medium" mt="auto" fontSize="md">
                      {ct.readable}
                    </Text>
                  </Stack>
                </Button>
              ))}
            </SimpleGrid>
          </Box>

          {/* Carrier form */}
          <chakra.form
            id="carrier-form"
            display="flex"
            flexDir="column"
            gap="2"
            hidden={!showCarrierForm || !selectedCarrier}
            onSubmit={handleSaveCarrier}
          >
            {Object.entries(selectedCarrierFields?.credentials || {}).map(([key, field]: any) => {
              const defaultValue = carrierAccount?.data?.credentials?.[camelCase(key)] || '';
              const isRequired = key !== 'business_address_street2';

              return (
                field[mode] === true && (
                  <FormControl key={key} isRequired={isRequired}>
                    <FormLabel>{field.label}</FormLabel>
                    <Input name={key} type={field.type} defaultValue={defaultValue as string} />
                  </FormControl>
                )
              );
            })}

            {mode === 'create' && isUPS && (
              <Stack>
                <Checkbox name="consent1" mt="1">
                  To start shipping with UPS, I am agreeing to the UPS Tariff / Terms and Conditions of
                  Service.{' '}
                  <Link href="https://www.ups.com/us/en/help-center/legal-terms-conditions/service.page">
                    UPS Tariff/Terms and Conditions of Service
                  </Link>
                  .
                </Checkbox>
                <Checkbox name="consent2">
                  I will not attempt to ship any items prohibited by UPS, or any UPS-regulated items without
                  express written contract with UPS.{' '}
                  <Link href="https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page">
                    List of Prohibited Articles for Shipping
                  </Link>
                </Checkbox>
                <Checkbox name="consent3">
                  I will also agree to the UPS Technology Agreement.{' '}
                  <Link href="https://www.ups.com/assets/resources/webcontent/en_US/UTA.pdf">
                    UPS Technology Agreement and End User Rights
                  </Link>
                  .
                </Checkbox>
                <Checkbox name="consent4">
                  I agree to the Ground Saver Terms and Conditions.{' '}
                  <Link href="https://www.ups.com/assets/resources/media/en_US/Ground-Saver.pdf">
                    UPS Ground Saver Terms and Conditions
                  </Link>
                </Checkbox>

                <chakra.small color="muted">
                  UPS, the UPS brandmark, UPS Ready®, and the color brown are trademarks of United Parcel
                  Service of America, Inc. All Rights Reserved.
                </chakra.small>
              </Stack>
            )}
          </chakra.form>
        </ModalBody>

        <ModalFooter gap="2">
          <Button variant="ghost" onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button
            hidden={showCarrierForm}
            colorScheme="brand"
            rightIcon={<LuArrowRight />}
            isDisabled={!selectedCarrier}
            onClick={() => setShowCarrierForm(true)}
          >
            Continue
          </Button>

          <Button
            isLoading={isLoading}
            hidden={!showCarrierForm}
            type="submit"
            colorScheme="brand"
            form="carrier-form"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
