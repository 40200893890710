import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';

type DeleteBox = { id: number };
export type DeleteBoxResponse = { status: string };
export type DeleteBoxError = AxiosError<{ message: string }>;

export const deleteBox = async ({ id }: DeleteBox) => {
  const { data } = await axios.delete(`/v3/boxes/${id}`);
  return data;
};

const useDeleteBox = (opts?: UseMutationOptions<DeleteBoxResponse, DeleteBoxError, DeleteBox>) =>
  useMutation<DeleteBoxResponse, DeleteBoxError, DeleteBox>(deleteBox, opts);

export default useDeleteBox;
