import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetLogins } from 'api/logins/get';
import { useRemoveLogin } from 'api/logins/remove';
import { useUserInvitations } from 'api/user';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { useUser } from 'contexts/auth-context';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { BsEnvelope } from 'react-icons/bs';
import { FiClipboard, FiEdit2, FiTrash2 } from 'react-icons/fi';
import useClipboard from 'react-use-clipboard';
import { Login } from 'types';
import { PermissionModal } from './edit-permissions-modal';
import { InviteUserModal } from './invite-user-modal';

export const LoginSettings = () => {
  const [selectedLogin, setSelectedLogin] = useState<Login | null>(null);

  const user = useUser();
  const logins = useGetLogins();
  const removeLogin = useRemoveLogin();
  const invitations = useUserInvitations();

  const inviteUserModal = useDisclosure();
  const permissionModal = useDisclosure();
  const confirmRemoveModal = useDisclosure();

  const [copiedValue, setCopiedValue] = useState('');
  const [isCopied, setCopied] = useClipboard(copiedValue, { successDuration: 2000 });

  // Required to trigger copying the invitation link
  useEffect(() => setCopied(), [copiedValue]);

  const isLoading = logins.isLoading || logins.isFetching;

  return (
    <>
      <Card>
        <CardHeader>
          <HStack justify="space-between">
            <Box>
              <Text fontWeight="semibold" fontSize="lg" m="0">
                Dashboard Logins
              </Text>
              <Text fontSize="sm" color="muted" p="0">
                Manage additional logins for your account.
              </Text>
            </Box>
            <Button size="sm" colorScheme="brand" leftIcon={<BsEnvelope />} onClick={inviteUserModal.onOpen}>
              Invite user
            </Button>
          </HStack>
        </CardHeader>
        <CardBody minH="52">
          <TableContainer opacity={isLoading ? 0.4 : 1}>
            {isLoading && <Spinner pos="absolute" top="50%" left="50%" />}
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Email</Th>
                  <Th>Name</Th>
                  <Th>Permissions</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {/* Logins */}
                {logins.data?.map((login) => {
                  const hasAllPermissions = Object.values(login.permissions).every((has) => has);
                  const isAdmin = user?.isAdmin && login.email === user.email;

                  const onEdit = () => {
                    setSelectedLogin(login);
                    permissionModal.onOpen();
                  };

                  const onRemove = () => {
                    setSelectedLogin(login);
                    confirmRemoveModal.onOpen();
                  };

                  return (
                    <Tr key={login.id}>
                      <Td>{login.email}</Td>
                      <Td>{login.fullName}</Td>
                      <Td>
                        <HStack>
                          <Tag size="sm" colorScheme={hasAllPermissions ? 'green' : 'gray'}>
                            {hasAllPermissions ? 'All' : 'Custom'}
                          </Tag>

                          {isAdmin && (
                            <Tag size="sm" colorScheme="blue">
                              Admin
                            </Tag>
                          )}
                        </HStack>
                      </Td>

                      <Td>
                        <Button size="xs" variant="outline" hidden={isAdmin} onClick={onEdit}>
                          <FiEdit2 />
                        </Button>

                        <Button
                          ml="2"
                          size="xs"
                          color="red.400"
                          variant="outline"
                          colorScheme="red"
                          borderColor="red.300"
                          _hover={{ bg: 'red.50' }}
                          arial-label="Delete"
                          hidden={isAdmin}
                          onClick={onRemove}
                        >
                          <FiTrash2 />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}

                {/* Invitations (pending) */}
                {invitations.data?.map((invitation, index) => {
                  const onCopyUrl = () => {
                    setCopiedValue(invitation.url);
                  };

                  return (
                    <Tr key={index}>
                      <Td>
                        <HStack>
                          <Text>{invitation.email}</Text>
                          <Tag size="sm" colorScheme="gray">
                            {startCase(invitation.status)}
                          </Tag>
                        </HStack>
                      </Td>
                      <Td>{invitation.name}</Td>
                      <Td />
                      <Td>
                        <Button size="xs" variant="outline" onClick={onCopyUrl} title="Copy invitation link">
                          {isCopied && copiedValue === invitation.url ? <>Copied!</> : <FiClipboard />}
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      {inviteUserModal.isOpen && <InviteUserModal isOpen={inviteUserModal.isOpen} onClose={inviteUserModal.onClose} />}
      {permissionModal.isOpen && selectedLogin && (
        <PermissionModal isOpen={permissionModal.isOpen} onClose={permissionModal.onClose} login={selectedLogin} />
      )}
      <ConfirmationModal
        description="Are you sure you want to remove this login? This action cannot be undone."
        header="Remove login"
        onConfirm={() => {
          if (!selectedLogin?.id) return;
          removeLogin.mutate({ loginId: selectedLogin?.id }, { onSuccess: logins.refetch });
        }}
        type="delete"
        {...confirmRemoveModal}
      />
    </>
  );
};
