import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';
import { paths } from 'api/types';

export type DeleteProductParams = paths['/api/v3/products/{productId}']['delete']['parameters']['path'];
export type DeleteProductResponse =
  paths['/api/v3/products/{productId}']['delete']['responses']['200']['content']['application/json'];
export type DeleteProductError = AxiosError<{ message: string }>;

export const deleteProduct = async (input: DeleteProductParams) => {
  const { productId } = input;
  const { data } = await axios.delete(`/v3/products/${productId}`);
  return data;
};

export const useDeleteProduct = (
  opts?: UseMutationOptions<DeleteProductResponse, DeleteProductError, DeleteProductParams>,
) => useMutation<DeleteProductResponse, DeleteProductError, DeleteProductParams>(deleteProduct, opts);
