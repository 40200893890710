import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useDeleteAddress } from 'api/addresses';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { FC } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { getCountryNames } from 'utils/misc';
import { AddressForm } from './address-form';

interface PropTypes {
  address: any;
  refetchAddresses: () => void;
}

const AddressCard: FC<PropTypes> = ({ address, refetchAddresses }) => {
  const addressFormModal = useDisclosure();
  const deleteAddressModal = useDisclosure();
  const deleteAddress = useDeleteAddress({
    onSuccess: () => {
      refetchAddresses();
    },
  });

  const handleDelete = async (id: string) => {
    deleteAddress.mutate({ id });
  };

  return (
    <Card h="full">
      <CardBody h="full">
        <VStack spacing={2} alignItems="left" h="full">
          <HStack>
            <Text fontSize="md">{address.nickname ? address.nickname : address.line1}</Text>
            {address.primary && <FiCheckCircle title="Primary Address" color="gray" />}
          </HStack>
          <HStack h="full" justifyContent="space-between" gap={10}>
            <Box>
              <Text color="muted">{address.name}</Text>
              <Text color="muted" title={`${address.line1} ${address.line2}`} noOfLines={1}>
                {address.line1} {address.line2}
              </Text>
              <Text color="muted">
                {address.city}, {address.state} {address.postal} <br />
              </Text>
              <Text color="muted">{getCountryNames(address.country || '')}</Text>
            </Box>
            <Box alignSelf="end">
              <ButtonGroup gap="2">
                <Button size="xs" type="button" variant="link" onClick={deleteAddressModal.onOpen}>
                  Delete
                </Button>
                <ConfirmationModal
                  header="Delete address"
                  description={
                    <>
                      <p>Are you sure you want to delete this address?</p>
                      <br />
                      <p>
                        If you have inventories associated with this address, they will be deleted as well.
                      </p>
                    </>
                  }
                  type="delete"
                  isOpen={deleteAddressModal.isOpen}
                  onClose={deleteAddressModal.onClose}
                  onConfirm={() => handleDelete(address.id)}
                />
                <Button size="xs" variant="outline" type="button" onClick={addressFormModal.onOpen}>
                  Edit
                </Button>
                {addressFormModal.isOpen && <AddressForm changeAddress={address} {...addressFormModal} />}
              </ButtonGroup>
            </Box>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default AddressCard;
