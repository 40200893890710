import { PrintPDFLabels } from './print-pdf-labels';
import { PrintZPLLabels } from './print-zpl-labels';
import { PrintLabelModalProps } from './types';

export function PrintLabelModal(props: PrintLabelModalProps & { zpl: boolean }) {
  const { zpl = false, ...rest } = props;

  if (zpl) return <PrintZPLLabels {...rest} />;

  return <PrintPDFLabels {...rest} />;
}
