import {
  Button,
  Checkbox,
  PopoverContent,
  PopoverTrigger,
  Tag,
  useDisclosure,
  Popover,
  PopoverBody,
  HStack,
  VStack,
  Text,
  Center,
} from '@chakra-ui/react';
import { Store, useGetStores } from 'api/stores';
import { noop } from 'lodash';
import { FC, useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

type Props = {
  selectedStores: string[];
  onSelect: (selectedStores: string[]) => void;
};

const getStoreKey = (store: Store) => `${store.platform}:${store.id}`;

export const StoreSelectPopover: FC<Props> = (props) => {
  const { onSelect = noop, selectedStores: initialStores = [] } = props;
  const [selectedStores, setSelectedStores] = useState<string[]>(initialStores);
  const { isOpen } = useDisclosure();

  const stores = useGetStores();

  const onSelectStore = (storeKey: string, checked: boolean) => {
    let selected = [...selectedStores];
    if (checked) selected.push(storeKey);
    else selected = [...selected.filter((s) => s !== storeKey)];
    setSelectedStores([...selected]);
    onSelect(selected);
  };

  const onClearSelected = () => {
    setSelectedStores([]);
    onSelect([]);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" variant="outline" rightIcon={isOpen ? <FaCaretUp /> : <FaCaretDown />}>
          {selectedStores.length > 0 && (
            <Tag size="sm" mr={1}>
              {selectedStores.length}
            </Tag>
          )}
          Store
        </Button>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverBody p={5}>
          <VStack align="start">
            {stores?.data?.map((s) => (
              <HStack key={s.id}>
                <Checkbox
                  value={getStoreKey(s)}
                  isChecked={selectedStores.includes(getStoreKey(s))}
                  onChange={(event) => onSelectStore(getStoreKey(s), event.target.checked)}
                />
                <Text>{s.name}</Text>
              </HStack>
            ))}
          </VStack>
          <Center mt={3}>
            <Button isDisabled={!selectedStores.length} variant="outline" size="sm" onClick={onClearSelected}>
              Clear
            </Button>
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
