import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useCreateBatchOrders } from 'api/batch/create-orders';
import { GetOrdersResponse } from 'api/orders/get';
import isNumber from 'lodash/isNumber';
import max from 'lodash/max';
import min from 'lodash/min';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import { FC } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useNavigate } from 'router';
import { toUSD } from 'utils/currency';
import { parseCarrier } from 'utils/misc';

type Order = ArrayElement<GetOrdersResponse['results']>;

export interface Props {
  onBuy: () => void;
  isOpen: boolean;
  onClose: () => void;
  orders: Order[];
}

export const BuyBatchModal: FC<Props> = (props) => {
  const { isOpen, onClose, orders, onBuy } = props;

  const createBatch = useCreateBatchOrders();

  const navigate = useNavigate();

  const zones = orders.map((order) => order.uspsZone).filter(isNumber);
  const maxWeight = max(orders.map((o) => o.parcel?.weight || o.totalWeightOz || 0));
  const minWeight = min(orders.map((o) => o.parcel?.weight || o.totalWeightOz || 0));
  const platforms = uniq(orders.map((o) => startCase(o.platform)));

  const carriers: string[] = [];
  let totalCostCents = 0;
  let totalCostInsuranceCents = 0;

  orders.forEach((order) => {
    const rate = order?.rates?.find((r) => r.id === order.selectedRate);
    if (!rate) return;

    carriers.push(parseCarrier(rate?.carrier || ''));
    totalCostCents += rate?.price || 0;
    totalCostInsuranceCents += order.insuranceCents ? order.insuranceCents / 100 : 0;
  });

  const weightMarkup = maxWeight === minWeight ? `${maxWeight} oz` : `${minWeight} - ${maxWeight} oz`;

  const onSubmit = () => {
    const orderIds = orders.map((o) => o.id);

    createBatch.mutate(
      { orderIds },
      {
        onError: onClose,
        onSuccess: (data) => {
          if (data.batch.id) {
            navigate('/orders/batches/:id', { params: { id: `${data.batch.id}` } });
          }
          onBuy();
          onClose();
        },
      },
    );
  };

  const batchConfirmMarkup = (
    <>
      <ModalHeader>Buy {orders.length} labels</ModalHeader>
      <ModalBody>
        <Text color="muted">
          You are buying {orders.length} labels. Purchasing labels will fulfill the order on their respective
          platforms.
        </Text>
        <Stack spacing="2" my="4">
          <Text fontWeight="medium">Overview</Text>
          <Stack divider={<hr />} rounded="md" border="1px" borderColor="gray.300">
            <Flex justify="space-between" p="3">
              <Text fontWeight="medium">Zones</Text>
              <Text color="muted">{uniq(zones).join(', ')}</Text>
            </Flex>
            <Flex justify="space-between" p="3">
              <Text fontWeight="medium">Weight</Text>
              <Text color="muted">{weightMarkup}</Text>
            </Flex>
            <Flex justify="space-between" p="3">
              <Text fontWeight="medium">Platform</Text>
              <Text color="muted">{platforms.join(', ')}</Text>
            </Flex>
            <Flex justify="space-between" p="3">
              <Text fontWeight="medium">Carrier</Text>
              <Text color="muted">{uniq(carriers).join(', ')}</Text>
            </Flex>
          </Stack>
        </Stack>
        <Stack spacing="2" my="4">
          <Text fontWeight="medium">Summary</Text>
          <Stack divider={<hr />} rounded="md" border="1px" borderColor="gray.300" bg="gray.50">
            <Flex justify="space-between" p="3">
              <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                Postage <FiInfo title="Price of all labels combined" />
              </Text>
              <Text>{toUSD(totalCostCents)}</Text>
            </Flex>
            <Flex justify="space-between" p="3">
              <Text fontWeight="medium" display="flex" gap="1" alignItems="center">
                Insurance <FiInfo title="Price of all shipment insurances combined" />
              </Text>
              <Text>{toUSD(totalCostInsuranceCents)}</Text>
            </Flex>
            <Flex justify="space-between" p="3">
              <Text fontWeight="bold" display="flex" gap="1" alignItems="center">
                Total
              </Text>
              <Text>{toUSD(totalCostCents + totalCostInsuranceCents)}</Text>
            </Flex>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button disabled={createBatch.isLoading} mr={2} onClick={onClose} type="button">
          Close
        </Button>
        <Button isLoading={createBatch.isLoading} colorScheme="brand" onClick={onSubmit} type="button">
          Purchase
        </Button>
      </ModalFooter>
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={createBatch.reset} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {batchConfirmMarkup}
      </ModalContent>
    </Modal>
  );
};
