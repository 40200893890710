import { useEffect } from 'react';
import { Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { PageHeader } from 'components/Page';
import { useSearchParams } from 'react-router-dom';
import Payment from './_components/payment';
import { PaymentLogs } from './_components/payment-logs';
import { Invoices } from './_components/invoices';
import { AddStripePaymentMethod } from './_components/add-stripe-payment-method';
import { GET_PAYMENT_METHODS_QUERY } from '../../../api/payments';
import { queryClient } from '../../../config/query-client';
import { GET_ME_QUERY } from '../../../api/auth';
import { useUser } from '../../../contexts/auth-context';

export default function () {
  const [searchParams] = useSearchParams();
  const setupIntentId = searchParams.get('setup_intent');

  const user = useUser();
  const isInvoicesVisible = user?.billingType === 'invoicing';

  useEffect(() => {
    const timer = setTimeout(() => {
      if (setupIntentId !== null) {
        queryClient.invalidateQueries(GET_PAYMENT_METHODS_QUERY);
        queryClient.invalidateQueries(GET_ME_QUERY);
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <HStack justify="space-between">
        <PageHeader>
          <h1>Billing</h1>
        </PageHeader>
        <AddStripePaymentMethod />
      </HStack>
      <Tabs colorScheme="brand" isLazy>
        <TabList>
          <Tab>Balance and Payment Methods</Tab>
          <Tab>Payment Logs</Tab>
          {isInvoicesVisible && <Tab>Invoices</Tab>}
        </TabList>
        <TabPanels p="0" m="0">
          <TabPanel px="0" pt="6">
            <Payment />
          </TabPanel>
          <TabPanel px="0" pt="6">
            <PaymentLogs />
          </TabPanel>
          <TabPanel px="0" pt="6">
            <Invoices />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
