import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

import type { OriginAddress } from 'types/address.d';
import axios from 'config/axios';

type Update = {
  id: string;
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  postal: string;
  state: string;
  primary?: boolean;
  company: string | null;
  nickname: string | null;
};
export type UpdateResponse = { address: OriginAddress };
export type LoginError = AxiosError<{ message: string }>;

export const update = async ({ id, ...vars }: Update) => {
  const { data } = await axios.put(`/v3/origin-addresses/${id}`, vars);
  return data;
};

const useUpdateAddress = (opts?: UseMutationOptions<UpdateResponse, LoginError, Update>) =>
  useMutation<UpdateResponse, LoginError, Update>(update, opts);

export default useUpdateAddress;
