import {
  Button,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Divider,
  Icon,
  Stack,
  StackDivider,
  HStack,
} from '@chakra-ui/react';
import { useNotification } from 'contexts/notification.context';
import { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { SiEtsy } from 'react-icons/si';
import { useConnectEtsyStore } from 'api/stores/etsy/connect';

const validationSchema = z.object({
  name: z.string().nonempty('Required'),
  storeUrl: z.string().nonempty('Required'),
});

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
      {children}
    </Stack>
  );
};

type FormValues = z.infer<typeof validationSchema>;

const ConnectEtsyForm: FC<{}> = () => {
  const connectEtsyStore = useConnectEtsyStore();
  const { notifyError } = useNotification();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: '', storeUrl: '' },
    resolver: zodResolver(validationSchema),
  });

  const { formState, register } = methods;

  const onSubmit = async (values: FormValues) => {
    const { name, storeUrl } = values;
    connectEtsyStore.mutate(
      { name, url: storeUrl },
      {
        onSuccess: (result) => {
          if (result?.url) window.location.replace(result.url);
          else notifyError('Error connecting store. Please try again later.');
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <>
        <HStack>
          <Flex justify="center" alignItems="center" bg="zinc.200" rounded="full" boxSize={14}>
            <Icon as={SiEtsy} boxSize={8} />
          </Flex>
          <Stack spacing={0}>
            <Text fontSize="lg" fontWeight="semibold">
              Etsy Store
            </Text>
            <Text color="muted">Connect Vesyl to your Etsy store to import your orders.</Text>
          </Stack>
        </HStack>

        <Divider my={6} />

        <chakra.form onSubmit={methods.handleSubmit((e) => onSubmit(e))}>
          <Stack spacing="3.5" divider={<StackDivider />}>
            <FormControl isRequired isInvalid={!!formState.errors?.name}>
              <Section>
                <FormLabel variant="inline">Name</FormLabel>
                <Input {...register('name')} placeholder="e.g. My Etsy Store" />
              </Section>
            </FormControl>
            <FormControl isRequired isInvalid={!!formState.errors?.storeUrl}>
              <Section>
                <FormLabel variant="inline">URL</FormLabel>
                <Input {...register('storeUrl')} placeholder="e.g. https://store.etsy.com" />
              </Section>
            </FormControl>
          </Stack>
          <Flex justify="end" pt="6">
            <Button type="submit" colorScheme="brand" isLoading={connectEtsyStore.isLoading}>
              Connect
            </Button>
          </Flex>
        </chakra.form>
      </>
    </FormProvider>
  );
};

export default ConnectEtsyForm;
