import { Box, Flex, HStack, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/layout';
import {
  Card,
  CardBody,
  CardHeader,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useGetAnalytics } from 'api/reports/get-analytics';
import { CarrierLogo } from 'components/CarrierLogo';
import { EmptyState } from 'components/EmptyState';
import { DateFilter } from 'components/date-filter';
import { Stat } from 'components/stat';
import _, { get, max, maxBy, noop, startCase } from 'lodash';
import { useState } from 'react';
import { LuSearch } from 'react-icons/lu';
import { toUSD } from 'utils/currency';
import { dayjs } from 'utils/dates';
import { parseCarrier } from 'utils/misc';

const zones = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const trackingStatuses = [
  'unknown',
  'preTransit',
  'inTransit',
  'outForDelivery',
  'delivered',
  'availableForPickup',
  'returnToSender',
  'failure',
];

export const Analytics = () => {
  const initialFromDate = dayjs().subtract(3, 'month').toDate();
  const initialToDate = dayjs().toDate();

  const [fromDate, setFromDate] = useState<Date | null>(initialFromDate);
  const [toDate, setToDate] = useState<Date | null>(initialToDate);

  const analytics = useGetAnalytics(
    { startDate: dayjs(fromDate).format('YYYY-MM-DD'), endDate: dayjs(toDate).format('YYYY-MM-DD') },
    { enabled: !!fromDate && !!toDate },
  );

  const carriers = Object.keys(analytics.data || {}).map((carrier) => ({
    carrier,
    formatted: parseCarrier(carrier),
  }));

  const isEmptyStateMarkup = !carriers.length && analytics.isFetched;

  const emptyStateMarkup = (
    <EmptyState header="No data" icon={LuSearch} h="sm">
      <Text color="muted" maxW="sm">
        No data available for the selected date range. Try selecting a different period.
      </Text>
    </EmptyState>
  );

  return (
    <>
      <Tabs variant="solid-rounded" colorScheme="brand" isLazy>
        <HStack w="full">
          {!isEmptyStateMarkup && (
            <TabList>
              <HStack width="max-content">
                {carriers.map(({ carrier, formatted }) => (
                  <Tab key={carrier} ml="1">
                    <CarrierLogo mr="2" carrier={carrier} />
                    {formatted}
                  </Tab>
                ))}
              </HStack>
            </TabList>
          )}
          <VStack w="full">
            <Box alignSelf="start">
              <DateFilter
                fromDate={fromDate || undefined}
                toDate={toDate || undefined}
                inputProps={{ placeholder: 'Select date...', ml: 'auto' }}
                onStartDateChange={(d) => (d ? setFromDate(d) : noop())}
                onEndDateChange={(d) => (d ? setToDate(d) : noop())}
              />
            </Box>
            {isEmptyStateMarkup && emptyStateMarkup}
          </VStack>
        </HStack>

        <TabPanels>
          {carriers.map(({ carrier }) => {
            const serviceData = analytics.data?.[carrier] || {};
            const services = _(serviceData || {})
              .entries()
              .orderBy(([, data]) => data.shipmentsCount, 'desc')
              .map(([service]) => service)
              .value();

            return (
              <TabPanel key={carrier}>
                {services.map((service) => {
                  const data = serviceData[service];

                  if (!data) return null;

                  const shipmentsCount = data.shipmentsCount || 0;
                  const totalSpent = data.rateCents / 100 || 0;
                  const averageSpent = totalSpent / shipmentsCount || 0;

                  return (
                    <Stack gap="2" mb="6" key={service}>
                      <Text fontSize="lg" fontWeight="semibold">
                        {startCase(service)}
                      </Text>

                      <SimpleGrid columns={3} gap="2">
                        <Stat label="Total ($)" value={toUSD(totalSpent)} />
                        <Stat label="Shipments" value={shipmentsCount} />
                        <Stat label="Avg. Rate ($)" value={toUSD(averageSpent)} />
                      </SimpleGrid>

                      <SimpleGrid columns={2} gap="2">
                        <Card>
                          <CardHeader pt={4} pb={0}>
                            Tracking Status
                          </CardHeader>

                          <CardBody>
                            <Stack gap="3.4">
                              {trackingStatuses.map((status) => {
                                const maxValue = maxBy(Object.values(data.trackingStatusCounts)) || 0;
                                const count = get(data.trackingStatusCounts, status, 0);
                                return (
                                  <Box key={status}>
                                    <Text fontWeight="medium">{startCase(status)}</Text>
                                    <HStack>
                                      <Text color="zinc.400">{count}</Text>
                                      <Progress
                                        value={count}
                                        max={maxValue}
                                        size="sm"
                                        w="full"
                                        bg="transparent"
                                      />
                                    </HStack>
                                  </Box>
                                );
                              })}
                            </Stack>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader pt={4} pb={0}>
                            Time In Transit (days)
                          </CardHeader>
                          <CardBody>
                            {data.timeInTransit.shipmentsCount === 0 && (
                              <Flex h="full" alignItems="center" justifyContent="center">
                                <Text>Time In Transit data not available</Text>
                              </Flex>
                            )}

                            {data.timeInTransit.shipmentsCount > 0 &&
                              zones.map((zone) => {
                                const shipmentCount = get(data.timeInTransit.zonedShipmentsCounts, zone, 0);
                                const totalDays = get(data.timeInTransit.zonedTotalDays, zone, 0);
                                const count = totalDays ? shipmentCount / totalDays : 0;

                                const transits = zones.map((z) => {
                                  const s = get(data.timeInTransit.zonedShipmentsCounts, z, 0);
                                  const d = get(data.timeInTransit.zonedTotalDays, z, 0);
                                  const c = d ? Math.round(s / d) : 0;
                                  return c;
                                });

                                const maxValue = max(transits) || 1;

                                return (
                                  <Box key={zone}>
                                    <Text fontWeight="medium">Zone {zone}</Text>
                                    <HStack>
                                      <Text color="zinc.400">
                                        {count === 0 ? 'No shipments' : count.toFixed(2)}
                                      </Text>
                                      <Progress
                                        hidden={count === 0}
                                        value={count}
                                        max={maxValue}
                                        size="sm"
                                        w="full"
                                        bg="transparent"
                                      />
                                    </HStack>
                                  </Box>
                                );
                              })}
                          </CardBody>
                        </Card>
                      </SimpleGrid>
                    </Stack>
                  );
                })}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </>
  );
};
