import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useConnectOAuthStore } from 'api/stores/oauth';
import { useNotification } from 'contexts/notification.context';
import { FC, PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SiShopify } from 'react-icons/si';
import { StorePlatform } from 'types/enums';
import { z } from 'zod';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '1.5', md: '8' }} justify="space-between">
      {children}
    </Stack>
  );
};

const validationSchema = z.object({
  name: z.string().nonempty('Required'),
  storeUrl: z.string().nonempty('Required'),
});

type FormValues = z.infer<typeof validationSchema>;

const ConnectShopifyForm: FC<{}> = () => {
  const connectStore = useConnectOAuthStore();
  const { notifyError } = useNotification();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: '', storeUrl: '' },
    resolver: zodResolver(validationSchema),
  });

  const { formState, register } = methods;

  const onSubmit = async (values: FormValues) => {
    const { name, storeUrl } = values;
    connectStore.mutate(
      { name, url: storeUrl, platform: StorePlatform.Shopify },
      {
        onSuccess: (result) => {
          if (result?.url) window.location.replace(result.url);
          else notifyError('Error connecting store. Please try again later.');
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <>
        <Stack>
          <Flex justify="center" alignItems="center" bg="zinc.200" rounded="full" boxSize={14}>
            <Icon as={SiShopify} boxSize={8} />
          </Flex>
          <Stack spacing={0}>
            <Text fontSize="lg" fontWeight="semibold">
              Shopify Store
            </Text>
            <Text color="muted">Connect Vesyl to your Shopify store to import your orders.</Text>
          </Stack>
        </Stack>

        <Divider my={6} />

        <chakra.form onSubmit={methods.handleSubmit((e) => onSubmit(e))}>
          <Stack spacing="3.5" divider={<StackDivider />}>
            <FormControl isRequired isInvalid={!!formState.errors?.name}>
              <Section>
                <FormLabel variant="inline">Name</FormLabel>
                <Input {...register('name')} placeholder="e.g. My Shopify Store" />
              </Section>
            </FormControl>
            <FormControl isRequired isInvalid={!!formState.errors?.storeUrl}>
              <Section>
                <FormLabel variant="inline">URL</FormLabel>
                <Input {...register('storeUrl')} placeholder="e.g. https://store.myshopify.com" />
              </Section>
            </FormControl>
          </Stack>
          <Flex justify="end" pt="6">
            <Button type="submit" colorScheme="brand" isLoading={connectStore.isLoading}>
              Connect
            </Button>
          </Flex>
        </chakra.form>
      </>
    </FormProvider>
  );
};

export default ConnectShopifyForm;
