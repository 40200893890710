import { Button, HStack, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useRunAutomations } from 'api/automation';
import { PageBody, PageHeader } from 'components/Page';
import { useNotification } from 'contexts/notification.context';
import { useDisclosure } from 'hooks/use-disclosure';
import { useState } from 'react';
import { BiCog } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { AutomationForm } from './_components/automation-form';
import AutomationsList from './_components/automation-list';
import { AutomationLogs } from './_components/automation-logs';

export default function () {
  const editAutomationModal = useDisclosure();
  const { notifySuccess } = useNotification();

  const runAutomations = useRunAutomations({ onSuccess: () => notifySuccess('Automations have been run') });

  const [selectedAutomationId, setSelectedAutomationId] = useState<number | null>(null);

  return (
    <>
      <PageHeader>
        <HStack>
          <Heading as="h1">Automations</Heading>
        </HStack>
        <HStack ml="auto">
          <Button
            ml="auto"
            variant="outline"
            leftIcon={<BiCog />}
            isLoading={runAutomations.isLoading}
            onClick={() => {
              runAutomations.mutate({});
            }}
          >
            Run Automations
          </Button>
          <Button
            ml="auto"
            colorScheme="brand"
            leftIcon={<FiPlus />}
            onClick={() => {
              setSelectedAutomationId(null);
              editAutomationModal.onOpen();
            }}
          >
            Create
          </Button>
        </HStack>
      </PageHeader>
      <PageBody>
        <Tabs colorScheme="brand" isLazy>
          <TabList>
            <Tab>My Automations</Tab>
            <Tab>Log</Tab>
          </TabList>

          <TabPanels>
            <TabPanel px="0">
              <AutomationsList />
            </TabPanel>
            <TabPanel px="0">
              <AutomationLogs />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageBody>

      {editAutomationModal.isOpen && (
        <AutomationForm
          automation={selectedAutomationId ? { id: selectedAutomationId } : null}
          {...editAutomationModal}
        />
      )}
    </>
  );
}
