import { Box, Card, CardBody, Divider, HStack, SimpleGrid, Stack, StackProps, Text } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import { startCase } from 'lodash';
import { RefObject, forwardRef, useEffect, useState } from 'react';
import { OrderStatus } from 'types/enums';

const statuses: OrderStatus[] = [
  OrderStatus.ReadyToShip,
  OrderStatus.Open,
  OrderStatus.UnverifiedAddress,
  OrderStatus.OnHold,
];

type Props = {
  data?: { name: string; count: number }[];
  selected?: 'views' | 'status';
  onStatusSelect?: () => void;
};

export const StatusBar = forwardRef<AgGridReact, Props>((props, ref) => {
  const { data = [], onStatusSelect, selected } = props;

  const gridRef = ref as RefObject<AgGridReact>;
  const gridApi = gridRef.current?.api;

  const [selectedStatus, setSelectedStatus] = useState<OrderStatus | null>(OrderStatus.ReadyToShip);

  useEffect(() => {
    if (!selected) return;
    if (selected === 'views') setSelectedStatus(null);
  }, [selected]);

  const getStatusStyles = (status: string): StackProps => {
    if (selectedStatus !== status) return {};
    if (status === OrderStatus.ReadyToShip) return { backgroundColor: 'green.50' };
    if (status === OrderStatus.Open) return { backgroundColor: 'yellow.50' };
    if (status === OrderStatus.UnverifiedAddress) return { backgroundColor: 'red.50' };
    if (status === OrderStatus.OnHold) return { backgroundColor: 'slate.100' };
    return { backgroundColor: 'white' };
  };

  const getIconStyle = (status: OrderStatus): StackProps => {
    if (selectedStatus !== status) return {};
    if (status === OrderStatus.ReadyToShip) return { bg: 'green.500' };
    if (status === OrderStatus.Open) return { bg: 'yellow.500' };
    if (status === OrderStatus.UnverifiedAddress) return { bg: 'red.400' };
    if (status === OrderStatus.OnHold) return { bg: 'slate.500' };
    return { bg: 'transparent' };
  };

  return (
    <Card>
      <CardBody padding={0}>
        <SimpleGrid columns={statuses.length}>
          {statuses.map((status, index) => (
            <Stack
              as="button"
              key={index}
              px={4}
              pt={2}
              borderLeft={index > 0 ? '1px solid' : undefined}
              borderColor={'zinc.200'}
              borderLeftRadius={index === 0 ? 'base' : 0}
              borderRightRadius={index === statuses.length - 1 ? 'base' : 0}
              {...getStatusStyles(status)}
              onClick={() => {
                setSelectedStatus(status);
                onStatusSelect?.();
                gridApi?.setFilterModel({ status: { values: [status] } });
                gridRef.current?.api?.onFilterChanged();
                gridRef.current?.api?.deselectAll();
              }}
            >
              <HStack spacing={1.5}>
                <Text
                  fontWeight={selectedStatus === status ? 'bold' : 'normal'}
                  fontSize={'sm'}
                  color={selectedStatus === status ? 'zinc.900' : 'zinc.500'}
                >
                  {startCase(status)}
                </Text>
                <Box {...getIconStyle(status)} w="2" h="2" rounded="full" />
              </HStack>
              <Text fontSize={'lg'} fontWeight="semibold">
                {data?.find((x) => x.name === status)?.count || 0}
              </Text>
              <Divider orientation="vertical" />
            </Stack>
          ))}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
});
