import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { SortingState } from '@tanstack/react-table';
import { GET_PRODUCTS_QUERY, GetProductsResponse, useGetProducts } from 'api/products/get';
import { useGetStores } from 'api/stores';
import { PageBody, PageHeader } from 'components/Page';
import { DataTable } from 'components/data-table';
import { queryClient } from 'config/query-client';
import useDebounce from 'hooks/use-debounce';
import debounce from 'lodash/debounce';
import { columns } from 'pages/(app)/products/_components/helpers';
import { useEffect, useMemo, useState } from 'react';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { isProductEditingDisabled } from 'utils/misc';
import { BulkEditDrawer } from './_components/bulk-edit-drawer';
import { ProductContext } from './_components/product-context';
import { SelectPopover } from './_components/select-popover';

type Product = ArrayElement<GetProductsResponse['results']>;

export default function () {
  const getStores = useGetStores('custom-store');
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const handleSearch = debounce((searchInput) => setSearchQuery(searchInput), 250);
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const bulkEditDrawer = useDisclosure();

  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const selectableProducts = selectedProducts.filter((product) => !isProductEditingDisabled(product?.platforms)) || [];

  const [pagination, setPagination] = useState<any>({ per: 10, page: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);

  const getProducts = useGetProducts({
    q: debouncedSearchQuery,
    store: selectedStores.join(','),
    platform: selectedPlatforms.join(','),
    tags: selectedTags.join(','),
    page: pagination.page,
    per: pagination.per,
    sort: sorting[0]?.id as any,
    direction: sorting[0]?.desc === false ? 'asc' : 'desc',
  });

  const [products, setProducts] = useState<Product[]>([]);
  const [productsCount, setProductsCount] = useState<number>(0);

  const productColumns = useMemo(() => columns, [columns]);
  const isLoading = getStores.isLoading || getProducts.isLoading;

  const handleBulkEdit = async (): Promise<void> => {
    await queryClient.invalidateQueries(GET_PRODUCTS_QUERY);
    await getProducts.refetch();
  };

  const bulkEditButtonMarkup =
    selectableProducts.length > 0 ? (
      <Button size="xs" variant="outline" onClick={bulkEditDrawer.onOpen}>
        Edit {selectableProducts.length} labels
      </Button>
    ) : null;

  useEffect(() => {
    if (!getProducts.isSuccess) return;
    if (getProducts.data?.results) setProducts(getProducts.data?.results);
    if (getProducts.data?.total) setProductsCount(getProducts.data?.total);
  }, [getProducts.data]);

  return (
    <ProductContext.Provider value={{ getProducts }}>
      <PageHeader>
        <HStack>
          <Heading as="h1">Products</Heading>
        </HStack>
        <Flex alignItems="center" ml="auto">
          <Button type="button" colorScheme="brand" leftIcon={<FiPlus />} onClick={() => navigate('/products/new')}>
            Add Product
          </Button>
        </Flex>
      </PageHeader>
      <PageBody>
        <Card>
          <CardBody p="0">
            <HStack py="4" px="4">
              <Flex gap="2">{bulkEditButtonMarkup}</Flex>
              <Spacer />
              <Flex gap="2">
                <Box>
                  <InputGroup size="sm">
                    <InputLeftAddon>
                      <FiSearch />
                    </InputLeftAddon>
                    <Input type="text" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                  </InputGroup>
                </Box>
                {getStores.data && getStores.data.length > 0 && (
                  <Flex gap="2">
                    <SelectPopover
                      selectTitle="Stores"
                      selectedValues={getStores.data.map((z) => z.name ?? '')}
                      onSelect={setSelectedStores}
                    />
                    <SelectPopover
                      selectTitle="Platforms"
                      selectedValues={getStores.data.map((z) => z.platform ?? '')}
                      onSelect={setSelectedPlatforms}
                    />
                    <SelectPopover
                      selectTitle="Tags"
                      selectedValues={
                        [
                          ...new Set(
                            getProducts.data?.results?.flatMap((result) => result?.tags.map((tag) => tag?.name)),
                          ),
                        ].filter((tag) => tag) as string[]
                      }
                      onSelect={setSelectedTags}
                    />
                  </Flex>
                )}
              </Flex>
            </HStack>
            <DataTable
              isSelectable
              id="products"
              isLoading={isLoading}
              columns={productColumns}
              data={products}
              rowCount={productsCount}
              onRowSelect={setSelectedProducts}
              pagination={pagination}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              isRowDisabled={{
                hasCondition: true,
                field: 'platforms',
                callback: isProductEditingDisabled,
              }}
            />
          </CardBody>
        </Card>
      </PageBody>
      {bulkEditDrawer.isOpen && (
        <BulkEditDrawer
          isOpen={bulkEditDrawer.isOpen}
          onClose={bulkEditDrawer.onClose}
          products={selectableProducts}
          handleBulkEdit={handleBulkEdit}
        />
      )}
    </ProductContext.Provider>
  );
}
