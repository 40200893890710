import { Spinner } from '@chakra-ui/spinner';
import { useGetQuery } from 'api/client';
import { Navigate } from 'react-router';

export default function () {
  const locations = useGetQuery('/api/v3/locations', {});
  if (!locations.data) return <Spinner />;

  const [location] = locations.data;
  if (!location) return <p>No locations found</p>;

  return <Navigate to={`/inventories/locations/${location.id}`} replace />;
}
