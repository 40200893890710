import { ColorModeScript } from '@chakra-ui/system';
import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import { theme } from 'theme';
import { Routes } from '@generouted/react-router';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://76454239fbd641b8939293725d7f206d@o446404.ingest.sentry.io/4505165965623296',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Replay({ maskAllText: false, blockAllMedia: true }),
    ],
    environment: import.meta.env.VITE_ENV,
    ignoreErrors: [
      // When an authorization error happens (401), ongoings requests are aborted.
      'Request aborted',
      // When something is wrong with the user's connection
      'Network Error',
      // https://stackoverflow.com/a/50387233/3496534
      'ResizeObserver loop completed with undelivered notifications',
    ],
  });
}

createRoot(document.getElementById('root')!).render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <Routes />
  </>,
);
