import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetOrder } from 'api/orders';
import { PageBody, PageHeader } from 'components/Page';
import { StoreLogo } from 'components/store-logo';
import { startCase } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiSettings } from 'react-icons/fi';
import { useLocation } from 'react-router';
import { useQuickShipStore } from 'store';
import { z } from 'zod';
import { CustomsDrawer } from './_components/CustomsDrawer';
import { FromAddress, fromAddressSchema } from './_components/FromAddress';
import { Options, optionsSchema } from './_components/Options';
import { defaultParcel, Package, parcelSchema } from './_components/Package';
import { Rates } from './_components/Rates';
import { ReturnAddress, returnAddressSchema } from './_components/ReturnAddress';
import { customsFormSchema } from './_components/schemas';
import { SettingsDrawer } from './_components/SettingsDrawer';
import { ToAddress, toAddressSchema } from './_components/ToAddress';
import { usePostMutation } from 'api/client';

const schema = z
  .object({})
  .merge(fromAddressSchema)
  .merge(toAddressSchema)
  .merge(parcelSchema)
  .merge(optionsSchema)
  .merge(returnAddressSchema)
  .merge(customsFormSchema);

export type FormValues = z.infer<typeof schema>;

const QuickShip = () => {
  const { showReturnAddress } = useQuickShipStore();

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    shouldUnregister: false,
    resolver: zodResolver(schema),
    defaultValues: {
      options: {
        labelDate: new Date(),
        isIrregularPackage: false,
        saturdayDelivery: false,
        carbonNeutral: false,
        isMediaMail: false,
        hazmat: undefined,
        insuranceAmount: undefined,
        deliveryConfirmation: '',
        payment: {
          type: 'SENDER',
          account: undefined,
          postalCode: undefined,
          country: undefined,
        },
      },
      to: { country: 'US' },
      customs: undefined,
      // All shipments start with one parcel
      parcels: [defaultParcel],
    },
  });

  const settingsDrawer = useDisclosure();
  const customsDrawer = useDisclosure();

  const location = useLocation();
  const orderId = location.state?.order?.id as number | undefined;
  const shipment = location.state?.shipment as Record<string, any> | null;

  const order = useGetOrder({ id: orderId || -1 }, { enabled: !!orderId });

  useEffect(() => {
    if (!order?.data?.shippingAddress) return;

    const { line1, line2, city, state, company, postal, phone, name, email, country } =
      order?.data?.shippingAddress;
    const { totalWeightOz, customsInfo, options, insuranceCents, reference } = order.data || {};

    const isInternational = order?.data?.shippingAddress?.country !== 'US';

    if (company) methods.setValue('to.company', company);
    if (name) methods.setValue('to.name', name);
    if (line1) methods.setValue('to.line1', line1);
    if (line2) methods.setValue('to.line2', line2);
    if (city) methods.setValue('to.city', city);
    if (state) methods.setValue('to.state', state);
    if (postal) methods.setValue('to.postal', postal);
    if (phone) methods.setValue('to.phone', phone);
    if (email) methods.setValue('to.email', email);
    if (country) methods.setValue('to.country', country);
    if (reference) methods.setValue('options.reference', reference);
    if (totalWeightOz) methods.setValue('parcels.0.weightOz', totalWeightOz);
    if (insuranceCents) methods.setValue('options.insuranceAmount', insuranceCents);
    if (customsInfo && customsInfo.customsItems && isInternational) {
      if (customsInfo?.contentsType) methods.setValue('customs.contentsType', customsInfo.contentsType);
      if (customsInfo?.nonDeliveryOption)
        methods.setValue('customs.nonDeliveryOption', customsInfo.nonDeliveryOption);
      if (Array.isArray(customsInfo?.customsItems)) {
        for (const [index, item] of customsInfo?.customsItems?.entries()) {
          methods.setValue(`customs.items.${index}.description`, item.description);
          methods.setValue(`customs.items.${index}.quantity`, item.quantity);
          methods.setValue(`customs.items.${index}.value`, item.value);
          methods.setValue(`customs.items.${index}.weight`, item.weight);
          methods.setValue(`customs.items.${index}.hsTariffNumber`, item.hsTariffNumber);
          methods.setValue(`customs.items.${index}.originCountry`, item.originCountry!);
        }
      }
    }
    if (options) {
      if (options.poNumber) methods.setValue('to.printCustom1', options.poNumber);
      if (options.deliveryConfirmation)
        methods.setValue('options.deliveryConfirmation', options.deliveryConfirmation);
      if (options.specialRatesEligibility === 'USPS.MEDIAMAIL') methods.setValue('options.isMediaMail', true);
      if (options.payment) {
        const type = String(options.payment.type).toUpperCase() as 'SENDER' | 'RECEIVER' | 'THIRD_PARTY';
        methods.setValue('options.payment.type', type);
        methods.setValue('options.payment.account', options.payment?.account);
        methods.setValue('options.payment.postalCode', options.payment?.postalCode);
        methods.setValue('options.payment.country', options.payment?.country);
      }
    }
  }, [order.data]);

  useEffect(() => {
    if (!shipment) return;

    const { line1, line2, city, state, company, postal, phone, name, email, country, printCustom1 } =
      shipment?.to || {};
    const { weight, height, width, length, predefinedPackage } = shipment?.parcel || {};
    const {
      deliveryConfirmation,
      hazmat,
      machinable,
      specialRatesEligibility,
      saturdayDelivery,
      carbonNeutral,
      isReturn,
      additionalHandling,
    } = shipment?.options || {};
    const { insuranceCents } = shipment || {};
    const from = shipment.from || {};

    if (company) methods.setValue('to.company', company);
    if (name) methods.setValue('to.name', name);
    if (line1) methods.setValue('to.line1', line1);
    if (line2) methods.setValue('to.line2', line2);
    if (city) methods.setValue('to.city', city);
    if (state) methods.setValue('to.state', state);
    if (postal) methods.setValue('to.postal', postal);
    if (phone) methods.setValue('to.phone', phone);
    if (email) methods.setValue('to.email', email);
    if (country) methods.setValue('to.country', country);
    if (printCustom1) methods.setValue('to.printCustom1', printCustom1);

    if (deliveryConfirmation) methods.setValue('options.deliveryConfirmation', deliveryConfirmation);
    if (hazmat) methods.setValue('options.hazmat', hazmat);
    if (machinable === false) methods.setValue('options.isIrregularPackage', true);

    if (saturdayDelivery) methods.setValue('options.saturdayDelivery', true);
    if (carbonNeutral) methods.setValue('options.carbonNeutral', true);
    if (isReturn) methods.setValue('options.isReturn', true);
    if (additionalHandling) methods.setValue('options.additionalHandling', true);

    if (specialRatesEligibility === 'USPS.MEDIAMAIL') methods.setValue('options.isMediaMail', true);
    if (insuranceCents) methods.setValue('options.insuranceAmount', insuranceCents / 100);

    if (weight) methods.setValue('parcels.0.weightOz', weight);
    if (height) methods.setValue('parcels.0.height', height);
    if (width) methods.setValue('parcels.0.width', width);
    if (length) methods.setValue('parcels.0.length', length);
    if (predefinedPackage) methods.setValue('parcels.0.predefinedPackage', predefinedPackage);

    if (from.company) methods.setValue('from.company', from.company);
    if (from.name) methods.setValue('from.name', from.name);
    if (from.line1) methods.setValue('from.line1', from.line1);
    if (from.line2) methods.setValue('from.line2', from.line2);
    if (from.city) methods.setValue('from.city', from.city);
    if (from.state) methods.setValue('from.state', from.state);
    if (from.postal) methods.setValue('from.postal', from.postal);
    if (from.phone) methods.setValue('from.phone', from.phone);
  }, [shipment]);

  const createShipment = usePostMutation('/api/v4/shipments');

  const onSubmit = (values: FormValues) => {
    const payment =
      values.options?.payment?.type !== 'SENDER'
        ? {
            payment: {
              type: values.options?.payment?.type,
              account: values.options?.payment?.account,
              postalCode: values.options?.payment?.postalCode,
              country: values.options?.payment?.country,
            },
          }
        : {};

    createShipment.mutate(
      {
        body: {
          orderId,
          to: values.to,
          from: values.from,
          return: values.return,
          reference: values.options?.reference,
          options: {
            hazmat: values.options.hazmat as any,
            insuranceCents: (values.options.insuranceAmount || 0) * 100,
            deliveryConfirmation: (values.options.deliveryConfirmation as any) || null,
            specialRatesEligibility: values.options.isMediaMail ? 'USPS.MEDIAMAIL' : null,
            machinable: values.options.isIrregularPackage === true ? false : undefined,
            saturdayDelivery: values.options.saturdayDelivery,
            carbonNeutral: values.options.carbonNeutral,
            labelDate: values.options.labelDate?.toISOString(),
            printCustom1: values.to.printCustom1,
            isReturn: values.options.isReturn,
            additionalHandling: values.options.additionalHandling,
            labelFormat: (values.options?.labelFormat as 'PNG' | 'ZPL') || undefined,
            ...(payment as any),
          },
          parcels:
            values.parcels?.map((p) => ({
              width: p.width || null,
              height: p.height || null,
              length: p.length || null,
              weight: Number(p.weightOz || 0) + Number(p.weightLbs || 0) * 16,
              boxId: p.boxId || null,
              predefinedPackage: p.predefinedPackage || null,
            })) || [],
        },
      },
      { onSuccess: () => {} },
    );
  };

  return (
    <>
      <PageHeader>
        <HStack gap="0">
          <Heading as="h1">Quick Ship</Heading>
        </HStack>
        <Button variant="outline" ml="auto" onClick={settingsDrawer.onOpen}>
          <FiSettings />
        </Button>
      </PageHeader>
      <PageBody>
        <Card>
          <CardBody>
            <Box minH="lg">
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit, (errors) => {
                    // If there has been an error in the Customs form,
                    // opens the drawer to give the user a chance to fix it.
                    if (errors.customs) customsDrawer.onOpen();
                  })}
                  noValidate
                >
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: 4, xl: 6 }}>
                    <Stack spacing="4">
                      <FromAddress />
                      {showReturnAddress && <ReturnAddress />}
                      <ToAddress />
                    </Stack>
                    <Stack spacing="4">
                      <Package />
                      <Options />
                    </Stack>
                    <Rates createShipment={createShipment} />
                  </SimpleGrid>
                </form>

                {customsDrawer.isOpen && (
                  <CustomsDrawer parcels={createShipment.data?.parcels || []} {...customsDrawer} />
                )}
              </FormProvider>
            </Box>
            {order?.data?.platform && (
              <HStack color="muted" pt={8}>
                <StoreLogo size={20} platform={order?.data?.platform} />
                <Text fontSize="xs">
                  This label is linked to a <b>{startCase(order?.data?.platform)}</b> order.{' '}
                  {!!order?.data?.totals?.shippingCents && (
                    <>
                      Total spent on shipping was{' '}
                      <b>${(order.data?.totals.shippingCents / 100).toFixed(2)}</b>.{' '}
                    </>
                  )}
                  {order?.data?.shippingMethod && (
                    <>
                      The selected shipping method was <b>{order?.data?.shippingMethod}</b>.
                    </>
                  )}
                </Text>
              </HStack>
            )}
          </CardBody>
        </Card>
      </PageBody>
      <SettingsDrawer {...settingsDrawer} />
    </>
  );
};

export default QuickShip;
