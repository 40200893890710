import d, { Dayjs } from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as isYesterday from 'dayjs/plugin/isYesterday';

d.extend(utc.default);
d.extend(relativeTime.default);
d.extend(isYesterday.default);

export const dayjs = d;

export const timeSince = (date: Date | string) => {
  return d(date).fromNow();
};

export const formatDate = (date: Date | string | Dayjs, formatString?: string) => {
  return d(date).format(formatString || 'YYYY-MM-DD');
};

export const formatUtcDate = (date?: Date | string) => {
  const utcString = date ? d.utc(date).format('YYYY-MM-DD') : d.utc().format('YYYY-MM-DD');
  return d(utcString).toDate();
};

export const utcDate = (date?: Date | string) => {
  const utcString = date ? d.utc(date) : d.utc();
  return d(utcString).toDate();
};

export const subDays = (date: Date | string, number: number) => {
  return d(date).subtract(number, 'days').toDate();
};

export const subWeeks = (date: Date | string, number: number) => {
  return d(date).subtract(number, 'weeks').toDate();
};

export const subMonths = (date: Date | string, number: number) => {
  return d(date).subtract(number, 'months').toDate();
};

export const addDays = (date: Date | string, number: number) => {
  return d(date).add(number, 'days').toDate();
};

export const addWeeks = (date: Date | string, number: number) => {
  return d(date).add(number, 'weeks').toDate();
};

export const addMonths = (date: Date | string, number: number) => {
  return d(date).add(number, 'months').toDate();
};

export const isoString = (date: Date | string) => {
  return d(date).toISOString();
};

export const startOf = (date: Date | string, unit: any) => {
  return d(date).startOf(unit).toDate();
};

export const endOf = (date: Date | string, unit: any) => {
  return d(date).endOf(unit).toDate();
};

export const diff = (d1: Date | string, d2: Date | string, unit: any) => {
  return d(d1).diff(d2, unit);
};

export const isDateYesterday = (date: Date | string) => {
  return d(date).isYesterday();
};

export const isSame = (d1: Date | string, d2: Date | string, unit: any) => {
  return d(d1).isSame(d2, unit);
};
