import { Box, Divider, Input } from '@chakra-ui/react';
import { Combobox } from '@headlessui/react';
import { ALL_COUNTRIES as allCountries } from 'constants/countries';
import { noop } from 'lodash';
import { forwardRef, useState } from 'react';
import { ComboboxOption, ComboboxOptions } from './util';

interface CountrySelectProps {
  onChange?: (value: any) => void;
  value: any;
  isDisabled?: boolean;
}

export const CountrySelect = forwardRef((props: CountrySelectProps, ref: any) => {
  const { onChange = noop, value, isDisabled = false } = props;

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Combobox
      as="div"
      disabled={isDisabled}
      value={value ? allCountries.find((o) => o.value === value) : ''}
      onChange={(v: { value: string; label: string }) => onChange(v.value)}
    >
      <Combobox.Input
        ref={ref}
        as={Input}
        placeholder=""
        onChange={(e) => setSearchQuery(e.target.value)}
        displayValue={(country: any) => (country ? country.label : '')}
      />
      <Combobox.Options as={ComboboxOptions} style={{ listStyle: 'none' }}>
        {allCountries
          .filter(
            (c) =>
              c.label.toLowerCase().includes((searchQuery || '').toLowerCase()) ||
              c.value.toLowerCase().includes((searchQuery || '').toLowerCase()),
          )
          .map((country, index) => (
            <Box key={country.value}>
              <Combobox.Option value={country}>
                {({ selected, active }) => (
                  <ComboboxOption selected={selected || active}>
                    <span>{country.label}</span>
                  </ComboboxOption>
                )}
              </Combobox.Option>
              {index === 3 && !searchQuery ? <Divider /> : null}
            </Box>
          ))}
      </Combobox.Options>
    </Combobox>
  );
});
