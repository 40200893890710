import { useMutation, UseMutationOptions } from 'react-query';
import axios from 'config/axios';
import { AxiosError } from 'axios';
import { PostAuthTokenBody, PostAuthTokenResponse } from 'types/api-types';

export type LoginResponse = PostAuthTokenResponse;

export const login = async (vars: PostAuthTokenBody) => {
  const result = await axios.post<PostAuthTokenResponse>('/v3/auth_token', vars);
  return result?.data;
};

export const useCreateAuthToken = (
  opts?: UseMutationOptions<PostAuthTokenResponse, AxiosError<{ message: string }>, PostAuthTokenBody>,
) => useMutation<PostAuthTokenResponse, AxiosError<{ message: string }>, PostAuthTokenBody>(login, opts);

export default useCreateAuthToken;
