import { StyleConfig } from '@chakra-ui/react';

const sizes = {
  xs: {
    rounded: 'full',
  },
  sm: {
    rounded: 'full',
  },
  md: {
    fontSize: 'sm',
    rounded: 'full',
    h: 9,
    fontWeight: 'medium',
  },
};

export const buttonTheme: StyleConfig = {
  sizes,
  baseStyle: {
    color: 'black !important',
  },
  variants: {
    solid: {},
  },
  defaultProps: {},
};
