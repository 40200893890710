import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { GET_PRODUCTS_QUERY, GetProductsResponse } from 'api/products/get';
import { Variables as PutProductBody, useUpdateProduct } from 'api/products/update';
import { queryClient } from 'config/query-client';
import { useNotification } from 'contexts/notification.context';
import { FC, useEffect } from 'react';
import { Controller, FieldErrorsImpl, FormProvider, useForm, useWatch } from 'react-hook-form';
import { ozToLbsOz } from 'utils/misc';
import { z } from 'zod';
import { TagInput } from 'components/tag-input';
import { CountrySelect } from 'pages/(app)/ship/_components/CountrySelect';

type Product = ArrayElement<GetProductsResponse['results']>;

export interface Props {
  product: Product;
  isOpen: boolean;
  onClose: () => void;

  /**
   * Fields to validate when the drawer is opened.
   * This is useful if you want to direct the user to
   * update a certain missing field (for ex.: missing weight)
   */
  validateFields?: Array<keyof FormValues>;
}

const invalid_type_error = 'Must be a number';

const schema = z
  .object({
    name: z.string().nonempty(),
    sku: z.string().nonempty(),
    description: z.string().nullable(),
    price: z.coerce.number({ invalid_type_error }),
    weightOz: z.coerce.number({ invalid_type_error }).min(0).max(1900),
    weightLbs: z.coerce.number({ invalid_type_error }).min(0).max(120),
    length: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    width: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    height: z.coerce.number({ invalid_type_error }).min(0).max(120).nullable(),
    location: z.string().nullable(),
    hsTariffCode: z.string().nullable(),
    tags: z.array(z.string()).nullable(),
    originCountry: z.string().nullable(),
  })
  .refine((data) => data.weightLbs > 0 || data.weightOz > 0, {
    path: ['weight'],
  })
  .refine(
    (data) =>
      [data.length, data.width, data.height].every((d) => d && Number(d) > 0) ||
      [data.length, data.width, data.height].every((d) => d === 0),
    { path: ['dimensions'], message: 'Required' },
  );

type FormValues = z.infer<typeof schema>;

export const EditOrderDrawer: FC<Props> = (props) => {
  const { product, isOpen, onClose, validateFields } = props;

  const { notifySuccess } = useNotification();
  const updateProduct = useUpdateProduct();

  if (!product) {
    return null;
  }

  const weightLbsOz = ozToLbsOz(product?.weightOz || 0);

  const formattedInitialPrice = product?.priceCents ? product?.priceCents / 100 : 0;

  const form = useForm<FormValues>({
    shouldUnregister: false,
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      name: product.name,
      sku: product.sku || undefined,
      description: product?.description,
      price: formattedInitialPrice,
      weightOz: weightLbsOz.ounces ?? 0,
      weightLbs: weightLbsOz.lbs ?? 0,
      length: product?.lengthIn ?? 0,
      width: product?.widthIn ?? 0,
      height: product?.heightIn ?? 0,
      location: product?.location || undefined,
      hsTariffCode: product?.hsTariffCode || undefined,
      tags: product?.tags?.map((tag) => tag?.name) || [],
      originCountry: product?.originCountry || undefined,
    },
  });

  const { register, formState, setValue, getValues, control } = form;

  const errors = formState.errors as typeof formState.errors &
    FieldErrorsImpl<{
      weight?: { message: string };
      dimensions?: { message: string };
    }>;

  const heightIn = useWatch({ control, name: 'height' });
  const lengthIn = useWatch({ control, name: 'length' });
  const widthIn = useWatch({ control, name: 'width' });

  // Check if any of the dimensions have a value (not NaN or '') and if so, require all dimensions
  const hasDimension = [heightIn, lengthIn, widthIn].some(
    (dim) => Number(dim) !== 0 && !Number.isNaN(Number(dim)),
  );

  const onSubmit = async (values: FormValues) => {
    if (!product?.id) return;

    const {
      name,
      sku,
      description,
      weightOz,
      weightLbs,
      length,
      width,
      height,
      location,
      tags,
      originCountry,
      hsTariffCode,
    } = values;

    const weight = Number(weightLbs ?? 0) * 16 + Number(weightOz);
    const dimensions = [length, width, height].every((d) => d && d > 0)
      ? { length, width, height }
      : { length: null, width: null, height: null };

    const priceInCents = values.price ? Number(values.price) * 100 : 0;

    const payload: PutProductBody = {
      productId: String(product.id),
      name,
      sku,
      description,
      weightOz: weight,
      lengthIn: dimensions.length,
      widthIn: dimensions.width,
      heightIn: dimensions.height,
      priceCents: priceInCents,
      location,
      hsTariffCode,
      tags: tags?.map((tag: string) => tag.trim()) || [],
      originCountry,
    };

    const onSuccess = () => {
      queryClient.invalidateQueries([GET_PRODUCTS_QUERY]);
      onClose();
      notifySuccess('Product saved successfully');
    };

    updateProduct.mutate(payload, { onSuccess });
  };

  useEffect(() => {
    if (!validateFields?.length) return;
    validateFields.forEach((field) => form.trigger(field));
  }, [validateFields]);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="sm">
      <chakra.form onSubmit={form.handleSubmit(onSubmit)} h="full" fontSize="sm">
        <FormProvider {...form}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <HStack>
                <Box>Edit Product</Box>
              </HStack>
            </DrawerHeader>
            <DrawerBody p="0">
              <Flex flexDir="column" justify="space-between" h="full">
                <Accordion defaultIndex={[0, 2, 3]} allowMultiple>
                  <AccordionItem>
                    <AccordionButton>
                      <Text fontWeight="medium" fontSize="md" py="1" flex="1" textAlign="left">
                        Product Details
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel py="4">
                      <Stack>
                        <FormControl
                          isInvalid={!!errors.name}
                          isDisabled={updateProduct.isLoading}
                          isRequired
                        >
                          <Flex alignItems="center" justifyContent="space-between">
                            <FormLabel>Name </FormLabel>
                          </Flex>
                          <Stack direction="row" spacing="2">
                            <Input {...register('name', { required: true })} />
                            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                          </Stack>
                        </FormControl>

                        <FormControl isInvalid={!!errors.sku} isDisabled={updateProduct.isLoading} isRequired>
                          <FormLabel>SKU</FormLabel>
                          <Stack direction="row" spacing="2">
                            <InputGroup>
                              <Input {...register('sku', { required: true })} />
                            </InputGroup>
                            <FormErrorMessage>{errors.sku?.message}</FormErrorMessage>
                          </Stack>
                        </FormControl>

                        <FormLabel>Description</FormLabel>
                        <Stack direction="row" spacing="2">
                          <FormControl isInvalid={!!errors.description} isDisabled={updateProduct.isLoading}>
                            <InputGroup>
                              <Input {...register('description')} />
                            </InputGroup>
                            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <FormControl>
                          <FormLabel>Origin Country</FormLabel>
                          <Controller
                            name="originCountry"
                            control={control}
                            render={({ field: { ref, value, onChange } }) => (
                              <CountrySelect value={value} ref={ref} onChange={onChange} />
                            )}
                          />
                        </FormControl>

                        <FormLabel>HS tariff</FormLabel>
                        <Stack direction="row" spacing="2">
                          <FormControl isInvalid={!!errors.hsTariffCode} isDisabled={updateProduct.isLoading}>
                            <InputGroup>
                              <Input {...register('hsTariffCode')} />
                            </InputGroup>
                            <FormErrorMessage>{errors.hsTariffCode?.message}</FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <FormLabel>Price</FormLabel>
                        <Stack direction="row" spacing="2">
                          <FormControl isInvalid={!!errors.price} isDisabled={updateProduct.isLoading}>
                            <InputGroup>
                              <InputLeftElement>$</InputLeftElement>
                              <Input {...register('price')} />
                              <InputRightElement color="muted" mr={2}>
                                USD
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <Stack>
                          <FormLabel>Dimensions </FormLabel>

                          <HStack>
                            <InputGroup>
                              <FormControl
                                isInvalid={
                                  (!!errors?.dimensions || hasDimension) &&
                                  (!getValues('length') || Number(getValues('length')) === 0)
                                }
                              >
                                <Input {...register('length')} />
                              </FormControl>
                            </InputGroup>
                            <InputGroup>
                              <FormControl
                                isInvalid={
                                  (!!errors?.dimensions || hasDimension) &&
                                  hasDimension &&
                                  (!getValues('width') || Number(getValues('width')) === 0)
                                }
                              >
                                <Input {...register('width')} />
                              </FormControl>
                            </InputGroup>
                            <InputGroup>
                              <FormControl
                                isInvalid={
                                  (!!errors?.dimensions || hasDimension) &&
                                  hasDimension &&
                                  (!getValues('height') || Number(getValues('height')) === 0)
                                }
                              >
                                <Input {...register('height')} />
                              </FormControl>
                            </InputGroup>
                          </HStack>

                          <FormLabel>Weight</FormLabel>
                          <Stack direction="row" spacing="2">
                            <FormControl
                              isInvalid={!!errors.weightLbs || !!errors.weight}
                              isDisabled={updateProduct.isLoading}
                            >
                              <InputGroup>
                                <Input {...register('weightLbs')} />
                                <InputRightElement>lbs</InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>{errors.weightLbs?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={!!errors.weightOz || !!errors.weight}
                              isDisabled={updateProduct.isLoading}
                            >
                              <InputGroup>
                                <Input {...register('weightOz')} />
                                <InputRightElement>oz</InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>{errors.weightOz?.message}</FormErrorMessage>
                            </FormControl>
                          </Stack>

                          <FormControl
                            hidden
                            isInvalid={!!errors.location}
                            isDisabled={updateProduct.isLoading}
                          >
                            <FormLabel>Location</FormLabel>
                            <InputGroup>
                              <Input {...register('location')} />
                            </InputGroup>
                            <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
                          </FormControl>
                        </Stack>
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionButton>
                      <Text fontWeight="medium" fontSize="md" py="1" flex="1" textAlign="left">
                        Tags
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel py="4">
                      <Stack direction="row" spacing="2" h="300px">
                        <FormControl isInvalid={!!errors.tags} isDisabled={updateProduct.isLoading}>
                          <FormLabel>Select Tags</FormLabel>
                          <Controller
                            name="tags"
                            control={form.control}
                            render={({ field: { ref } }) => (
                              <TagInput
                                ref={ref}
                                value={getValues('tags')?.map((tag) => ({ value: tag, label: tag })) || []}
                                onChange={(data) =>
                                  setValue(
                                    'tags',
                                    data.map((d) => d.value),
                                  )
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                <Flex p="4" justify="end" gap="2">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={onClose}
                    isDisabled={updateProduct.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button colorScheme="brand" type="submit" isLoading={updateProduct.isLoading}>
                    Save
                  </Button>
                </Flex>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </FormProvider>
      </chakra.form>
    </Drawer>
  );
};
