import { useCallback, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

const isProduction = import.meta.env.PROD;

export const useDisclosure = (isOpenDefault = false) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  const intercom = useIntercom();

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen((state) => !state), []);

  // Hides the Intercom launcher based on the `isOpen` state.
  // This action is performed here because this hook is mostly used
  // on overlay components (modals, drawer, etc.)
  useEffect(() => {
    // `react-use-intercom` displays a warning if you run this in production
    if (!isProduction) return;

    intercom.update({ hideDefaultLauncher: isOpen });
  }, [isOpen]);

  return { isOpen, onOpen, onClose, onToggle };
};
