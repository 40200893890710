import { Card, CardBody, HStack, Select, Spacer } from '@chakra-ui/react';
import { SortingState } from '@tanstack/react-table';
import { useGetPaymentLogs } from 'api/payments';
import { DataTable } from 'components/data-table';
import { DateFilter } from 'components/date-filter';
import { startCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { PaymentLog } from 'types/payment';
import { formatDate } from 'utils/dates';
import { columns as paymentLogsColumns } from './helpers';

const types = ['purchase', 'refund', 'adjustment', 'credit_card', 'bank_account', 'platform_fee', 'manual'];

export const PaymentLogs = () => {
  const [pagination, setPagination] = useState<any>({ per: 10, page: 0 });
  const [, setSorting] = useState<SortingState>([]);

  // Filters
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [type, setType] = useState<string | null>(null);

  const paymentLogs = useGetPaymentLogs({
    page: pagination?.page,
    per: pagination?.per,
    fromDate: fromDate ? formatDate(fromDate) : undefined,
    toDate: toDate ? formatDate(toDate) : undefined,
    type: type || undefined,
  });

  const [data, setData] = useState<PaymentLog[]>([]);
  const [total, setTotal] = useState<number>(0);
  const columns = useMemo(() => paymentLogsColumns, []);

  useEffect(() => {
    if (!paymentLogs.isSuccess) return;
    if (paymentLogs.data?.results) setData(paymentLogs.data?.results);
    if (paymentLogs.data?.total) setTotal(paymentLogs.data?.total);
  }, [paymentLogs.data]);

  return (
    <>
      <Card>
        <CardBody p="0">
          <HStack p="4">
            <DateFilter
              inputProps={{ placeholder: 'Select date...' }}
              onStartDateChange={setFromDate}
              onEndDateChange={setToDate}
            />
            <Select w="60" onChange={(e) => setType(e.target.value)}>
              <option value="">All</option>
              {types.map((t) => (
                <option key={t} value={t}>
                  {startCase(t)}
                </option>
              ))}
            </Select>
            <Spacer />
          </HStack>
          <DataTable
            id="payment-logs"
            isLoading={paymentLogs.isLoading}
            columns={columns}
            rowCount={total}
            pagination={pagination}
            data={data}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            isSelectable={false}
          />
        </CardBody>
      </Card>
    </>
  );
};
