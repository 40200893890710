import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';

export type GetLogsResponse = Array<{
  id: number;
  type: 'order' | 'automation';
  createdAt: string;

  order?: Record<string, any> | null;
  automation?: Record<string, any> | null;
}>;

export type GetLogsInput = {
  type?: 'order' | 'automation';
};

export const getLogs = async (input: GetLogsInput) => {
  const result = await axios.get(`/v3/logs`, { params: input });
  return result?.data;
};

export const GET_LOGS_QUERY = 'getLogs';

export const useGetLogs = (input: GetLogsInput, opts?: UseQueryOptions<any>) =>
  useQuery<GetLogsResponse>([GET_LOGS_QUERY], () => getLogs(input), opts ?? {});
