import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

import type { OriginAddress } from 'types/address.d';
import axios from 'config/axios';

export type CreateAddress = {
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  postal: string;
  state: string;
  phone: string;
  primary: boolean;
  company: string | null;
  nickname: string | null;
};
export type CreateAddressResponse = { address: OriginAddress };
export type CreateAddressError = AxiosError<{ errors: { msg: string; param: string }[] }>;

export const create = async ({ ...vars }: CreateAddress) => {
  const { data } = await axios.post(`/v3/origin-addresses/`, vars);
  return data;
};

const useCreateAddress = (opts?: UseMutationOptions<CreateAddressResponse, CreateAddressError, CreateAddress>) =>
  useMutation<CreateAddressResponse, CreateAddressError, CreateAddress>(create, opts);

export default useCreateAddress;
