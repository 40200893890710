import { Center, Flex, Link } from '@chakra-ui/react';
import { HiDocumentDownload } from 'react-icons/hi';
import { dayjs, formatDate } from 'utils/dates';
import { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { startCase } from 'lodash';
import { GetManifestsResponse } from 'api/manifests';
import { GetReportsResponse } from 'api/reports';
import { CarrierLogo } from 'components/CarrierLogo';

export type Report = ArrayElement<GetReportsResponse['results']>;

export const reportTypes = [
  'shipment',
  'payment_log',
  'shipment_invoice',
  'tracking',
  'payment_summary',
  'refund',
  'balance_snapshot',
];

export const reportsColumns = () =>
  [
    {
      headerName: 'Date',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['inRange'],
        suppressAndOrCondition: true,
      },
      valueGetter: (params: ValueGetterParams) => {
        return dayjs.utc(params.data.createdAt).format('YYYY-MM-DD');
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        return <>{value}</>;
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: reportTypes,
        valueFormatter: (params: ValueFormatterParams) => {
          return startCase(params.value);
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return startCase(params.value);
      },
    },
    {
      headerName: 'From',
      field: 'startDate',
      valueFormatter: (params: ValueFormatterParams) => {
        return formatDate(params.value, 'MM/DD/YYYY');
      },
    },
    {
      headerName: 'To',
      field: 'endDate',
      valueFormatter: (params: ValueFormatterParams) => {
        return formatDate(params.value, 'MM/DD/YYYY');
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      valueFormatter: (params: ValueFormatterParams) => {
        return startCase(params.value);
      },
    },
    {
      headerName: 'Download',
      field: 'reportUrl',
      cellRenderer: (params: ICellRendererParams) => {
        const url = params.value;
        if (!url) return null;
        return (
          <Link alignSelf="center" href={url} target="_blank" rel="noopener noreferrer">
            <HiDocumentDownload size={22} />
          </Link>
        );
      },
    },
  ] as ColDef<Report>[];

type Manifest = ArrayElement<GetManifestsResponse['results']>;

export const manifestsColumns = () =>
  [
    {
      headerName: 'ID',
      field: 'id',
    },
    {
      headerName: 'Carrier',
      field: 'carrier',
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;
        return (
          <Flex alignItems="center" h="full" w="full">
            <CarrierLogo carrier={value} />
          </Flex>
        );
      },
    },
    {
      headerName: 'Number of shipments',
      field: 'numOfShipments',
      valueFormatter: (params: ValueFormatterParams) => {
        return params?.data?.trackingCodes?.length;
      },
    },
    {
      headerName: 'Download',
      field: 'formUrl',
      cellRenderer: (params: ICellRendererParams) => {
        const url = params.value;
        if (!url) return null;
        return (
          <Center>
            <Link href={url} target="_blank" rel="noopener noreferrer">
              <HiDocumentDownload size={22} />
            </Link>
          </Center>
        );
      },
    },
    {
      headerName: 'Created',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['inRange'],
        suppressAndOrCondition: true,
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return formatDate(params.value, 'MM/DD/YYYY');
      },
    },
  ] as ColDef<Manifest>[];
